import React from 'react'
import { connect } from 'react-redux'
import { View } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'

const RichText = ({ id, headerText = '', bodyText = '', mode = 'medium', theme, typography }) => {
  const { headerFontFamily, bodyFontFamily } = typography
  let headerFontSize = 22
  let bodyFontSize = 14
  switch (mode) {
    case 'large':
      headerFontSize = 28
      bodyFontSize = 16
      break
    case 'small':
      headerFontSize = 20
      bodyFontSize = 12
      break
    default:
      break
  }
  return (
    <View nativeID={id} style={{ ...styles.container }}>
      <View
        style={styles.innerContainer}
      >
        <Text
          type='custom'
          bold
          customType={headerFontFamily}
          style={{ flex: 1, textAlign: 'center', marginBottom: 10 }}
          fontSize={headerFontSize}
          color={theme.headerColor}
        >
          {headerText}
        </Text>
        <Text
          type='custom'
          customType={bodyFontFamily}
          style={{ flex: 1, textAlign: 'center' }}
          fontSize={bodyFontSize}
          color={theme.bodyColor}
        >
          {bodyText}
        </Text>
      </View>
    </View>
  )
}

const styles = {
  container: { padding: 10, paddingBottom: 5, paddingTop: 5 },
  innerContainer: {
    padding: 5,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.richText', {}),
    typography: _.get(state, 'config.theme.typography.richText', {})
  }
}

export default connect(mapStateToProps)(RichText)
