import React from 'react'
import { connect } from 'react-redux'
import { Text } from 'react-native'
import { fontSizes } from '@utils/theme'
import { scale } from '@utils/scale'
import _ from 'lodash'

const TextWrapper = ({
  bold,
  light,
  type,
  fontSize,
  lineHeight,
  color,
  children,
  style,
  colors,
  typography,
  secondary,
  capitalizeLetters,
  customType,
  ...otherProps
}) => {
  const selectFontStyle = () => {
    const defaultStyles = {
      color: color,
      fontSize: fontSize ? scale(fontSize) : scale(fontSizes.default),
      lineHeight: lineHeight ? scale(lineHeight) : null
    }

    let appendFamilyWeight = 'regular'

    if (bold) {
      appendFamilyWeight = 'bold'
    }

    if (secondary) {
      defaultStyles.opacity = 0.6
    }

    switch (type) {
      case 'heading':
        return {
          ...defaultStyles,
          color: colors.page.headingText,
          fontFamily: `${_.get(typography, 'navigationAndButtons.fontFamily')}-${appendFamilyWeight}`,
          textTransform: _.get(typography, 'navigationAndButtons.capitalizeLetters') ? 'uppercase' : 'none'
        }
      case 'button':
        return {
          ...defaultStyles,
          color: colors.page.buttonTextColor,
          fontFamily: `${_.get(typography, 'navigationAndButtons.fontFamily')}-${appendFamilyWeight}`,
          textTransform: _.get(typography, 'navigationAndButtons.capitalizeLetters') ? 'uppercase' : 'none'
        }
      case 'secondary':
        return { ...defaultStyles, color: colors.text.secondary }
      case 'custom':
        return {
          ...defaultStyles,
          backgroundColor: otherProps.backgroundColor || 'transparent',
          fontFamily: `${customType || _.get(typography, 'contentText.fontFamily')}-${appendFamilyWeight}`,
          textTransform: capitalizeLetters ? 'uppercase' : 'none'
        }
      default:
        return {
          ...defaultStyles,
          color: defaultStyles.color || colors.page.contentText,
          fontFamily: `${_.get(typography, 'contentText.fontFamily')}-${appendFamilyWeight}`
        }
    }
  }

  const _style = {
    ...selectFontStyle(),
    ...style
  }

  return (
    <Text style={_style} {...otherProps}>
      {children}
    </Text>
  )
}

const mapStateToProps = state => {
  return {
    colors: _.get(state, 'config.theme.colors'),
    typography: _.get(state, 'config.theme.typography')
  }
}

export default connect(mapStateToProps)(TextWrapper)
