import React, { useEffect } from 'react'
import { Animated, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { width as _width } from '@utils/scale'

function brightnessByColor (color) {
  const isHEX = color.indexOf('#') === 0
  const isRGB = color.indexOf('rgb') === 0
  let r, g, b
  if (isHEX) {
    const hasFullSpec = color.length === 7
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16)
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16)
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16)
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g)
    if (m) {
      r = m[0]
      g = m[1]
      b = m[2]
    }
  }
  if (typeof r !== 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000
  }
  return null
}

function lightenDarkenColor (col, amt) {
  var usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

const ShimmerItem = ({
  delay = 0,
  duration = 1100,
  width = _width,
  height = 200,
  backgroundColor = '#333333',
  widthShimmer = 1.5
}) => {
  const colorRatio = brightnessByColor(backgroundColor) / 8
  const beginPosition = new Animated.Value(-1)
  const location = [0.4, 0.5, 0.6]
  let shimmerColor = lightenDarkenColor(backgroundColor, colorRatio > 8 ? colorRatio : 8)
  if (colorRatio > 22) {
    shimmerColor = lightenDarkenColor(backgroundColor, -colorRatio)
  }
  useEffect(() => {
    const anim = Animated.timing(beginPosition, {
      toValue: 1,
      delay: delay,
      duration: duration,
      useNativeDriver: true
    })
    const startAnimation = () => {
      beginPosition.setValue(-1)
      anim.start(() => {
        startAnimation()
      })
    }
    startAnimation()
    return () => {
      if (anim && anim.stop) {
        anim.stop()
      }
    }
  }, [])
  const outputRange = [-width, width]
  const linearTranslate = beginPosition.interpolate({
    inputRange: [-1, 0.5],
    outputRange: outputRange
  })

  const verticalTranslate = beginPosition.interpolate({
    inputRange: [-2, 5],
    outputRange: outputRange
  })

  return (
    <View
      style={{
        backgroundColor,
        width,
        height,
        overflow: 'hidden',
        margin: 5,
        marginLeft: 15,
        marginRight: 15
      }}
    >
      <Animated.View
        style={{
          height: height * 10,
          width,
          flex: 1,
          transform: [
            { translateX: linearTranslate },
            { translateY: verticalTranslate },
            { rotate: '18deg' }
          ]
        }}
      >
        <LinearGradient
          colors={[backgroundColor, shimmerColor, backgroundColor]}
          style={{ height: height * 10, width: width * widthShimmer }}
          start={{
            x: -1,
            y: 0.5
          }}
          end={{
            x: 2,
            y: 0.5
          }}
          locations={location}
        />
      </Animated.View>
    </View>
  )
}

export default ShimmerItem
