import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  View,
  TouchableOpacity
} from 'react-native'
import { Mutation } from 'react-apollo'
import Text from '@components/Text'
import Collapsible from 'react-native-collapsible'
import Input from '@components/Input'
import { customerUpdate } from '@gql/Mutations'
import _ from 'lodash'
import { width } from '@utils/scale'

const Profile = ({ customer: remoteCustomer, accessToken, dispatch, theme, navigation }) => {
  const [mode, setMode] = useState('normal')
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [phone, setPhone] = useState()
  const [customer, setCustomer] = useState(remoteCustomer)

  useEffect(() => {
    setCustomer(remoteCustomer)
  }, [remoteCustomer])

  const getDisplayInitials = () => {
    let displayInitials = ''
    if (customer && customer.displayName) {
      const tempInitials = customer.displayName.split(' ')
      displayInitials = tempInitials.reduce((prev, curr) => {
        return prev + curr.substring(0, 1)
      }, '')
    }
    return displayInitials
  }

  const updateCustomer = async mutation => {
    const updateObject = {
      firstName: firstName || customer.firstName,
      lastName: lastName || customer.lastName,
      phone: phone || customer.phone
    }

    console.log(updateObject)

    const response = await mutation({
      variables: { customerAccessToken: accessToken, customer: updateObject }
    })

    if (_.get(response, 'data.customerUpdate.customer')) {
      dispatch({
        type: 'SET_CUSTOMER',
        data: {
          customer: _.get(response, 'data.customerUpdate.customer')
        }
      })
      setMode('normal')
      setPhone(null)
      setFirstName(null)
      setLastName(null)
    }

    if (!_.isEmpty(_.get(response, 'data.customerUpdate.customerUserErrors'))) {
      const message = _.get(
        response,
        'data.customerUpdate.customerUserErrors'
      ).reduce((prev, curr) => {
        return prev + curr.message
      }, '')
      dispatch({
        type: 'ADD_TOAST',
        data: {
          message: {
            message: message,
            type: 'Error'
          }
        }
      })
    }
  }

  const renderForm = () => {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ marginTop: 10, padding: 10 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Input
              viewStyle={{ flex: 1, marginRight: 5, marginBottom: 2 }}
              label='First Name'
              labelFontSize={13}
              onComplete={e => setFirstName(e)}
              inputProps={{
                placeholder: 'First Name',
                defaultValue: _.get(customer, 'firstName')
              }}
            />
            <Input
              viewStyle={{ flex: 1, marginBottom: 2 }}
              label='Last Name'
              labelFontSize={13}
              onComplete={e => setLastName(e)}
              inputProps={{
                placeholder: 'Last Name',
                defaultValue: _.get(customer, 'lastName')
              }}
            />
          </View>
          <Input
            viewStyle={{ marginBottom: 2 }}
            label='Email'
            labelFontSize={13}
            inputProps={{
              editable: false,
              placeholder: 'Email Address',
              autoCapitalize: 'none',
              keyboardType: 'email-address',
              defaultValue: _.get(customer, 'email')
            }}
          />
          <Input
            type='mobile'
            viewStyle={{ marginBottom: 2 }}
            label='Phone'
            labelFontSize={13}
            onComplete={e => setPhone(e)}
            inputProps={{
              defaultValue: _.get(customer, 'phone'),
              placeholder: 'Phone',
              autoCapitalize: 'none',
              keyboardType: 'email-address'
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', padding: 10, paddingBottom: 0, flex: 1, justifyContent: 'center', alignItems: 'center', width: '90%' }}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setMode('normal')
              setPhone(null)
              setFirstName(null)
              setLastName(null)
            }}
          >
            <Text color={theme.textColor}>CANCEL</Text>
          </TouchableOpacity>
          <Mutation mutation={customerUpdate}>
            {(customerUpdate, { loading }) => {
              return (
                <TouchableOpacity
                  style={{
                    ...styles.button,
                    backgroundColor: theme.buttonColor,
                    flex: 1
                  }}
                  onPress={() => updateCustomer(customerUpdate)}
                >
                  {loading ? (
                    <ActivityIndicator color={theme.buttonTextColor} />
                  ) : (
                    <Text color={theme.buttonTextColor} style={{ fontWeight: '600', textAlign: 'center' }}>
                      UPDATE
                    </Text>
                  )}
                </TouchableOpacity>
              )
            }}
          </Mutation>
        </View>
      </View>
    )
  }

  if (_.isEmpty(customer)) {
    return (
      <View style={{ marginTop: 50 }}>
        <Text style={{ textAlign: 'center', color: theme.textColor }}>
            You are not logged in yet, go ahead
        </Text>
        <View style={{ height: 45, paddingHorizontal: 35, marginTop: 10 }}>
          <TouchableOpacity
            style={{ ...styles.button, backgroundColor: theme.buttonColor }}
            onPress={() => navigation.navigate('LoginSignUp')}
          >
            <Text color={theme.buttonTextColor} style={{ fontWeight: '600' }}>
                Login / Signup
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  return (
    <View style={{ alignItems: 'center', marginTop: 50 }}>
      <View
        style={{
          ...styles.profileBubble,
          backgroundColor: theme.buttonColor
        }}
      >
        <Text
          fontSize={25}
          color={theme.textColor}
          style={{ textTransform: 'uppercase' }}
        >
          {getDisplayInitials()}
        </Text>
      </View>
      <Collapsible collapsed={mode !== 'normal'}>
        <TouchableOpacity onPress={() => setMode('editing')}>
          <View style={{ alignItems: 'center', marginTop: 10 }}>
            <Text fontSize={18} style={{ fontWeight: '600', color: theme.textColor }}>
              {_.get(customer, 'firstName', '')}{' '}
              {_.get(customer, 'lastName', '')}
            </Text>
            <Text numberOfLines={1} textOverflow='ellipsis' fontSize={14} style={{ marginVertical: 3, color: theme.textColor }}>
              {_.get(customer, 'email')}
            </Text>
            <Text fontSize={14} style={{ color: theme.textColor }}>{_.get(customer, 'phone')}</Text>
          </View>
        </TouchableOpacity>
      </Collapsible>
      <Collapsible collapsed={mode !== 'editing'} style={styles.formGroup}>
        {mode === 'editing' && renderForm()}
      </Collapsible>
      <View style={styles.hr} />
    </View>
  )
}

const styles = {
  profileBubble: {
    width: 80,
    height: 80,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  formGroup: { flex: 1, width: (width / 3) * 2.5, padding: 10 },
  button: { flex: 1, alignItems: 'center', padding: 12, justifyContent: 'center' },
  hr: { height: 1, width: '80%', backgroundColor: '#ccc', marginVertical: 20 }
}

export default Profile
