import * as React from 'react'
import { useRoute, useNavigationState } from '@react-navigation/native'

export const navigationRef = React.createRef()

export function navigate (name, params) {
  return navigationRef.current?.navigate(name, params)
}

export function useIsFirstRouteInParent () {
  const route = useRoute()
  const isFirstRouteInParent = useNavigationState(
    state => state.routes[0].key === route.key
  )

  return isFirstRouteInParent
}
// add other navigation functions that you need and export them

export default {
  navigate,
  navigationRef,
  useIsFirstRouteInParent
}
