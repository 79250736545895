import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import * as SecureStore from 'expo-secure-store'
import uuid from 'uuid-random'

const baseUrl = 'https://www.google-analytics.com/collect?'
const baseParams = 'v=1&tid=UA-191458641-1'

const Analytics = ({ type = 'event', data = { category: 'Checkout', action: 'Checkout' } }) => {
  const [userId, setUserId] = useState()
  const store = useSelector(state => state.config)

  useEffect(() => {
    const getUserId = async () => {
      try {
        let uId = await SecureStore.getItemAsync('aid')
        if (!uId) {
          uId = uuid()
          await SecureStore.setItemAsync('aid', uId)
        }
        setUserId(uId)

        switch (type) {
          default:
            return createEvent()
        }
      } catch (e) {
        console.log('Analytics error', e)
      }
    }
    getUserId()
  }, [])

  const createEvent = () => {
    // v=1              // Version.
    // &tid=UA-XXXXX-Y  // Tracking ID / Property ID.
    // &cid=555         // Anonymous Client ID.

    // &t=event         // Event hit type
    // &ec=video        // Event Category. Required.
    // &ea=play         // Event Action. Required.
    // &el=holiday      // Event label.
    // &ev=300          // Event value.
    if (!data.category || !data.action) {
      console.log('Missing data.category or data.action', data)
    }
    const eventUrl = `t=event&ec=${data.category}&ea=${data.action}`
    callAnalytics(eventUrl)
  }

  const callAnalytics = async (url) => {
    try {
      const defaultParams = `cid=${userId}&cd1=${store._id}&an=${encodeURI(store.appName)}`
      const compiledUrl = `${baseUrl}${baseParams}&${url}&${defaultParams}`
      await fetch(compiledUrl, {
        method: 'POST',
        headers: {}
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <View style={{ width: 0, height: 0 }} />
  )
}

export default Analytics
