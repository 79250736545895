import React from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import {
  checkoutCreate,
  checkoutUpdate,
  checkoutAssociate
} from '@gql/Mutations'
import { Mutation, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import Text from '@components/Text'
import _ from 'lodash'

const AddToCartButton = ({
  checkout,
  disabled,
  theme,
  client,
  userAccessToken,
  dispatch,
  quantity,
  variant,
  lineItems,
  onAddToCart
}) => {
  const dispatchToast = (lineItemEdges = [], variantId) => {
    let _item = lineItemEdges.filter(
      n => _.get(n, 'node.variant.id') === variantId
    )
    _item = _.head(_item)
    if (_item && _item.node) {
      let processedTitle = _item.node.title
      if (_.get(_item, 'node.variant.title')) {
        if (_.get(_item, 'node.variant.title') !== 'Default Title') {
          processedTitle += `- ${_item.node.variant.title}`
        }
      }

      const mappedItem = {
        title: processedTitle,
        image: _.get(_item, 'node.variant.image.transformedSrc'),
        type: 'AddToCart'
      }

      dispatch({
        type: 'ADD_TOAST',
        data: {
          message: mappedItem
        }
      })
    }
  }

  const addToCart = async mutation => {
    let mappedVariants
    if (_.isArray(variant)) {
      console.warn('VARIANT MUST BE AN OBJECT NOT ARRAY')
      return
    } else {
      const _variant = {
        variantId: variant.id,
        quantity: quantity || 1
      }
      mappedVariants = [_variant]
    }

    if (_.get(checkout, 'id')) {
      const currentVariants = lineItems.map(item => {
        const cleanItem = {
          variantId: _.get(item, 'node.variant.id'),
          quantity: _.get(item, 'node.quantity')
        }
        return cleanItem
      })
      const newLineItems = _.unionBy(
        mappedVariants,
        currentVariants,
        'variantId'
      )
      const response = await mutation({
        variables: { checkoutId: checkout.id, lineItems: newLineItems }
      })

      if (response.data) {
        dispatch({
          type: 'UPSERT_CHECKOUT',
          data: {
            checkout: _.get(response, 'data.checkoutLineItemsReplace.checkout')
          }
        })

        if (
          _.isArray(
            _.get(
              response,
              'data.checkoutLineItemsReplace.checkout.lineItems.edges'
            )
          )
        ) {
          dispatchToast(
            _.get(
              response,
              'data.checkoutLineItemsReplace.checkout.lineItems.edges'
            ),
            variant.id
          )
        }

        if (_.isFunction(onAddToCart)) {
          onAddToCart()
        }
      }
    } else {
      const response = await mutation({
        variables: { lineItems: mappedVariants }
      })
      if (response.data) {
        dispatch({
          type: 'UPSERT_CHECKOUT',
          data: {
            checkout: _.get(response, 'data.checkoutCreate.checkout')
          }
        })
        if (
          _.isArray(
            _.get(response, 'data.checkoutCreate.checkout.lineItems.edges')
          )
        ) {
          dispatchToast(
            _.get(response, 'data.checkoutCreate.checkout.lineItems.edges'),
            variant.id
          )
        }
        if (_.isFunction(onAddToCart)) {
          onAddToCart()
        }
        const checkoutId = _.get(response, 'data.checkoutCreate.checkout.id')
        if (checkoutId && userAccessToken) {
          const res = await client.mutate({
            mutation: checkoutAssociate,
            variables: {
              checkoutId: checkoutId,
              customerAccessToken: userAccessToken
            }
          })
          dispatch({
            type: 'SET_LOCAL_CHECKOUT',
            data: {
              checkout: _.get(res, 'data.checkoutCustomerAssociateV2.checkout')
            }
          })
        }
      }
    }
  }

  const mutation = _.get(checkout, 'id') ? checkoutUpdate : checkoutCreate
  return (
    <Mutation mutation={mutation}>
      {(mutation, { loading }) => {
        return (
          <TouchableOpacity
            disabled={disabled}
            style={{
              ...styles.button,
              backgroundColor: theme.buttonColor,
              opacity: disabled ? 0.5 : 1
            }}
            onPress={() => addToCart(mutation)}
          >
            {loading ? (
              <ActivityIndicator color={theme.buttonTextColor} />
            ) : (
              <Text
                fontSize={16}
                type='button'
                bold
                style={{ ...styles.buttonText }}
              >
                {disabled ? 'Out of stock' : 'Add to cart'}
              </Text>
            )}
          </TouchableOpacity>
        )
      }}
    </Mutation>
  )
}

const styles = {
  button: { width: '100%', padding: 15, marginTop: 20 },
  buttonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white'
  }
}

const mapStateToProps = state => {
  return {
    lineItems: _.get(state, 'cart.lineItems', []),
    checkout: _.get(state, 'cart.checkout', {}),
    theme: _.get(state, 'config.theme.colors.page'),
    userAccessToken: _.get(state, 'user.accessToken')
  }
}

export default connect(mapStateToProps)(withApollo(AddToCartButton))
