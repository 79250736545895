import React from 'react'
import SkeletonBase from './SkeletonBase'

const xml = `
<svg width="1146px" height="2491px" viewBox="0 0 1146 2491" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="Group" transform="translate(-50.000000, -114.000000)">
<rect id="Rectangle" x="0" y="0" width="1242" height="2692" fill="none"></rect>
<path d="M54,340 L574,340 L574,861 L54,861 L54,340 Z M674,340 L1194,340 L1194,861 L674,861 L674,340 Z M674,1104 L1194,1104 L1194,1625 L674,1625 L674,1104 Z M50,1104 L570,1104 L570,1625 L50,1625 L50,1104 Z M50,1928 L570,1928 L570,2449 L50,2449 L50,1928 Z M669,1928 L1189,1928 L1189,2449 L669,2449 L669,1928 Z M54,114 L496,114 L496,188 L54,188 L54,114 Z M754,114 L1099,114 L1099,188 L754,188 L754,114 Z M1123,114 L1196,114 L1196,188 L1123,188 L1123,114 Z M121,889 L498,889 L498,932 L121,932 L121,889 Z M741,889 L1118,889 L1118,932 L741,932 L741,889 Z M741,1653 L1118,1653 L1118,1696 L741,1696 L741,1653 Z M117,1653 L494,1653 L494,1696 L117,1696 L117,1653 Z M117,2477 L494,2477 L494,2520 L117,2520 L117,2477 Z M736,2477 L1113,2477 L1113,2520 L736,2520 L736,2477 Z M155,976 L465,976 L465,1017 L155,1017 L155,976 Z M775,976 L1085,976 L1085,1017 L775,1017 L775,976 Z M775,1740 L1085,1740 L1085,1781 L775,1781 L775,1740 Z M151,1740 L461,1740 L461,1781 L151,1781 L151,1740 Z M151,2564 L461,2564 L461,2605 L151,2605 L151,2564 Z M770,2564 L1080,2564 L1080,2605 L770,2605 L770,2564 Z" id="Combined-Shape"></path>
</g>
</svg>
`
const CollectionProductsSkeleton = ({ theme, padded }) => {
  return (
    <SkeletonBase backgroundColor={theme.pageBackground} xml={xml} padded={padded} />
  )
}

export default CollectionProductsSkeleton
