import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import SectionHeader from '@components/SectionHeader'
import { width } from '@utils/scale'
import WebView from 'react-native-webview'
import _ from 'lodash'

const EmbeddedVideo = ({
  buttonText,
  description,
  handle,
  id,
  navigation,
  route,
  theme,
  title,
  videoUrl
}) => {
  const [url, setUrl] = useState()
  useEffect(() => {
    const parseVimeoUrl = () => {
      const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
      const match = videoUrl.match(regExp)
      return match && match[5] ? match[5] : false
    }

    const parseYoutubeUrl = () => {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = videoUrl.match(regExp)
      return match && match[7].length === 11 ? match[7] : false
    }
    if (!url) {
      if (videoUrl) {
        if (videoUrl.indexOf('vimeo.com') !== -1) {
          const vimeoId = parseVimeoUrl()
          vimeoId &&
            setUrl(
              `https://player.vimeo.com/video/${vimeoId}?autoplay=0&title=0&byline=0&portrait=0`
            )
        } else {
          const youtubeId = parseYoutubeUrl()
          youtubeId &&
            setUrl(
              `https://www.youtube.com/embed/${youtubeId}?modestbranding=1&playsinline=1&showinfo=0&rel=0`
            )
        }
      }
    }
  }, [videoUrl])

  const handleNavigation = () => {
    if (route) {
      navigation.navigate(route, { handle })
    }
  }

  return url ? (
    <View nativeID={id}>
      {!_.isEmpty(title) && (
        <SectionHeader
          title={title}
          type='plain'
          style={{ marginBottom: 10 }}
        />
      )}
      <View
        style={{
          position: 'relative',
          marginBottom: 10,
          backgroundColor: theme.contentBackground
        }}
      >
        <WebView
          style={{ width, height: (width * 9) / 16 }}
          javaScriptEnabled
          domStorageEnabled
          mediaPlaybackRequiresUserAction={false}
          source={{
            uri: url
          }}
        />
        {(!_.isEmpty(description) || (route && !_.isEmpty(buttonText))) && (
          <View
            style={{
              padding: 15,
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {!_.isEmpty(description) && (
              <View style={{ flex: 1 }}>
                <Text>{description}</Text>
              </View>
            )}
            {route && !_.isEmpty(buttonText) && (
              <TouchableOpacity
                style={{
                  backgroundColor: theme.buttonColor,
                  padding: 10,
                  alignSelf: 'flex-end',
                  justifySelf: 'flex-end',
                  margin: 5,
                  marginLeft: 15
                }}
                onPress={handleNavigation}
              >
                <Text fontSize={16} type='button' bold>
                  {buttonText}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    </View>
  ) : (
    <View nativeID={id} />
  )
}

export default EmbeddedVideo
