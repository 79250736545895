import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useQuery } from '@apollo/react-hooks'
import * as gql from '@gql/Queries'
import _ from 'lodash'
import MainCollectionsList from '@components/MainCollectionsList'
import SectionHeader from '@components/SectionHeader'
import { spacing } from '@utils/scale'

const CustomiserCollections = ({
  id,
  items,
  title: collectionTitle,
  horizontal,
  hideHeader,
  mode,
  listHeaderComponent,
  navigation,
  theme,
  imageAlignment,
  dynamicHeight
}) => {
  const [ids, setIds] = useState(() => items ? items.map(item => item.id) : [])
  const [title, setTitle] = useState(collectionTitle)
  const { data, loading, error, refetch } = useQuery(gql.getCollectionsByIds, {
    variables: { ids }
  })

  useEffect(() => {
    setTitle(collectionTitle)
  }, [collectionTitle])

  useEffect(() => {
    if (!_.isEqual(ids, items.map(item => item.id))) {
      setIds(items.map(item => item.id))
      refetch()
    }
  }, [JSON.stringify(items)])

  const handleCollectionPress = (handle, node) => {
    navigation.navigate('CollectionProducts', {
      handle,
      title: node.title,
      item: node
    })
  }

  const renderDefaultHeader = () => {
    return (
      <SectionHeader
        title={title}
        onViewPressed={() => navigation.navigate('Collections')}
      />
    )
  }

  if (error) return <View />

  const collections = _.get(data, 'nodes', []).map(col => ({
    node: { ...col }
  }))

  return (
    <View nativeID={id} style={{ ...spacing }}>
      <>
        {(!!collections && !hideHeader) && renderDefaultHeader()}
        <MainCollectionsList
          theme={theme}
          loading={loading}
          listHeaderComponent={listHeaderComponent}
          mode={mode}
          imageAlignment={imageAlignment}
          dynamicHeight={dynamicHeight}
          horizontal={horizontal}
          onCollectionItemPressed={handleCollectionPress}
          items={collections || []}
        />
      </>
    </View>
  )
}

export default CustomiserCollections
