import React, { useState } from 'react'
import { connect } from 'react-redux'
import { View, TouchableOpacity, ActivityIndicator } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import base64 from 'base-64'
import StarInput from '@components/StarInput'
import Input from '@components/Input'
import ModalContainer from '@containers/ModalContainer'
import DCImage from '@components/DCImage'
import Text from '@components/Text'
import { width } from '@utils/scale'
import _ from 'lodash'

const ReviewForm = ({ navigation, route, theme, reviews, user, dispatch }) => {
  const product = route.params?.product ?? {}
  if (_.isEmpty(product)) navigation.goBack()

  let userName

  if (user?.firstName && user?.lastName) {
    userName = `${user?.firstName} ${user?.lastName}`
  }

  const [loading, setLoading] = useState()
  const [stars, setStars] = useState(5)
  const [name, setName] = useState(userName)
  const [email, setEmail] = useState(user?.email)
  const [reviewTitle, setReviewTitle] = useState()
  const [review, setReview] = useState()
  const image = _.get(_.head(_.get(product, 'images.edges')), 'node.transformedSrc')

  // TODO: Add more review submission types here
  const handleSubmit = async () => {
    if (reviews?.provider !== 'STAMPED') return

    try {
      setLoading(true)
      let productId = base64.decode(product?.id)
      productId = _.replace(productId, 'gid://shopify/Product/', '')
      const data = {
        author: name,
        email: email,
        reviewTitle: reviewTitle,
        reviewMessage: review,
        reviewRating: stars,
        productId,
        productName: product?.title,
        productImage: image,
        productSKU: _.get(_.head(_.get(product, 'variants.edges')), 'node.sku'),
        productUrl: product?.onlineStoreUrl,
        reviewRecommendProduct: true,
        reviewSource: 'api'
        // location United States
      }

      const { apiKey, storeHash } = reviews
      const url = `https://stamped.io/api/reviews2?apiKey=${apiKey}&sId=${storeHash}`

      const encodedData = Object.keys(data).reduce((prev, curr) => {
        const nxt = `${curr}=${encodeURI(data[curr])}`
        if (prev) {
          return `${prev}&${nxt}`
        }
        return nxt
      }, '')

      await fetch(url, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        body: encodedData
      })

      const mappedItem = {
        title: 'Thank you for submitting your review!',
        type: 'Success'
      }

      dispatch({
        type: 'ADD_TOAST',
        data: {
          message: mappedItem
        }
      })

      setLoading(false)
      navigation.goBack()
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const disabled = !name || !email || !reviewTitle || !review
  return (
    <ModalContainer
      doubleTopPadding
      theme={theme}
      handleBack={() => navigation.goBack()}
    >
      <KeyboardAwareScrollView contentContainerStyle={{ paddingBottom: 100 }}>
        <View style={{ padding: 20, marginTop: 30, flex: 1 }}>
          <View style={{ flexDirection: 'row', width: width - 40 }}>
            <DCImage
              style={{
                width: 150,
                height: 150,
                marginRight: 10
              }}
              source={{ uri: image }}
            />
            <Text bold style={{ flex: 1 }}>{product?.title}</Text>
          </View>
          <View>
            {/* TODO: Add star selector, add form stuff and endpoint */}
            <StarInput
              theme={theme}
              value={stars}
              onChange={(val) => setStars(val)}
            />
            <Input
              inputProps={{
                defaultValue: name
              }}
              validation='required'
              onChange={(val) => setName(val)}
              labelStyle={{ fontSize: 14 }}
              label='Name'
            />
            <Input
              inputProps={{
                defaultValue: email
              }}
              type='email'
              validation='email'
              onChange={(val) => setEmail(val)}
              labelStyle={{ fontSize: 14 }}
              label='Email'
            />
            <Input
              validation='required'
              onChange={(val) => setReviewTitle(val)}
              labelStyle={{ fontSize: 14 }}
              label='Review Title'
            />
            <Input
              validation='required'
              onChange={(val) => setReview(val)}
              labelStyle={{ fontSize: 14 }}
              inputStyle={{
                height: 'auto',
                minHeight: 138,
                paddingTop: 15,
                paddingBottom: 15
              }}
              inputProps={{
                numberOfLines: 5,
                multiline: true
              }}
              label='Review'
              numberOfLines={3}
              multiline
            />
          </View>
        </View>
        <TouchableOpacity
          disabled={disabled}
          style={{
            ...styles.button,
            backgroundColor: theme.buttonColor,
            opacity: disabled ? 0.5 : 1
          }}
          onPress={handleSubmit}
        >
          {loading ? (
            <ActivityIndicator color={theme.buttonTextColor} />
          ) : (
            <Text
              fontSize={16}
              type='button'
              bold
              style={{ ...styles.buttonText }}
            >
              Submit
            </Text>
          )}
        </TouchableOpacity>
      </KeyboardAwareScrollView>
    </ModalContainer>
  )
}

const styles = {
  button: { flex: 1, padding: 15, marginTop: 20, marginRight: 20, marginLeft: 20 },
  buttonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white'
  }
}

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    reviews: _.get(state, 'config.integrations.reviews', {}),
    user: _.get(state, 'user.customer')
  }
}

export default connect(mapStateToProps)(ReviewForm)
