import React from 'react'
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Keyboard
} from 'react-native'
import Text from '@components/Text'
import Input from '@components/Input'
import { FormHeader, Terms } from '@components/FormHelpers'
import { scale } from '@utils/scale'

const LoginForm = ({ theme, onUpdateValue, onSubmit, toggleMode, disabled, loading }) => {
  const handleClosePress = () => {
    Keyboard.dismiss()
  }

  return (
    <>
      <FormHeader title='Login' />
      <View
        style={{
          flexDirection: 'row',
          marginBottom: 40,
          justifyContent: 'center',
          marginTop: 10
        }}
      >
        <Text>Don't have an account? </Text>
        <TouchableOpacity onPress={() => toggleMode('signup')}>
          <Text style={{ color: theme.accentText }}>Sign up here.</Text>
        </TouchableOpacity>
      </View>
      <TouchableWithoutFeedback
        style={{ flex: 1 }}
        onPress={handleClosePress}
        accessible={false}
      >
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <View>
            <Input
              onComplete={e => onUpdateValue({ value: e, key: 'email' })}
              validation='email'
              label='Email'
              inputProps={{
                placeholder: 'Email Address',
                autoCapitalize: 'none',
                keyboardType: 'email-address'
              }}
            />
            <Input
              onComplete={e => onUpdateValue({ value: e, key: 'password' })}
              label='Password'
              validation='min8'
              inputProps={{
                placeholder: 'Password',
                autoCapitalize: 'none',
                secureTextEntry: true
              }}
            />
          </View>
          <View>
            <Terms color={theme.contentText} />
            <TouchableOpacity
              disabled={disabled}
              onPress={onSubmit}
              style={{
                ...styles.button,
                backgroundColor: theme.buttonColor,
                opacity: disabled ? 0.5 : 1
              }}
            >
              {loading ? (
                <ActivityIndicator color={theme.buttonTextColor} />
              ) : (
                <Text fontSize={18} style={{ ...styles.buttonText, color: theme.buttonTextColor }}>
                    Login
                </Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </>
  )
}

const styles = {
  button: { padding: 15, marginVertical: 10, height: scale(50) },
  buttonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white'
  }
}

export default LoginForm
