import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import Text from '@components/Text'
import { moneyV2 } from '@utils'
import _ from 'lodash'

const CheckoutReviewButton = ({ checkout, lineItems, theme, onPress }) => {
  return (
    <LinearGradient
      start={[0.5, 1.0]}
      end={[0.5, 0]}
      colors={[theme.pageBackground, `${theme.pageBackground}E6`]}
      style={styles.holder}
    >
      <View style={{ flex: 1 }}>
        <View>
          <View style={{ ...styles.priceHolder, paddingBottom: 2.5, paddingTop: 10, borderTopColor: theme.contentBackground, borderTopWidth: 1 }}>
            <Text fontSize={15} style={{ textAlign: 'left', flex: 1 }}>
                  Subtotal:{' '}
            </Text>
            <Text
              bold
              fontSize={15}
              style={{ fontWeight: '600', textAlign: 'right' }}
            >
              {' '}
              {moneyV2(checkout.subtotalPriceV2)}
            </Text>
          </View>
        </View>
        <View>
          <View style={{ ...styles.priceHolder, paddingBottom: 2.5, paddingTop: 2.5 }}>
            <Text fontSize={15} style={{ textAlign: 'left', flex: 1 }}>
                  Shipping:{' '}
            </Text>
            <Text
              bold
              fontSize={15}
              style={{ fontWeight: '600', textAlign: 'right' }}
            >
              {' '}
              {moneyV2(_.get(checkout, 'shippingLine.priceV2'))}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.priceHolder, marginBottom: 15, paddingBottom: 10, paddingTop: 5, borderBottomColor: theme.contentBackground, borderBottomWidth: 1 }}>
          <Text fontSize={15} style={{ textAlign: 'left', flex: 1 }}>
                  Tax:{' '}
          </Text>
          <Text
            bold
            fontSize={15}
            style={{ fontWeight: '600', textAlign: 'right' }}
          >
            {' '}
            {moneyV2(checkout.totalTaxV2)}
          </Text>
        </View>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={styles.textHolder}>
            <View>
              <Text fontSize={14} style={{ textAlign: 'right' }}>
                {lineItems.length} {lineItems.length === 1 ? 'Item' : 'Items'}
              </Text>
            </View>
            <View style={styles.priceHolder}>
              <Text fontSize={18} style={{ textAlign: 'right', flex: 1 }}>
                  Total:{' '}
              </Text>
              <Text
                bold
                fontSize={18}
                style={{ fontWeight: '600', textAlign: 'right' }}
              >
                {' '}
                {moneyV2(checkout.totalPriceV2)}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={_.isFunction(onPress) && onPress}
            style={{
              ...styles.checkoutButton,
              backgroundColor: theme.buttonColor
            }}
          >
            <Text
              bold
              type='button'
              fontSize={16}
              style={{ ...styles.checkoutButtonText }}
            >
                Checkout
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </LinearGradient>
  )
}

const styles = {
  holder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textHolder: { flex: 0.5, height: '100%', justifyContent: 'center' },
  priceHolder: { flexDirection: 'row', alignItems: 'flex-end', flex: 1 },
  checkoutButton: {
    flex: 0.6,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    height: 50
  },
  checkoutButtonText: { fontWeight: '600' }
}

export default CheckoutReviewButton
