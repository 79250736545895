import React from 'react'
import { View } from 'react-native'
import Image from '@components/DCImage'
import _ from 'lodash'
import Text from '@components/Text'
import TouchableExpand from '@components/TouchableExpand'
import { width } from '@utils/scale'

const CollectionItem = ({ itemPressed, item, horizontal, imageAlignment, dynamicHeight, theme }) => {
  const handleOnPress = handle => {
    if (handle && _.isFunction(itemPressed)) {
      itemPressed(handle)
    }
  }

  let fallbackImg = _.get(item, 'products.edges[0].node.images.edges', [])
  fallbackImg = _.get(_.first(fallbackImg), 'node.transformedSrc')

  const img = _.get(item, 'image.transformedSrc') || fallbackImg
  const horizontalStyles = horizontal
    ? { width: width, marginLeft: 0 }
    : { width: width + 60, marginLeft: -15 }

  const imageStyles = styles.image

  if (imageAlignment === 'top') {
    imageStyles.top = 0
    imageStyles.bottom = 'auto'
  }

  if (imageAlignment === 'center') {
    imageStyles.top = '-50%'
    imageStyles.bottom = 'auto'
  }

  if (imageAlignment === 'bottom') {
    imageStyles.top = 'auto'
    imageStyles.bottom = 0
  }

  if (dynamicHeight) {
    imageStyles.position = 'relative'
    imageStyles.top = 'auto'
    imageStyles.bottom = 'auto'
  } else {
    imageStyles.position = 'absolute'
  }

  return (
    <TouchableExpand
      style={{ ...styles.holder, backgroundColor: theme.contentBackground }}
      onPress={() => handleOnPress(item.handle)}
    >
      <View style={{ ...styles.imageHolder, ...horizontalStyles }}>
        {item && (
          <Image
            scalable
            style={{ ...imageStyles, ...horizontalStyles }}
            source={{ uri: img }}
          />
        )}
      </View>
      <View style={{ marginLeft: 20 }}>
        <Text fontSize={16} style={styles.title} bold>
          {item.title}
        </Text>
        <Text fontSize={12} style={styles.subTitle}>
          VIEW COLLECTION
        </Text>
      </View>
    </TouchableExpand>
  )
}

const styles = {
  holder: {
    width: width,
    paddingBottom: 10,
    marginTop: 10,
    marginBottom: 10,
    minHeight: (width - 30) / 1.84
  },
  imageHolder: {
    width: width + 60,
    marginLeft: -15,
    minHeight: (width - 30) / 1.84,
    overflow: 'hidden'
  },
  image: {
    position: 'absolute',
    top: '-50%', // TODO: Make this inherit
    width: width + 60,
    marginLeft: -15,
    backgroundColor: '#ebebeb'
  },
  title: {
    fontWeight: '600',
    marginTop: 5,
    marginBottom: 2,
    letterSpacing: 0.5
  },
  subTitle: {
    fontWeight: '300',
    letterSpacing: 0.2
  }
}

export default CollectionItem
