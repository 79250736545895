import React, { useEffect, useState } from 'react'
import { Video } from 'expo-av'
import _ from 'lodash'
import { width } from '@utils/scale'

const isValidUrl = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

const InstaVideo = ({ id, url }) => {
  const [videoUrl, setVideUrl] = useState()
  useEffect(() => {
    const getInstaVideo = async () => {
      try {
        if (!url || !isValidUrl(url)) {
          throw new Error('Please include a valid url.')
        }

        const content = await fetch(url)
        const contentText = await content.text()
        const regex = /"video_url":"(.*?)"/i

        let m
        if ((m = regex.exec(contentText)) !== null) {
          // The result can be accessed through the `m`-variable.
          if (_.isArray(m) && !_.isEmpty(m[1])) {
            const url = m[1]
            let uri = url
            if (url && url.replace) {
              uri = url.replace(/\\u0026/g, '&')
            }
            setVideUrl(uri)
            return
          }
        }
        throw new Error('Could not find your insta item. Please make sure the account is public.')
      } catch (e) {
        console.warn(e)
        return null
      }
    }
    getInstaVideo()
  }, [])

  return (
    <>
      {videoUrl && (
        <Video
          nativeID={id}
          source={{ uri: videoUrl }}
          rate={1.0}
          volume={1.0}
          isMuted
          resizeMode='cover'
          shouldPlay
          isLooping
          style={{ width, height: width }}
        />
      )}
    </>
  )
}

export default InstaVideo
