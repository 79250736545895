import React from 'react'
import { View } from 'react-native'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import _ from 'lodash'
import Carousel, { getInputRangeFromIndexes } from 'react-native-snap-carousel'
import { width } from '@utils/scale'
import DCImage from '@components/DCImage'
import Text from '@components/Text'
import ProductItemPrice from '@components/ProductItemPrice'
import TouchableExpand from '@components/TouchableExpand'
import ProductItemSaleBadge from '@components/ProductItemSaleBadge'
import MetaFieldManager from '@components/MetaFieldManager'

const IMAGE_OFFSET = 70

const ProductCarousel = ({ id, theme, items = [], title, description, navigation, mode }) => {
  const getProductsByIdsQuery = useProductQuery('getProductsByIds')
  const handleNav = (item) => {
    navigation.navigate('Product', { handle: item.handle })
  }

  const scrollInterpolator = (index, carouselProps) => {
    const range = [1, 0, -1]
    const inputRange = getInputRangeFromIndexes(range, index, carouselProps)
    const outputRange = range

    return { inputRange, outputRange }
  }

  const animatedStyles = (index, animatedValue, carouselProps) => {
    const sizeRef = carouselProps.vertical
      ? carouselProps.itemHeight
      : carouselProps.itemWidth

    return {
      zIndex: animatedValue.interpolate({
        inputRange: [-1, -0.8, 0, 0.8, 1],
        outputRange: [0, 1, 1, 1, 0],
        extrapolate: 'clamp'
      }),
      transform: [
        {
          scale: animatedValue.interpolate({
            inputRange: [-1, 0, 1],
            outputRange: [0.8, 1, 0.8],
            extrapolate: 'clamp'
          })
        },
        {
          translateX: animatedValue.interpolate({
            inputRange: [-1, -0.9, 0, 0.9, 1],
            outputRange: [
              sizeRef * 0.2,
              sizeRef * 0.05,
              0,
              -sizeRef * 0.05,
              -sizeRef * 0.2
            ],
            extrapolate: 'clamp'
          })
        }
      ]
    }
  }

  const renderItem = ({ item }) => {
    const image = _.get(
      _.head(_.get(item, 'images.edges')),
      'node.transformedSrc'
    )
    // MetaFields
    const metaFields = _.get(item, 'metafields.edges')

    return (
      <TouchableExpand style={{ padding: 10 }} onPress={() => handleNav(item)}>
        <View
          style={{
            ...styles.mainHolder,
            backgroundColor: theme.contentBackground
          }}
        >
          <View style={{ overflow: 'hidden', borderRadius: 10 }}>
            <View
              style={{
                position: 'relative',
                width: width - IMAGE_OFFSET - 10,
                height: width - IMAGE_OFFSET - 10
              }}
            >
              <DCImage
                scalable
                source={{ uri: image }}
                style={{ width: width - IMAGE_OFFSET - 10, height: width - IMAGE_OFFSET - 10 }}
              />
              <ProductItemSaleBadge item={item} fontSize={15} />
            </View>
            <View style={styles.textHolder}>
              <Text
                bold
                fontSize={13.5}
                lineHeight={17}
                style={{ ...styles.font, ...styles.productTitle }}
              >
                {item.title}
              </Text>
              {!_.isEmpty(metaFields) && <MetaFieldManager meta={metaFields} />}
              {/* ProductItemPrice */}
              <ProductItemPrice
                fontSize={16}
                selectedFontStyle={{ ...styles.font }}
                item={item}
              />
              {/* End ProductItemPrice */}
            </View>
          </View>
        </View>
      </TouchableExpand>
    )
  }
  const ids = items.map((item) => item.id)
  let extraConfig = {}
  switch (mode) {
    case 'basic':
      extraConfig = {}
      break
    case 'stack':
      extraConfig = {
        layout: 'stack'
      }
      break
    case 'tinder':
      extraConfig = {
        layout: 'tinder'
      }
      break
    default:
      extraConfig = {
        scrollInterpolator: scrollInterpolator,
        slideInterpolatedStyle: animatedStyles,
        useScrollView: true
      }
      break
  }
  return (
    <View nativeID={id}>
      <Query
        query={getProductsByIdsQuery}
        variables={{
          ids: ids
        }}
      >
        {({ loading, error, data }) => {
          // if (loading) return <View />
          if (error) return <View />
          if (data) {
            const products = _.filter(_.get(data, 'nodes'), (i) => !_.isEmpty(i))
            return (
              <View>
                <View style={{ padding: 15 }}>
                  {!_.isEmpty(title) && (
                    <Text bold type='heading' fontSize={20}>
                      {title}
                    </Text>
                  )}
                  {!_.isEmpty(description) && (
                    <Text fontSize={14} style={{ marginBottom: 10 }}>
                      {description}
                    </Text>
                  )}
                </View>

                <Carousel
                  loop
                  // layout='tinder'
                  data={products}
                  renderItem={renderItem}
                  sliderWidth={width}
                  itemWidth={width - IMAGE_OFFSET}
                  {...extraConfig}
                />
              </View>
            )
          }
          return <View />
        }}
      </Query>
    </View>
  )
}

const styles = {
  mainHolder: {
    shadowOpacity: 0.15,
    shadowRadius: 8,
    shadowColor: 'black',
    shadowOffset: {
      x: 0,
      y: -5
    },
    elevation: 3,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5
  },
  textHolder: { alignItems: 'center', padding: 10, paddingBottom: 20 },
  font: { letterSpacing: 0.2, textAlign: 'center' },
  productTitle: {
    maxWidth: '85%',
    marginTop: 5,
    marginBottom: 10,
    fontWeight: '700',
    letterSpacing: 0.5
  }
}

export default ProductCarousel
