import React, { useState } from 'react'
import {
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  ActivityIndicator
} from 'react-native'
import Text from '@components/Text'
import Input from '@components/Input'
import { FormHeader, Terms } from '@components/FormHelpers'

const SignupForm = ({
  theme,
  password,
  onUpdateValue,
  onSubmit,
  toggleMode,
  disabled,
  loading
}) => {
  const [passwordError, setPasswordError] = useState()
  return (
    <>
      <FormHeader title='Sign up' />
      <View
        style={{
          flexDirection: 'row',
          marginBottom: 10,
          justifyContent: 'center',
          marginTop: 10
        }}
      >
        <Text>Have an account? </Text>
        <TouchableOpacity onPress={() => toggleMode('login')}>
          <Text style={{ color: theme.accentText }}>Login here.</Text>
        </TouchableOpacity>
      </View>
      <TouchableWithoutFeedback
        style={{ flex: 1 }}
        onPress={() => Keyboard.dismiss()}
        accessible={false}
      >
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <View>
            <Input
              onComplete={e => onUpdateValue({ value: e, key: 'email' })}
              validation='email'
              label='Email'
              inputProps={{
                placeholder: 'Email Address',
                autoCapitalize: 'none',
                keyboardType: 'email-address'
              }}
            />
            <Input
              onComplete={e => onUpdateValue({ value: e, key: 'password' })}
              label='Password'
              validation='min8'
              inputProps={{
                placeholder: 'Password',
                autoCapitalize: 'none',
                secureTextEntry: true
              }}
            />
            <Input
              error={passwordError}
              onComplete={e => {
                if (e !== password) {
                  setPasswordError(['Passwords dont match'])
                } else {
                  setPasswordError(null)
                  onUpdateValue({ value: e, key: 'confirmPassword' })
                }
              }}
              label='Confirm Password'
              validation='min8'
              inputProps={{
                placeholder: 'Confirm Password',
                autoCapitalize: 'none',
                secureTextEntry: true
              }}
            />
          </View>
          <View>
            <Terms color={theme.contentText} />
            <TouchableOpacity
              disabled={disabled || passwordError}
              onPress={onSubmit}
              style={{
                ...styles.button,
                backgroundColor: theme.buttonColor,
                opacity: disabled ? 0.5 : 1
              }}
            >
              {loading ? (
                <ActivityIndicator color={theme.buttonTextColor} />
              ) : (
                <Text
                  fontSize={18}
                  style={{
                    ...styles.buttonText,
                    color: theme.buttonTextColor
                  }}
                >
                  SIGN UP
                </Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </>
  )
}

const styles = {
  button: { padding: 15, marginVertical: 10 },
  buttonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white'
  }
}

export default SignupForm
