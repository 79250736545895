import React, { useState } from 'react'
import { ScrollView, View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import {
  checkoutShippingUpdate,
  customerAddressCreate,
  customerAddressUpdate,
  checkoutEmailUpdate,
  customerAddressDelete
} from '@gql/Mutations'
import * as Animatable from 'react-native-animatable'
import HeaderBar from '@components/HeaderBar'
import Text from '@components/Text'
import Icon from '@components/Icon'
import _ from 'lodash'
import AddressCard from '@components/AddressCard'
import AddressForm from '@components/AddressForm'
import Panel from '@components/Panel'
import AddressCardSwipeable from '@components/AddressCardSwipeable'

const Header = ({ user, mode }) => {
  switch (mode) {
    case 'new':
      return (
        <View style={{ padding: 10, marginBottom: 10 }}>
          <>
            <Text bold fontSize={20} style={{ marginBottom: 5 }}>
              Add Address
            </Text>
            <Text fontSize={13}>Save an address for future use.</Text>
          </>
        </View>
      )
    case 'edit':
      return (
        <View style={{ padding: 10, marginBottom: 10 }}>
          <>
            <Text
              bold
              fontSize={20}
              style={{ fontWeight: '600', marginBottom: 5 }}
            >
              Edit Address
            </Text>
            <Text fontSize={13}>Edit your address.</Text>
          </>
        </View>
      )
    default:
      return (
        <View style={{ padding: 10, marginBottom: 10 }}>
          {!user ? (
            <>
              <Text
                bold
                fontSize={20}
                style={{ fontWeight: '600', marginBottom: 5 }}
              >
                Shipping Address
              </Text>
              <Text fontSize={13}>Enter your shipping address.</Text>
            </>
          ) : (
            <>
              <Text
                bold
                fontSize={20}
                style={{ fontWeight: '600', marginBottom: 5 }}
              >
                Addresses
              </Text>
              <Text fontSize={13}>Manage your saved addresses.</Text>
            </>
          )}
        </View>
      )
  }
}

const Addresses = ({ user, checkout, dispatch, customerAccessToken, client, navigation, theme }) => {
  const [showForm, setShowForm] = useState(
    _.isEmpty(_.get(user, 'addresses.edges')) &&
    !_.get(checkout, 'shippingAddress')
  )
  const [address, setAddress] = useState()
  const [mode, setMode] = useState()

  const handleCancel = () => {
    if (!user || !checkout.shippingAddress) {
      navigation.goBack()
    } else {
      setShowForm(false)
      setAddress(null)
      setMode(null)
    }
  }

  const handleErrorMessage = errors => {
    const message = errors.reduce((prev, curr) => {
      if (
        !_.isEmpty(curr.field) &&
        curr.field.indexOf('address') > -1 &&
        curr.field.indexOf('country') > -1
      ) {
        curr.message = 'Shipping to your country is currently not supported'
      }
      return prev + curr.message + '\n'
    }, '')

    dispatch({
      type: 'ADD_TOAST',
      data: {
        message: {
          message: message,
          type: 'Error'
        }
      }
    })
  }

  const saveLocalAddress = address => {
    if (address) {
      dispatch({
        type: 'SET_CUSTOMER_ADDRESS',
        data: {
          address
        }
      })
      setMode()
      setAddress()
      setShowForm(false)
    }
  }

  const handleAddAddress = (mutation, data) => {
    if (mode === 'edit-new' && customerAccessToken && data.id) {
      editAddress(mutation, data)
      return
    }
    if (mode === 'new' && customerAccessToken) {
      addNewAddress(mutation, data)
      return
    }
    addShippingAddress(mutation, data)
  }

  const editAddress = async (mutation, data) => {
    const {
      firstName,
      lastName,
      country,
      zip,
      address1,
      address2,
      city,
      province,
      id
    } = data

    const response = await mutation({
      variables: {
        address: {
          firstName,
          lastName,
          country,
          zip,
          address1,
          address2,
          city,
          province
        },
        id,
        customerAccessToken
      }
    })

    if (
      !_.isEmpty(
        _.get(response, 'data.customerAddressUpdate.customerUserErrors')
      )
    ) {
      handleErrorMessage(
        _.get(response, 'data.customerAddressUpdate.customerUserErrors')
      )
    } else {
      saveLocalAddress({
        ..._.get(response, 'data.customerAddressUpdate.customerAddress', {}),
        ...data
      })
    }
  }

  const addNewAddress = async (mutation, data) => {
    const { firstName, lastName, country, zip, address1, address2, city, province } = data
    const response = await mutation({
      variables: {
        address: {
          firstName,
          lastName,
          country,
          zip,
          address1,
          address2,
          city,
          province
        },
        customerAccessToken
      }
    })

    if (
      !_.isEmpty(
        _.get(response, 'data.customerAddressCreate.customerUserErrors')
      )
    ) {
      handleErrorMessage(
        _.get(response, 'data.customerAddressCreate.customerUserErrors')
      )
    } else {
      saveLocalAddress(
        _.get(response, 'data.customerAddressCreate.customerAddress')
      )
    }
  }

  const addShippingAddress = async (mutation, data) => {
    const { firstName, lastName, country, zip, address1, address2, city, province } = data
    let response
    if (mutation) {
      response = await mutation({
        variables: {
          shippingAddress: {
            firstName,
            lastName,
            country,
            zip,
            address1,
            address2,
            city,
            province
          },
          checkoutId: checkout.id
        }
      })
    } else {
      response = await client.mutate({
        mutation: checkoutShippingUpdate,
        variables: {
          shippingAddress: {
            firstName,
            lastName,
            country,
            zip,
            address1,
            address2,
            city,
            province
          },
          checkoutId: checkout.id
        }
      })
    }

    if (
      !_.isEmpty(
        _.get(response, 'data.checkoutShippingAddressUpdateV2.userErrors')
      )
    ) {
      handleErrorMessage(
        _.get(response, 'data.checkoutShippingAddressUpdateV2.userErrors')
      )
    } else {
      dispatch({
        type: 'SET_LOCAL_CHECKOUT',
        data: {
          checkout: _.get(
            response,
            'data.checkoutShippingAddressUpdateV2.checkout'
          )
        }
      })
      navigation.goBack()
    }

    // HANDLE ADDRESS FOR UN-LOGGED-IN USERS
    if (data.email) {
      const _response = await client.mutate({
        mutation: checkoutEmailUpdate,
        variables: { checkoutId: checkout.id, email: data.email }
      })

      if (_.get(_response, 'data.node')) {
        dispatch({
          type: 'SET_LOCAL_CHECKOUT',
          data: {
            checkout: _.get(_response, 'data.node')
          }
        })
      }
    }
  }

  const setMutation = () => {
    let mutation
    switch (mode) {
      case 'edit-new':
        mutation = customerAddressUpdate
        break
      case 'new':
        mutation = customerAccessToken
          ? customerAddressCreate
          : checkoutShippingUpdate
        break
      default:
        mutation = checkoutShippingUpdate
        break
    }
    return mutation
  }

  const renderForm = () => {
    // TODO: need mode aka 'account-address-create, shipping-address-create, and edits and hasUser'
    let _address = address
    if (user && !_address) {
      _address = {
        firstName: user.firstName,
        lastName: user.lastName
      }
    }

    return (
      <AddressForm
        address={_address}
        hasUser={user}
        theme={theme}
        mutation={setMutation()}
        onSubmit={handleAddAddress}
        onCancel={handleCancel}
      />
    )
  }

  const onlyHasDefault =
    _.isEmpty(_.get(user, 'addresses.edges')) && _.get(user, 'defaultAddress')
  return (
    <ScrollView
      contentContainerStyle={{ padding: 10, paddingTop: 50 }}
      style={{ flex: 1, backgroundColor: theme.pageBackground }}
    >
      <TouchableOpacity
        style={{
          position: 'absolute',
          right: 20,
          top: 50,
          zIndex: 99,
          backgroundColor: theme.contentBackground,
          padding: 7,
          borderRadius: 30
        }}
        onPress={() => navigation.goBack()}
      >
        <Icon name='x' size={16} />
      </TouchableOpacity>
      <View>
        <Header user={user} mode={mode} />
        {showForm && renderForm()}
        <View style={{ padding: 10 }}>
          {!showForm && (
            <Animatable.View animation='fadeIn'>
              <View style={{ marginBottom: 10 }}>
                <Text>SELECTED SHIPPING ADDRESS:</Text>
              </View>
              {_.isEmpty(checkout.shippingAddress) ? (
                <Panel theme={theme} containerStyle={{ padding: 20 }}>
                  <Text fontSize={14} bold style={{ textAlign: 'center' }}>
                    NO SHIPPING ADDRESS SELECTED
                  </Text>
                  <Text fontSize={12} style={{ textAlign: 'center' }}>
                    Tap on one of your previously saved addresses to select
                    it. Alternatively create a new one.
                  </Text>
                </Panel>
              ) : (
                <AddressCard
                  padded
                  active
                  address={checkout.shippingAddress}
                  theme={theme}
                  onCaretPress={address => {
                    setShowForm(true)
                    setAddress({ ...address, email: checkout.email })
                    setMode('edit-shipping')
                  }}
                />
              )}
              {!_.isEmpty(user) && (
                <View style={{ marginTop: 20, marginBottom: 15 }}>
                  <Text>AVAILABLE ADDRESSES:</Text>
                </View>
              )}
              {!onlyHasDefault &&
                _.get(user, 'addresses.edges', []).map((address, i) => (
                  <AddressCardSwipeable
                    dispatch={dispatch}
                    customerAccessToken={customerAccessToken}
                    onPress={address => addShippingAddress(null, address)}
                    onCaretPress={address => {
                      setShowForm(true)
                      setAddress({ ...address })
                      setMode('edit-new')
                    }}
                    address={address}
                    theme={theme}
                    key={`addr-${i}`}
                    mutation={customerAddressDelete}
                  />
                ))}
              {onlyHasDefault && (
                <AddressCard
                  padded
                  key='addr-def'
                  address={_.get(user, 'defaultAddress')}
                  theme={theme}
                  onCaretPress={address => {
                    setShowForm(true)
                    setAddress({ ...address })
                    setMode('edit-new')
                  }}
                />
              )}
              {!_.isEmpty(user) && (
                <TouchableOpacity
                  onPress={() => {
                    setShowForm(true)
                    setAddress(null)
                    setMode('new')
                  }}
                  style={{ ...styles.addButton, borderColor: theme.buttonColor, backgroundColor: `${theme.buttonColor}10` }}
                >
                  <Text type='button' bold fontSize={15}>
                    ADD ADDRESS
                  </Text>
                </TouchableOpacity>
              )}
            </Animatable.View>
          )}
        </View>
      </View>
    </ScrollView>
  )
}

Addresses.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

const styles = {
  addButton: {
    paddingVertical: 30,
    marginVertical: 10,
    borderColor: '#ebebeb',
    borderWidth: 2,
    borderStyle: 'dashed',
    backgroundColor: '#ffffff70',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}

const mapStateToProps = state => {
  return {
    customerAccessToken: state.user.accessToken,
    user: state.user.customer,
    theme: _.get(state, 'config.theme.colors.page'),
    checkout: state.cart.checkout
  }
}

export default connect(mapStateToProps)(withApollo(Addresses))
