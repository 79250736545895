import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import Lottie from 'lottie-react-native'

const Loader = () => {
  const animation = useRef()
  useEffect(() => {
    animation && animation.current && animation.current.play()
  }, [animation])

  return (
    <View style={{ marginTop: 20, width: 60, height: 60 }}>
      <Lottie ref={animation} source={require('../../../assets/data.json')} autoPlay />
    </View>
  )
}

export default Loader
