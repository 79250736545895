import React from 'react'
import { View } from 'react-native'
import Text from '@components/Text'
import PrivacyLinks from '@components/PrivacyLinks'

export const FormHeader = ({ title }) => (
  <View>
    <Text fontSize={32} style={{ fontWeight: '600', letterSpacing: 0.8 }}>
      {title}
    </Text>
    {/* <Text style={{ letterSpacing: 0.9, marginVertical: 2.5 }}>
      Please enter your details.
    </Text> */}
    <View style={{
      width: '100%',
      height: 1,
      backgroundColor: '#ebebeb',
      marginVertical: 10
    }}
    />
  </View>
)

export const Terms = ({ color = '#000' }) => (
  <>
    <Text fontSize={12} style={{ textAlign: 'center', marginBottom: 5 }}>
      By using this application and logging in, you hereby agree to the following:
    </Text>
    <PrivacyLinks color={color} condensed />
  </>
)
