import React, { useState } from 'react'
import { Animated, TouchableWithoutFeedback } from 'react-native'

const TouchableExpand = ({ children, style, onPress }) => {
  const [animatedValue] = useState(new Animated.Value(1))

  const handlePressIn = () => {
    Animated.spring(animatedValue, {
      toValue: 0.96,
      useNativeDriver: true
    }).start()
  }

  const handlePressOut = () => {
    Animated.spring(animatedValue, {
      toValue: 1,
      useNativeDriver: true
    }).start()
  }

  const animatedStyle = { transform: [{ scale: animatedValue }] }

  return (
    <TouchableWithoutFeedback
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onPress={() => onPress && onPress()}
    >
      <Animated.View style={{ ...style, ...animatedStyle }}>
        {children}
      </Animated.View>
    </TouchableWithoutFeedback>
  )
}

export default TouchableExpand
