import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Animated, Image, View, ActivityIndicator } from 'react-native'
import ScalableImage from '@components/ScalableImage'
import Icon from '@components/Icon'
import _ from 'lodash'

const isWeb = process.env.REACT_APP_REDIRECT_URL
const DCImage = ({
  style: itemStyle,
  scalable,
  source,
  checkExists,
  theme
}) => {
  const [fadeAnim] = useState(new Animated.Value(1))
  const [scaleProps, setScaleProps] = useState({ width: itemStyle?.width || itemStyle?.height })
  const [exists, setExists] = useState(true)
  const [minHeight, setMinHeight] = useState(
    itemStyle?.height || itemStyle?.width
  )

  useEffect(() => {
    // WEB FIX
    const timeout = setTimeout(() => {
      if (isWeb) {
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true
        }).start()
        setMinHeight('auto')
      }
    }, 800)

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [])

  const handleFadeOut = () => {
    if (scalable) {
      setMinHeight('auto')
    }
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true
    }).start()
  }

  const handleSize = ({ width, height }) => {
    if (!scaleProps || (scaleProps.width !== width)) {
      if (width > height) {
        setScaleProps({ width })
      } else {
        setScaleProps({ height })
      }
    }
  }

  const _scaleProps = scaleProps || { width: itemStyle.width }
  if (checkExists && !exists) {
    return <View style={{ position: 'absolute', height: 0, width: 0 }} />
  }

  return (
    <View
      style={{
        ...itemStyle,
        minHeight,
        minWidth: itemStyle.width,
        ...{ backgroundColor: theme.imageBackgroundColor || theme.pageBackground },
        justifyContent: 'center'
      }}
    >
      {!!source &&
        !!source.uri &&
        (scalable ? (
          <ScalableImage
            source={source}
            {..._scaleProps}
            onLoadEnd={handleFadeOut}
            onSize={handleSize}
            onSizeError={() => {
              setExists(false)
            }}
            onError={err => console.log(err)}
          />
        ) : (
          <Image
            style={{ ...itemStyle, ...styles.absolute }}
            source={source}
            resizeMode='cover'
            onLoadEnd={handleFadeOut}
          />
        ))}
      <Animated.View
        style={{
          ...{ backgroundColor: theme.pageBackground },
          ...styles.absolute,
          opacity: fadeAnim,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ActivityIndicator color={theme.contentText} />
      </Animated.View>
      {(!source || !source.uri) && (
        <View
          style={{
            backgroundColor: theme.contentBackground,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: itemStyle.height || itemStyle.width,
            minWidth: itemStyle.width,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon size={40} name='image' color={theme.contentText} />
        </View>
      )}
    </View>
  )
}

const styles = {
  absolute: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(DCImage)
