import React from 'react'
import { View } from 'react-native'
import Image from '@components/DCImage'
import TouchableExpand from '@components/TouchableExpand'
import { width, spacing } from '@utils/scale'

const ImageOnly = ({
  id,
  imageUrl,
  route,
  handle,
  navigation
}) => {
  const handleNavigation = () => {
    if (route) {
      navigation.navigate(route, { handle })
    }
  }

  return (
    <View nativeID={id}>
      <TouchableExpand onPress={handleNavigation} style={{ position: 'relative', ...spacing }}>
        <Image style={{ width }} source={{ uri: imageUrl }} scalable />
      </TouchableExpand>
    </View>
  )
}

export default ImageOnly
