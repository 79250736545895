import React, { useState, useEffect } from 'react'
import {
  ActivityIndicator,
  ScrollView,
  View,
  TouchableOpacity
} from 'react-native'
import { connect } from 'react-redux'
import { Query, Mutation } from 'react-apollo'
import * as gql from '@gql/Queries'
import { checkoutShippingMethodSelect } from '@gql/Mutations'
import { moneyV2 } from '@utils'
import Text from '@components/Text'
import Icon from '@components/Icon'
import _ from 'lodash'
import Panel from '@components/Panel'
import Loader from '@components/Loader'

const ReloadComponent = ({
  refetch = () => {},
  currentCount = 1,
  setCurrentCount,
  theme
}) => {
  const [showReload, setShowReload] = useState(false)

  useEffect(() => {
    const refreshPage = () => {
      setTimeout(() => {
        setCurrentCount(currentCount++)
        refetch()
        console.log('Refetching', currentCount)
      }, 200)
    }
    if (currentCount && currentCount < 4) {
      refreshPage()
      return
    }
    setShowReload(true)
  }, [])

  return showReload ? (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingTop: 80
      }}
    >
      <View style={{ padding: 20 }}>
        <Text
          secondary
          fontSize={14}
          style={{ fontWeight: '300', textAlign: 'center' }}
        >
          There was a problem retrieving shipping rates.
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => _.isFunction(refetch) && refetch()}
        style={{
          backgroundColor: theme.buttonColor,
          padding: 15,
          paddingHorizontal: 40
        }}
      >
        <Text fontSize={14} type='button' bold>
          Try Again
        </Text>
      </TouchableOpacity>
    </View>
  ) : (
    <View style={styles.loaderHolder}>
      <Loader />
    </View>
  )
}

const ShippingRates = ({ checkout: remoteCheckout, theme, navigation }) => {
  const [shouldGoBack, setShouldGoBack] = useState(false)
  const [hasGoneBack, setHasGoneBack] = useState(false)
  const [checkout, setCheckout] = useState(remoteCheckout)
  const [currentCount, setCurrentCount] = useState(1)

  useEffect(() => {
    setCheckout(remoteCheckout)
  }, [remoteCheckout])

  useEffect(() => {
    if (shouldGoBack && !hasGoneBack) {
      navigation.goBack()
      setHasGoneBack(true)
    }
  }, [shouldGoBack])

  const selectedRate = _.get(checkout, 'shippingLine.handle')
  return (
    <ScrollView
      contentContainerStyle={{ padding: 15, paddingTop: 50 }}
      style={{ flex: 1, backgroundColor: theme.pageBackground }}
    >
      <TouchableOpacity
        style={{
          position: 'absolute',
          right: 20,
          top: 50,
          zIndex: 99,
          backgroundColor: theme.contentBackground,
          padding: 7,
          borderRadius: 30
        }}
        onPress={() => navigation.goBack()}
      >
        <Icon name='x' size={16} />
      </TouchableOpacity>
      <View>
        <View style={{ padding: 10, marginBottom: 10 }}>
          <>
            <Text fontSize={20} bold style={{ marginBottom: 5 }}>
              Shipping Method
            </Text>
            <Text fontSize={13}>Select your preferred shipping method</Text>
          </>
        </View>
        <View style={{ flex: 1 }}>
          <Query
            query={gql.getCheckoutShippingRates}
            variables={{ id: checkout.id }}
            fetchPolicy='network-only'
          >
            {({ loading, error, data, refetch }) => {
              if (loading) {
                return (
                  <View style={styles.loaderHolder}>
                    <Loader />
                  </View>
                )
              }

              const rates = _.get(
                data,
                'node.availableShippingRates.shippingRates',
                []
              )

              if (!rates) {
                return (
                  <ReloadComponent
                    theme={theme}
                    refetch={refetch}
                    currentCount={currentCount}
                    setCurrentCount={setCurrentCount}
                  />
                )
              }

              return (
                <View>
                  {(rates || []).map((item, k) => {
                    return (
                      <Mutation
                        key={`Shi-${k}`}
                        mutation={checkoutShippingMethodSelect}
                      >
                        {(
                          checkoutShippingMethodSelect,
                          { data, loading, error }
                        ) => {
                          if (data) {
                            setTimeout(() => {
                              setShouldGoBack(true)
                            }, 50)
                          }
                          return (
                            <TouchableOpacity
                              disabled={loading}
                              onPress={() =>
                                checkoutShippingMethodSelect({
                                  variables: {
                                    shippingRateHandle: item.handle,
                                    checkoutId: checkout.id
                                  }
                                })}
                            >
                              <Panel
                                theme={theme}
                                containerStyle={{
                                  borderColor:
                                    selectedRate === item.handle &&
                                    theme.buttonColor,
                                  borderWidth:
                                    selectedRate === item.handle ? 2 : 0,
                                  borderBottomColor:
                                    selectedRate === item.handle &&
                                    theme.buttonColor,
                                  borderBottomWidth:
                                    selectedRate === item.handle ? 2 : 0
                                }}
                              >
                                {loading ? (
                                  <View>
                                    <ActivityIndicator />
                                  </View>
                                ) : (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <Text>{item.title}</Text>
                                    <Text bold>
                                      {moneyV2(_.get(item, 'priceV2', 0))}
                                    </Text>
                                  </View>
                                )}
                              </Panel>
                            </TouchableOpacity>
                          )
                        }}
                      </Mutation>
                    )
                  })}
                </View>
              )
            }}
          </Query>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = {
  loaderHolder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 30
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page'),
    checkout: state.cart.checkout
  }
}

export default connect(mapStateToProps)(ShippingRates)
