import React from 'react'
import { View } from 'react-native'
import Text from '@components/Text'

const Panel = ({ children, title, containerStyle, theme }) => {
  return (
    <View style={{ ...styles.holder, ...containerStyle, backgroundColor: theme.contentBackground }}>
      {title && (
        <View>
          <Text bold fontSize={17} style={{ fontWeight: '600' }}>Shipping</Text>
          <View style={{ ...styles.hr, backgroundColor: theme.contentText, opacity: 0.3 }} />
        </View>
      )}
      {children}
    </View>
  )
}

const styles = {
  holder: {
    marginVertical: 8,
    padding: 10
  },
  hr: {
    height: 1,
    width: '100%',
    marginVertical: 5
  }
}

export default Panel
