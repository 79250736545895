import React from 'react'
import { View } from 'react-native'
import { Query } from 'react-apollo'
import * as gql from '@gql/Queries'
import _ from 'lodash'
import MainCollectionsList from '@components/MainCollectionsList'
import Text from '@components/Text'
import SectionHeader from '@components/SectionHeader'
import CollectionsSkeleton from '@components/Skeletons/CollectionsSkeleton'

const MainCollections = ({
  blockLoad,
  dynamicHeight,
  hideHeader,
  horizontal,
  imageAlignment,
  limit,
  listHeaderComponent,
  mode,
  navigation,
  theme,
  title
}) => {
  const handleCollectionPress = (handle, node) => {
    navigation.navigate('CollectionProducts', {
      handle,
      title: node.title,
      item: node
    })
  }

  const renderDefaultHeader = () => {
    return (
      <SectionHeader
        theme={theme}
        title={title}
        onViewPressed={() => navigation.navigate('Collections')}
      />
    )
  }

  return (
    <View>
      <Query query={gql.getCollections} variables={{ limit: limit || 15 }}>
        {({ loading, error, data, fetchMore }) => {
          if (loading) {
            return (
              <View
                style={{
                  top: 10,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute'
                }}
              >
                <CollectionsSkeleton theme={theme} />
              </View>
            )
          }
          if (error) return <Text>Error :(</Text>
          const pageInfo = _.get(data, 'collections.pageInfo')
          const collections = _.get(data, 'collections.edges', [])
          const cursor = _.get(_.last(collections), 'cursor')

          const curatedProducts = collections.filter(item => {
            if (!_.isEmpty(_.get(item, 'node.products.edges'))) {
              return true
            }
            return false
          })

          return (
            <>
              {!hideHeader && renderDefaultHeader()}
              <MainCollectionsList
                theme={theme}
                listHeaderComponent={listHeaderComponent}
                mode={mode}
                horizontal={horizontal}
                imageAlignment={imageAlignment}
                dynamicHeight={dynamicHeight}
                onCollectionItemPressed={handleCollectionPress}
                items={curatedProducts || []}
                pageInfo={_.get(data, 'collections.pageInfo')}
                loadMore={() => {
                  if (!blockLoad && pageInfo && pageInfo.hasNextPage) {
                    fetchMore({
                      query: gql.getCollections,
                      variables: {
                        after: cursor,
                        limit: 10
                      },
                      updateQuery: (prevResult, { fetchMoreResult }) => {
                        const newObj = {
                          ...fetchMoreResult,
                          collections: {
                            ...fetchMoreResult.collections,
                            edges: _.unionBy(
                              [
                                ..._.get(prevResult, 'collections.edges', []),
                                ..._.get(
                                  fetchMoreResult,
                                  'collections.edges',
                                  []
                                )
                              ],
                              'node.id'
                            )
                          }
                        }
                        return newObj
                      }
                    })
                  }
                }}
              />
            </>
          )
        }}
      </Query>
    </View>
  )
}

export default MainCollections
