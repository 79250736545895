import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import TabBar from '@components/TabBar'

const Tab = createBottomTabNavigator()

const Tabs = ({ route }) => {
  const tabs = route?.params?.tabs ?? {}
  const config = route?.params?.config ?? {}
  const flattenedTabs = Object.keys(tabs).map((k) => tabs[k])
  return (
    <Tab.Navigator
      initialRouteName={config.initialRoute || 'Shop'}
      tabBar={props => (
        <TabBar
          hideLabel={config.hideLabel}
          hideTabBar={config.hideTabBar}
          tabs={tabs}
          {...props}
        />
      )}
      backBehavior='none'
    >
      {flattenedTabs.map((flatTab, i) => {
        return (
          <Tab.Screen
            key={`Tabs=${i}`}
            name={flatTab.stack}
            component={flatTab.component}
          />
        )
      })}
    </Tab.Navigator>
  )
}

export default Tabs
