export const initialState = {
  appName: null,
  header: {
    image: null
  },
  theme: {
    primaryColor: 'green',
    accentColor: '#4A90E2',
    iconColor: '#575757'
  },
  tabs: [
    { stack: 'Shop', icon: 'shopping-bag', order: 0 },
    { stack: 'Home', icon: 'star', label: 'Featured', order: 1 },
    { stack: 'Cart', icon: 'shopping-cart', order: 2 },
    { stack: 'Orders', icon: 'gift', order: 3 }
  ],
  tabsConfig: { initialRoute: 'Home', hideLabel: false }
}

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        ...action.data
      }
    case 'PREVIEWR_RESET':
    case 'RESET_CONFIG':
      return { ...initialState }
    default:
      return state
  }
}

export default configReducer
