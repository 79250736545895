import React from 'react'
import SkeletonBase from './SkeletonBase'

const xml = `
<svg width="1242px" height="2412px" viewBox="0 0 1242 2412" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M0,0 L1242,0 L1242,1122 L0,1122 L0,0 Z M65,1250 L772,1250 L772,1325 L65,1325 L65,1250 Z M65,1340 L305,1340 L305,1400 L65,1400 L65,1340 Z M65,1453 L868,1453 L868,1513 L65,1513 L65,1453 Z M65,1626 L1073,1626 L1073,1686 L65,1686 L65,1626 Z M65,1701 L318,1701 L318,1761 L65,1761 L65,1701 Z M65,1799 L814,1799 L814,1859 L65,1859 L65,1799 Z M65,1889 L1145,1889 L1145,1949 L65,1949 L65,1889 Z M65,2077 L171,2077 L171,2166 L65,2166 L65,2077 Z M65,2246 L1161,2246 L1161,2412 L65,2412 L65,2246 Z M835,2062 L1161,2062 L1161,2153 L835,2153 L835,2062 Z M835,2171 L1161,2171 L1161,2218 L835,2218 L835,2171 Z M283,2077 L389,2077 L389,2166 L283,2166 L283,2077 Z M192,2077 L266,2077 L266,2166 L192,2166 L192,2077 Z M1059,1250 L1177,1250 L1177,1325 L1059,1325 L1059,1250 Z" id="Combined-Shape"></path>
</svg>
`
const FullProductSkeleton = ({ theme }) => {
  return (
    <SkeletonBase backgroundColor={theme.contentBackground} xml={xml} />
  )
}

export default FullProductSkeleton
