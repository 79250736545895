import React from 'react'
import { View, Image, TouchableOpacity, Platform } from 'react-native'
import { connect } from 'react-redux'
import Icon from '@components/Icon'
import Text from '@components/Text'
import _ from 'lodash'
import { width } from '@utils/scale'
import { ifIphoneX } from 'react-native-iphone-x-helper'
import { useIsFirstRouteInParent } from '@navigation/NavigationService'

const HeaderBar = ({ navigation, theme }) => {
  const isFirstRouteInParent = useIsFirstRouteInParent()
  const shouldShowBack = !navigation || !isFirstRouteInParent
  const colors = _.get(theme, 'theme.colors.header', {})
  const branding = _.get(theme, 'theme.header.image') || _.get(theme, 'appName')
  // TODO: scale based on device size
  return (
    <View
      style={{
        backgroundColor: colors.backgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        ...ifIphoneX({
          height: 90,
          paddingTop: 40
        }, {
          height: Platform.OS === 'android' ? 90 : 70,
          paddingTop: 20
        })
      }}
    >
      {shouldShowBack && (
        <TouchableOpacity
          style={{ position: 'absolute', left: 10, bottom: 5, padding: 10 }}
          onPress={() => navigation.goBack()}
        >
          <Icon
            name='arrow-left'
            size={18}
            color={colors.iconColor}
          />
        </TouchableOpacity>
      )}
      {!shouldShowBack && (
        <TouchableOpacity
          style={{ position: 'absolute', left: 10, bottom: 5, padding: 10 }}
          onPress={() => navigation.navigate('Search')}
        >
          <Icon
            name='search'
            size={18}
            color={colors.iconColor}
          />
        </TouchableOpacity>
      )}
      {_.get(theme, 'theme.header.image') ? (
        <View style={{ marginTop: -5 }}>
          <Image
            cache='force-cache'
            resizeMode='contain'
            source={{ uri: branding, height: 38, width: width / 1.7 }}
          />
        </View>
      ) : (
        <Text fontSize={18} color={colors.textColor}>{branding}</Text>
      )}
      <TouchableOpacity
        style={{ position: 'absolute', right: 10, bottom: 5, padding: 10 }}
        onPress={() => navigation.openDrawer()}
      >
        <Icon
          name='menu'
          size={18}
          color={colors.iconColor}
        />
      </TouchableOpacity>
    </View>
  )
}

const mapStateToProps = state => {
  return {
    theme: state.config
  }
}

export default connect(mapStateToProps)(HeaderBar)
