import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import _ from 'lodash'
import ImageSlider from '@components/ImageSlider'
import ProductItemPrice from '@components/ProductItemPrice'
import { width, spacing } from '@utils/scale'
import MetaFieldManager from '../MetaFieldManager'

const FeaturedProduct = ({ id, product: config, mode, theme, navigation, hideDescription }) => {
  const getProductByHandleQuery = useProductQuery('getProductByHandle')
  const renderCompactProduct = (product, images) => {
    // MetaFields
    const metaFields = _.get(product, 'metafields.edges')
    return (
      <View
        nativeID={id}
        style={{
          ...styles.compactHolder,
          backgroundColor: theme.contentBackground
        }}
      >
        <View style={styles.compactContent}>
          <ImageSlider
            internalKey='small'
            hidePagination
            theme={theme}
            items={images}
            sliderHeight={width / 2}
            itemHeight={width / 2}
            sliderWidth={width / 2}
            itemWidth={width / 2}
          />
          <View style={styles.compactText}>
            <View style={{ flex: 1 }}>
              <Text
                numberOfLines={4}
                fontSize={17}
                style={{ marginBottom: 5 }}
                bold
              >
                {product?.title}
              </Text>
            </View>
            {!_.isEmpty(metaFields) && (
              <View>
                <MetaFieldManager meta={metaFields} />
              </View>
            )}
            <ProductItemPrice
              style={{ alignSelf: 'flex-end' }}
              fontSize={17}
              item={product}
            />
            <TouchableOpacity
              onPress={() => navigation.navigate('Product', { handle: config?.handle })}
              style={{
                marginTop: 15,
                padding: 10,
                backgroundColor: theme.buttonColor
              }}
            >
              <Text
                type='button'
                bold
                fontSize={18}
                style={{ textAlign: 'center' }}
              >
                Buy Now
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
  const renderLargeProduct = (product, images) => {
    // MetaFields
    const metaFields = _.get(product, 'metafields.edges')
    return (
      <View
        nativeID={id}
        style={{
          ...styles.largeHolder,
          backgroundColor: theme.contentBackground
        }}
      >
        <View style={{ marginLeft: -20, marginBottom: 20 }}>
          <ImageSlider
            internalKey='large'
            hidePagination
            theme={theme}
            items={images}
            sliderWidth={width}
            itemWidth={width}
          />
        </View>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 1 }}>
            <Text fontSize={17} style={{ marginBottom: 5 }} bold>
              {product?.title}
            </Text>
            {!hideDescription && (
              <Text numberOfLines={3}>{product?.description}</Text>
            )}
          </View>
          <View style={styles.largePriceHolder}>
            <View>
              {!_.isEmpty(metaFields) && (
                <View>
                  <MetaFieldManager meta={metaFields} />
                </View>
              )}
              <ProductItemPrice
                style={{ alignSelf: 'flex-end' }}
                fontSize={17}
                item={product}
              />
            </View>
            <TouchableOpacity
              onPress={() => navigation.navigate('Product', { handle: config?.handle })}
              style={{
                margin: 5,
                padding: 10,
                backgroundColor: theme.buttonColor
              }}
            >
              <Text type='button' bold fontSize={18}>
                Buy Now
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
  const renderProductMode = (product, images) => {
    switch (mode) {
      case 'compact':
        return renderCompactProduct(product, images)
      default:
        return renderLargeProduct(product, images)
    }
  }

  if (!config?.handle) {
    return <View />
  }

  return (
    <Query query={getProductByHandleQuery} variables={{ handle: config?.handle }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <View />
        }
        if (error) return <Text>Error :(</Text>
        const product = _.get(data, 'productByHandle', {})
        let images = _.get(product, 'images.edges', [])
        images = images.filter(
          image =>
            _.get(image, 'node.transformedSrc', '').indexOf('.gif') === -1
        )

        return renderProductMode(product, images)
      }}
    </Query>
  )
}

const styles = {
  compactHolder: {
    ...spacing,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10
  },
  compactContent: {
    flexDirection: 'row',
    marginLeft: -5,
    marginTop: 15,
    marginBottom: 10
  },
  compactText: {
    flex: 1,
    marginLeft: 15,
    height: '100%',
    minHeight: width / 2
  },
  largeHolder: {
    ...spacing,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20
  },
  largePriceHolder: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    flex: 0.5
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' })
  }
}

export default connect(mapStateToProps)(FeaturedProduct)
