import gql from 'graphql-tag'
import { checkoutResponse } from './utils'

export const checkoutCreate = gql`
mutation($lineItems: [CheckoutLineItemInput!]) {
  checkoutCreate(input: {
    lineItems: $lineItems
  }) {
    checkout {
      ${checkoutResponse}
    }
  }
}`

export const checkoutUpdate = gql`
mutation($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
  checkoutLineItemsReplace(
    lineItems: $lineItems,
    checkoutId: $checkoutId
  ) {
    checkout {
      ${checkoutResponse}
    }
  }
}`

export const refreshToken = gql`
mutation customerAccessTokenRenew($customerAccessToken: String!) {
  customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    userErrors {
      field
      message
    }
  }
}
`

export const checkoutAssociate = gql`
mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
  checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
    checkout {
      ${checkoutResponse}
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}
`

export const checkoutShippingUpdate = gql`
mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
  checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
    userErrors {
      field
      message
    }
    checkout {
      ${checkoutResponse}
    }
  }
}`

export const checkoutEmailUpdate = gql`
mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
  checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
    checkout {
      ${checkoutResponse}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`

export const checkoutShippingMethodSelect = gql`
mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
  checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
    checkout {
      ${checkoutResponse}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
`

export const signup = gql`
mutation($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    userErrors {
      field
      message
    }
    customer {
      id
    }
  }
}`

export const login = gql`
mutation($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    userErrors {
      field
      message
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
  }
}`

export const forgotPassword = gql`
mutation($email: String!) {
  customerRecover(email: $email) {
    userErrors {
      field
      message
    }
  }
}`

export const customerUpdate = gql`
  mutation($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
        customer {
        id
        phone
        displayName
        email
        firstName
        lastName
        defaultAddress {
          id
          firstName
          lastName
          name
          address1
          address2
          city
          company
          country
          countryCodeV2
          province
          provinceCode
          zip
        }
      }
      customerUserErrors {
        field
        message
      }
    }
  }
`

export const customerAddressCreate = gql`
  mutation($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
        firstName
        lastName
        name
        address1
        address2
        city
        company
        country
        countryCodeV2
        province
        provinceCode
        zip
      }
      customerUserErrors {
        field
        message
      }
    }
  }
`

export const customerAddressUpdate = gql`
  mutation($customerAccessToken: String!, $address: MailingAddressInput!, $id: ID!) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, address: $address, id: $id) {
      customerAddress {
        id
        firstName
        lastName
        name
        address1
        address2
        city
        company
        country
        countryCodeV2
        province
        provinceCode
        zip
      }
      customerUserErrors {
        field
        message
      }
    }
  }
`

export const customerAddressDelete = gql`
  mutation($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      deletedCustomerAddressId
      customerUserErrors {
        field
        message
      }
    }
  }
`
