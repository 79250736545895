import React from 'react'
import _ from 'lodash'

const DynamicRenderer = ({ items, navigation, theme }) => {
  const _config = {}
  return (
    <>
      {
        items && items.map((item, i) => {
          const Comp = item.component
          if (Comp) {
            const otherProps = {}
            if (!_.isEmpty(item.config)) {
              Object.keys(item.config).map((k) => {
                if (_config[k]) {
                  otherProps[k] = _config[k]
                } else {
                  otherProps[k] = item.config[k]
                }
              })
            }
            return <Comp id={item._id || item.tempId} theme={theme} navigation={navigation} key={`Col-Home-${i}`} {...otherProps} />
          }
        })
      }
    </>
  )
}

export default DynamicRenderer

// EG:

// import Collections from '@containers/Collections'
// import CollectionProducts from '@containers/CollectionProducts'
// import ListTitle from '@components/ListTitle'

// const layout = {
//   Shop: {
//     items: [
//       {
//         component: Collections
//       }
//     ]
//   },
//   Home: {
//     items: [
//       {
//         component: Collections,
//         config: {
//           title: ListTitle('On Sale ', '80% off'),
//           horizontal: true
//         }
//       },
//       {
//         component: CollectionProducts,
//         config: {
//           title: ListTitle('Best ', 'Sellers', true),
//           handle: 'best-sellers',
//           horizontal: true,
//           showFilter: false
//         }
//       }
//     ]
//   },
//   Products: {
//     items: [{
//       component: CollectionProducts,
//       config: {
//         handle: true,
//         showFilter: true
//       }
//     }]
//   }
// }

// export default layout
