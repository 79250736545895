import gql from 'graphql-tag'
import { checkoutResponse, collectionResponse, orderResponse } from './utils'
import { width as mainWidth } from '@utils/scale'
const width = Math.ceil(mainWidth)

export const getCollections = gql`
query($after: String, $limit: Int) {
    collections(first: $limit, after: $after) {
      edges {
        cursor
        node {
          ${collectionResponse}
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const getCollectionsByIds = gql`
  query($ids: [ID!]!) {
    nodes(ids: $ids) {
      ...on Collection {
        ${collectionResponse}
      }
    }
  }
`

export const getCustomer = gql`
  query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      displayName
      email
      firstName
      lastName
      phone
      lastIncompleteCheckout {
        id
      }
      defaultAddress {
        name
        address1
        address2
        city
        company
        country
        countryCodeV2
        province
        provinceCode
        zip
        firstName
        lastName
        name
      }
      addresses(first: 10) {
        edges {
          node {
            id
            address1
            address2
            city
            company
            country
            countryCodeV2
            province
            provinceCode
            zip
            firstName
            lastName
            name
          }
        }
      }
      orders(first: 100) {
        edges {
          node {
            ${orderResponse}
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

export const getCheckoutById = gql`
query($id: ID!) {
  node(id: $id) {
    id
    ... on Checkout {
      ${checkoutResponse}
    }
  }
}
`

export const getCheckoutShippingRates = gql`
query($id: ID!) {
  node(id: $id) {
    id
    ... on Checkout {
      availableShippingRates {
        ready
        shippingRates {
          handle
          priceV2 {
            amount
          }
          title
        }
      }
    }
  }
}
`

export const getBlogs = gql`
  query {
    blogs (first: 50) {
      edges {
        node {
          articles (first: 50) {
            handle
            id
            title
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const getArticles = gql`
  query {
    articles (first: 10) {
      edges {
        node {
          content
          contentHtml
          excerpt
          excerptHtml
          handle
          image {
            id
            transformedSrc(maxWidth: ${width})
          }
          title
          url
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const getShop = gql`
  query {
    shop {
      privacyPolicy {
        url
      },
      termsOfService {
        url
      }
    }
  }
`

export const getShippingCountries = gql`
  query {
    shop {
      shipsToCountries
    }
  }
`
