import React from 'react'
import { View } from 'react-native'
import { WebView } from 'react-native-webview'
import { width, scale } from '@utils/scale'
import HTML from 'react-native-render-html'
import Text from '@components/Text'
import DCImage from '@components/DCImage'
import _ from 'lodash'

const HtmlRenderer = ({ containerStyle = {}, html, theme }) => {
  const paddingHorizontal = containerStyle.paddingLeft || 25

  const renderChildren = (children = [], type = 'h1') => {
    let fontSize

    switch (type) {
      case 'h2':
        fontSize = 20
        break
      case 'h3':
        fontSize = 18
        break
      default:
        fontSize = 24
        break
    }

    return children.map((childComponent) => {
      if (childComponent.props && childComponent.props.children) {
        const innerChildren = childComponent.props.children || []
        return innerChildren.map((finalChild, i) => {
          return <Text key={`${Date.now()}-${i}`} bold fontSize={fontSize}>{_.get(finalChild, 'props.children', '')}</Text>
        })
      }
    })
  }

  return (
    <View>
      {/* eslint-disable-next-line */}
      <HTML
        // ignoredTags={['img']}
        containerStyle={{
          width: width,
          paddingLeft: paddingHorizontal,
          paddingRight: paddingHorizontal,
          paddingBottom: 15,
          ...containerStyle
        }}
        html={html}
        renderers={{
          h1: (htmlAttribs, children) => {
            return !_.isEmpty(children) ? children.map((childHolder) => renderChildren(childHolder)) : <View />
          },
          h2: (htmlAttribs, children) => {
            return !_.isEmpty(children) ? children.map((childHolder) => renderChildren(childHolder, 'h2')) : <View />
          },
          h3: (htmlAttribs, children) => {
            return !_.isEmpty(children) ? children.map((childHolder) => renderChildren(childHolder, 'h3')) : <View />
          },
          iframe: data => {
            let _width = '100%'
            let height = 'auto'
            if (data.width && data.height) {
              const maxWidth = width - (paddingHorizontal * 2)
              if (data.width > maxWidth) {
                const expectedWidthRatio = data.width / maxWidth
                _width = maxWidth
                height = data.height / expectedWidthRatio
              }
            }
            return (
              <View
                key={`iframe-${Date.now()}`}
                style={{
                  width: _width,
                  height,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <WebView
                  style={{
                    backgroundColor: 'transparent',
                    height: height,
                    width: _width,
                    alignSelf: 'center',
                    alignContent: 'center'
                  }}
                  javaScriptEnabled
                  domStorageEnabled
                  source={{ uri: data.src }}
                />
              </View>
            )
          },
          img: (htmlAttribs) => {
            let src = htmlAttribs.src
            if (!src) {
              return <View key={`${Date.now()}-no-img`} />
            }
            if (src.indexOf('http') === -1) {
              src = `https:${src}`
            }
            return <DCImage id='html-renderer-img' checkExists key={`${Date.now()}-img`} scalable style={{ marginTop: 5, marginBottom: 5, width: width - (paddingHorizontal * 2) }} source={{ uri: src }} />
          }
        }}
        staticContentMaxWidth={width - (paddingHorizontal * 2)}
        imagesMaxWidth={width - (paddingHorizontal * 2)}
        ignoredStyles={['font-family', 'letter-spacing', 'line-height']}
        baseFontStyle={{
          fontSize: scale(14),
          lineHeight: scale(15),
          color: theme.contentText
        }}
        tagStyles={{ img: { width: '100%', height: 'auto' } }}
      />
    </View>
  )
}

export default HtmlRenderer
