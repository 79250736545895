import React from 'react'
import { FlatList } from 'react-native'
import MainProductItem from '@components/MainProductItem'
import { width } from '@utils/scale'
import _ from 'lodash'
import CanvasProductItem from '@components/MainProductItem/CanvasProductItem'

const MainProductList = ({
  dynamicHeight,
  goBackBeforeForward,
  pushToTop,
  horizontal,
  imageAlignment,
  items,
  listHeaderComponent,
  loadMore,
  mode,
  navigation,
  onScroll,
  showSaleBadge,
  stickyHeader,
  theme
}) => {
  const renderProductItem = ({ item, index }) => {
    const { node } = item
    return (
      <MainProductItem
        pushToTop={pushToTop}
        goBackBeforeForward={goBackBeforeForward}
        theme={theme}
        index={index}
        type={mode}
        navigation={navigation}
        horizontal={horizontal}
        item={node}
        showSaleBadge={showSaleBadge}
      />
    )
  }

  const renderCanvasProductItem = ({ item, index }) => {
    const { node } = item
    return (
      <CanvasProductItem
        goBackBeforeForward={goBackBeforeForward}
        navigation={navigation}
        item={node}
        horizontal={horizontal}
        imageAlignment={imageAlignment}
        dynamicHeight={dynamicHeight}
        showSaleBadge={showSaleBadge}
        theme={theme}
      />
    )
  }

  const handleOnEndReached = () => {
    if (_.isFunction(loadMore)) {
      loadMore()
    }
  }

  let extraProps = {}

  const list = 'default'
  let horizontalStyles = {}
  let renderMethod = renderProductItem
  switch (mode) {
    case 'canvas':
      renderMethod = renderCanvasProductItem
      break
    case 'split':
    default:
      horizontalStyles = horizontal
        ? {
          style: {
            width: width
          }
        }
        : {
          numColumns: 2,
          contentContainerStyle: {
            ...extraProps.contentContainerStyle,
            minHeight: '100%'
          }
        }
      extraProps = {
        ...horizontalStyles
      }
      break
  }

  switch (list) {
    default:
      return (
        <FlatList
          nativeID={`MAIN_PRODUCT_LIST-${extraProps.numColumns || 1}-${horizontal}`}
          keyboardShouldPersistTaps='always'
          key={horizontal ? `${mode}-hoz` : `${mode}-vert`}
          renderItem={renderMethod}
          ListHeaderComponent={listHeaderComponent}
          stickyHeaderIndices={stickyHeader && listHeaderComponent && [0]}
          showsHorizontalScrollIndicator={false}
          scrollEnabled={horizontal}
          horizontal={horizontal}
          pagingEnabled={horizontal}
          data={items}
          onScrollBeginDrag={() => onScroll && onScroll()}
          keyExtractor={item => _.get(item, 'node.id')}
          onEndReached={handleOnEndReached}
          onEndReachedThreshold={0.5}
          {...extraProps}
        />
      )
  }
}

export default React.memo(MainProductList)
