import React, { useState, useRef, useEffect } from 'react'
import { Animated, View } from 'react-native'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import DCImage from '@components/DCImage'
import { width } from '@utils/scale'
import _ from 'lodash'

const ImageSlider = ({
  sliderWidth,
  sliderHeight,
  itemWidth,
  itemHeight,
  items,
  selectedVariant,
  paginationAnimation,
  theme,
  hidePagination,
  internalKey
}) => {
  const carousel = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [selectedInternalVariant, setSelectedInternalVariant] = useState()

  useEffect(() => {
    setTimeout(() => {
      setSelectedInternalVariant(selectedVariant)
    }, 400)
  }, [selectedVariant])

  useEffect(() => {
    if (_.get(selectedInternalVariant, 'image.transformedSrc')) {
      const activeIndex = _.findIndex(
        _items,
        item =>
          item.node.transformedSrc ===
          _.get(selectedInternalVariant, 'image.transformedSrc')
      )
      if (activeIndex && carousel.current.snapToItem) {
        carousel.current.snapToItem(activeIndex)
      }
    }
  }, [selectedInternalVariant])

  const _renderItem = ({ item, index }) => {
    const _item = item.node
    return (
      <View>
        <DCImage
          scalable
          source={{ uri: _item.transformedSrc }}
          style={{ ...styles.itemImage, width: itemWidth || width, height: itemHeight || width }}
        />
      </View>
    )
  }

  let _items = items
  if (items && items.length === 1) {
    _items = [...items, ...items]
  }

  const pagination = () => {
    const entries = items || []
    return (
      <Animated.View style={{ transform: [{ scale: paginationAnimation }] }}>
        <Pagination
          dotsLength={entries.length}
          activeDotIndex={activeSlide}
          containerStyle={{
            position: 'absolute',
            bottom: -55,
            left: 0,
            right: 0,
            backgroundColor: 'transparent',
            marginTop: 0,
            marginBottom: 0
          }}
          dotStyle={{
            width: 8,
            height: 8,
            borderRadius: 5,
            marginHorizontal: 1,
            backgroundColor: theme.contentText
          }}
          inactiveDotStyle={{}}
          inactiveDotOpacity={0.4}
          inactiveDotScale={0.6}
        />
      </Animated.View>
    )
  }

  return (
    <View style={{ height: hidePagination ? 'auto' : width - 40 }}>
      <Carousel
        key={internalKey}
        loop
        layout='default'
        ref={carousel}
        data={_items}
        renderItem={_renderItem}
        sliderWidth={sliderWidth || width}
        sliderHeight={sliderHeight || width - 40}
        itemHeight={itemHeight || width - 40}
        itemWidth={itemWidth || width}
        onSnapToItem={index => setActiveSlide(index)}
      />
      {!hidePagination && items && items.length !== 1 && pagination()}
    </View>
  )
}

const styles = {
  itemHolder: {},
  itemImage: {
    borderRadius: 0,
    overflow: 'hidden',
    width: width,
    height: width
  }
}

export default ImageSlider
