import React from 'react'
import { View } from 'react-native'
import ShimmerItem from '@components/ShimmerItem'
import { width } from '@utils/scale'

const WebviewSkeleton = ({ backgroundColor }) => {
  const getRandomArbitrary = () => {
    const min = 40
    const max = width / 1.3
    return Math.random() * (max - min) + min
  }

  const renderWebViewItems = i => {
    return (
      <View
        style={{
          width,
          marginTop: 30,
          marginBottom: 10,
          marginLeft: 15
        }}
      >
        {[...Array(25).keys()].map(j => {
          return (
            <View key={`ite-${j}`} style={{ marginBottom: 5 }}>
              <ShimmerItem backgroundColor={backgroundColor} width={getRandomArbitrary()} height={15} />
            </View>
          )
        })}
      </View>
    )
  }

  return <View style={{ width }}>{renderWebViewItems()}</View>
}

export default WebviewSkeleton
