import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import OptionsSelector from '@components/OptionsSelector'

const OptionsManager = ({ selectedVariant, variants, options, theme, onAvailableVariantsChanged }) => {
  const [baseVariant, setBaseVariant] = useState(selectedVariant)
  const [chosenOptions, setChosenOptions] = useState({})
  const [availableOptions, setAvailableOptions] = useState({})
  const [modifiedVariants, setModifiedVariants] = useState([])

  useEffect(() => {
    const getInitialChosenOption = () => {
      if (selectedVariant && !baseVariant) {
        if (selectedVariant.selectedOptions) {
          const chosenOptions = selectedVariant.selectedOptions.reduce(
            (curr, nxt) => {
              curr[nxt.name] = nxt.value
              return curr
            },
            {}
          )
          setChosenOptions(chosenOptions)
          setBaseVariant(selectedVariant)
        }
      }
    }

    getInitialChosenOption()
  }, [selectedVariant])

  useEffect(() => {
    setModifiedVariants(modifyVariants(variants || []))
    setAvailableOptions(getAvailableOptions())
  }, [baseVariant])

  useEffect(() => {
    if (baseVariant) {
      setModifiedVariants(modifyVariants(variants || []))
      setAvailableOptions(getAvailableOptions())
    }
  }, [chosenOptions])

  useEffect(() => {
    if (
      onAvailableVariantsChanged &&
      _.isFunction(onAvailableVariantsChanged)
    ) {
      onAvailableVariantsChanged(modifiedVariants)
    }
  }, [modifiedVariants])

  const setChosenOption = (key, value) => {
    setChosenOptions({ ...chosenOptions, [key]: value })
  }

  const filterOutSelections = _params => {
    const params = _params || variants
    return _.every(
      Object.keys(chosenOptions).map(k => {
        if (params[k] === chosenOptions[k]) return true
      })
    )
  }

  const modifyVariants = variants =>
    variants
      .map(_item => {
        const item = _item.node || _item
        item.selectedObject = item.selectedOptions.reduce((curr, nxt) => {
          curr[nxt.name] = nxt.value
          return curr
        }, {})
        return item
      })
      .filter(variant => filterOutSelections(variant.selectedObject))

  const getAvailableOptions = () => {
    return modifiedVariants.reduce((curr, nxt) => {
      var _curr = {
        ...curr
      }

      nxt.selectedOptions.map(item => {
        if (chosenOptions[item.name]) {
          _curr[item.name] = _.get(
            _.find(options, option => option.name === item.name),
            'values'
          )
          return
        }
        if (_curr[item.name]) {
          if (_curr[item.name].indexOf(item.value) === -1) {
            _curr[item.name].push(item.value)
          }
        } else {
          _curr[item.name] = [item.value]
        }
      })
      return _curr
    }, {})
  }

  return (
    <OptionsSelector
      theme={theme}
      onOptionSelect={(a, b) => setChosenOption(a, b.name)}
      selectedOptions={chosenOptions}
      options={availableOptions}
      allOptions={options}
    />
  )
}

export default OptionsManager
