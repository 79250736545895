import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import PopOverMenu from '@components/PopOverMenu'
import Text from '@components/Text'

const SelectInput = ({
  theme,
  onChange,
  items,
  value,
  hasSearch
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [internalValue, setInternalValue] = useState(
    items.find(i => i.id === value) || {}
  )

  useEffect(() => {
    setInternalValue(items.find(i => (i.id === value || i.name === value)) || {})
  }, [value])

  useEffect(() => {
    setInternalValue(items.find(i => (i.id === value || i.name === value)) || {})
  }, [items])

  const handleSelect = ({ id }) => {
    if (onChange && typeof onChange === 'function') {
      onChange(id)
    }
  }

  return (
    <View>
      <TouchableOpacity
        style={{
          ...styles.inputDefault,
          backgroundColor: theme.pageBackground
        }}
        onPress={() => setIsMenuOpen(!isMenuOpen)}
      >
        <Text style={{ marginLeft: 10 }}>{internalValue.name}</Text>
      </TouchableOpacity>
      <PopOverMenu
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        theme={theme}
        onSelect={handleSelect}
        options={items}
        opened={isMenuOpen}
        hasSearch={hasSearch}
      />
    </View>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    borderRadius: 5,
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelDefault: {
    fontWeight: '600',
    marginVertical: 8
  }
}

export default SelectInput
