import React, { useState } from 'react'
import { Animated, View, TouchableWithoutFeedback } from 'react-native'
import { connect } from 'react-redux'
import Icon from '@components/Icon'
import Text from '@components/Text'
import _ from 'lodash'
import { ifIphoneX } from 'react-native-iphone-x-helper'

const ExpandyTabButton = ({
  cart: { lineItems },
  active,
  tab,
  hideLabel,
  routeName,
  theme,
  onPress
}) => {
  const [animatedValue] = useState(new Animated.Value(1))

  const handlePressIn = () => {
    Animated.spring(animatedValue, {
      toValue: 1.2,
      useNativeDriver: true
    }).start()
  }

  const handlePressOut = () => {
    Animated.spring(animatedValue, {
      toValue: 1,
      friction: 4,
      tension: 60,
      delay: 80,
      useNativeDriver: true
    }).start()
  }

  const renderCartBubble = () => {
    if (!_.isEmpty(lineItems)) {
      return (
        <View style={styles.cartBubble}>
          <Text fontSize={10} color='white' style={styles.cartBubbleText}>
            {lineItems.length}
          </Text>
        </View>
      )
    }
  }

  const animatedStyle = { transform: [{ scale: animatedValue }] }
  return (
    <TouchableWithoutFeedback
      activeOpacity={0.7}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onPress={() => onPress && onPress()}
    >
      <View
        style={{
          alignItems: 'center',
          opacity: theme.activeIconColor || active ? 1 : 0.5
        }}
      >
        <Animated.View style={{ alignItems: 'center', ...animatedStyle }}>
          {routeName === 'Cart' && renderCartBubble()}
          <Icon
            name={tab?.icon}
            size={hideLabel ? 20 : 16}
            color={
              theme.activeIconColor && active
                ? theme.activeIconColor
                : theme.iconColor
            }
            style={{ padding: 5 }}
          />
          {!hideLabel && (
            <Text
              fontSize={11}
              color={
                theme.activeIconColor && active
                  ? theme.activeIconColor
                  : theme.textColor
              }
            >
              {tab?.label || tab?.stack}
            </Text>
          )}
        </Animated.View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const TabBar = ({
  navigation,
  tabs,
  hideLabel,
  hideTabBar,
  cart,
  theme,
  ...rest
}) => {
  const { routes, index } = rest.state
  const themeStyles = _.get(theme, 'colors.tabBar.sameAsHeader')
    ? _.get(theme, 'colors.header')
    : _.get(theme, 'colors.tabBar', {})
  return (
    <View
      style={{
        ...ifIphoneX({
          height: hideTabBar ? 0 : 76
        }, {
          height: hideTabBar ? 0 : 68
        }),
        flexDirection: 'row',
        backgroundColor: themeStyles.backgroundColor
      }}
    >
      {routes.map((route, i) => {
        const routeName = route.name
        const tab = tabs[routeName]
        const active = i === index
        return (
          <View
            style={{ flex: 1, alignItems: 'center', paddingTop: 10 }}
            key={`tab-${i}`}
          >
            <ExpandyTabButton
              theme={themeStyles}
              cart={cart}
              onPress={() => navigation?.navigate(route.name)}
              routeName={routeName}
              active={active}
              tab={tab}
              hideLabel={hideLabel}
            />
          </View>
        )
      })}
    </View>
  )
}

const styles = {
  cartBubble: {
    position: 'absolute',
    backgroundColor: 'red',
    width: 18,
    height: 18,
    zIndex: 1,
    top: -4,
    right: -10,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center'
  },
  cartBubbleText: {
    fontWeight: 'bold'
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    theme: _.get(state, 'config.theme', {})
  }
}

export default connect(mapStateToProps)(TabBar)
