import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Mutation } from 'react-apollo'
import * as Animatable from 'react-native-animatable'
import Input from '@components/Input'
import Text from '@components/Text'
import _ from 'lodash'

const AddressForm = ({
  address = {},
  theme,
  mutation,
  hasUser,
  onCancel,
  onSubmit
}) => {
  const [firstName, setFirstName] = useState(address?.firstName)
  const [lastName, setLastName] = useState(address?.lastName)
  const [email, setEmail] = useState(address?.email)
  const [country, setCountry] = useState(address?.country)
  const [zip, setZip] = useState(address?.zip)
  const [address1, setAddress1] = useState(address?.address1)
  const [address2, setAddress2] = useState(address?.address2)
  const [city, setCity] = useState(address?.city)
  const [province, setProvince] = useState(address?.province)
  const [availableProvinces, setAvailableProvinces] = useState([])

  useEffect(() => {
    if (availableProvinces && availableProvinces.length) {
      if (
        !province ||
        !availableProvinces.find(
          i => i.code === province || i.name === province
        )
      ) {
        setProvince(_.get(_.first(availableProvinces), 'name'))
      }
    }
  }, [JSON.stringify(availableProvinces)])

  const handleOnCancel = () => {
    if (_.isFunction(onCancel)) {
      onCancel()
    }
  }

  const handleSubmit = mutation => {
    if (_.isFunction(onSubmit)) {
      const addr = {
        id: address?.id,
        firstName,
        lastName,
        email,
        country,
        zip,
        address1,
        address2,
        city,
        province
      }
      onSubmit(mutation, addr)
    }
  }

  const disabled = !firstName || !lastName || (!hasUser && !email) || !address1 || !zip

  return (
    <KeyboardAwareScrollView>
      <Animatable.View
        animation='fadeIn'
        style={{
          padding: 10,
          backgroundColor: theme.contentBackground,
          shadowOpacity: 0.1,
          shadowOffset: { height: 1, width: 1 },
          elevation: 3
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Input
            viewStyle={{ flex: 1, marginRight: 5, marginBottom: 2 }}
            label='First Name'
            validation='required'
            labelFontSize={13}
            onComplete={setFirstName}
            inputProps={{
              placeholder: 'First Name',
              defaultValue: firstName
            }}
          />
          <Input
            viewStyle={{ flex: 1, marginBottom: 2 }}
            label='Last Name'
            validation='required'
            labelFontSize={13}
            onComplete={setLastName}
            inputProps={{
              placeholder: 'Last Name',
              defaultValue: lastName
            }}
          />
        </View>
        {!hasUser && (
          <Input
            viewStyle={{ flex: 1, marginBottom: 2 }}
            label='Email'
            validation='email'
            labelFontSize={13}
            onComplete={setEmail}
            inputProps={{
              placeholder: 'Email',
              defaultValue: email
            }}
          />
        )}
        <Input
          viewStyle={{ flex: 1, marginBottom: 2 }}
          label='Address Line 1'
          validation='required'
          labelFontSize={13}
          onComplete={setAddress1}
          inputProps={{
            placeholder: 'Address Line 1',
            defaultValue: address1
          }}
        />
        <Input
          viewStyle={{ flex: 1, marginBottom: 2 }}
          label='Address Line 2'
          labelFontSize={13}
          onComplete={setAddress2}
          inputProps={{
            placeholder: 'Address Line 2',
            defaultValue: address2
          }}
        />
        <Input
          viewStyle={{ flex: 1, marginBottom: 2 }}
          label='City'
          validation='required'
          labelFontSize={13}
          onComplete={setCity}
          inputProps={{
            placeholder: 'City',
            defaultValue: city
          }}
        />
        <Input
          viewStyle={{ flex: 1, marginBottom: 2 }}
          label='Country'
          type='country'
          labelFontSize={13}
          onComplete={setCountry}
          onChangeInternalData={e => setAvailableProvinces(e || [])}
          inputProps={{
            defaultValue: country || 'US',
            placeholder: 'Country'
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {!_.isEmpty(availableProvinces) && (
            <Input
              viewStyle={{ flex: 1, marginRight: 5, marginBottom: 2 }}
              type='select'
              validation={!!availableProvinces && 'required'}
              hasSearch
              label='State'
              labelFontSize={13}
              onComplete={setProvince}
              inputProps={{
                placeholder: 'State / Province',
                items: availableProvinces,
                defaultValue: province
              }}
            />
          )}
          <Input
            viewStyle={{ flex: 1, marginBottom: 2 }}
            validation='required'
            label='Zip code'
            labelFontSize={13}
            onComplete={setZip}
            inputProps={{
              placeholder: 'Zip code',
              defaultValue: zip
            }}
          />
        </View>
        <Mutation mutation={mutation}>
          {(mutation, { loading }) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  flex: 1,
                  marginVertical: 10
                }}
              >
                <TouchableOpacity
                  onPress={handleOnCancel}
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    marginRight: 10
                  }}
                >
                  <Text type='button' bold>
                    CANCEL
                  </Text>
                </TouchableOpacity>
                <View style={{
                  opacity: disabled ? 0.5 : 1
                }}
                >
                  <TouchableOpacity
                    onPress={() => handleSubmit(mutation)}
                    disabled={disabled}
                    style={{
                      backgroundColor: theme.buttonColor,
                      paddingHorizontal: 20,
                      paddingVertical: 10
                    }}
                  >
                    <Text type='button' bold>
                      {loading ? 'LOADING' : 'SUBMIT'}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )
          }}
        </Mutation>
      </Animatable.View>
    </KeyboardAwareScrollView>
  )
}

export default AddressForm
