import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { navigate } from '@navigation/NavigationService'
import { Query } from 'react-apollo'
import * as gql from '@gql/Queries'
import Text from '@components/Text'
import _ from 'lodash'

const PrivacyLinks = ({ color, condensed }) => {
  return (
    <Query query={gql.getShop}>
      {({ loading, error, data }) => {
        if (loading) return <View />
        if (error) return <View />
        if (data) {
          return (
            <View
              style={{
                alignSelf: 'center',
                justifySelf: 'flex-end',
                alignItems: 'center'
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  navigate && navigate('GeneralWebView', {
                    url: _.get(data, 'shop.privacyPolicy.url')
                  })}
                style={{ marginBottom: 10 }}
              >
                <Text fontSize={condensed ? 12 : 14} style={{ color }}>
                  Privacy Policy
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  navigate && navigate('GeneralWebView', {
                    url: _.get(data, 'shop.termsOfService.url')
                  })}
                style={{ marginBottom: 10 }}
              >
                <Text fontSize={condensed ? 12 : 14} style={{ color }}>
                  Terms And Conditions
                </Text>
              </TouchableOpacity>
            </View>
          )
        }
        return <View />
      }}
    </Query>
  )
}

export default PrivacyLinks
