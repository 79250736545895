import React, { useState, useEffect } from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { View, TextInput, TouchableOpacity, Image } from 'react-native'
import flags from '@utils/flags'
import Text from '@components/Text'
import PopOverMenu from '@components/PopOverMenu'
import { allCountries } from 'country-telephone-data'
import _ from 'lodash'

const PhoneInput = ({ theme, inputStyle, value, onEndEditing, onError }) => {
  const [internalValue, setInternalValue] = useState(value || '')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [country, setCountry] = useState({ iso2: 'us', dialCode: '1' }) // TODO: handle input value
  const [countries] = useState(allCountries.map((c) => { return { id: c.iso2, name: c.name } }))
  const [flag, setFlag] = useState()

  useEffect(() => {
    if (value) {
      const parsedValue = parsePhoneNumberFromString(internalValue)
      if (parsedValue && parsedValue.country && parsedValue.nationalNumber) {
        const _country = allCountries.find((_c) => _c.iso2 === _.toLower(parsedValue.country))
        setCountry(_country)
        setInternalValue(parsedValue.nationalNumber)
      }
    }
  }, [value])

  useEffect(() => {
    if (country && country.iso2) {
      setFlag(flags.get(country.iso2))
    }
  }, [country])

  const handleSelect = (e) => {
    const _country = allCountries.find((_c) => _c.iso2 === e.id)
    setCountry(_country)
  }

  const handleUpdate = (e) => {
    setInternalValue(e)
  }

  const handleSubmit = () => {
    const phone = parsePhoneNumberFromString(internalValue, _.upperCase(country.iso2))
    if (phone && phone.isValid() && phone.number) {
      if (onEndEditing && typeof onEndEditing === 'function') {
        onEndEditing(phone.number)
      }
      return
    }
    if (onError && typeof onError === 'function') {
      onError && onError('Phone number is invalid')
    }
  }

  return (
    <View
      style={{
        ...styles.inputDefault,
        ...inputStyle,
        backgroundColor: theme.pageBackground
      }}
    >
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          left: 12,
          borderRightWidth: 1,
          borderRightColor: theme.contentText,
          paddingRight: 10
        }}
        onPress={() => setIsMenuOpen(!isMenuOpen)}
      >
        <View
          style={{
            borderRadius: 2,
            overflow: 'hidden',
            marginRight: 5
          }}
        >
          <Image
            source={flag}
            style={{ width: 30, height: 20, resizeMode: 'stretch' }}
          />
        </View>
        <Text fontSize={11}>+ {country.dialCode}</Text>
      </TouchableOpacity>
      <TextInput
        style={{
          ...styles.input,
          color: theme.contentText
        }}
        keyboardType='numeric'
        value={internalValue}
        onChangeText={handleUpdate}
        onEndEditing={handleSubmit}
      />
      <PopOverMenu
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        theme={theme}
        onSelect={handleSelect}
        options={countries}
        opened={isMenuOpen}
      />
    </View>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 5,
    paddingLeft: 2.5
  },
  input: {
    height: 46,
    width: '100%',
    marginLeft: 20
  },
  labelDefault: {
    fontWeight: '600',
    marginVertical: 8
  }
}

export default PhoneInput
