import React, { useEffect, useState } from 'react'
import { ScrollView, View, TouchableOpacity, RefreshControl } from 'react-native'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import * as gql from '@gql/Queries'
import Text from '@components/Text'
import Icon from '@components/Icon'
import HeaderBar from '@components/HeaderBar'
import TouchableExpand from '@components/TouchableExpand'
import { moneyV2 } from '@utils'
import moment from 'moment'
import _ from 'lodash'

const OrdersScreen = ({ theme, navigation, user, client, orders: _orders }) => {
  const isFocused = useIsFocused()
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [orders, setOrders] = useState(_orders)

  useEffect(() => {
    setOrders(_orders)
  }, [_orders])

  useEffect(() => {
    const getOrders = async () => {
      const res = await client.query({
        fetchPolicy: 'no-cache',
        query: gql.getCustomer,
        variables: {
          customerAccessToken: user.accessToken
        }
      })

      const newOrders = _.get(res, 'data.customer.orders.edges')
      setOrders(newOrders)
    }

    if (isFocused && user.accessToken) {
      getOrders()
    }
  }, [isFocused])

  useEffect(() => {
    const getOrders = async () => {
      const res = await client.query({
        fetchPolicy: 'no-cache',
        query: gql.getCustomer,
        variables: {
          customerAccessToken: user.accessToken
        }
      })

      const newOrders = _.get(res, 'data.customer.orders.edges')
      setOrders(newOrders)
    }

    if (!user.accessToken) {
      setOrders([])
    }

    if (isFocused && user.accessToken) {
      getOrders()
    }
  }, [user.accessToken])

  const renderEmpty = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          paddingTop: 80
        }}
      >
        <Icon type='light' name='shopping-cart' size={40} />
        <View style={{ padding: 20 }}>
          <Text
            secondary
            fontSize={14}
            style={{ fontWeight: '300', textAlign: 'center' }}
          >
            You have no orders yet!
          </Text>
          {!user.accessToken && (
            <Text
              secondary
              fontSize={12}
              style={{ paddingTop: 10, fontWeight: '300' }}
            >
              In order to see your previous orders please login
            </Text>
          )}
        </View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Shop')}
          style={{
            backgroundColor: theme.buttonColor,
            padding: 15,
            paddingHorizontal: 40
          }}
        >
          <Text fontSize={14} type='button' bold>
            GET SHOPPING
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  const renderOrders = orders => {
    return orders.map(({ node: order }) => {
      return (
        <TouchableExpand
          onPress={() =>
            navigation.navigate('Webview', { url: order.statusUrl })}
          key={order.orderNumber}
          style={{
            backgroundColor: theme.contentBackground,
            padding: 20,
            marginBottom: 10
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <View>
              <Text bold fontSize={16}>
                Order: {order.name}
              </Text>
              <Text>
                {moment(order.processedAt).format('Do MMM YYYY, H:mm')}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ marginRight: 10 }} bold fontSize={18}>
                {moneyV2(_.get(order, 'totalPriceV2'))}
              </Text>
              <Icon
                style={{ marginRight: -5 }}
                name='chevron-right'
                color={theme.buttonColor}
                size={18}
              />
            </View>
          </View>
        </TouchableExpand>
      )
    })
  }

  const handleRefresh = async () => {
    if (!user.accessToken) return
    setIsRefreshing(true)
    const res = await client.query({
      fetchPolicy: 'no-cache',
      query: gql.getCustomer,
      variables: {
        customerAccessToken: user.accessToken
      }
    })
    const newOrders = _.get(res, 'data.customer.orders.edges', [])
    setOrders(newOrders)
    setIsRefreshing(false)
  }

  return (
    <>
      <ScrollView
        automaticallyAdjustContentInsets
        contentContainerStyle={{
          padding: 10,
          paddingBottom: 80,
          paddingTop: 30
        }}
        style={{ flex: 1, backgroundColor: theme.pageBackground }}
        refreshControl={<RefreshControl tintColor={theme.contentText} refreshing={isRefreshing} onRefresh={handleRefresh} />}
      >
        {orders?.length
          ? renderOrders(_.sortBy(
            orders,
            ['processedAt']
          ).reverse())
          : renderEmpty()}
      </ScrollView>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: _.get(state, 'user', {}),
    theme: _.get(state, 'config.theme.colors.page'),
    orders: _.get(state, 'user.customer.orders.edges', [])
  }
}

OrdersScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

export default connect(mapStateToProps)(withApollo(OrdersScreen))
