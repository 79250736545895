import React, { useEffect, useState } from 'react'
import { View, FlatList, TouchableOpacity, ScrollView } from 'react-native'
import Icon from '@components/Icon'
import base64 from 'base-64'
import { width } from '@utils/scale'
import Stars from '@components/Stars'
import Text from '@components/Text'
import DCImage from '@components/DCImage'
import TouchableExpand from '@components/TouchableExpand'
import ReadMore from 'react-native-read-more-text'
import _ from 'lodash'

const ImageRenderer = ({ source, setScrollEnabled, navigation }) => {
  const handleImageNavigation = () => {
    const images = _.isString(source) && !_.isArray(source) ? [source] : source
    navigation.navigate('ImageModal', { images })
  }
  if (_.isString('string') && !_.isArray(source)) {
    return (
      <TouchableExpand
        onPress={handleImageNavigation}
        key={source}
        style={{
          padding: 5,
          marginTop: 10,
          borderRadius: 10,
          overflow: 'hidden'
        }}
      >
        <DCImage
          source={{ uri: source }}
          style={{
            width: width / 5,
            height: width / 5,
            borderRadius: 5
          }}
        />
      </TouchableExpand>
    )
  }

  if (_.isArray(source)) {
    return (
      <ScrollView
        onScrollBeginDrag={() => setScrollEnabled(false)}
        onScrollEndDrag={() => setScrollEnabled(true)}
        horizontal
        showsHorizontalScrollIndicator={false}
        style={{ flexDirection: 'row', width: width - 60 }}
      >
        {source.map(img => {
          return (
            <TouchableExpand
              onPress={handleImageNavigation}
              key={img}
              style={{
                padding: 5,
                marginTop: 10,
                borderRadius: 10,
                overflow: 'hidden'
              }}
            >
              <DCImage
                source={{ uri: img }}
                style={{
                  width: width / 5,
                  height: width / 5,
                  borderRadius: 5
                }}
              />
            </TouchableExpand>
          )
        })}
      </ScrollView>
    )
  }
  return <View />
}

const StampedReviews = ({
  theme,
  productId,
  product,
  domain,
  apiKey,
  minRating = 4,
  storeHash,
  navigation
}) => {
  const [reviews, setReviews] = useState([])
  const [scrollEnabled, setScrollEnabled] = useState(true)
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const id = _.replace(
          base64.decode(productId),
          'gid://shopify/Product/',
          ''
        )
        if (!domain || !apiKey || !id) return

        const res = await fetch(
          `https://stamped.io/api/widget/reviews?productId=${id}&minRating=${minRating}&take=10&page=1&storeUrl=${domain}&apiKey=${apiKey}`
        )
        const d = await res.json()
        const { data } = d
        if (data) {
          setReviews(data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (productId && apiKey) {
      fetchReviews()
    }
  }, [productId])

  const renderTruncatedFooter = handlePress => {
    return (
      <Text bold type='heading' style={{ marginTop: 5 }} onPress={handlePress}>
        Read more
      </Text>
    )
  }

  const renderRevealedFooter = handlePress => {
    return (
      <Text bold type='heading' style={{ marginTop: 5 }} onPress={handlePress}>
        Show less
      </Text>
    )
  }

  const renderReview = ({ item }) => {
    return (
      <View style={{ width, padding: 30, paddingTop: 10 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <DCImage
              source={{ uri: `https:${item?.avatar}` }}
              style={{ width: 32, height: 32, borderRadius: 16 }}
            />
            <Text fontSize={13} style={{ marginLeft: 10 }}>
              {item?.author}
            </Text>
          </View>
          <Stars rating={item?.reviewRating} />
        </View>
        <Text bold style={{ marginBottom: 5 }}>
          {item?.reviewTitle}
        </Text>
        <ReadMore
          renderRevealedFooter={renderRevealedFooter}
          renderTruncatedFooter={renderTruncatedFooter}
          numberOfLines={3}
        >
          <Text>{item?.reviewMessage}</Text>
        </ReadMore>
        {!_.isEmpty(item?.reviewUserPhotos) && <ImageRenderer source={item?.reviewUserPhotos} setScrollEnabled={setScrollEnabled} navigation={navigation} />}
      </View>
    )
  }

  if (!productId || !domain || !apiKey || !navigation) return <View />
  // FOR TESTING

  // const treviewUserPhotos = [
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYXiRuQYknvDDiVNjb_naQFurOeXGIuNKA8w&usqp=CAU',
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiVRvBREiFwJ3gXloKu4ISXLhZMNBUeI06UQ&usqp=CAU',
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4olLYV51yuyta-2lDjD0dCtqHxgPfynyjwQ&usqp=CAU',
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB4guFnVs13D_MEbt9Uc1QDLQI9j7x0_gZQw&usqp=CAU',
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYwPUpDbV8ft7fDU9OVEFHdbnluvrsCXf6kcVsJ7BXh8uv8oWqZWxgMHvdG7yq8mvMDwiL7BA&usqp=CAc'
  // ]
  // const treviewUserPhotos2 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYwPUpDbV8ft7fDU9OVEFHdbnluvrsCXf6kcVsJ7BXh8uv8oWqZWxgMHvdG7yq8mvMDwiL7BA&usqp=CAc'
  // const _reviews = reviews.map(te => ({
  //   ...te,
  //   reviewUserPhotos: treviewUserPhotos
  // }))

  return (
    <View style={{ width }}>
      {!_.isEmpty(reviews) && (
        <View>
          <View style={{ padding: 15 }}>
            <Text fontSize={20} bold type='heading'>
              Reviews
            </Text>
          </View>
          <View style={styles.flatListHolder}>
            <FlatList
              scrollEnabled={scrollEnabled}
              horizontal
              data={reviews}
              renderItem={renderReview}
              keyExtractor={item => `${item.id}`}
              pagingEnabled
              showsHorizontalScrollIndicator={false}
            />
          </View>
        </View>
      )}
      {_.isEmpty(reviews) && (
        <View>
          <View style={{ padding: 15 }}>
            <Text fontSize={20} bold type='heading'>
              Reviews
            </Text>
            <Text style={{ padding: 15, marginBottom: 0 }}>
              No reviews yet, be the first!
            </Text>
          </View>
        </View>
      )}
      {!_.isEmpty(storeHash) && !_.isEmpty(product) && (
        <View style={{ padding: 30 }}>
          <TouchableOpacity
            onPress={() => navigation.navigate('ReviewForm', { product })}
            style={{
              ...styles.button,
              borderColor: theme.contentText,
              borderWidth: 1
            }}
          >
            <Icon
              name='edit-3'
              size={15}
              style={{ marginRight: 10 }}
              color={theme.contentText}
            />
            <Text bold style={{ ...styles.buttonText }}>
              Write A Review
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

const styles = {
  flatListHolder: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 0.1
  },
  button: {
    width: '80%',
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    textAlign: 'center',
    fontWeight: 'bold'
  }
}

export default StampedReviews
