import React, { useEffect, useState } from 'react'
import { Animated, View, TouchableOpacity, Share } from 'react-native'
import PropTypes from 'prop-types'
import ImageSlider from '@components/ImageSlider'
import ProductItemPrice from '@components/ProductItemPrice'
import Text from '@components/Text'
import { width } from '@utils/scale'
import _ from 'lodash'
import OptionsManager from '@components/OptionsManager'
import Icon from '@components/Icon'
import AddToCartButton from '@components/AddToCartButton'
import MainProductList from '@components/MainProductList'
import { LinearGradient } from 'expo-linear-gradient'
import HtmlRenderer from '@components/HtmlRenderer'
import { ifIphoneX } from 'react-native-iphone-x-helper'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import { useNavigation } from '@react-navigation/native'
import MetaFieldManager from '@components/MetaFieldManager'

const FullProduct = ({
  config,
  disableRecommendations,
  onClosePress,
  product,
  theme
}) => {
  const navigation = useNavigation()
  const [selectedVariant, setSelectedVariant] = useState()
  const [filteredVariants, setFilteredVariants] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [scrollY] = useState(new Animated.Value(0))
  // MetaFields
  const getRecommendationsByIdQuery = useProductQuery('getRecommendationsById')
  const metaFields = _.get(product, 'metafields.edges')

  useEffect(() => {
    const variants = _.get(product, 'variants.edges', []).map(item => {
      const newItem = item.node
      newItem.opts = newItem.selectedOptions.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr.value
        }
      }, {})
      return newItem
    })

    let selectedVariant = _.first(variants)
    if (selectedVariant && selectedVariant.node) {
      selectedVariant = selectedVariant.node
    }
    if (selectedVariant && selectedVariant.selectedOptions) {
      selectedVariant.selectedOptions.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr.value
        }
      }, {})

      setSelectedVariant(selectedVariant)
      setFilteredVariants(variants)
    }
    setSelectedVariant(selectedVariant)
    setFilteredVariants(variants)
  }, [])

  useEffect(() => {
    if (filteredVariants && filteredVariants.length === 1) {
      setSelectedVariant(_.head(filteredVariants))
    }
  }, [filteredVariants])

  const handleChangedVariants = variants => {
    setFilteredVariants(variants)
  }

  const handleOnClosePress = () => {
    if (_.isFunction(onClosePress)) {
      onClosePress()
    }
  }

  const handleOnShare = async () => {
    const { onlineStoreUrl } = product
    console.log({
      message: `Take a look at ${product?.title}`,
      url: onlineStoreUrl
    })
    try {
      await Share.share({
        message: `Take a look at ${product?.title}`,
        url: onlineStoreUrl
      })
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const headerHeight = scrollY.interpolate({
    inputRange: [-70, 0, 35],
    outputRange: [1.3, 1, 0],
    extrapolate: 'clamp'
  })

  const headerPosition = scrollY.interpolate({
    inputRange: [38, 335],
    outputRange: [0, -(width / 4)],
    extrapolate: 'clamp'
  })

  const scrollerTop = scrollY.interpolate({
    inputRange: [38, 335],
    outputRange: [0, -(width / 4)],
    extrapolate: 'clamp'
  })

  let images = _.get(product, 'images.edges', [])
  images = images.filter(
    image => _.get(image, 'node.transformedSrc', '').indexOf('.gif') === -1
  )
  return (
    <View style={styles.mainHolder}>
      {/* Image Slider */}
      <Animated.View
        style={{
          ...styles.imageHolder,
          transform: [{ translateY: headerPosition }]
        }}
      >
        <ImageSlider
          internalKey={product?.id}
          theme={theme}
          selectedVariant={selectedVariant}
          paginationAnimation={headerHeight}
          items={images}
        />
      </Animated.View>
      {/* Main View */}
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        automaticallyAdjustContentInsets
        scrollEnabled
        style={{
          ...styles.scrollContainer,
          transform: [{ translateY: scrollerTop }]
        }}
        contentContainerStyle={{
          ...styles.scrollContent,
          marginTop: images.length > 1 ? 30 : 10
        }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true }
        )}
      >
        {/* Top Section */}
        <View style={styles.titleHolder}>
          <Text
            numberOfLines={3}
            ellipsizeMode='tail'
            fontSize={19}
            style={styles.title}
            bold
          >
            {product?.title}
          </Text>
          <TouchableOpacity
            onPress={handleOnShare}
            style={{ padding: 10, position: 'absolute', right: 15, top: -10 }}
          >
            <Icon size={18} color={theme.contentText} name='share' />
          </TouchableOpacity>
          {!_.get(config, 'hideProductSku') && (
            <Text
              numberOfLines={1}
              ellipsizeMode='tail'
              secondary
              fontSize={13}
              style={{ maxWidth: width - 100, marginBottom: 3 }}
            >
              {selectedVariant && `SKU ${selectedVariant.sku}`}
            </Text>
          )}
          {!_.isEmpty(metaFields) && (
            <View style={{ marginTop: 10 }}>
              <MetaFieldManager meta={metaFields} />
            </View>
          )}
        </View>
        <View style={{ marginBottom: 10, width: '100%', alignItems: 'center' }}>
          <OptionsManager
            theme={theme}
            selectedVariant={selectedVariant}
            options={_.get(product, 'options')}
            variants={_.get(product, 'variants.edges')}
            onAvailableVariantsChanged={handleChangedVariants}
          />
        </View>
        {/* Description */}
        <View>
          <HtmlRenderer theme={theme} html={product?.descriptionHtml} />
        </View>
        {/* Recommendations */}
        {!disableRecommendations && config?.dynamicProductRecommendations && (
          <Query
            query={getRecommendationsByIdQuery}
            variables={{ productId: product.id }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <View />
              }
              if (error) return <View />
              let products = _.get(data, 'productRecommendations', [])
              if (products && products.length > 0) {
                products = products.map(product => ({ node: { ...product } }))
                return (
                  <View style={{ flex: 1 }}>
                    <Text style={{ marginLeft: 15 }} type='heading' bold fontSize={18}>{config?.recommendationsTitle}</Text>
                    <MainProductList
                      pushToTop
                      theme={theme}
                      horizontal
                      items={products}
                      navigation={navigation}
                    />
                  </View>
                )
              }
              return <View />
            }}
          </Query>
        )}
        {/* Product Reviews */}
        <MetaFieldManager mode='full-reviews' meta={metaFields} productId={product.id} product={product} navigation={navigation} />
      </Animated.ScrollView>
      {/* Bottom Section */}
      <LinearGradient
        pointerEvents='none'
        colors={[
          `${theme.contentBackground}00`,
          theme.contentBackground,
          theme.contentBackground,
          theme.contentBackground
        ]}
        style={{
          ...styles.gradient,
          ...ifIphoneX(
            {
              height: 230
            },
            {
              height: 180
            }
          )
        }}
      />
      <View
        style={{
          ...styles.bottomHolder,
          ...ifIphoneX(
            {
              bottom: 30
            },
            {
              bottom: 15
            }
          )
        }}
      >
        <View
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: 10
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => {
                if (quantity - 1 > 0) {
                  setQuantity(quantity - 1)
                }
              }}
            >
              <Icon color={theme.contentText} size={24} name='minus-circle' />
            </TouchableOpacity>
            <Text
              fontSize={19}
              bold
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {quantity}
            </Text>
            <TouchableOpacity
              onPress={() => {
                setQuantity(quantity + 1)
              }}
            >
              <Icon size={24} color={theme.contentText} name='plus-circle' />
            </TouchableOpacity>
          </View>
          <ProductItemPrice
            style={{ alignSelf: 'flex-end' }}
            fontSize={25}
            item={selectedVariant || product}
            isVariant
            stacked
          />
        </View>
        <AddToCartButton
          quantity={quantity}
          variant={selectedVariant}
          disabled={!selectedVariant || !selectedVariant.availableForSale}
          onAddToCart={handleOnClosePress}
        />
      </View>
    </View>
  )
}

FullProduct.propTypes = {
  theme: PropTypes.shape({
    contentBackground: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired
  }),
  product: PropTypes.object.isRequired
}

const styles = {
  mainHolder: {
    flex: 1,
    alignItems: 'center',
    width: '100%'
  },
  imageHolder: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflow: 'hidden',
    width: width,
    height: width + 30
  },
  titleHolder: {
    marginTop: 10,
    marginBottom: 5,
    alignSelf: 'flex-start',
    marginLeft: 25,
    position: 'relative',
    width: width - 25
  },
  title: {
    textAlign: 'left',
    paddingBottom: 0,
    fontWeight: '500',
    maxWidth: width - 80
  },
  bottomHolder: {
    position: 'absolute',
    left: 30,
    right: 30,
    bottom: 30
  },
  scrollContainer: {
    width: width,
    flex: 1,
    paddingBottom: 30,
    marginTop: -70
  },
  scrollContent: {
    width: width,
    alignItems: 'center',
    marginTop: 30,
    paddingBottom: '70%'
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 230
  }
}

export default FullProduct
