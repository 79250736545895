import React from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import _ from 'lodash'
import FullProduct from '@components/FullProduct'
import ModalContainer from '@containers/ModalContainer'
import FullProductSkeleton from '@components/Skeletons/FullProductSkeleton'

const ProductByHandle = ({
  config,
  disableRecommendations,
  handle,
  onClosePress,
  theme
}) => {
  const getProductByHandleQuery = useProductQuery('getProductByHandle')
  return (
    <ModalContainer
      padTop={disableRecommendations}
      theme={theme}
      handleBack={onClosePress}
    >
      <Query query={getProductByHandleQuery} variables={{ handle }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <View
                style={{
                  position: 'absolute',
                  top: -50,
                  left: 0,
                  right: 0,
                  bottom: 0
                }}
              >
                <FullProductSkeleton theme={theme} />
              </View>
            )
          }
          if (error) return <Text>Error :(</Text>
          const product = _.get(data, 'productByHandle', {})
          return (
            <FullProduct
              product={product}
              onClosePress={onClosePress}
              theme={theme}
              config={config}
              disableRecommendations={disableRecommendations}
            />
          )
        }}
      </Query>
    </ModalContainer>
  )
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    config: _.get(state, 'config.theme.productPage', {
      dynamicProductRecommendations: false
    })
  }
}

export default connect(mapStateToProps)(ProductByHandle)
