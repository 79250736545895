import React, { useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import { ProductCollectionSortKeys } from '@gql/Enums'
import _ from 'lodash'
import Text from '@components/Text'
import Icon from '@components/Icon'
import MainProductList from '@components/MainProductList'
import CollectionProductsSkeleton from '@components/Skeletons/CollectionProductsSkeleton'
// import ProductListLoader from '@components/ProductListLoader'

const MainCollectionProducts = ({
  blockLoad,
  dynamicHeight,
  handle,
  hideHeader,
  horizontal,
  imageAlignment,
  limit,
  listHeaderComponent,
  navigation,
  mode,
  reverse: propsReverse,
  showSaleBadge,
  sort: propsSort,
  stickyHeader,
  theme
}) => {
  const [_sort] = useState(ProductCollectionSortKeys.BEST_SELLING)
  const [_reverse] = useState(false)
  const sort = propsSort || _sort
  const reverse = propsReverse || _reverse
  const getProductsForCollectionQuery = useProductQuery('getProductsForCollection')
  const header = !hideHeader && listHeaderComponent

  const renderEmpty = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Icon type='light' name='shopping-bag' size={40} />
        <Text
          secondary
          fontSize={14}
          style={{ padding: 20, fontWeight: '300' }}
        >
          No products found.
        </Text>
        <TouchableOpacity
          onPress={() => navigation && navigation.goBack()}
          style={{
            backgroundColor: theme.buttonColor,
            padding: 15,
            paddingHorizontal: 40
          }}
        >
          <Text fontSize={14} type='button' bold>
            GET SHOPPING
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View>
      <Query
        query={getProductsForCollectionQuery}
        variables={{
          handle,
          sort: sort,
          reverse: reverse,
          limit: limit || 15
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading) {
            return (
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  padding: 15
                }}
              >
                <CollectionProductsSkeleton theme={theme} padded />
              </View>
            )
          }
          if (error) return <Text>Error :(</Text>
          const pageInfo = _.get(data, 'collectionByHandle.products.pageInfo')
          const products = _.get(data, 'collectionByHandle.products.edges', [])
          const cursor = _.get(_.last(products), 'cursor')
          if (_.isEmpty(products)) {
            return renderEmpty()
          }
          return (
            <MainProductList
              theme={theme}
              loading={loading}
              stickyHeader={stickyHeader}
              listHeaderComponent={header}
              mode={mode}
              horizontal={horizontal}
              items={products}
              navigation={navigation}
              dynamicHeight={dynamicHeight}
              imageAlignment={imageAlignment}
              showSaleBadge={showSaleBadge}
              loadMore={() => {
                if (!blockLoad && pageInfo && pageInfo.hasNextPage) {
                  fetchMore({
                    query: getProductsForCollectionQuery,
                    variables: {
                      after: cursor,
                      handle,
                      sort: sort,
                      reverse: reverse,
                      limit: 10
                    },
                    updateQuery: (prevResult, { fetchMoreResult }) => {
                      const newObj = {
                        ...fetchMoreResult,
                        collectionByHandle: {
                          ...fetchMoreResult.collectionByHandle,
                          products: {
                            ...fetchMoreResult.collectionByHandle.products,
                            edges: _.unionBy(
                              [
                                ..._.get(
                                  prevResult,
                                  'collectionByHandle.products.edges',
                                  []
                                ),
                                ..._.get(
                                  fetchMoreResult,
                                  'collectionByHandle.products.edges',
                                  []
                                )
                              ],
                              'node.id'
                            )
                          }
                        }
                      }
                      return newObj
                    }
                  })
                }
              }}
            />
          )
        }}
      </Query>
    </View>
  )
}

export default MainCollectionProducts
