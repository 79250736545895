import React from 'react'
import { ScrollView, View } from 'react-native'
import { connect } from 'react-redux'
import HeaderBar from '@components/HeaderBar'
import CustomiserCollectionProducts from '@containers/CustomiserCollectionProducts'
import CustomiserCollections from '@containers/CustomiserCollections'
import EmbeddedVideo from '@components/EmbeddedVideo'
import ImageAndText from '@components/ImageAndText'
import ImageOnly from '@components/ImageOnly'
import InstaItemOnly from '@components/InstaItemOnly'
import InstaItemWithText from '@components/InstaItemWithText'
import FeaturedProduct from '@components/FeaturedProduct'
import Divider from '@components/Divider'
import DynamicRenderer from '@components/DynamicRenderer'
import _ from 'lodash'
import AnnouncementBar from '@components/AnnouncementBar'
import RichText from '@components/RichText'
import ProductCarousel from '@components/ProductCarousel'

const HomeScreen = ({ theme, navigation, layout: _layout }) => {
  const mapTypeToComponent = () => {
    let layout = _.cloneDeep(_layout)
    layout = layout.filter(item => item.type !== 'AnnouncementBar')
    return layout.map(item => {
      switch (item.type) {
        case 'CollectionProducts':
          if (item.config && item.config.mode === 'radial') {
            item.config.horizontal = true
          }
          item.component = CustomiserCollectionProducts
          break
        case 'Collections':
          item.component = CustomiserCollections
          break
        case 'ImageAndText':
          item.component = ImageAndText
          break
        case 'Image':
          item.component = ImageOnly
          break
        case 'EmbeddedVideo':
          item.component = EmbeddedVideo
          break
        case 'InstaPost':
          item.component = InstaItemOnly
          break
        case 'InstaPostWithText':
          item.component = InstaItemWithText
          break
        case 'FeaturedProduct':
          item.component = FeaturedProduct
          break
        case 'Divider':
          item.component = Divider
          break
        case 'RichText':
          item.component = RichText
          break
        case 'ProductSlider':
          item.component = ProductCarousel
          break
        default:
          break
      }
      return item
    })
  }
  const staticItems =
    _.isArray(_layout) &&
    _.cloneDeep(_layout).filter(item => item.type === 'AnnouncementBar')
  const items = mapTypeToComponent()

  return (
    <ScrollView
      nativeID='HOME'
      stickyHeaderIndices={[_.isEmpty(staticItems) ? null : 0]}
      contentContainerStyle={{ paddingBottom: 20 }}
      style={{
        flex: 1,
        paddingBottom: 20,
        backgroundColor: theme.pageBackground
      }}
    >
      {!_.isEmpty(staticItems) && (
        <View nativeID='AnnouncementsHeaderHolder' style={{ marginBottom: -12 }}>
          {staticItems.map((si, i) => (
            <AnnouncementBar key={`AB-${i}`} config={si.config} navigation={navigation} />
          ))}
        </View>
      )}
      <DynamicRenderer items={items} navigation={navigation} theme={theme} />
    </ScrollView>
  )
}

HomeScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    layout: _.get(state, 'config.theme.homePage.layout', []),
    config: _.get(state, 'config.theme.homePage', {}),
    checkout: _.get(state, 'cart.checkout', {})
  }
}

export default connect(mapStateToProps)(HomeScreen)
