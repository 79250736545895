import React from 'react'
import { View } from 'react-native'
import Stars from '@components/Stars'
import _ from 'lodash'
import { connect } from 'react-redux'
import StampedReviews from '@components/FullProductReviews/StampedReviews'

const getStampedReviewMeta = meta => {
  try {
    const smallData = meta?.value && meta?.value.slice(0, 1000)
    const ratingRegex = new RegExp(/data-rating="(.[^"]+)"/g)
    const countRegex = new RegExp(/data-count="([0-9])"/g)
    const rating = _.head(_.tail(ratingRegex.exec(smallData)))
    const count = _.head(_.tail(countRegex.exec(smallData)))
    return { count, rating }
  } catch (e) {
    console.log('Stamped error', e)
    return {}
  }
}

const getSPRReviewMeta = meta => {
  try {
    const regEx = new RegExp(/<script[^>]*>[\s\S]*?<\/script>/g)
    let str = _.head(regEx.exec(meta?.value))
    str = _.replace(_.tail(_.split(str, '">')), '</script>', '')
    const data = JSON.parse(str)
    return { count: data?.reviewCount, rating: data?.ratingValue }
  } catch (e) {
    console.log('SPR error', e)
    return {}
  }
}

const getMetaByNamespace = (meta, namespace) => {
  const extractedMeta =
    _.isArray(meta) &&
    _.get(
      _.head(meta.filter(item => item?.node?.namespace === namespace)),
      'node'
    )
  if (
    extractedMeta?.key === 'reviews' &&
    extractedMeta?.namespace === namespace &&
    !_.isEmpty(extractedMeta?.value)
  ) {
    return extractedMeta
  }
  return null
}

const MetaFieldManager = ({
  meta,
  mode = 'reviews',
  integrations = {},
  domain,
  productId,
  product,
  theme,
  navigation
}) => {
  const handleReviews = () => {
    if (!integrations?.reviews?.enabled) return <View />

    let internalMeta = {}
    switch (integrations?.reviews?.provider) {
      case 'STAMPED':
        internalMeta = getMetaByNamespace(meta, 'stamped')
        if (!_.isEmpty(internalMeta)) {
          const data = getStampedReviewMeta(internalMeta)
          return <Stars {...data} />
        }
        return <View />
      case 'SPR':
        internalMeta = getMetaByNamespace(meta, 'spr')
        if (!_.isEmpty(internalMeta)) {
          const data = getSPRReviewMeta(internalMeta)
          return <Stars {...data} />
        }
        return <View />
      default:
        return <View />
    }
  }

  const handleFullReviews = () => {
    if (!integrations?.reviews?.enabled) return <View />
    switch (integrations?.reviews?.provider) {
      case 'STAMPED':
        if (
          !!domain &&
          integrations?.reviews?.apiKey &&
          !!productId
        ) {
          return (
            <StampedReviews
              navigation={navigation}
              theme={theme}
              domain={domain}
              apiKey={integrations?.reviews?.apiKey}
              minRating={integrations?.reviews?.minRating}
              productId={productId}
              product={product}
              storeHash={integrations?.reviews?.storeHash}
            />
          )
        }
        return <View />
      default:
        return <View />
    }
  }

  switch (mode) {
    case 'reviews':
      return handleReviews()
    case 'full-reviews':
      return handleFullReviews()
    default:
      return <View />
  }
}

const mapStateToProps = state => {
  return {
    domain: _.get(state, 'config.domain'),
    integrations: _.get(state, 'config.integrations', { reviews: {} }),
    theme: _.get(state, 'config.theme.colors.page', {})
  }
}

export default connect(mapStateToProps)(MetaFieldManager)
