import React, { useState } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import MainCollectionProducts from '@containers/MainCollectionProducts'
import HeaderBar from '@components/HeaderBar'
import _ from 'lodash'
import { ProductCollectionSortKeys } from '@gql/Enums'
import SectionHeader from '@components/SectionHeader'

const filters = [
  { id: 'TITLE', title: 'Name' },
  { id: 'PRICE', title: 'Price' },
  { id: 'BEST_SELLING', title: 'Best Selling' },
  { id: 'CREATED', title: 'Newest' }
]

const CollectionProductsScreen = ({ theme, config, navigation, route }) => {
  const [sort, setSort] = useState(ProductCollectionSortKeys.BEST_SELLING)
  const [reverse, setReverse] = useState(false)

  const handle = route?.params?.handle ?? null
  const title = route?.params?.title ?? null
  const limit = route?.params?.limit ?? 20

  return (
    <View style={{ flex: 1, backgroundColor: theme.pageBackground, overflowY: 'scroll' }}>
      {handle && (
        <MainCollectionProducts
          {...config}
          listHeaderComponent={(
            <SectionHeader
              type='filters'
              title={title}
              options={filters}
              activeOption={sort}
              reverse={reverse}
              selectedOption={(i) => setSort(i.id)}
              changeReverse={() => setReverse(!reverse)}
            />
          )}
          theme={theme}
          stickyHeader
          limit={limit}
          navigation={navigation}
          handle={handle}
          sort={sort}
          reverse={reverse}
        />
      )}
    </View>
  )
}

CollectionProductsScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    config: _.get(state, 'config.theme.collectionsPage', {})
  }
}

export default connect(mapStateToProps)(CollectionProductsScreen)
