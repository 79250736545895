import React, { useEffect, useRef, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import { scale } from '@utils/scale'
import * as Animatable from 'react-native-animatable'
import NavigationService from '@navigation/NavigationService'
import Icon from '@components/Icon'
import DCImage from '@components/DCImage'
import Text from '@components/Text'
import { colors } from '@utils/theme'
import _ from 'lodash'

const toasterColors = colors.toast

const ToastMessage = ({ message, dispatch, theme }) => {
  const view = useRef()
  let timer
  useEffect(() => {
    timer = setTimeout(() => {
      handleRemoveToast()
    }, 3500)
  }, [])

  const handleRemoveToast = () => {
    view.current &&
    view.current.fadeOutUp(400).then(() => {
      if (timer) {
        clearTimeout(timer)
      }
      dispatch({
        type: 'REMOVE_TOAST',
        data: {
          message: { id: message.id }
        }
      })
    })
  }

  const handleNavigateToRoute = () => {
    NavigationService.navigate('Cart')
    handleRemoveToast()
  }

  const handleRenderType = () => {
    switch (message.type) {
      case 'AddToCart':
        return (
          <View style={styles.mainHolder}>
            <View style={styles.image}>
              {message.image && (
                <DCImage style={styles.image} source={{ uri: message.image }} />
              )}
            </View>
            <View style={styles.titleDescHolder}>
              <TouchableOpacity onPress={handleNavigateToRoute}>
                <Text
                  numberOfLines={2}
                  ellipsizeMode='tail'
                  style={{ fontWeight: '600' }}
                >
                  {message.title || 'Item'} was added to your cart!
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleNavigateToRoute}>
                <Text fontSize={12}>TAP HERE TO VIEW</Text>
              </TouchableOpacity>
            </View>
            <View style={{ position: 'absolute', right: 0 }}>
              <TouchableOpacity onPress={handleRemoveToast}>
                <Icon size={scale(18)} style={{ opacity: 0.5 }} name='x' />
              </TouchableOpacity>
            </View>
          </View>
        )
      case 'Error':
      case 'Warning':
      case 'Info':
      case 'Success':
        return (
          <View style={styles.mainHolder}>
            <View
              style={{
                ...styles.image,
                justifyContent: 'center',
                alignItems: 'center',
                width: 40,
                height: 40
              }}
            >
              {message.type === 'Error' && (
                <Icon
                  size={32}
                  name='alert-octagon'
                  color={toasterColors.error}
                />
              )}
              {message.type === 'Warning' && (
                <Icon
                  size={32}
                  name='alert-triangle'
                  color={toasterColors.warning}
                />
              )}
              {message.type === 'Info' && (
                <Icon
                  size={32}
                  name='alert-circle'
                  color={toasterColors.info}
                />
              )}
              {message.type === 'Success' && (
                <Icon
                  size={32}
                  name='check-circle'
                  color={toasterColors.success}
                />
              )}
            </View>
            <View style={styles.titleDescHolder}>
              <Text style={{ fontWeight: '600' }}>
                {message.title || message.type}
              </Text>
              <Text>{message.message}</Text>
            </View>
            <View style={{ position: 'absolute', right: 0 }}>
              <TouchableOpacity onPress={handleRemoveToast}>
                <Icon size={scale(18)} style={{ opacity: 0.5 }} name='x' />
              </TouchableOpacity>
            </View>
          </View>
        )
      default:
        break
    }
  }

  return (
    <Animatable.View
      ref={view}
      animation='slideInDown'
      style={{ ...styles.toasterServiceHolder, backgroundColor: theme.contentBackground }}
    >
      {handleRenderType()}
    </Animatable.View>
  )
}

const Toaster = ({ toaster: { messages }, theme, dispatch }) => {
  const [stateMessages, setStateMessages] = useState(messages)

  useEffect(() => {
    if (messages !== stateMessages) {
      setStateMessages(messages)
    }
  }, [messages])

  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 40,
        zIndex: 999
      }}
    >
      {stateMessages.map(message => (
        <ToastMessage
          theme={theme}
          key={message.id}
          message={message}
          dispatch={dispatch}
        />
      ))}
    </View>
  )
}

const styles = {
  toasterServiceHolder: {
    padding: 10,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 0.2,
    flexDirection: 'row',
    elevation: 5
  },
  mainHolder: { flex: 1, flexDirection: 'row' },
  image: { width: 80, height: 80 },
  titleDescHolder: {
    marginLeft: 10,
    maxWidth: '60%',
    justifyContent: 'space-between'
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page'),
    toaster: state.toaster
  }
}

export default connect(mapStateToProps)(Toaster)
