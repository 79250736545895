import React from 'react'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import * as gql from '@gql/Queries'
import { View, ScrollView, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import Icon from '@components/Icon'
import Image from '@components/DCImage'
import HeaderBar from '@components/HeaderBar'
import TouchableExpand from '@components/TouchableExpand'
import BlogSkeleton from '@components/Skeletons/BlogSkeleton'
import { width } from '@utils/scale'
import _ from 'lodash'
const BlogItem = ({ item, theme, onItemPress }) => {
  const secondaryText = item.excerpt || (item.content && `${item.content.substr(0, 100)}...`)
  return (
    <TouchableExpand onPress={() => onItemPress(item)} style={{ backgroundColor: theme.contentBackground, marginBottom: 10 }}>
      <Image style={{ width }} scalable source={{ uri: _.get(item, 'image.transformedSrc') }} />
      <View style={{ padding: 15 }}>
        <Text bold fontSize={20}>{item.title}</Text>
        <Text style={{ marginTop: 5, marginBottom: 5 }} fontSize={14}>{secondaryText}</Text>
      </View>
    </TouchableExpand>
  )
}

const BlogList = ({ items, theme, onItemPress }) => {
  return items && items.map((item, i) => {
    return <BlogItem onItemPress={onItemPress} key={`key-${i}`} item={item.node} theme={theme} />
  })
}

const BlogScreen = ({ theme, navigation }) => {
  const renderEmpty = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          paddingTop: 80
        }}
      >
        <Icon type='light' name='book' size={40} />
        <Text
          secondary
          fontSize={14}
          style={{ padding: 20, fontWeight: '300' }}
        >
          No posts available.
        </Text>
        <TouchableOpacity
          onPress={() => navigation.navigate('Shop')}
          style={{
            backgroundColor: theme.buttonColor,
            padding: 15,
            paddingHorizontal: 40
          }}
        >
          <Text fontSize={14} type='button' bold>
            GET SHOPPING
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      automaticallyAdjustContentInsets
      contentContainerStyle={{ paddingBottom: 80, paddingTop: 30 }}
      style={{ flex: 1, backgroundColor: theme.pageBackground }}
    >
      <Query query={gql.getArticles}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return (
              <View
                style={{
                  top: 30,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute'
                }}
              >
                <BlogSkeleton theme={theme} />
              </View>
            )
          }
          if (error) return <View><Text>Error :(</Text></View>
          if (data) {
            if (data.articles && !_.isEmpty(data.articles.edges)) {
              return <BlogList onItemPress={(item) => navigation.navigate('BlogArticle', { ...item })} theme={theme} items={data.articles.edges} />
            }
            return renderEmpty()
          }
        }}
      </Query>
    </ScrollView>
  )
}

BlogScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(BlogScreen)
