import { combineReducers } from 'redux'
import cart from './cart'
import config from './config'
import user from './user'
import toaster from './toaster'
import { persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-community/async-storage'

/* istanbul ignore next */
const reducers = combineReducers({
  cart: persistReducer({ key: 'cart', storage: AsyncStorage }, cart),
  config: persistReducer({ key: 'config', storage: AsyncStorage }, config),
  user: persistReducer({ key: 'user', storage: AsyncStorage }, user),
  toaster
})

export const cleanReducers = { // FOR SISU-PREVIEWR
  cart,
  config,
  user,
  toaster
}

export default reducers
