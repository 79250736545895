import React, { useState, useEffect } from 'react'
import { Animated } from 'react-native'
import { SvgXml } from 'react-native-svg'
import { width, height } from '@utils/scale'

function brightnessByColor (color) {
  const isHEX = color.indexOf('#') === 0
  const isRGB = color.indexOf('rgb') === 0
  let r, g, b
  if (isHEX) {
    const hasFullSpec = color.length === 7
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16)
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16)
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16)
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g)
    if (m) {
      r = m[0]
      g = m[1]
      b = m[2]
    }
  }
  if (typeof r !== 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000
  }
  return null
}

function lightenDarkenColor (col, amt) {
  var usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

const SkeletonBase = ({ backgroundColor = '#282828', xml, padded = false }) => {
  const [fadeAnim] = useState(new Animated.Value(1))
  const colorRatio = brightnessByColor(backgroundColor) / 8
  let shimmerColor = lightenDarkenColor(backgroundColor, colorRatio > 8 ? colorRatio : 8)
  if (colorRatio > 22) {
    shimmerColor = lightenDarkenColor(backgroundColor, -(colorRatio / 2))
  }

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 0.1,
          duration: 600,
          delay: 1000,
          useNativeDriver: false
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 600,
          useNativeDriver: false
        })
      ]),
      {
        iterations: 8
      }
    ).start()
  }, [])

  return (
    <Animated.View style={{ opacity: fadeAnim, width, height }}>
      <SvgXml fill={shimmerColor} width={padded ? width - 30 : width} height={padded ? height - 30 : height} xml={xml} />
    </Animated.View>
  )
}

export default SkeletonBase
