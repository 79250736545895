import React from 'react'
import { View } from 'react-native'
import DCImage from '@components/DCImage'
import Text from '@components/Text'
import _ from 'lodash'
import ProductItemPrice from '@components/ProductItemPrice'
import ProductItemSaleBadge from '@components/ProductItemSaleBadge'
import TouchableExpand from '@components/TouchableExpand'
import { width } from '@utils/scale'
import MetaFieldManager from '@components/MetaFieldManager'

const MainProductItem = ({
  pushToTop,
  goBackBeforeForward,
  horizontal,
  index,
  item,
  navigation,
  showSaleBadge,
  theme
}) => {
  // MetaFields
  const metaFields = _.get(item, 'metafields.edges')

  const handleOnProductPress = () => {
    if (navigation) {
      if (goBackBeforeForward) {
        navigation.goBack()
        setTimeout(() => {
          navigation.navigate('Product', { handle: item.handle, product: item })
        }, 200)
        return
      }
      if (pushToTop) {
        navigation.push('Product', {
          handle: item.handle,
          product: item,
          disableRecommendations: true
        })
      }
      navigation.navigate('Product', { handle: item.handle, product: item })
    }
  }

  const image = _.get(item, 'images.edges[0].node')
  const indexOffset = horizontal
    ? {}
    : index % 2 === 0
      ? { marginLeft: 2 }
      : { marginRight: 2 }

  return (
    <View style={{ ...styles.mainHolder }}>
      <TouchableExpand
        style={{
          ...indexOffset,
          backgroundColor: theme.contentBackground,
          ...styles.contentHolder
        }}
        onPress={handleOnProductPress}
      >
        <View style={styles.image}>
          <View style={styles.image}>
            <DCImage
              resizeMode='contain'
              source={{ uri: image?.transformedSrc }}
              style={styles.image}
              scalable // TODO: watch performance here
            />
          </View>
          {showSaleBadge && <ProductItemSaleBadge item={item} />}
        </View>
        <View style={{ alignItems: 'center' }}>
          <Text
            bold
            fontSize={13.5}
            lineHeight={17}
            style={{ ...styles.font, ...styles.productTitle }}
          >
            {item.title}
          </Text>
          {!_.isEmpty(metaFields) && (
            <View style={{ justifyContent: 'flex-end' }}>
              <MetaFieldManager meta={metaFields} />
            </View>
          )}
          {/* ProductItemPrice */}
          <ProductItemPrice
            selectedFontStyle={{ ...styles.font }}
            item={item}
          />
          {/* End ProductItemPrice */}
        </View>
      </TouchableExpand>
    </View>
  )
}

const styles = {
  contentHolder: {
    alignItems: 'center',
    flex: 1,
    padding: 5,
    paddingBottom: 10,
    paddingTop: 15
  },
  mainHolder: {
    width: width / 2,
    padding: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  font: { letterSpacing: 0.2, textAlign: 'center' },
  image: {
    width: width / 2.4,
    height: width / 2.4,
    backgroundColor: '#ffffff',
    overflow: 'hidden'
  },
  productTitle: {
    maxWidth: '85%',
    marginTop: 5,
    marginBottom: 10,
    fontWeight: '700',
    letterSpacing: 0.5
  }
}

export default React.memo(MainProductItem)
