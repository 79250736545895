import React from 'react'
import { View } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'
import { moneyV2 } from '@utils'

const ProductItemPrice = ({
  fontSize = 14,
  isVariant,
  item = {
    priceRange: {}
  },
  selectedFontStyle,
  stacked,
  style
}) => {
  if (!item) {
    return <View />
  }

  const {
    priceRange,
    variants,
    selectedFontStyle: itemSelectedFontStyle
  } = item
  const renderVariantPrice = () => {
    const _selectedFontStyle = item.selectedFontStyle || selectedFontStyle || {}
    if (item && item.compareAtPriceV2 && item.priceV2) {
      const comparePrice = _.get(item, 'compareAtPriceV2.amount', 0)
      const price = _.get(item, 'priceV2.amount', 0)
      if (parseFloat(comparePrice) > parseFloat(price)) {
        if (stacked) {
          return (
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginBottom: -15,
                ...style
              }}
            >
              <Text
                fontSize={fontSize}
                lineHeight={fontSize + 2}
                style={{
                  ..._selectedFontStyle,
                  ...styles.saleText,
                  marginRight: 0
                }}
              >
                {moneyV2(_.get(item, 'priceV2', 0))}
              </Text>
              <Text
                fontSize={12}
                lineHeight={13}
                style={{ ..._selectedFontStyle, opacity: 0.6 }}
              >
                Was {moneyV2(_.get(item, 'compareAtPriceV2', 0))}
              </Text>
            </View>
          )
        } else {
          return (
            <View style={{ flexDirection: 'row' }}>
              <Text
                fontSize={fontSize}
                lineHeight={fontSize + 2}
                style={{ ..._selectedFontStyle, ...styles.saleText }}
              >
                {moneyV2(_.get(item, 'priceV2', 0))}
              </Text>
              <Text
                fontSize={fontSize}
                lineHeight={fontSize + 2}
                style={{ ..._selectedFontStyle, ...styles.priceLineThrough }}
              >
                {moneyV2(_.get(item, 'compareAtPriceV2', 0))}
              </Text>
            </View>
          )
        }
      }
    }
    return (
      <View style={style}>
        <Text
          fontSize={fontSize}
          style={{ ..._selectedFontStyle, ...styles.default }}
          lineHeight={fontSize + 2}
        >
          {moneyV2(_.get(item, 'priceV2', 0))}
        </Text>
      </View>
    )
  }

  if (isVariant) {
    return renderVariantPrice()
  }

  const { minVariantPrice, maxVariantPrice } = priceRange
  const _selectedFontStyle = selectedFontStyle || itemSelectedFontStyle || {}
  const variantPrices = _.get(variants, 'edges[0].node')

  if (
    variantPrices &&
    variantPrices.compareAtPriceV2 &&
    variantPrices.priceV2
  ) {
    const comparePrice = _.get(variantPrices, 'compareAtPriceV2.amount', 0)
    const price = _.get(variantPrices, 'priceV2.amount', 0)
    if (parseFloat(comparePrice) > parseFloat(price)) {
      return (
        <View style={{ flexDirection: stacked ? 'column' : 'row' }}>
          <Text
            fontSize={fontSize}
            lineHeight={fontSize + 2}
            style={{
              ..._selectedFontStyle,
              ...styles.saleText,
              marginRight: stacked ? 0 : 10
            }}
          >
            {moneyV2(_.get(variantPrices, 'priceV2', 0))}
          </Text>
          <Text
            fontSize={fontSize}
            lineHeight={fontSize + 2}
            style={{ ..._selectedFontStyle, ...styles.priceLineThrough }}
          >
            {moneyV2(_.get(variantPrices, 'compareAtPriceV2', 0))}
          </Text>
        </View>
      )
    }
  }

  const _maxVariantPrice = !_.isUndefined(maxVariantPrice) && moneyV2(maxVariantPrice)
  if (_.get(minVariantPrice, 'amount') && _.get(maxVariantPrice, 'amount')) {
    if (
      parseFloat(minVariantPrice.amount) !== parseFloat(maxVariantPrice.amount)
    ) {
      return (
        <Text
          fontSize={fontSize}
          style={{ ..._selectedFontStyle, ...styles.default }}
          lineHeight={fontSize + 2}
        >
          FROM {moneyV2(minVariantPrice)}
        </Text>
      )
    }
  }
  return (
    <Text
      fontSize={fontSize}
      style={{ ..._selectedFontStyle, ...styles.default }}
      lineHeight={fontSize + 2}
    >
      {_maxVariantPrice}
    </Text>
  )
}

const styles = {
  default: { fontWeight: '400' },
  saleText: {
    marginRight: 10,
    fontWeight: '400'
  },
  priceLineThrough: {
    textDecorationLine: 'line-through',
    fontWeight: '400',
    opacity: 0.4
  }
}

export default ProductItemPrice
