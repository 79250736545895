import configJson from '../../config.json'

const dev = {
  API_ROOT: 'https://7dx5wufmka.execute-api.us-east-1.amazonaws.com/dev',
  DEVICE_ROOT: 'https://f5c0sl2xi6.execute-api.us-east-1.amazonaws.com/dev',
  TOKEN: 'HKJLasdjhadi7yw34kjSJKdhhajsd77wehdjc',
  APP: configJson.store
}

const prod = {
  API_ROOT: 'https://wqf9o0tgs9.execute-api.us-east-1.amazonaws.com/prod',
  DEVICE_ROOT: 'https://hljvqnmegh.execute-api.us-east-1.amazonaws.com/prod',
  TOKEN: 'HKJLasdjhadi7yw34kjSJKdhhajsd77wehdjc',
  APP: configJson.store
}
export default dev
