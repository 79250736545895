import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'

class UserStore {
  constructor () {
    if (!UserStore.instance) {
      UserStore.instance = this
    }
    return UserStore.instance
  }

  url = null
  token = null
  client = null

  init = () => {
    if (!this.client) {
      const URL = this.url // set your comp's url here
      const HEADERS = {
        'X-Shopify-Storefront-Access-Token': `${this.token}`
      }
      const cache = new InMemoryCache()
      const errorLink = onError((errors) => {
        console.log(errors)
      })
      const httpLink = createHttpLink({
        uri: `${URL}`,
        headers: {
          ...HEADERS
        }
      })
      const link = ApolloLink.from([
        errorLink,
        httpLink
      ])

      this.client = new ApolloClient({
        link,
        cache
      })
      return this.client
    }
    return this.client
  }

  resetAll = () => { // FOR SISU-PREVIEWR
    this.url = null
    this.client = null
    this.token = null
  }

  setCredentials = (url, token) => {
    this.url = url
    this.token = token
    if (this.url && this.token) {
      return this.init()
    }
  }
}

const instance = new UserStore()

export default instance
