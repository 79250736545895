import React, { useState } from 'react'
import { View } from 'react-native'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import { ProductCollectionSortKeys } from '@gql/Enums'
import _ from 'lodash'
import SectionHeader from '@components/SectionHeader'
import MainProductList from '@components/MainProductList'
import { spacing } from '@utils/scale'
// import ProductListLoader from '@components/ProductListLoader'

const CustomiserCollectionProducts = ({
  id,
  blockLoad,
  dynamicHeight,
  handle,
  hideHeader,
  horizontal,
  imageAlignment,
  limit,
  listHeaderComponent,
  mode,
  navigation,
  reverse: propsReverse,
  showSaleBadge,
  sort: propsSort,
  stickyHeader,
  theme,
  title
}) => {
  const [_sort] = useState(ProductCollectionSortKeys.BEST_SELLING)
  const [_reverse] = useState(false)
  const getProductsForCollectionQuery = useProductQuery('getProductsForCollection')
  const renderDefaultHeader = () => {
    return (
      <SectionHeader
        style={{ marginBottom: 5 }}
        title={title}
        onViewPressed={() =>
          navigation.navigate('CollectionProducts', {
            handle,
            title
          })}
      />
    )
  }

  const sort = propsSort || _sort
  const reverse = propsReverse || _reverse

  const header = !hideHeader && listHeaderComponent

  return (
    <View nativeID={id} style={{ ...spacing }}>
      <Query
        query={getProductsForCollectionQuery}
        variables={{
          handle,
          sort: sort,
          reverse: reverse,
          limit: limit || 15
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (error) return <View />
          const pageInfo = _.get(data, 'collectionByHandle.products.pageInfo')
          const products = _.get(data, 'collectionByHandle.products.edges', [])
          const cursor = _.get(_.last(products), 'cursor')

          return (
            <>
              {!!products && !hideHeader && renderDefaultHeader()}
              <MainProductList
                theme={theme}
                loading={loading}
                stickyHeader={stickyHeader}
                listHeaderComponent={header}
                mode={mode}
                horizontal={horizontal}
                items={products}
                navigation={navigation}
                dynamicHeight={dynamicHeight}
                imageAlignment={imageAlignment}
                showSaleBadge={showSaleBadge}
                loadMore={() => {
                  if (!blockLoad && pageInfo && pageInfo.hasNextPage) {
                    fetchMore({
                      query: getProductsForCollectionQuery,
                      variables: {
                        after: cursor,
                        handle,
                        sort: sort,
                        reverse: reverse,
                        limit: 10
                      },
                      updateQuery: (prevResult, { fetchMoreResult }) => {
                        const newObj = {
                          ...fetchMoreResult,
                          collectionByHandle: {
                            ...fetchMoreResult.collectionByHandle,
                            products: {
                              ...fetchMoreResult.collectionByHandle.products,
                              edges: _.unionBy(
                                [
                                  ..._.get(
                                    prevResult,
                                    'collectionByHandle.products.edges',
                                    []
                                  ),
                                  ..._.get(
                                    fetchMoreResult,
                                    'collectionByHandle.products.edges',
                                    []
                                  )
                                ],
                                'node.id'
                              )
                            }
                          }
                        }
                        return newObj
                      }
                    })
                  }
                }}
              />
            </>
          )
        }}
      </Query>
    </View>
  )
}

export default CustomiserCollectionProducts
