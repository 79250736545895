import React from 'react'
import { View } from 'react-native'
import ProductByHandle from '@containers/ProductByHandle'

const Product = ({ navigation, route }) => {
  const handle = route.params?.handle ?? 'defaultValue'
  const disableRecommendations = route.params?.disableRecommendations
  return (
    <View style={{ flex: 1, backgroundColor: 'transparent' }}>
      <ProductByHandle
        handle={handle}
        disableRecommendations={disableRecommendations}
        onClosePress={() => navigation.goBack()}
      />
    </View>
  )
}

export default Product
