import React, { useState, useEffect } from 'react'
import { colors } from '@utils/theme'
import { Animated, View, ScrollView, Keyboard, TextInput } from 'react-native'
import {
  renderers,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger
} from 'react-native-popup-menu'
import Text from '@components/Text'
import _ from 'lodash'

const { SlideInMenu } = renderers

const useKeyboard = (config = {}) => {
  const { useWillShow = false, useWillHide = false } = config
  const [visible, setVisible] = useState(false)
  const showEvent = useWillShow ? 'keyboardWillShow' : 'keyboardDidShow'
  const hideEvent = useWillHide ? 'keyboardWillHide' : 'keyboardDidHide'

  function dismiss () {
    Keyboard.dismiss()
    setVisible(false)
  }

  useEffect(() => {
    function onKeyboardShow () {
      setVisible(true)
    }

    function onKeyboardHide () {
      setVisible(false)
    }

    Keyboard.addListener(showEvent, onKeyboardShow)
    Keyboard.addListener(hideEvent, onKeyboardHide)

    return () => {
      Keyboard.removeListener(showEvent, onKeyboardShow)
      Keyboard.removeListener(hideEvent, onKeyboardHide)
    }
  }, [useWillShow, useWillHide])

  return [visible, dismiss]
}

const PopOverMenuOptions = ({ option, onSelect }) => {
  return (
    <MenuOption onSelect={onSelect} style={{ paddingHorizontal: 15 }}>
      <View style={styles.menuItem}>
        <Text fontSize={20} style={styles.menuItemText}>
          {option.title || option.name}
        </Text>
      </View>
    </MenuOption>
  )
}

const PopOverMenu = ({ onSelect, onClose, options, opened, theme, hasSearch }) => {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [positionAnim] = useState(new Animated.Value(0))
  const [searchTerm, setSearchTerm] = useState('')
  const [visible] = useKeyboard()

  useEffect(() => {
    if (visible) {
      Animated.timing(positionAnim, {
        toValue: 200,
        duration: 150,
        useNativeDriver: false
      }).start()
    } else {
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: false
      }).start()
    }
  }, [visible])

  useEffect(() => {
    const newOptions = options && options.filter((option) => {
      return _.toLower(option.title || option.name || option).indexOf(_.toLower(searchTerm)) !== -1
    })
    setFilteredOptions(newOptions)
  }, [searchTerm])

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const selectOption = option => {
    if (onSelect && _.isFunction(onSelect)) {
      onSelect(option)
    }
    handleOnClose()
  }

  const handleOnClose = () => {
    setSearchTerm('')
    if (onClose && _.isFunction(onClose)) {
      onClose()
    }
  }

  if (!options) return <View />
  return (
    <Menu
      renderer={SlideInMenu}
      opened={opened}
      onBackdropPress={handleOnClose}
      onSelect={handleOnClose}
    >
      <MenuTrigger />
      <MenuOptions
        optionsContainerStyle={{
          backgroundColor: theme.contentBackground,
          borderRadius: 20
        }}
        style={styles.menuOptionsHolder}
      >
        <Animated.View style={{ marginBottom: positionAnim }}>
          {hasSearch && (
            <View style={{ padding: 20 }}>
              <TextInput
                style={{
                  ...styles.inputDefault,
                  backgroundColor: theme.pageBackground,
                  color: theme.contentText
                }}
                placeholderTextColor={`${theme.contentText}80`}
                onChangeText={(e) => setSearchTerm(e)}
                value={searchTerm}
                placeholder='Search'
              />
            </View>
          )}
          <ScrollView style={{ minHeight: visible ? 300 : 0, maxHeight: 300, marginBottom: 20 }} keyboardShouldPersistTaps='always'>
            {filteredOptions.map(option => {
              let _option = {}
              if (typeof option === 'string') {
                _option.id = option
                _option.name = option
              } else {
                _option = option
              }
              return (
                <PopOverMenuOptions
                  key={_option.id}
                  option={_option}
                  onSelect={() => selectOption(_option)}
                />
              )
            })}
          </ScrollView>
        </Animated.View>
      </MenuOptions>
    </Menu>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    borderRadius: 5,
    paddingLeft: 10
  },
  menuItem: {
    borderBottomColor: colors.lineColor,
    borderBottomWidth: 1,
    marginHorizontal: 30
  },
  menuItemText: {
    fontWeight: '300',
    textAlign: 'center',
    paddingVertical: 10
  },
  menuOptionsHolder: {
    borderRadius: 20,
    paddingBottom: 30
  }
}

export default PopOverMenu
