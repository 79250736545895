import React from 'react'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'
import * as Screens from '@screens'
import HeaderBar from '@components/HeaderBar'

const Stack = createStackNavigator()

const OrdersStack = () => {
  return (
    <Stack.Navigator
      initialRouteName='Orders'
      screenOptions={{
        header: ({ navigation }) => <HeaderBar navigation={navigation} />,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
      }}
    >
      <Stack.Screen
        name='Orders'
        component={Screens.Orders}
      />
      <Stack.Screen
        name='Collections'
        component={Screens.Collections}
      />
      <Stack.Screen
        name='CollectionProducts'
        component={Screens.CollectionProducts}
      />
      <Stack.Screen
        name='Blog'
        component={Screens.Blog}
      />
    </Stack.Navigator>
  )
}

export default OrdersStack
