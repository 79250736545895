import _ from 'lodash'
import cuid from 'cuid'

const initialState = {
  messages: []
}

const userReducer = (state = initialState, action) => {
  let messages
  let newMessage
  switch (action.type) {
    case 'ADD_TOAST':
      messages = state.messages
      if (_.get(action, 'data.message')) {
        newMessage = _.get(action, 'data.message')
        newMessage.id = cuid()
        messages.push(newMessage)
      }
      return { ...state, messages }
    case 'REMOVE_TOAST':
      messages = state.messages
      if (_.get(action, 'data.message.id')) {
        messages = messages.filter((item) => item.id !== _.get(action, 'data.message.id'))
      }
      return { ...state, messages: messages }
    case 'PREVIEWR_RESET':
      return { ...initialState }
    default:
      return state
  }
}

export default userReducer
