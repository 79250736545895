import React from 'react'
import SkeletonBase from './SkeletonBase'

const xml = `
<svg width="1355px" height="3183px" viewBox="0 0 1355 3183" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
    <title>Combined Shape</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" fill-rule="evenodd">
        <path d="M0,0 L1355,0 L1355,1212 L0,1212 L0,0 Z M0,1683 L1355,1683 L1355,2895 L0,2895 L0,1683 Z M58,1259 L829,1259 L829,1342 L58,1342 L58,1259 Z M58,2942 L829,2942 L829,3025 L58,3025 L58,2942 Z M58,1385 L1257,1385 L1257,1432 L58,1432 L58,1385 Z M58,3068 L1257,3068 L1257,3115 L58,3115 L58,3068 Z M58,1453 L829,1453 L829,1500 L58,1500 L58,1453 Z M58,3136 L829,3136 L829,3183 L58,3183 L58,3136 Z" id="Combined-Shape"></path>
    </g>
</svg>
`

const BlogSkeleton = ({ theme }) => {
  return (
    <SkeletonBase backgroundColor={theme.pageBackground} xml={xml} />
  )
}

export default BlogSkeleton
