import React from 'react'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'
import * as Screens from '@screens'
import * as Stacks from './Stacks'
import Drawer from './Drawer'

const MainStack = createStackNavigator()

const MainNavigator = ({ tabs, config }) => {
  return (
    <MainStack.Navigator
      headerMode='none'
      mode='modal'
      transparentCard
      initialRouteName='App'
      screenOptions={{
        cardStyle: { backgroundColor: 'transparent' },
        cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS
      }}
    >
      <MainStack.Screen name='App' component={Drawer} initialParams={{ tabs, config }} />
      <MainStack.Screen name='Product' component={Screens.Product} />
      <MainStack.Screen name='Webview' component={Screens.Webview} />
      <MainStack.Screen name='Search' component={Screens.Search} />
      <MainStack.Screen name='BlogArticle' component={Screens.BlogArticle} />
      <MainStack.Screen name='GeneralWebView' component={Screens.GeneralWebView} />
      <MainStack.Screen name='Addresses' component={Screens.Addresses} />
      <MainStack.Screen name='ShippingRates' component={Screens.ShippingRates} />
      <MainStack.Screen name='Notifications' component={Screens.Notifications} />
      <MainStack.Screen name='ReviewForm' component={Screens.ReviewForm} />
      <MainStack.Screen name='ImageModal' component={Screens.ImageModal} />
      <MainStack.Screen name='LoginSignUp' component={Screens.LoginSignUp} options={{ gestureEnabled: false }} />
    </MainStack.Navigator>
  )
}

const stacks = [
  'Shop',
  'Home',
  'Cart',
  'Orders',
  'Blog'
]

const defaultTabs = [
  { stack: 'Shop', icon: 'shopping-bag', order: 0 },
  { stack: 'Home', icon: 'star', label: 'Featured', order: 1 },
  { stack: 'Cart', icon: 'shopping-cart', order: 2 },
  { stack: 'Orders', icon: 'gift', order: 3 }
]

const setupTabs = (tabs = defaultTabs) => {
  const tabsObject = {}
  const allowedRoutes = stacks
  tabs.map(tab => {
    if (allowedRoutes.indexOf(tab.stack) !== -1) {
      tabsObject[tab.stack] = {
        component: Stacks[tab.stack],
        label: tab.label || tab.stack,
        order: tab.order,
        ...tab
      }
    }
  })
  return tabsObject
}

const MainRouter = ({ tabs, config = {} }) => {
  return (<MainNavigator tabs={setupTabs(tabs)} config={config} />)
}
export default MainRouter
