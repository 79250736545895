import React from 'react'
import { connect } from 'react-redux'
import { View } from 'react-native'
import ModalContainer from '@containers/ModalContainer'
import DCImage from '@components/DCImage'
import { width, height } from '@utils/scale'
import _ from 'lodash'
import { ScrollView } from 'react-native-gesture-handler'

const ImageModal = ({ navigation, route, theme }) => {
  const images = route.params?.images ?? []
  const _height = height - ((height / 100) * 10)
  return (
    <ModalContainer
      doubleTopPadding
      theme={theme}
      pageBack
      handleBack={() => navigation.goBack()}
    >
      <View style={{ flexDirection: 'row', width: width }}>
        <ScrollView style={{ height: _height }} horizontal pagingEnabled contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}>
          {images.map((img, i) => {
            return (
              <View key={i} style={{ width }}>
                <DCImage
                  scalable
                  style={{
                    width,
                    marginRight: 10
                  }}
                  source={{ uri: img }}
                />
              </View>
            )
          })}
        </ScrollView>
      </View>
    </ModalContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    reviews: _.get(state, 'config.integrations.reviews', {}),
    user: _.get(state, 'user.customer')
  }
}

export default connect(mapStateToProps)(ImageModal)
