import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, ScrollView } from 'react-native'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import * as gql from '@gql/Queries'
import Icon from '@components/Icon'
import Text from '@components/Text'
import Panel from '@components/Panel'
import AddressCard from '@components/AddressCard'
import { moneyV2 } from '@utils'
import _ from 'lodash'

import CheckoutReviewButton from '@components/CheckoutReviewButton'
import { height } from '@utils/scale'

const CheckoutReview = ({
  user,
  checkout,
  client,
  settings,
  theme,
  navigation,
  dispatch
}) => {
  const [hasAccount, seHasAccount] = useState(user)
  const [hasMadeSelection, setHasMadeSelection] = useState(false)
  const isFocused = useIsFocused()

  useEffect(() => {
    const getCheckout = async () => {
      const response = await client.query({
        query: gql.getCheckoutById,
        variables: { id: checkout.id }
      })

      if (_.get(response, 'data.node')) {
        dispatch({
          type: 'SET_LOCAL_CHECKOUT',
          data: {
            checkout: _.get(response, 'data.node')
          }
        })
      }
    }
    if (isFocused) {
      getCheckout()
    }
  }, [isFocused])

  useEffect(() => {
    if (!checkout || !checkout.id) {
      navigation.goBack()
    }
  }, [checkout])

  useEffect(() => {
    if (user) {
      setHasMadeSelection(true)
    }
    seHasAccount(user)
  }, [user])

  if (!hasAccount && !hasMadeSelection && settings.allowUserAccounts === 'allow') {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: theme.pageBackground }}
        contentContainerStyle={{ padding: 15 }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            minHeight: height - 200
          }}
        >
          <View
            style={{
              ...styles.loginBlock,
              backgroundColor: theme.contentBackground
            }}
          >
            <Icon name='lock' size={50} />
            <Text style={{ textAlign: 'center', marginTop: 20 }}>
              I'd like to login to view my orders and get the latest updates.
            </Text>
            <View style={styles.buttonHolder}>
              <TouchableOpacity
                style={{
                  ...styles.button,
                  backgroundColor: theme.buttonColor
                }}
                onPress={() => navigation.navigate('LoginSignUp')}
              >
                <Text
                  color={theme.buttonTextColor}
                  style={{ fontWeight: '600' }}
                >
                  Login / Signup
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              ...styles.loginBlock,
              backgroundColor: theme.contentBackground
            }}
          >
            <Icon name='user' size={50} />
            <Text style={{ marginTop: 20, textAlign: 'center' }}>
              I'd like to checkout as a guest. I'll use my email to monitor my
              orders.
            </Text>
            <View style={styles.buttonHolder}>
              <TouchableOpacity
                style={{
                  ...styles.button,
                  backgroundColor: theme.buttonColor
                }}
                onPress={() => setHasMadeSelection(true)}
              >
                <Text
                  color={theme.buttonTextColor}
                  style={{ fontWeight: '600' }}
                >
                  Checkout as guest
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    )
  }

  if (!hasAccount && !hasMadeSelection && settings.allowUserAccounts === 'required') {
    return (
      <ScrollView
        style={{ flex: 1, backgroundColor: theme.pageBackground }}
        contentContainerStyle={{ padding: 15 }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            minHeight: height - 200
          }}
        >
          <View
            style={{
              ...styles.loginBlock,
              backgroundColor: theme.contentBackground
            }}
          >
            <Icon name='lock' size={50} />
            <Text style={{ textAlign: 'center', marginTop: 20 }}>
              In order to keep you up to date and make sure you get your items, we require you to sign up.
            </Text>
            <View style={styles.buttonHolder}>
              <TouchableOpacity
                style={{
                  ...styles.button,
                  backgroundColor: theme.buttonColor
                }}
                onPress={() => navigation.navigate('LoginSignUp')}
              >
                <Text
                  color={theme.buttonTextColor}
                  style={{ fontWeight: '600' }}
                >
                  Login / Signup
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <>
      <ScrollView
        style={{ flex: 1, backgroundColor: theme.pageBackground }}
        contentContainerStyle={{ padding: 15 }}
      >
        <>
          <Panel title='Shipping' containerStyle={{ flex: 1 }} theme={theme}>
            <View
              style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}
            >
              <View style={{ padding: 10 }}>
                <Icon name='truck' size={25} type='light' />
              </View>
              {checkout.shippingAddress ? (
                <AddressCard
                  theme={theme}
                  onPress={() => navigation.navigate('Addresses')}
                  address={checkout.shippingAddress}
                  containerStyle={{ borderBottomWidth: 0, marginBottom: 0 }}
                />
              ) : (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text fontSize={14}>Add your shipping address</Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('Addresses')}
                    style={{
                      backgroundColor: theme.buttonColor,
                      paddingHorizontal: 20,
                      paddingVertical: 10
                    }}
                  >
                    <Text
                      type='button'
                      bold
                      style={{ color: theme.buttonTextColor }}
                    >
                      ADD
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </Panel>
          {!_.isEmpty(checkout.shippingAddress) && (
            <Panel
              containerStyle={{
                marginTop: -5,
                paddingHorizontal: 20,
                paddingVertical: 15
              }}
              theme={theme}
            >
              <TouchableOpacity
                onPress={() => navigation.navigate('ShippingRates')}
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'space-between'
                }}
              >
                {_.isEmpty(checkout.shippingLine) ? (
                  <Text>Select your shipping method</Text>
                ) : (
                  <View style={{ flex: 1 }}>
                    <Text>{_.get(checkout, 'shippingLine.title')}</Text>
                    <Text
                      style={{
                        alignSelf: 'flex-start',
                        fontWeight: '600'
                      }}
                    >
                      {moneyV2(_.get(checkout, 'shippingLine.priceV2', 0))}
                    </Text>
                  </View>
                )}
                <View style={{ justifyContent: 'center', paddingLeft: 10 }}>
                  <Icon name='chevron-right' size={20} type='light' />
                </View>
              </TouchableOpacity>
            </Panel>
          )}
        </>
        {/* <View>
          <TouchableOpacity><Text>Add Payment Block?</Text></TouchableOpacity>
        </View>
        <View>
          <TouchableOpacity><Text>Summary Block</Text></TouchableOpacity>
        </View> */}
      </ScrollView>
      {/* TODO: Disable based on shipping and address */}
      <CheckoutReviewButton
        onPress={() =>
          navigation.navigate('Webview', {
            url: checkout.webUrl,
            handleCheckouts: true
          })}
        checkout={checkout}
        lineItems={_.get(checkout, 'lineItems.edges', [])}
        theme={theme}
      />
    </>
  )
}

const styles = {
  button: { flex: 1, alignItems: 'center', padding: 12 },
  buttonHolder: { height: 45, width: '100%', marginTop: 30 },
  loginBlock: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    padding: 20
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page'),
    settings: _.get(state, 'config.theme.sideMenu'),
    checkout: _.get(state, 'cart.checkout', {}),
    user: _.get(state, 'user.customer')
  }
}

export default connect(mapStateToProps)(withApollo(CheckoutReview))
