import { Dimensions } from 'react-native'
const { width, height } = Dimensions.get('window')
const spacing = { marginBottom: 12, marginTop: 12 }
// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350
const guidelineBaseHeight = 680

export const aspectRatio = width / height
/* istanbul ignore next */
const scale = size => {
  if (aspectRatio > 0.6) {
    return scaleVertical(size)
  } else {
    return width / guidelineBaseWidth * size
  }
}
/* istanbul ignore next */
const scaleVertical = size => height / guidelineBaseHeight * size
/* istanbul ignore next */
const scaleModerate = (size, factor = 0.5) => size + (scale(size) - size) * factor
const hasDefaultAspect = width / height < 0.5
export { scale, scaleVertical, scaleModerate, width, height, hasDefaultAspect, spacing }
