import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import PropTypes from 'prop-types'

import {
  Image
} from 'react-native'

const resolveAssetSource = Image.resolveAssetSource

const ScalableImage = props => {
  const [scalableWidth, setScalableWidth] = useState(null)
  const [scalableHeight, setScalableHeight] = useState(null)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    onProps(props)
  }, [])

  const onProps = async localProps => {
    const { source } = localProps
    if (source.uri) {
      const sourceToUse = source.uri
        ? source.uri
        : source
      await Image.prefetch(source.uri)
      Image.getSize(
        sourceToUse,
        (width, height) => adjustSize(width, height, props),
        () => props.onSizeError && props.onSizeError()
      )
    } else {
      const sourceToUse = resolveAssetSource(source)
      adjustSize(sourceToUse.width, sourceToUse.height, props)
    }
  }

  const adjustSize = (sourceWidth, sourceHeight, localProps) => {
    const { width, height } = localProps

    let ratio = 1

    if (width && height) {
      ratio = Math.min(width / sourceWidth, height / sourceHeight)
    } else if (width) {
      ratio = width / sourceWidth
    } else if (height) {
      ratio = height / sourceHeight
    }

    if (mounted.current) {
      const computedWidth = sourceWidth * ratio
      const computedHeight = sourceHeight * ratio

      setScalableWidth(computedWidth)
      setScalableHeight(computedHeight)

      props.onSize({ width: computedWidth, height: computedHeight })
    }
  }

  return (
    <Image
      {...props}
      style={[props.style, {
        width: scalableWidth,
        height: scalableHeight
      }]}
    />
  )
}

ScalableImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onPress: PropTypes.func,
  onSize: PropTypes.func,
  onSizeError: PropTypes.func
}

ScalableImage.defaultProps = {
  onSize: size => {}
}

export default ScalableImage
