import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import DrawerComponent from '@components/Drawer'
import { width } from '@utils/scale'
import Tabs from '../Tabs'

const DrawerNav = createDrawerNavigator()

const Drawer = ({ route = {} }) => {
  const tabs = route?.params?.tabs ?? {}
  const config = route?.params?.config ?? {}
  return (
    <DrawerNav.Navigator
      initialRouteName='App'
      drawerType='back'
      drawerPosition='right'
      overlayColor='transparent'
      drawerStyle={{
        width: (width / 3) * 2.5
      }}
      drawerContent={({ navigation, progress }) => <DrawerComponent navigation={navigation} progress={progress} />}
    >
      <DrawerNav.Screen name='App' component={Tabs} initialParams={{ tabs, config }} />
    </DrawerNav.Navigator>
  )
}

export default Drawer
