import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Text from '@components/Text'
import { colors } from '@utils/theme'
import PopOverMenu from '@components/PopOverMenu'
import Icon from '@components/Icon'
import _ from 'lodash'
import { width } from '@utils/scale'

const OptionsSelector = ({ onOptionSelect, selectedOptions, theme, options }) => {
  const [opened, setOpened] = useState(false)
  const [selectOptionGroup, setSelectOptionGroup] = useState(null)

  const handleOnSelectOption = option => {
    if (onOptionSelect) {
      onOptionSelect(selectOptionGroup, option)
    }
  }

  const handleOnClose = () => {
    setOpened(false)
  }

  const renderOption = (option, index) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setOpened(true)
          setSelectOptionGroup(option.name)
        }}
        key={`${option.name}-${index}`}
        style={{
          flex: 1,
          margin: 5,
          minWidth: width / 3,
          height: 75,
          maxHeight: 75
        }}
      >
        <Text
          style={{ marginBottom: 5, fontWeight: '600' }}
          fontSize={15}
        >
          {option.name}
        </Text>
        <View
          style={{
            padding: 10,
            paddingTop: 0,
            paddingBottom: 0,
            borderWidth: 1,
            borderColor: colors.lineColor,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Text fontSize={15} numberOfLines={1} ellipsizeMode='tail'>
            {selectedOptions[option.name] || option.name}
          </Text>
          <Icon
            style={{ paddingTop: 5, paddingBottom: 5 }}
            name='chevron-down'
            size={16}
            color={theme.contentText}
          />
        </View>
      </TouchableOpacity>
    )
  }

  const mappedOptions = (!_.isEmpty(options) &&
      Object.keys(options).map(optionGroupName => {
        return {
          name: optionGroupName,
          values: options[optionGroupName]
        }
      })) ||
    {}

  if (_.isEmpty(mappedOptions)) return <View />

  const _options = _.find(mappedOptions, o => {
    return selectOptionGroup === o.name
  })
  const isOnlyDefault =
    _.get(mappedOptions, '[0].values', []).indexOf('Default Title') === 0

  if (isOnlyDefault) return <View />

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '90%',
        marginTop: 10
      }}
    >
      {mappedOptions.map((option, index) => {
        return renderOption(option, index)
      })}
      <PopOverMenu
        theme={theme}
        options={_.get(_options, 'values', [])}
        onSelect={handleOnSelectOption}
        onClose={handleOnClose}
        opened={opened}
      />
    </View>
  )
}

export default OptionsSelector
