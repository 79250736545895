import _ from 'lodash'

export const initialState = {
  accessToken: null,
  expiresAt: null,
  customer: null
}

const upsert = (arr, key, newVal) => {
  const _arr = arr.map(item => item.node)
  var match = _.find(_arr, key)
  if (match) {
    var index = _.indexOf(_arr, _.find(_arr, key))
    _arr.splice(index, 1, newVal)
  } else {
    _arr.push(newVal)
  }
  return _arr.map(_item => ({ node: _item }))
}

const userReducer = (state = initialState, action) => {
  let address
  let updatedAddresses
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        ..._.get(action, 'data.token', {})
      }
    case 'SET_CUSTOMER':
      return {
        ...state,
        customer: { ..._.get(action, 'data.customer') }
      }
    case 'SET_CUSTOMER_ADDRESS':
      address = _.get(action, 'data.address', {})
      updatedAddresses = upsert(
        _.get(state, 'customer.addresses.edges', []),
        { id: address.id },
        address
      )
      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: {
            edges: updatedAddresses
          }
        }
      }
    case 'REMOVE_ADDRESS':
      address = _.get(action, 'data.id')
      updatedAddresses = _.get(state, 'customer.addresses.edges', []).filter((add) => {
        return add.node.id !== address
      })

      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: {
            edges: updatedAddresses
          }
        }
      }
    case 'PREVIEWR_RESET':
    case 'LOGOUT':
      return { ...initialState }
    default:
      return state
  }
}

export default userReducer
