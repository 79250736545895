import React from 'react'
import { connect } from 'react-redux'
import { View, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import SectionFilter from './SectionFilter'
import _ from 'lodash'

const SectionHeader = ({
  activeOption,
  type,
  title,
  options,
  reverse,
  selectedOption,
  changeReverse,
  onViewPressed,
  theme,
  style = {}
}) => {
  switch (type) {
    case 'filters':
      return (
        <View style={{ ...styles.mainHolder, ...style, backgroundColor: theme.pageBackground }}>
          <Text type='heading' bold fontSize={20} numberOfLines={1} style={{ maxWidth: '60%' }}>
            {title}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            <SectionFilter
              theme={theme}
              activeOption={activeOption}
              options={options}
              reverse={reverse}
              selectedOption={selectedOption}
              changeReverse={changeReverse}
            />
          </View>
        </View>
      )
    case 'plain':
      return (
        <View style={{ ...styles.mainHolder, marginBottom: 0, paddingLeft: 20, paddingRight: 20, paddingBottom: 5, paddingTop: 0, ...style, backgroundColor: theme.pageBackground }}>
          <Text type='heading' bold fontSize={20} numberOfLines={1} style={{ maxWidth: '80%' }}>
            {title}
          </Text>
        </View>
      )
    default:
      return (
        <View style={{ ...styles.mainHolder, marginBottom: 0, paddingLeft: 20, paddingRight: 20, paddingBottom: 5, paddingTop: 0, ...style, backgroundColor: theme.pageBackground }}>
          <Text type='heading' bold fontSize={20} numberOfLines={1} style={{ maxWidth: '60%' }}>
            {title}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity onPress={() => onViewPressed && onViewPressed()}>
              <Text fontSize={13} color={theme.accentText}>View All</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
  }
}

const styles = {
  mainHolder: {
    padding: 20,
    paddingTop: 35,
    paddingBottom: 15,
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' })
  }
}

export default connect(mapStateToProps)(SectionHeader)
