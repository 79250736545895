import React from 'react'
import { View } from 'react-native'
import { width } from '@utils/scale'

const Divider = ({ id, mode, theme }) => {
  switch (mode) {
    case 'thick':
      return <View nativeID={id} style={{ width: width - 40, margin: 20, height: 6, backgroundColor: theme.buttonColor }} />
    default:
      return <View nativeID={id} style={{ width: width - 40, margin: 20, height: 2, backgroundColor: theme.buttonColor }} />
  }
}

export default Divider
