import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { Animated, ScrollView, View, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import { SafeAreaView } from 'react-native-safe-area-context'
import * as gql from '@gql/Queries'
import { checkoutAssociate, refreshToken } from '@gql/Mutations'
import _ from 'lodash'
import Profile from '@components/Profile'
import { Feather } from '@expo/vector-icons'
import { scale } from '@utils/scale'
import PrivacyLinks from '@components/PrivacyLinks'
import * as SecureStore from 'expo-secure-store'
import Constants from 'expo-constants'
import moment from 'moment'

const Drawer = ({
  user,
  theme,
  layout,
  settings,
  navigation,
  dispatch,
  client,
  checkout
}) => {
  const [customer, setCustomer] = useState(user?.customer)
  const [accessToken, setAccessToken] = useState(user?.accessToken)
  const [hasCheckedRefresh, setHasCheckedRefresh] = useState(false)

  useEffect(() => {
    const checkIfUserHasNotifications = async () => {
      // await SecureStore.deleteItemAsync('hasAllowedNotifications')
      const hasAllowedNotifications = await SecureStore.getItemAsync(
        'hasAllowedNotifications'
      )
      if (!hasAllowedNotifications || hasAllowedNotifications !== 'true') {
        if (
          !hasAllowedNotifications ||
          moment.unix(Number(hasAllowedNotifications)).isSameOrBefore(moment())
        ) {
          navigation.navigate('Notifications')
        }
      }
    }
    Constants.isDevice && checkIfUserHasNotifications()
  }, [])

  useEffect(() => {
    if (accessToken !== user?.accessToken) {
      setAccessToken(user?.accessToken)
    }
    if (!user?.accessToken) {
      setAccessToken(null)
      setCustomer(null)
    }
  }, [user?.accessToken])

  useEffect(() => {
    const getUserCall = async () => {
      !hasCheckedRefresh && await refreshUserToken()
      await getUser()
    }

    if (accessToken) {
      console.log('NO ACCESS TOKEN')
      getUserCall()
    }
    if (!accessToken) {
      setAccessToken(null)
      setCustomer(null)
    }
  }, [accessToken])

  useEffect(() => {
    setCustomer(user?.customer)
  }, [user?.customer])

  const refreshUserToken = async () => {
    if (accessToken) {
      const result = await client.mutate({
        mutation: refreshToken,
        variables: {
          customerAccessToken: accessToken
        }
      })
      const at = result?.data?.customerAccessTokenRenew?.customerAccessToken?.accessToken
      setHasCheckedRefresh(true)
      if (at) {
        setAccessToken(at)
      }
    }
  }

  const getUser = async () => {
    if (accessToken) {
      const { data } = await client.query({
        // TODO: error comes as response.errors[]
        query: gql.getCustomer,
        variables: { customerAccessToken: accessToken }
      })
      if (_.get(data, 'customer')) {
        dispatch({
          type: 'SET_CUSTOMER',
          data: {
            customer: _.get(data, 'customer')
          }
        })

        if (
          !_.get(checkout, 'id') &&
          _.get(data, 'customer.lastIncompleteCheckout')
        ) {
          console.log('Has previous checkout')
          // TODO: ASK THE USER IF THEY WANT TO USE A PREVIOUS CHECKOUT
        }
        if (_.get(checkout, 'id') && !_.get(checkout, 'email')) {
          const res = await client.mutate({
            mutation: checkoutAssociate,
            variables: {
              checkoutId: _.get(checkout, 'id'),
              customerAccessToken: accessToken
            }
          })
          dispatch({
            type: 'SET_LOCAL_CHECKOUT',
            data: {
              checkout: _.get(res, 'data.checkoutCustomerAssociateV2.checkout')
            }
          })
        }
      }
    } else {
      setCustomer(null)
      setAccessToken(null)
    }
  }

  const handleLogout = () => {
    dispatch({
      type: 'LOGOUT'
    })
  }

  return (
    <>
      <Animated.View
        style={{
          ...styles.shadowAnimation,
          backgroundColor: theme.backgroundColor
        }}
      />
      <ScrollView
        style={{ flex: 1, backgroundColor: theme.backgroundColor }}
        contentContainerStyle={{ flex: 1 }}
      >
        <SafeAreaView
          style={styles.container}
          forceInset={{ top: 'always', horizontal: 'never' }}
        >
          {settings.allowUserAccounts !== 'no' && (
            <Profile
              settings={settings}
              theme={theme}
              customer={customer}
              accessToken={accessToken}
              dispatch={dispatch}
              navigation={navigation}
            />
          )}
          {/* <DrawerItems {...this.props} /> */}
          <View
            style={{ flex: 1, padding: 20, justifyContent: 'space-between' }}
          >
            <View>
              {layout.map((layoutItem, i) => {
                return (
                  <TouchableOpacity
                    key={`${layoutItem._id}`}
                    onPress={() => navigation.navigate(layoutItem.stack)}
                    style={{ padding: 10, paddingVertical: 15 }}
                  >
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Feather
                        name={layoutItem.icon}
                        size={scale(18)}
                        color={theme.textColor}
                      />
                      <Text
                        fontSize={18}
                        style={{ marginLeft: 10, color: theme.textColor }}
                      >
                        {layoutItem.label || layoutItem.stack}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )
              })}
              {settings.allowUserAccounts !== 'no' && user.accessToken && (
                <TouchableOpacity
                  style={{ padding: 10, paddingVertical: 15 }}
                  onPress={handleLogout}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Feather
                      name='log-out'
                      size={scale(18)}
                      color={theme.textColor}
                    />
                    <Text
                      fontSize={18}
                      style={{ marginLeft: 10, color: theme.textColor }}
                    >
                      Logout
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
            <PrivacyLinks color={theme.textColor} />
          </View>
        </SafeAreaView>
      </ScrollView>
    </>
  )
}

const styles = {
  container: {
    flex: 1
  },
  shadowAnimation: {
    zIndex: 2,
    backgroundColor: 'white',
    width: 50,
    top: 0,
    bottom: 0,
    left: -50,
    position: 'absolute',
    shadowOffset: { width: 3, height: 0 },
    shadowOpacity: 0.05,
    shadowRadius: 5,
    elevation: 3
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.sideMenu'),
    layout: _.get(state, 'config.theme.sideMenu.layout'),
    settings: _.get(state, 'config.theme.sideMenu'),
    user: state.user,
    checkout: state.cart.checkout
  }
}

export default connect(mapStateToProps)(withApollo(Drawer))
