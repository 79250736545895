import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import Panel from '@components/Panel'
import Text from '@components/Text'
import Icon from '@components/Icon'
import _ from 'lodash'

const AddressCard = ({ onPress, onCaretPress, address, theme, active, containerStyle, email, padded }) => {
  const handleOnCardPress = () => {
    if (_.isFunction(onPress)) {
      onPress(address)
    }
  }

  const handleOnCaretPress = () => {
    if (_.isFunction(onCaretPress)) {
      onCaretPress(address)
    } else {
      if (_.isFunction(onPress)) {
        onPress(address)
      }
    }
  }

  const activeStyle =
      theme && active
        ? {
          ...containerStyle,
          borderColor: theme.buttonColor,
          borderWidth: 2,
          borderBottomColor: theme.buttonColor,
          borderBottomWidth: 2,
          flex: 1
        }
        : {
          ...containerStyle,
          flex: 1
        }
  const _address = address || {}

  return (
    <Panel containerStyle={activeStyle} theme={theme}>
      <TouchableOpacity onPress={handleOnCardPress}>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between'
          }}
        >
          <View style={{ flex: 1, padding: padded && 5 }}>
            <Text fontSize={16} bold>
              {_address.firstName} {_address.lastName}
            </Text>
            {!!email && <Text fontSize={14}>{email}</Text>}
            <Text secondary fontSize={14}>{_address.address1}{_address.address2 ? `, ${_address.address2}` : ''}</Text>
            <Text secondary fontSize={14}>
              {_address.city}, {_address.province}{' '}
              {_address.countryCodeV2 || _address.country} {_address.zip}
            </Text>
          </View>
          <TouchableOpacity
            onPress={handleOnCaretPress}
            style={{ justifyContent: 'center', paddingLeft: 10 }}
          >
            <Icon name='chevron-right' size={20} type='light' />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </Panel>
  )
}

export default AddressCard
