import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Animated } from 'react-native'
import { WebView } from 'react-native-webview'
import { width } from '@utils/scale'
import _ from 'lodash'
import WebviewSkeleton from '@components/Skeletons/WebviewSkeleton'
import ModalContainer from '@containers/ModalContainer'

const GeneralWebview = ({ theme, navigation, route }) => {
  const opacityValue = new Animated.Value(0)
  const [loading, setLoading] = useState(true)
  const webUrl = route.params?.url ?? 'defaultValue'

  useEffect(() => {
    if (!loading) {
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: 200,
        useNativeDriver: false
      }).start()
    }
  }, [loading])

  const handleOnLoad = (e) => {
    setLoading(false)
  }

  const handleBack = () => {
    navigation.goBack()
  }

  return (
    <ModalContainer theme={theme} handleBack={handleBack}>
      {loading && <WebviewSkeleton backgroundColor={theme.pageBackground} />}
      <Animated.View style={{ opacity: opacityValue }}>
        <WebView
          onLoadEnd={handleOnLoad}
          style={{ width, height: '100%', paddingTop: 20, paddingBottom: 20 }}
          source={{ uri: webUrl }}
        />
      </Animated.View>
    </ModalContainer>
  )
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(GeneralWebview)
