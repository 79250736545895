const countries = [
  {
    id: 300077744264,
    name: 'Afghanistan',
    code: 'AF',
    provinces: []
  },
  {
    id: 300079382664,
    name: 'Aland Islands',
    code: 'AX',
    provinces: []
  },
  {
    id: 300079415432,
    name: 'Albania',
    code: 'AL',
    provinces: []
  },
  {
    id: 300081348744,
    name: 'Algeria',
    code: 'DZ',
    provinces: []
  },
  {
    id: 300079448200,
    name: 'Andorra',
    code: 'AD',
    provinces: []
  },
  {
    id: 300081381512,
    name: 'Angola',
    code: 'AO',
    provinces: []
  },
  {
    id: 300083150984,
    name: 'Anguilla',
    code: 'AI',
    provinces: []
  },
  {
    id: 300083183752,
    name: 'Antigua And Barbuda',
    code: 'AG',
    provinces: []
  },
  {
    id: 300084265096,
    name: 'Argentina',
    code: 'AR',
    provinces: [
      {
        id: 3150336229512,
        country: 300084265096,
        code: 'B',
        name: 'Buenos Aires'
      },
      {
        id: 3150336262280,
        country: 300084265096,
        code: 'K',
        name: 'Catamarca'
      },
      {
        id: 3150336295048,
        country: 300084265096,
        code: 'H',
        name: 'Chaco'
      },
      {
        id: 3150336327816,
        country: 300084265096,
        code: 'U',
        name: 'Chubut'
      },
      {
        id: 3150336360584,
        country: 300084265096,
        code: 'C',
        name: 'Ciudad Autónoma de Buenos Aires'
      },
      {
        id: 3150336393352,
        country: 300084265096,
        code: 'X',
        name: 'Córdoba'
      },
      {
        id: 3150336426120,
        country: 300084265096,
        code: 'W',
        name: 'Corrientes'
      },
      {
        id: 3150336458888,
        country: 300084265096,
        code: 'E',
        name: 'Entre Ríos'
      },
      {
        id: 3150336491656,
        country: 300084265096,
        code: 'P',
        name: 'Formosa'
      },
      {
        id: 3150336524424,
        country: 300084265096,
        code: 'Y',
        name: 'Jujuy'
      },
      {
        id: 3150336557192,
        country: 300084265096,
        code: 'L',
        name: 'La Pampa'
      },
      {
        id: 3150336589960,
        country: 300084265096,
        code: 'F',
        name: 'La Rioja'
      },
      {
        id: 3150336622728,
        country: 300084265096,
        code: 'M',
        name: 'Mendoza'
      },
      {
        id: 3150336655496,
        country: 300084265096,
        code: 'N',
        name: 'Misiones'
      },
      {
        id: 3150336688264,
        country: 300084265096,
        code: 'Q',
        name: 'Neuquén'
      },
      {
        id: 3150336721032,
        country: 300084265096,
        code: 'R',
        name: 'Río Negro'
      },
      {
        id: 3150336753800,
        country: 300084265096,
        code: 'A',
        name: 'Salta'
      },
      {
        id: 3150336786568,
        country: 300084265096,
        code: 'J',
        name: 'San Juan'
      },
      {
        id: 3150336819336,
        country: 300084265096,
        code: 'D',
        name: 'San Luis'
      },
      {
        id: 3150336852104,
        country: 300084265096,
        code: 'Z',
        name: 'Santa Cruz'
      },
      {
        id: 3150336884872,
        country: 300084265096,
        code: 'S',
        name: 'Santa Fe'
      },
      {
        id: 3150336917640,
        country: 300084265096,
        code: 'G',
        name: 'Santiago Del Estero'
      },
      {
        id: 3150336950408,
        country: 300084265096,
        code: 'V',
        name: 'Tierra Del Fuego'
      },
      {
        id: 3150336983176,
        country: 300084265096,
        code: 'T',
        name: 'Tucumán'
      }
    ]
  },
  {
    id: 300079480968,
    name: 'Armenia',
    code: 'AM',
    provinces: []
  },
  {
    id: 300083216520,
    name: 'Aruba',
    code: 'AW',
    provinces: []
  },
  {
    id: 300084789384,
    name: 'Australia',
    code: 'AU',
    provinces: [
      {
        id: 3150340358280,
        country: 300084789384,
        code: 'ACT',
        name: 'Australian Capital Territory'
      },
      {
        id: 3150340391048,
        country: 300084789384,
        code: 'NSW',
        name: 'New South Wales'
      },
      {
        id: 3150340423816,
        country: 300084789384,
        code: 'NT',
        name: 'Northern Territory'
      },
      {
        id: 3150340456584,
        country: 300084789384,
        code: 'QLD',
        name: 'Queensland'
      },
      {
        id: 3150340489352,
        country: 300084789384,
        code: 'SA',
        name: 'South Australia'
      },
      {
        id: 3150340522120,
        country: 300084789384,
        code: 'TAS',
        name: 'Tasmania'
      },
      {
        id: 3150340554888,
        country: 300084789384,
        code: 'VIC',
        name: 'Victoria'
      },
      {
        id: 3150340587656,
        country: 300084789384,
        code: 'WA',
        name: 'Western Australia'
      }
    ]
  },
  {
    id: 300079513736,
    name: 'Austria',
    code: 'AT',
    provinces: []
  },
  {
    id: 300077777032,
    name: 'Azerbaijan',
    code: 'AZ',
    provinces: []
  },
  {
    id: 300083249288,
    name: 'Bahamas',
    code: 'BS',
    provinces: []
  },
  {
    id: 300077809800,
    name: 'Bahrain',
    code: 'BH',
    provinces: []
  },
  {
    id: 300077842568,
    name: 'Bangladesh',
    code: 'BD',
    provinces: []
  },
  {
    id: 300083282056,
    name: 'Barbados',
    code: 'BB',
    provinces: []
  },
  {
    id: 300079546504,
    name: 'Belarus',
    code: 'BY',
    provinces: []
  },
  {
    id: 300079579272,
    name: 'Belgium',
    code: 'BE',
    provinces: []
  },
  {
    id: 300083314824,
    name: 'Belize',
    code: 'BZ',
    provinces: []
  },
  {
    id: 300081414280,
    name: 'Benin',
    code: 'BJ',
    provinces: []
  },
  {
    id: 300083347592,
    name: 'Bermuda',
    code: 'BM',
    provinces: []
  },
  {
    id: 300077875336,
    name: 'Bhutan',
    code: 'BT',
    provinces: []
  },
  {
    id: 300084297864,
    name: 'Bolivia',
    code: 'BO',
    provinces: []
  },
  {
    id: 300079612040,
    name: 'Bosnia And Herzegovina',
    code: 'BA',
    provinces: []
  },
  {
    id: 300081447048,
    name: 'Botswana',
    code: 'BW',
    provinces: []
  },
  {
    id: 300079644808,
    name: 'Bouvet Island',
    code: 'BV',
    provinces: []
  },
  {
    id: 300084330632,
    name: 'Brazil',
    code: 'BR',
    provinces: [
      {
        id: 3150337015944,
        country: 300084330632,
        code: 'AC',
        name: 'Acre'
      },
      {
        id: 3150337048712,
        country: 300084330632,
        code: 'AL',
        name: 'Alagoas'
      },
      {
        id: 3150337081480,
        country: 300084330632,
        code: 'AP',
        name: 'Amapá'
      },
      {
        id: 3150337114248,
        country: 300084330632,
        code: 'AM',
        name: 'Amazonas'
      },
      {
        id: 3150337147016,
        country: 300084330632,
        code: 'BA',
        name: 'Bahia'
      },
      {
        id: 3150337179784,
        country: 300084330632,
        code: 'CE',
        name: 'Ceará'
      },
      {
        id: 3150337212552,
        country: 300084330632,
        code: 'DF',
        name: 'Distrito Federal'
      },
      {
        id: 3150337245320,
        country: 300084330632,
        code: 'ES',
        name: 'Espírito Santo'
      },
      {
        id: 3150337278088,
        country: 300084330632,
        code: 'GO',
        name: 'Goiás'
      },
      {
        id: 3150337310856,
        country: 300084330632,
        code: 'MA',
        name: 'Maranhão'
      },
      {
        id: 3150337343624,
        country: 300084330632,
        code: 'MT',
        name: 'Mato Grosso'
      },
      {
        id: 3150337376392,
        country: 300084330632,
        code: 'MS',
        name: 'Mato Grosso do Sul'
      },
      {
        id: 3150337409160,
        country: 300084330632,
        code: 'MG',
        name: 'Minas Gerais'
      },
      {
        id: 3150337441928,
        country: 300084330632,
        code: 'PA',
        name: 'Pará'
      },
      {
        id: 3150337474696,
        country: 300084330632,
        code: 'PB',
        name: 'Paraíba'
      },
      {
        id: 3150337507464,
        country: 300084330632,
        code: 'PR',
        name: 'Paraná'
      },
      {
        id: 3150337540232,
        country: 300084330632,
        code: 'PE',
        name: 'Pernambuco'
      },
      {
        id: 3150337573000,
        country: 300084330632,
        code: 'PI',
        name: 'Piauí'
      },
      {
        id: 3150337671304,
        country: 300084330632,
        code: 'RJ',
        name: 'Rio de Janeiro'
      },
      {
        id: 3150337605768,
        country: 300084330632,
        code: 'RN',
        name: 'Rio Grande do Norte'
      },
      {
        id: 3150337638536,
        country: 300084330632,
        code: 'RS',
        name: 'Rio Grande do Sul'
      },
      {
        id: 3150337704072,
        country: 300084330632,
        code: 'RO',
        name: 'Rondônia'
      },
      {
        id: 3150337736840,
        country: 300084330632,
        code: 'RR',
        name: 'Roraima'
      },
      {
        id: 3150337769608,
        country: 300084330632,
        code: 'SC',
        name: 'Santa Catarina'
      },
      {
        id: 3150337802376,
        country: 300084330632,
        code: 'SP',
        name: 'São Paulo'
      },
      {
        id: 3150337835144,
        country: 300084330632,
        code: 'SE',
        name: 'Sergipe'
      },
      {
        id: 3150337867912,
        country: 300084330632,
        code: 'TO',
        name: 'Tocantins'
      }
    ]
  },
  {
    id: 300077908104,
    name: 'British Indian Ocean Territory',
    code: 'IO',
    provinces: []
  },
  {
    id: 300077940872,
    name: 'Brunei',
    code: 'BN',
    provinces: []
  },
  {
    id: 300079677576,
    name: 'Bulgaria',
    code: 'BG',
    provinces: []
  },
  {
    id: 300081479816,
    name: 'Burkina Faso',
    code: 'BF',
    provinces: []
  },
  {
    id: 300081512584,
    name: 'Burundi',
    code: 'BI',
    provinces: []
  },
  {
    id: 300077973640,
    name: 'Cambodia',
    code: 'KH',
    provinces: []
  },
  {
    id: 300085444744,
    name: 'Canada',
    code: 'CA',
    provinces: [
      {
        id: 3150341144712,
        country: 300085444744,
        code: 'AB',
        name: 'Alberta'
      },
      {
        id: 3150341177480,
        country: 300085444744,
        code: 'BC',
        name: 'British Columbia'
      },
      {
        id: 3150341210248,
        country: 300085444744,
        code: 'MB',
        name: 'Manitoba'
      },
      {
        id: 3150341243016,
        country: 300085444744,
        code: 'NB',
        name: 'New Brunswick'
      },
      {
        id: 3150341275784,
        country: 300085444744,
        code: 'NL',
        name: 'Newfoundland and Labrador'
      },
      {
        id: 3150341308552,
        country: 300085444744,
        code: 'NT',
        name: 'Northwest Territories'
      },
      {
        id: 3150341341320,
        country: 300085444744,
        code: 'NS',
        name: 'Nova Scotia'
      },
      {
        id: 3150341374088,
        country: 300085444744,
        code: 'NU',
        name: 'Nunavut'
      },
      {
        id: 3150341406856,
        country: 300085444744,
        code: 'ON',
        name: 'Ontario'
      },
      {
        id: 3150341439624,
        country: 300085444744,
        code: 'PE',
        name: 'Prince Edward Island'
      },
      {
        id: 3150341472392,
        country: 300085444744,
        code: 'QC',
        name: 'Quebec'
      },
      {
        id: 3150341505160,
        country: 300085444744,
        code: 'SK',
        name: 'Saskatchewan'
      },
      {
        id: 3150341537928,
        country: 300085444744,
        code: 'YT',
        name: 'Yukon'
      }
    ]
  },
  {
    id: 300081578120,
    name: 'Cape Verde',
    code: 'CV',
    provinces: []
  },
  {
    id: 300084363400,
    name: 'Caribbean Netherlands',
    code: 'BQ',
    provinces: []
  },
  {
    id: 300083413128,
    name: 'Cayman Islands',
    code: 'KY',
    provinces: []
  },
  {
    id: 300081610888,
    name: 'Central African Republic',
    code: 'CF',
    provinces: []
  },
  {
    id: 300081643656,
    name: 'Chad',
    code: 'TD',
    provinces: []
  },
  {
    id: 300084396168,
    name: 'Chile',
    code: 'CL',
    provinces: [
      {
        id: 3150337966216,
        country: 300084396168,
        code: 'AN',
        name: 'Antofagasta'
      },
      {
        id: 3150338261128,
        country: 300084396168,
        code: 'AR',
        name: 'Araucanía'
      },
      {
        id: 3150337900680,
        country: 300084396168,
        code: 'AP',
        name: 'Arica and Parinacota'
      },
      {
        id: 3150337998984,
        country: 300084396168,
        code: 'AT',
        name: 'Atacama'
      },
      {
        id: 3150338359432,
        country: 300084396168,
        code: 'AI',
        name: 'Aysén'
      },
      {
        id: 3150338228360,
        country: 300084396168,
        code: 'BI',
        name: 'Biobío'
      },
      {
        id: 3150338031752,
        country: 300084396168,
        code: 'CO',
        name: 'Coquimbo'
      },
      {
        id: 3150338326664,
        country: 300084396168,
        code: 'LL',
        name: 'Los Lagos'
      },
      {
        id: 3150338293896,
        country: 300084396168,
        code: 'LR',
        name: 'Los Ríos'
      },
      {
        id: 3150338392200,
        country: 300084396168,
        code: 'MA',
        name: 'Magallanes'
      },
      {
        id: 3150338162824,
        country: 300084396168,
        code: 'ML',
        name: 'Maule'
      },
      {
        id: 3150338195592,
        country: 300084396168,
        code: 'NB',
        name: 'Ñuble'
      },
      {
        id: 3150338130056,
        country: 300084396168,
        code: 'LI',
        name: "O'Higgins"
      },
      {
        id: 3150338097288,
        country: 300084396168,
        code: 'RM',
        name: 'Santiago'
      },
      {
        id: 3150337933448,
        country: 300084396168,
        code: 'TA',
        name: 'Tarapacá'
      },
      {
        id: 3150338064520,
        country: 300084396168,
        code: 'VS',
        name: 'Valparaíso'
      }
    ]
  },
  {
    id: 300078006408,
    name: 'China',
    code: 'CN',
    provinces: [
      {
        id: 3150310506632,
        country: 300078006408,
        code: 'AH',
        name: 'Anhui'
      },
      {
        id: 3150310539400,
        country: 300078006408,
        code: 'BJ',
        name: 'Beijing'
      },
      {
        id: 3150310572168,
        country: 300078006408,
        code: 'CQ',
        name: 'Chongqing'
      },
      {
        id: 3150310604936,
        country: 300078006408,
        code: 'FJ',
        name: 'Fujian'
      },
      {
        id: 3150310637704,
        country: 300078006408,
        code: 'GS',
        name: 'Gansu'
      },
      {
        id: 3150310670472,
        country: 300078006408,
        code: 'GD',
        name: 'Guangdong'
      },
      {
        id: 3150310703240,
        country: 300078006408,
        code: 'GX',
        name: 'Guangxi'
      },
      {
        id: 3150310736008,
        country: 300078006408,
        code: 'GZ',
        name: 'Guizhou'
      },
      {
        id: 3150310768776,
        country: 300078006408,
        code: 'HI',
        name: 'Hainan'
      },
      {
        id: 3150310801544,
        country: 300078006408,
        code: 'HE',
        name: 'Hebei'
      },
      {
        id: 3150310834312,
        country: 300078006408,
        code: 'HL',
        name: 'Heilongjiang'
      },
      {
        id: 3150310867080,
        country: 300078006408,
        code: 'HA',
        name: 'Henan'
      },
      {
        id: 3150310899848,
        country: 300078006408,
        code: 'HB',
        name: 'Hubei'
      },
      {
        id: 3150310932616,
        country: 300078006408,
        code: 'HN',
        name: 'Hunan'
      },
      {
        id: 3150310965384,
        country: 300078006408,
        code: 'NM',
        name: 'Inner Mongolia'
      },
      {
        id: 3150310998152,
        country: 300078006408,
        code: 'JS',
        name: 'Jiangsu'
      },
      {
        id: 3150311030920,
        country: 300078006408,
        code: 'JX',
        name: 'Jiangxi'
      },
      {
        id: 3150311063688,
        country: 300078006408,
        code: 'JL',
        name: 'Jilin'
      },
      {
        id: 3150311096456,
        country: 300078006408,
        code: 'LN',
        name: 'Liaoning'
      },
      {
        id: 3150311129224,
        country: 300078006408,
        code: 'NX',
        name: 'Ningxia'
      },
      {
        id: 3150311161992,
        country: 300078006408,
        code: 'QH',
        name: 'Qinghai'
      },
      {
        id: 3150311194760,
        country: 300078006408,
        code: 'SN',
        name: 'Shaanxi'
      },
      {
        id: 3150311227528,
        country: 300078006408,
        code: 'SD',
        name: 'Shandong'
      },
      {
        id: 3150311260296,
        country: 300078006408,
        code: 'SH',
        name: 'Shanghai'
      },
      {
        id: 3150311293064,
        country: 300078006408,
        code: 'SX',
        name: 'Shanxi'
      },
      {
        id: 3150311325832,
        country: 300078006408,
        code: 'SC',
        name: 'Sichuan'
      },
      {
        id: 3150311358600,
        country: 300078006408,
        code: 'TJ',
        name: 'Tianjin'
      },
      {
        id: 3150311391368,
        country: 300078006408,
        code: 'XJ',
        name: 'Xinjiang'
      },
      {
        id: 3150311424136,
        country: 300078006408,
        code: 'YZ',
        name: 'Xizang'
      },
      {
        id: 3150311456904,
        country: 300078006408,
        code: 'YN',
        name: 'Yunnan'
      },
      {
        id: 3150311489672,
        country: 300078006408,
        code: 'ZJ',
        name: 'Zhejiang'
      }
    ]
  },
  {
    id: 300078039176,
    name: 'Christmas Island',
    code: 'CX',
    provinces: []
  },
  {
    id: 300078071944,
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    provinces: []
  },
  {
    id: 300084428936,
    name: 'Colombia',
    code: 'CO',
    provinces: [
      {
        id: 3150338457736,
        country: 300084428936,
        code: 'AMA',
        name: 'Amazonas'
      },
      {
        id: 3150338490504,
        country: 300084428936,
        code: 'ANT',
        name: 'Antioquia'
      },
      {
        id: 3150338523272,
        country: 300084428936,
        code: 'ARA',
        name: 'Arauca'
      },
      {
        id: 3150338556040,
        country: 300084428936,
        code: 'ATL',
        name: 'Atlántico'
      },
      {
        id: 3150338424968,
        country: 300084428936,
        code: 'DC',
        name: 'Bogotá, D.C.'
      },
      {
        id: 3150338588808,
        country: 300084428936,
        code: 'BOL',
        name: 'Bolívar'
      },
      {
        id: 3150338621576,
        country: 300084428936,
        code: 'BOY',
        name: 'Boyacá'
      },
      {
        id: 3150338654344,
        country: 300084428936,
        code: 'CAL',
        name: 'Caldas'
      },
      {
        id: 3150338687112,
        country: 300084428936,
        code: 'CAQ',
        name: 'Caquetá'
      },
      {
        id: 3150338719880,
        country: 300084428936,
        code: 'CAS',
        name: 'Casanare'
      },
      {
        id: 3150338752648,
        country: 300084428936,
        code: 'CAU',
        name: 'Cauca'
      },
      {
        id: 3150338785416,
        country: 300084428936,
        code: 'CES',
        name: 'Cesar'
      },
      {
        id: 3150338818184,
        country: 300084428936,
        code: 'CHO',
        name: 'Chocó'
      },
      {
        id: 3150338850952,
        country: 300084428936,
        code: 'COR',
        name: 'Córdoba'
      },
      {
        id: 3150338883720,
        country: 300084428936,
        code: 'CUN',
        name: 'Cundinamarca'
      },
      {
        id: 3150338916488,
        country: 300084428936,
        code: 'GUA',
        name: 'Guainía'
      },
      {
        id: 3150338949256,
        country: 300084428936,
        code: 'GUV',
        name: 'Guaviare'
      },
      {
        id: 3150338982024,
        country: 300084428936,
        code: 'HUI',
        name: 'Huila'
      },
      {
        id: 3150339014792,
        country: 300084428936,
        code: 'LAG',
        name: 'La Guajira'
      },
      {
        id: 3150339047560,
        country: 300084428936,
        code: 'MAG',
        name: 'Magdalena'
      },
      {
        id: 3150339080328,
        country: 300084428936,
        code: 'MET',
        name: 'Meta'
      },
      {
        id: 3150339113096,
        country: 300084428936,
        code: 'NAR',
        name: 'Nariño'
      },
      {
        id: 3150339145864,
        country: 300084428936,
        code: 'NSA',
        name: 'Norte de Santander'
      },
      {
        id: 3150339178632,
        country: 300084428936,
        code: 'PUT',
        name: 'Putumayo'
      },
      {
        id: 3150339211400,
        country: 300084428936,
        code: 'QUI',
        name: 'Quindío'
      },
      {
        id: 3150339244168,
        country: 300084428936,
        code: 'RIS',
        name: 'Risaralda'
      },
      {
        id: 3150339276936,
        country: 300084428936,
        code: 'SAP',
        name: 'San Andrés, Providencia y Santa Catalina'
      },
      {
        id: 3150339309704,
        country: 300084428936,
        code: 'SAN',
        name: 'Santander'
      },
      {
        id: 3150339342472,
        country: 300084428936,
        code: 'SUC',
        name: 'Sucre'
      },
      {
        id: 3150339375240,
        country: 300084428936,
        code: 'TOL',
        name: 'Tolima'
      },
      {
        id: 3150339408008,
        country: 300084428936,
        code: 'VAC',
        name: 'Valle del Cauca'
      },
      {
        id: 3150339440776,
        country: 300084428936,
        code: 'VAU',
        name: 'Vaupés'
      },
      {
        id: 3150339473544,
        country: 300084428936,
        code: 'VID',
        name: 'Vichada'
      }
    ]
  },
  {
    id: 300081676424,
    name: 'Comoros',
    code: 'KM',
    provinces: []
  },
  {
    id: 300081709192,
    name: 'Congo',
    code: 'CG',
    provinces: []
  },
  {
    id: 300081741960,
    name: 'Congo, The Democratic Republic Of The',
    code: 'CD',
    provinces: []
  },
  {
    id: 300084822152,
    name: 'Cook Islands',
    code: 'CK',
    provinces: []
  },
  {
    id: 300083445896,
    name: 'Costa Rica',
    code: 'CR',
    provinces: []
  },
  {
    id: 300079710344,
    name: 'Croatia',
    code: 'HR',
    provinces: []
  },
  {
    id: 300083478664,
    name: 'Cuba',
    code: 'CU',
    provinces: []
  },
  {
    id: 300083511432,
    name: 'Curaçao',
    code: 'CW',
    provinces: []
  },
  {
    id: 300079743112,
    name: 'Cyprus',
    code: 'CY',
    provinces: []
  },
  {
    id: 300079775880,
    name: 'Czech Republic',
    code: 'CZ',
    provinces: []
  },
  {
    id: 300081774728,
    name: "Côte d'Ivoire",
    code: 'CI',
    provinces: []
  },
  {
    id: 300079808648,
    name: 'Denmark',
    code: 'DK',
    provinces: []
  },
  {
    id: 300081807496,
    name: 'Djibouti',
    code: 'DJ',
    provinces: []
  },
  {
    id: 300083544200,
    name: 'Dominica',
    code: 'DM',
    provinces: []
  },
  {
    id: 300083576968,
    name: 'Dominican Republic',
    code: 'DO',
    provinces: []
  },
  {
    id: 300084461704,
    name: 'Ecuador',
    code: 'EC',
    provinces: []
  },
  {
    id: 300081840264,
    name: 'Egypt',
    code: 'EG',
    provinces: [
      {
        id: 3150332919944,
        country: 300081840264,
        code: 'SU',
        name: '6th of October'
      },
      {
        id: 3150332952712,
        country: 300081840264,
        code: 'SHR',
        name: 'Al Sharqia'
      },
      {
        id: 3150332985480,
        country: 300081840264,
        code: 'ALX',
        name: 'Alexandria'
      },
      {
        id: 3150333018248,
        country: 300081840264,
        code: 'ASN',
        name: 'Aswan'
      },
      {
        id: 3150333051016,
        country: 300081840264,
        code: 'AST',
        name: 'Asyut'
      },
      {
        id: 3150333083784,
        country: 300081840264,
        code: 'BH',
        name: 'Beheira'
      },
      {
        id: 3150333116552,
        country: 300081840264,
        code: 'BNS',
        name: 'Beni Suef'
      },
      {
        id: 3150333149320,
        country: 300081840264,
        code: 'C',
        name: 'Cairo'
      },
      {
        id: 3150333182088,
        country: 300081840264,
        code: 'DK',
        name: 'Dakahlia'
      },
      {
        id: 3150333214856,
        country: 300081840264,
        code: 'DT',
        name: 'Damietta'
      },
      {
        id: 3150333247624,
        country: 300081840264,
        code: 'FYM',
        name: 'Faiyum'
      },
      {
        id: 3150333280392,
        country: 300081840264,
        code: 'GH',
        name: 'Gharbia'
      },
      {
        id: 3150333313160,
        country: 300081840264,
        code: 'GZ',
        name: 'Giza'
      },
      {
        id: 3150333345928,
        country: 300081840264,
        code: 'HU',
        name: 'Helwan'
      },
      {
        id: 3150333378696,
        country: 300081840264,
        code: 'IS',
        name: 'Ismailia'
      },
      {
        id: 3150333411464,
        country: 300081840264,
        code: 'KFS',
        name: 'Kafr el-Sheikh'
      },
      {
        id: 3150333444232,
        country: 300081840264,
        code: 'LX',
        name: 'Luxor'
      },
      {
        id: 3150333477000,
        country: 300081840264,
        code: 'MT',
        name: 'Matrouh'
      },
      {
        id: 3150333509768,
        country: 300081840264,
        code: 'MN',
        name: 'Minya'
      },
      {
        id: 3150333542536,
        country: 300081840264,
        code: 'MNF',
        name: 'Monufia'
      },
      {
        id: 3150333575304,
        country: 300081840264,
        code: 'WAD',
        name: 'New Valley'
      },
      {
        id: 3150333608072,
        country: 300081840264,
        code: 'SIN',
        name: 'North Sinai'
      },
      {
        id: 3150333640840,
        country: 300081840264,
        code: 'PTS',
        name: 'Port Said'
      },
      {
        id: 3150333673608,
        country: 300081840264,
        code: 'KB',
        name: 'Qalyubia'
      },
      {
        id: 3150333706376,
        country: 300081840264,
        code: 'KN',
        name: 'Qena'
      },
      {
        id: 3150333739144,
        country: 300081840264,
        code: 'BA',
        name: 'Red Sea'
      },
      {
        id: 3150333771912,
        country: 300081840264,
        code: 'SHG',
        name: 'Sohag'
      },
      {
        id: 3150333804680,
        country: 300081840264,
        code: 'JS',
        name: 'South Sinai'
      },
      {
        id: 3150333837448,
        country: 300081840264,
        code: 'SUZ',
        name: 'Suez'
      }
    ]
  },
  {
    id: 300083609736,
    name: 'El Salvador',
    code: 'SV',
    provinces: []
  },
  {
    id: 300081873032,
    name: 'Equatorial Guinea',
    code: 'GQ',
    provinces: []
  },
  {
    id: 300081905800,
    name: 'Eritrea',
    code: 'ER',
    provinces: []
  },
  {
    id: 300079841416,
    name: 'Estonia',
    code: 'EE',
    provinces: []
  },
  {
    id: 300081938568,
    name: 'Eswatini',
    code: 'SZ',
    provinces: []
  },
  {
    id: 300081971336,
    name: 'Ethiopia',
    code: 'ET',
    provinces: []
  },
  {
    id: 300084494472,
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    provinces: []
  },
  {
    id: 300079874184,
    name: 'Faroe Islands',
    code: 'FO',
    provinces: []
  },
  {
    id: 300084854920,
    name: 'Fiji',
    code: 'FJ',
    provinces: []
  },
  {
    id: 300079906952,
    name: 'Finland',
    code: 'FI',
    provinces: []
  },
  {
    id: 300079939720,
    name: 'France',
    code: 'FR',
    provinces: []
  },
  {
    id: 300084527240,
    name: 'French Guiana',
    code: 'GF',
    provinces: []
  },
  {
    id: 300084887688,
    name: 'French Polynesia',
    code: 'PF',
    provinces: []
  },
  {
    id: 300085543048,
    name: 'French Southern Territories',
    code: 'TF',
    provinces: []
  },
  {
    id: 300082004104,
    name: 'Gabon',
    code: 'GA',
    provinces: []
  },
  {
    id: 300082036872,
    name: 'Gambia',
    code: 'GM',
    provinces: []
  },
  {
    id: 300079972488,
    name: 'Georgia',
    code: 'GE',
    provinces: []
  },
  {
    id: 300080005256,
    name: 'Germany',
    code: 'DE',
    provinces: []
  },
  {
    id: 300082069640,
    name: 'Ghana',
    code: 'GH',
    provinces: []
  },
  {
    id: 300080038024,
    name: 'Gibraltar',
    code: 'GI',
    provinces: []
  },
  {
    id: 300080070792,
    name: 'Greece',
    code: 'GR',
    provinces: []
  },
  {
    id: 300080103560,
    name: 'Greenland',
    code: 'GL',
    provinces: []
  },
  {
    id: 300083642504,
    name: 'Grenada',
    code: 'GD',
    provinces: []
  },
  {
    id: 300080136328,
    name: 'Guadeloupe',
    code: 'GP',
    provinces: []
  },
  {
    id: 300083675272,
    name: 'Guatemala',
    code: 'GT',
    provinces: [
      {
        id: 3150335082632,
        country: 300083675272,
        code: 'AVE',
        name: 'Alta Verapaz'
      },
      {
        id: 3150335115400,
        country: 300083675272,
        code: 'BVE',
        name: 'Baja Verapaz'
      },
      {
        id: 3150335148168,
        country: 300083675272,
        code: 'CMT',
        name: 'Chimaltenango'
      },
      {
        id: 3150335180936,
        country: 300083675272,
        code: 'CQM',
        name: 'Chiquimula'
      },
      {
        id: 3150335213704,
        country: 300083675272,
        code: 'EPR',
        name: 'El Progreso'
      },
      {
        id: 3150335246472,
        country: 300083675272,
        code: 'ESC',
        name: 'Escuintla'
      },
      {
        id: 3150335279240,
        country: 300083675272,
        code: 'GUA',
        name: 'Guatemala'
      },
      {
        id: 3150335312008,
        country: 300083675272,
        code: 'HUE',
        name: 'Huehuetenango'
      },
      {
        id: 3150335344776,
        country: 300083675272,
        code: 'IZA',
        name: 'Izabal'
      },
      {
        id: 3150335377544,
        country: 300083675272,
        code: 'JAL',
        name: 'Jalapa'
      },
      {
        id: 3150335410312,
        country: 300083675272,
        code: 'JUT',
        name: 'Jutiapa'
      },
      {
        id: 3150335443080,
        country: 300083675272,
        code: 'PET',
        name: 'Petén'
      },
      {
        id: 3150335475848,
        country: 300083675272,
        code: 'QUE',
        name: 'Quetzaltenango'
      },
      {
        id: 3150335508616,
        country: 300083675272,
        code: 'QUI',
        name: 'Quiché'
      },
      {
        id: 3150335541384,
        country: 300083675272,
        code: 'RET',
        name: 'Retalhuleu'
      },
      {
        id: 3150335574152,
        country: 300083675272,
        code: 'SAC',
        name: 'Sacatepéquez'
      },
      {
        id: 3150335606920,
        country: 300083675272,
        code: 'SMA',
        name: 'San Marcos'
      },
      {
        id: 3150335639688,
        country: 300083675272,
        code: 'SRO',
        name: 'Santa Rosa'
      },
      {
        id: 3150335672456,
        country: 300083675272,
        code: 'SOL',
        name: 'Sololá'
      },
      {
        id: 3150335705224,
        country: 300083675272,
        code: 'SUC',
        name: 'Suchitepéquez'
      },
      {
        id: 3150335737992,
        country: 300083675272,
        code: 'TOT',
        name: 'Totonicapán'
      },
      {
        id: 3150335770760,
        country: 300083675272,
        code: 'ZAC',
        name: 'Zacapa'
      }
    ]
  },
  {
    id: 300080169096,
    name: 'Guernsey',
    code: 'GG',
    provinces: []
  },
  {
    id: 300082102408,
    name: 'Guinea',
    code: 'GN',
    provinces: []
  },
  {
    id: 300082135176,
    name: 'Guinea Bissau',
    code: 'GW',
    provinces: []
  },
  {
    id: 300084560008,
    name: 'Guyana',
    code: 'GY',
    provinces: []
  },
  {
    id: 300083708040,
    name: 'Haiti',
    code: 'HT',
    provinces: []
  },
  {
    id: 300085575816,
    name: 'Heard Island And Mcdonald Islands',
    code: 'HM',
    provinces: []
  },
  {
    id: 300081315976,
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    provinces: []
  },
  {
    id: 300083740808,
    name: 'Honduras',
    code: 'HN',
    provinces: []
  },
  {
    id: 300078104712,
    name: 'Hong Kong',
    code: 'HK',
    provinces: [
      {
        id: 3150311522440,
        country: 300078104712,
        code: 'HK',
        name: 'Hong Kong Island'
      },
      {
        id: 3150311555208,
        country: 300078104712,
        code: 'KL',
        name: 'Kowloon'
      },
      {
        id: 3150311587976,
        country: 300078104712,
        code: 'NT',
        name: 'New Territories'
      }
    ]
  },
  {
    id: 300080201864,
    name: 'Hungary',
    code: 'HU',
    provinces: []
  },
  {
    id: 300080234632,
    name: 'Iceland',
    code: 'IS',
    provinces: []
  },
  {
    id: 300078137480,
    name: 'India',
    code: 'IN',
    provinces: [
      {
        id: 3150311620744,
        country: 300078137480,
        code: 'AN',
        name: 'Andaman and Nicobar Islands'
      },
      {
        id: 3150311653512,
        country: 300078137480,
        code: 'AP',
        name: 'Andhra Pradesh'
      },
      {
        id: 3150311686280,
        country: 300078137480,
        code: 'AR',
        name: 'Arunachal Pradesh'
      },
      {
        id: 3150311719048,
        country: 300078137480,
        code: 'AS',
        name: 'Assam'
      },
      {
        id: 3150311751816,
        country: 300078137480,
        code: 'BR',
        name: 'Bihar'
      },
      {
        id: 3150311784584,
        country: 300078137480,
        code: 'CH',
        name: 'Chandigarh'
      },
      {
        id: 3150311817352,
        country: 300078137480,
        code: 'CG',
        name: 'Chhattisgarh'
      },
      {
        id: 3150311850120,
        country: 300078137480,
        code: 'DN',
        name: 'Dadra and Nagar Haveli'
      },
      {
        id: 3150311882888,
        country: 300078137480,
        code: 'DD',
        name: 'Daman and Diu'
      },
      {
        id: 3150311915656,
        country: 300078137480,
        code: 'DL',
        name: 'Delhi'
      },
      {
        id: 3150311948424,
        country: 300078137480,
        code: 'GA',
        name: 'Goa'
      },
      {
        id: 3150311981192,
        country: 300078137480,
        code: 'GJ',
        name: 'Gujarat'
      },
      {
        id: 3150312013960,
        country: 300078137480,
        code: 'HR',
        name: 'Haryana'
      },
      {
        id: 3150312046728,
        country: 300078137480,
        code: 'HP',
        name: 'Himachal Pradesh'
      },
      {
        id: 3150312079496,
        country: 300078137480,
        code: 'JK',
        name: 'Jammu and Kashmir'
      },
      {
        id: 3150312112264,
        country: 300078137480,
        code: 'JH',
        name: 'Jharkhand'
      },
      {
        id: 3150312145032,
        country: 300078137480,
        code: 'KA',
        name: 'Karnataka'
      },
      {
        id: 3150312177800,
        country: 300078137480,
        code: 'KL',
        name: 'Kerala'
      },
      {
        id: 3150312210568,
        country: 300078137480,
        code: 'LA',
        name: 'Ladakh'
      },
      {
        id: 3150312243336,
        country: 300078137480,
        code: 'LD',
        name: 'Lakshadweep'
      },
      {
        id: 3150312276104,
        country: 300078137480,
        code: 'MP',
        name: 'Madhya Pradesh'
      },
      {
        id: 3150312308872,
        country: 300078137480,
        code: 'MH',
        name: 'Maharashtra'
      },
      {
        id: 3150312341640,
        country: 300078137480,
        code: 'MN',
        name: 'Manipur'
      },
      {
        id: 3150312374408,
        country: 300078137480,
        code: 'ML',
        name: 'Meghalaya'
      },
      {
        id: 3150312407176,
        country: 300078137480,
        code: 'MZ',
        name: 'Mizoram'
      },
      {
        id: 3150312439944,
        country: 300078137480,
        code: 'NL',
        name: 'Nagaland'
      },
      {
        id: 3150312472712,
        country: 300078137480,
        code: 'OR',
        name: 'Odisha'
      },
      {
        id: 3150312505480,
        country: 300078137480,
        code: 'PY',
        name: 'Puducherry'
      },
      {
        id: 3150312538248,
        country: 300078137480,
        code: 'PB',
        name: 'Punjab'
      },
      {
        id: 3150312571016,
        country: 300078137480,
        code: 'RJ',
        name: 'Rajasthan'
      },
      {
        id: 3150312603784,
        country: 300078137480,
        code: 'SK',
        name: 'Sikkim'
      },
      {
        id: 3150312636552,
        country: 300078137480,
        code: 'TN',
        name: 'Tamil Nadu'
      },
      {
        id: 3150312669320,
        country: 300078137480,
        code: 'TS',
        name: 'Telangana'
      },
      {
        id: 3150312702088,
        country: 300078137480,
        code: 'TR',
        name: 'Tripura'
      },
      {
        id: 3150312734856,
        country: 300078137480,
        code: 'UP',
        name: 'Uttar Pradesh'
      },
      {
        id: 3150312767624,
        country: 300078137480,
        code: 'UK',
        name: 'Uttarakhand'
      },
      {
        id: 3150312800392,
        country: 300078137480,
        code: 'WB',
        name: 'West Bengal'
      }
    ]
  },
  {
    id: 300078170248,
    name: 'Indonesia',
    code: 'ID',
    provinces: [
      {
        id: 3150312833160,
        country: 300078170248,
        code: 'AC',
        name: 'Aceh'
      },
      {
        id: 3150312865928,
        country: 300078170248,
        code: 'BA',
        name: 'Bali'
      },
      {
        id: 3150312898696,
        country: 300078170248,
        code: 'BB',
        name: 'Bangka Belitung'
      },
      {
        id: 3150312931464,
        country: 300078170248,
        code: 'BT',
        name: 'Banten'
      },
      {
        id: 3150312964232,
        country: 300078170248,
        code: 'BE',
        name: 'Bengkulu'
      },
      {
        id: 3150312997000,
        country: 300078170248,
        code: 'GO',
        name: 'Gorontalo'
      },
      {
        id: 3150313029768,
        country: 300078170248,
        code: 'JK',
        name: 'Jakarta'
      },
      {
        id: 3150313062536,
        country: 300078170248,
        code: 'JA',
        name: 'Jambi'
      },
      {
        id: 3150313095304,
        country: 300078170248,
        code: 'JB',
        name: 'Jawa Barat'
      },
      {
        id: 3150313128072,
        country: 300078170248,
        code: 'JT',
        name: 'Jawa Tengah'
      },
      {
        id: 3150313160840,
        country: 300078170248,
        code: 'JI',
        name: 'Jawa Timur'
      },
      {
        id: 3150313193608,
        country: 300078170248,
        code: 'KB',
        name: 'Kalimantan Barat'
      },
      {
        id: 3150313226376,
        country: 300078170248,
        code: 'KS',
        name: 'Kalimantan Selatan'
      },
      {
        id: 3150313259144,
        country: 300078170248,
        code: 'KT',
        name: 'Kalimantan Tengah'
      },
      {
        id: 3150313291912,
        country: 300078170248,
        code: 'KI',
        name: 'Kalimantan Timur'
      },
      {
        id: 3150313324680,
        country: 300078170248,
        code: 'KU',
        name: 'Kalimantan Utara'
      },
      {
        id: 3150313357448,
        country: 300078170248,
        code: 'KR',
        name: 'Kepulauan Riau'
      },
      {
        id: 3150313390216,
        country: 300078170248,
        code: 'LA',
        name: 'Lampung'
      },
      {
        id: 3150313422984,
        country: 300078170248,
        code: 'MA',
        name: 'Maluku'
      },
      {
        id: 3150313455752,
        country: 300078170248,
        code: 'MU',
        name: 'Maluku Utara'
      },
      {
        id: 3150313488520,
        country: 300078170248,
        code: 'SU',
        name: 'North Sumatra'
      },
      {
        id: 3150313521288,
        country: 300078170248,
        code: 'NB',
        name: 'Nusa Tenggara Barat'
      },
      {
        id: 3150313554056,
        country: 300078170248,
        code: 'NT',
        name: 'Nusa Tenggara Timur'
      },
      {
        id: 3150313586824,
        country: 300078170248,
        code: 'PA',
        name: 'Papua'
      },
      {
        id: 3150313619592,
        country: 300078170248,
        code: 'PB',
        name: 'Papua Barat'
      },
      {
        id: 3150313652360,
        country: 300078170248,
        code: 'RI',
        name: 'Riau'
      },
      {
        id: 3150313685128,
        country: 300078170248,
        code: 'SS',
        name: 'South Sumatra'
      },
      {
        id: 3150313717896,
        country: 300078170248,
        code: 'SR',
        name: 'Sulawesi Barat'
      },
      {
        id: 3150313750664,
        country: 300078170248,
        code: 'SN',
        name: 'Sulawesi Selatan'
      },
      {
        id: 3150313783432,
        country: 300078170248,
        code: 'ST',
        name: 'Sulawesi Tengah'
      },
      {
        id: 3150313816200,
        country: 300078170248,
        code: 'SG',
        name: 'Sulawesi Tenggara'
      },
      {
        id: 3150313848968,
        country: 300078170248,
        code: 'SA',
        name: 'Sulawesi Utara'
      },
      {
        id: 3150313881736,
        country: 300078170248,
        code: 'SB',
        name: 'West Sumatra'
      },
      {
        id: 3150313914504,
        country: 300078170248,
        code: 'YO',
        name: 'Yogyakarta'
      }
    ]
  },
  {
    id: 300078203016,
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
    provinces: []
  },
  {
    id: 300078235784,
    name: 'Iraq',
    code: 'IQ',
    provinces: []
  },
  {
    id: 300080267400,
    name: 'Ireland',
    code: 'IE',
    provinces: [
      {
        id: 3150324727944,
        country: 300080267400,
        code: 'CW',
        name: 'Carlow'
      },
      {
        id: 3150324760712,
        country: 300080267400,
        code: 'CN',
        name: 'Cavan'
      },
      {
        id: 3150324793480,
        country: 300080267400,
        code: 'CE',
        name: 'Clare'
      },
      {
        id: 3150324826248,
        country: 300080267400,
        code: 'CO',
        name: 'Cork'
      },
      {
        id: 3150324859016,
        country: 300080267400,
        code: 'DL',
        name: 'Donegal'
      },
      {
        id: 3150324891784,
        country: 300080267400,
        code: 'D',
        name: 'Dublin'
      },
      {
        id: 3150324924552,
        country: 300080267400,
        code: 'G',
        name: 'Galway'
      },
      {
        id: 3150324957320,
        country: 300080267400,
        code: 'KY',
        name: 'Kerry'
      },
      {
        id: 3150324990088,
        country: 300080267400,
        code: 'KE',
        name: 'Kildare'
      },
      {
        id: 3150325022856,
        country: 300080267400,
        code: 'KK',
        name: 'Kilkenny'
      },
      {
        id: 3150325055624,
        country: 300080267400,
        code: 'LS',
        name: 'Laois'
      },
      {
        id: 3150325088392,
        country: 300080267400,
        code: 'LM',
        name: 'Leitrim'
      },
      {
        id: 3150325121160,
        country: 300080267400,
        code: 'LK',
        name: 'Limerick'
      },
      {
        id: 3150325153928,
        country: 300080267400,
        code: 'LD',
        name: 'Longford'
      },
      {
        id: 3150325186696,
        country: 300080267400,
        code: 'LH',
        name: 'Louth'
      },
      {
        id: 3150325219464,
        country: 300080267400,
        code: 'MO',
        name: 'Mayo'
      },
      {
        id: 3150325252232,
        country: 300080267400,
        code: 'MH',
        name: 'Meath'
      },
      {
        id: 3150325285000,
        country: 300080267400,
        code: 'MN',
        name: 'Monaghan'
      },
      {
        id: 3150325317768,
        country: 300080267400,
        code: 'OY',
        name: 'Offaly'
      },
      {
        id: 3150325350536,
        country: 300080267400,
        code: 'RN',
        name: 'Roscommon'
      },
      {
        id: 3150325383304,
        country: 300080267400,
        code: 'SO',
        name: 'Sligo'
      },
      {
        id: 3150325416072,
        country: 300080267400,
        code: 'TA',
        name: 'Tipperary'
      },
      {
        id: 3150325448840,
        country: 300080267400,
        code: 'WD',
        name: 'Waterford'
      },
      {
        id: 3150325481608,
        country: 300080267400,
        code: 'WH',
        name: 'Westmeath'
      },
      {
        id: 3150325514376,
        country: 300080267400,
        code: 'WX',
        name: 'Wexford'
      },
      {
        id: 3150325547144,
        country: 300080267400,
        code: 'WW',
        name: 'Wicklow'
      }
    ]
  },
  {
    id: 300080300168,
    name: 'Isle Of Man',
    code: 'IM',
    provinces: []
  },
  {
    id: 300078268552,
    name: 'Israel',
    code: 'IL',
    provinces: []
  },
  {
    id: 300080332936,
    name: 'Italy',
    code: 'IT',
    provinces: [
      {
        id: 3150325579912,
        country: 300080332936,
        code: 'AG',
        name: 'Agrigento'
      },
      {
        id: 3150325612680,
        country: 300080332936,
        code: 'AL',
        name: 'Alessandria'
      },
      {
        id: 3150325645448,
        country: 300080332936,
        code: 'AN',
        name: 'Ancona'
      },
      {
        id: 3150325678216,
        country: 300080332936,
        code: 'AO',
        name: 'Aosta'
      },
      {
        id: 3150325710984,
        country: 300080332936,
        code: 'AR',
        name: 'Arezzo'
      },
      {
        id: 3150325743752,
        country: 300080332936,
        code: 'AP',
        name: 'Ascoli Piceno'
      },
      {
        id: 3150325776520,
        country: 300080332936,
        code: 'AT',
        name: 'Asti'
      },
      {
        id: 3150325809288,
        country: 300080332936,
        code: 'AV',
        name: 'Avellino'
      },
      {
        id: 3150325842056,
        country: 300080332936,
        code: 'BA',
        name: 'Bari'
      },
      {
        id: 3150325874824,
        country: 300080332936,
        code: 'BT',
        name: 'Barletta-Andria-Trani'
      },
      {
        id: 3150325907592,
        country: 300080332936,
        code: 'BL',
        name: 'Belluno'
      },
      {
        id: 3150325940360,
        country: 300080332936,
        code: 'BN',
        name: 'Benevento'
      },
      {
        id: 3150325973128,
        country: 300080332936,
        code: 'BG',
        name: 'Bergamo'
      },
      {
        id: 3150326005896,
        country: 300080332936,
        code: 'BI',
        name: 'Biella'
      },
      {
        id: 3150326038664,
        country: 300080332936,
        code: 'BO',
        name: 'Bologna'
      },
      {
        id: 3150326071432,
        country: 300080332936,
        code: 'BZ',
        name: 'Bolzano'
      },
      {
        id: 3150326104200,
        country: 300080332936,
        code: 'BS',
        name: 'Brescia'
      },
      {
        id: 3150326136968,
        country: 300080332936,
        code: 'BR',
        name: 'Brindisi'
      },
      {
        id: 3150326169736,
        country: 300080332936,
        code: 'CA',
        name: 'Cagliari'
      },
      {
        id: 3150326202504,
        country: 300080332936,
        code: 'CL',
        name: 'Caltanissetta'
      },
      {
        id: 3150326235272,
        country: 300080332936,
        code: 'CB',
        name: 'Campobasso'
      },
      {
        id: 3150326268040,
        country: 300080332936,
        code: 'CI',
        name: 'Carbonia-Iglesias'
      },
      {
        id: 3150326300808,
        country: 300080332936,
        code: 'CE',
        name: 'Caserta'
      },
      {
        id: 3150326333576,
        country: 300080332936,
        code: 'CT',
        name: 'Catania'
      },
      {
        id: 3150326366344,
        country: 300080332936,
        code: 'CZ',
        name: 'Catanzaro'
      },
      {
        id: 3150326399112,
        country: 300080332936,
        code: 'CH',
        name: 'Chieti'
      },
      {
        id: 3150326431880,
        country: 300080332936,
        code: 'CO',
        name: 'Como'
      },
      {
        id: 3150326464648,
        country: 300080332936,
        code: 'CS',
        name: 'Cosenza'
      },
      {
        id: 3150326497416,
        country: 300080332936,
        code: 'CR',
        name: 'Cremona'
      },
      {
        id: 3150326530184,
        country: 300080332936,
        code: 'KR',
        name: 'Crotone'
      },
      {
        id: 3150326562952,
        country: 300080332936,
        code: 'CN',
        name: 'Cuneo'
      },
      {
        id: 3150326595720,
        country: 300080332936,
        code: 'EN',
        name: 'Enna'
      },
      {
        id: 3150326628488,
        country: 300080332936,
        code: 'FM',
        name: 'Fermo'
      },
      {
        id: 3150326661256,
        country: 300080332936,
        code: 'FE',
        name: 'Ferrara'
      },
      {
        id: 3150326694024,
        country: 300080332936,
        code: 'FI',
        name: 'Firenze'
      },
      {
        id: 3150326726792,
        country: 300080332936,
        code: 'FG',
        name: 'Foggia'
      },
      {
        id: 3150326759560,
        country: 300080332936,
        code: 'FC',
        name: 'Forlì-Cesena'
      },
      {
        id: 3150326792328,
        country: 300080332936,
        code: 'FR',
        name: 'Frosinone'
      },
      {
        id: 3150326825096,
        country: 300080332936,
        code: 'GE',
        name: 'Genova'
      },
      {
        id: 3150326857864,
        country: 300080332936,
        code: 'GO',
        name: 'Gorizia'
      },
      {
        id: 3150326890632,
        country: 300080332936,
        code: 'GR',
        name: 'Grosseto'
      },
      {
        id: 3150326923400,
        country: 300080332936,
        code: 'IM',
        name: 'Imperia'
      },
      {
        id: 3150326956168,
        country: 300080332936,
        code: 'IS',
        name: 'Isernia'
      },
      {
        id: 3150326988936,
        country: 300080332936,
        code: 'AQ',
        name: "L'Aquila"
      },
      {
        id: 3150327021704,
        country: 300080332936,
        code: 'SP',
        name: 'La Spezia'
      },
      {
        id: 3150327054472,
        country: 300080332936,
        code: 'LT',
        name: 'Latina'
      },
      {
        id: 3150327087240,
        country: 300080332936,
        code: 'LE',
        name: 'Lecce'
      },
      {
        id: 3150327120008,
        country: 300080332936,
        code: 'LC',
        name: 'Lecco'
      },
      {
        id: 3150327152776,
        country: 300080332936,
        code: 'LI',
        name: 'Livorno'
      },
      {
        id: 3150327185544,
        country: 300080332936,
        code: 'LO',
        name: 'Lodi'
      },
      {
        id: 3150327218312,
        country: 300080332936,
        code: 'LU',
        name: 'Lucca'
      },
      {
        id: 3150327251080,
        country: 300080332936,
        code: 'MC',
        name: 'Macerata'
      },
      {
        id: 3150327283848,
        country: 300080332936,
        code: 'MN',
        name: 'Mantova'
      },
      {
        id: 3150327316616,
        country: 300080332936,
        code: 'MS',
        name: 'Massa-Carrara'
      },
      {
        id: 3150327349384,
        country: 300080332936,
        code: 'MT',
        name: 'Matera'
      },
      {
        id: 3150327382152,
        country: 300080332936,
        code: 'VS',
        name: 'Medio Campidano'
      },
      {
        id: 3150327414920,
        country: 300080332936,
        code: 'ME',
        name: 'Messina'
      },
      {
        id: 3150327447688,
        country: 300080332936,
        code: 'MI',
        name: 'Milano'
      },
      {
        id: 3150327480456,
        country: 300080332936,
        code: 'MO',
        name: 'Modena'
      },
      {
        id: 3150327513224,
        country: 300080332936,
        code: 'MB',
        name: 'Monza e Brianza'
      },
      {
        id: 3150327545992,
        country: 300080332936,
        code: 'NA',
        name: 'Napoli'
      },
      {
        id: 3150327578760,
        country: 300080332936,
        code: 'NO',
        name: 'Novara'
      },
      {
        id: 3150327611528,
        country: 300080332936,
        code: 'NU',
        name: 'Nuoro'
      },
      {
        id: 3150327644296,
        country: 300080332936,
        code: 'OG',
        name: 'Ogliastra'
      },
      {
        id: 3150327677064,
        country: 300080332936,
        code: 'OT',
        name: 'Olbia-Tempio'
      },
      {
        id: 3150327709832,
        country: 300080332936,
        code: 'OR',
        name: 'Oristano'
      },
      {
        id: 3150327742600,
        country: 300080332936,
        code: 'PD',
        name: 'Padova'
      },
      {
        id: 3150327775368,
        country: 300080332936,
        code: 'PA',
        name: 'Palermo'
      },
      {
        id: 3150327808136,
        country: 300080332936,
        code: 'PR',
        name: 'Parma'
      },
      {
        id: 3150327840904,
        country: 300080332936,
        code: 'PV',
        name: 'Pavia'
      },
      {
        id: 3150327873672,
        country: 300080332936,
        code: 'PG',
        name: 'Perugia'
      },
      {
        id: 3150327906440,
        country: 300080332936,
        code: 'PU',
        name: 'Pesaro e Urbino'
      },
      {
        id: 3150327939208,
        country: 300080332936,
        code: 'PE',
        name: 'Pescara'
      },
      {
        id: 3150327971976,
        country: 300080332936,
        code: 'PC',
        name: 'Piacenza'
      },
      {
        id: 3150328004744,
        country: 300080332936,
        code: 'PI',
        name: 'Pisa'
      },
      {
        id: 3150328037512,
        country: 300080332936,
        code: 'PT',
        name: 'Pistoia'
      },
      {
        id: 3150328070280,
        country: 300080332936,
        code: 'PN',
        name: 'Pordenone'
      },
      {
        id: 3150328103048,
        country: 300080332936,
        code: 'PZ',
        name: 'Potenza'
      },
      {
        id: 3150328135816,
        country: 300080332936,
        code: 'PO',
        name: 'Prato'
      },
      {
        id: 3150328168584,
        country: 300080332936,
        code: 'RG',
        name: 'Ragusa'
      },
      {
        id: 3150328201352,
        country: 300080332936,
        code: 'RA',
        name: 'Ravenna'
      },
      {
        id: 3150328234120,
        country: 300080332936,
        code: 'RC',
        name: 'Reggio Calabria'
      },
      {
        id: 3150328266888,
        country: 300080332936,
        code: 'RE',
        name: 'Reggio Emilia'
      },
      {
        id: 3150328299656,
        country: 300080332936,
        code: 'RI',
        name: 'Rieti'
      },
      {
        id: 3150328332424,
        country: 300080332936,
        code: 'RN',
        name: 'Rimini'
      },
      {
        id: 3150328365192,
        country: 300080332936,
        code: 'RM',
        name: 'Roma'
      },
      {
        id: 3150328397960,
        country: 300080332936,
        code: 'RO',
        name: 'Rovigo'
      },
      {
        id: 3150328430728,
        country: 300080332936,
        code: 'SA',
        name: 'Salerno'
      },
      {
        id: 3150328463496,
        country: 300080332936,
        code: 'SS',
        name: 'Sassari'
      },
      {
        id: 3150328496264,
        country: 300080332936,
        code: 'SV',
        name: 'Savona'
      },
      {
        id: 3150328529032,
        country: 300080332936,
        code: 'SI',
        name: 'Siena'
      },
      {
        id: 3150328561800,
        country: 300080332936,
        code: 'SR',
        name: 'Siracusa'
      },
      {
        id: 3150328594568,
        country: 300080332936,
        code: 'SO',
        name: 'Sondrio'
      },
      {
        id: 3150328627336,
        country: 300080332936,
        code: 'TA',
        name: 'Taranto'
      },
      {
        id: 3150328660104,
        country: 300080332936,
        code: 'TE',
        name: 'Teramo'
      },
      {
        id: 3150328692872,
        country: 300080332936,
        code: 'TR',
        name: 'Terni'
      },
      {
        id: 3150328725640,
        country: 300080332936,
        code: 'TO',
        name: 'Torino'
      },
      {
        id: 3150328758408,
        country: 300080332936,
        code: 'TP',
        name: 'Trapani'
      },
      {
        id: 3150328791176,
        country: 300080332936,
        code: 'TN',
        name: 'Trento'
      },
      {
        id: 3150328823944,
        country: 300080332936,
        code: 'TV',
        name: 'Treviso'
      },
      {
        id: 3150328856712,
        country: 300080332936,
        code: 'TS',
        name: 'Trieste'
      },
      {
        id: 3150328889480,
        country: 300080332936,
        code: 'UD',
        name: 'Udine'
      },
      {
        id: 3150328922248,
        country: 300080332936,
        code: 'VA',
        name: 'Varese'
      },
      {
        id: 3150328955016,
        country: 300080332936,
        code: 'VE',
        name: 'Venezia'
      },
      {
        id: 3150328987784,
        country: 300080332936,
        code: 'VB',
        name: 'Verbano-Cusio-Ossola'
      },
      {
        id: 3150329020552,
        country: 300080332936,
        code: 'VC',
        name: 'Vercelli'
      },
      {
        id: 3150329053320,
        country: 300080332936,
        code: 'VR',
        name: 'Verona'
      },
      {
        id: 3150329086088,
        country: 300080332936,
        code: 'VV',
        name: 'Vibo Valentia'
      },
      {
        id: 3150329118856,
        country: 300080332936,
        code: 'VI',
        name: 'Vicenza'
      },
      {
        id: 3150329151624,
        country: 300080332936,
        code: 'VT',
        name: 'Viterbo'
      }
    ]
  },
  {
    id: 300083773576,
    name: 'Jamaica',
    code: 'JM',
    provinces: []
  },
  {
    id: 300078301320,
    name: 'Japan',
    code: 'JP',
    provinces: [
      {
        id: 3150314668168,
        country: 300078301320,
        code: 'JP-23',
        name: 'Aichi'
      },
      {
        id: 3150314078344,
        country: 300078301320,
        code: 'JP-05',
        name: 'Akita'
      },
      {
        id: 3150313980040,
        country: 300078301320,
        code: 'JP-02',
        name: 'Aomori'
      },
      {
        id: 3150314307720,
        country: 300078301320,
        code: 'JP-12',
        name: 'Chiba'
      },
      {
        id: 3150315159688,
        country: 300078301320,
        code: 'JP-38',
        name: 'Ehime'
      },
      {
        id: 3150314504328,
        country: 300078301320,
        code: 'JP-18',
        name: 'Fukui'
      },
      {
        id: 3150315225224,
        country: 300078301320,
        code: 'JP-40',
        name: 'Fukuoka'
      },
      {
        id: 3150314143880,
        country: 300078301320,
        code: 'JP-07',
        name: 'Fukushima'
      },
      {
        id: 3150314602632,
        country: 300078301320,
        code: 'JP-21',
        name: 'Gifu'
      },
      {
        id: 3150314242184,
        country: 300078301320,
        code: 'JP-10',
        name: 'Gunma'
      },
      {
        id: 3150315028616,
        country: 300078301320,
        code: 'JP-34',
        name: 'Hiroshima'
      },
      {
        id: 3150313947272,
        country: 300078301320,
        code: 'JP-01',
        name: 'Hokkaidō'
      },
      {
        id: 3150314832008,
        country: 300078301320,
        code: 'JP-28',
        name: 'Hyōgo'
      },
      {
        id: 3150314176648,
        country: 300078301320,
        code: 'JP-08',
        name: 'Ibaraki'
      },
      {
        id: 3150314471560,
        country: 300078301320,
        code: 'JP-17',
        name: 'Ishikawa'
      },
      {
        id: 3150314012808,
        country: 300078301320,
        code: 'JP-03',
        name: 'Iwate'
      },
      {
        id: 3150315126920,
        country: 300078301320,
        code: 'JP-37',
        name: 'Kagawa'
      },
      {
        id: 3150315421832,
        country: 300078301320,
        code: 'JP-46',
        name: 'Kagoshima'
      },
      {
        id: 3150314373256,
        country: 300078301320,
        code: 'JP-14',
        name: 'Kanagawa'
      },
      {
        id: 3150315192456,
        country: 300078301320,
        code: 'JP-39',
        name: 'Kōchi'
      },
      {
        id: 3150315323528,
        country: 300078301320,
        code: 'JP-43',
        name: 'Kumamoto'
      },
      {
        id: 3150314766472,
        country: 300078301320,
        code: 'JP-26',
        name: 'Kyōto'
      },
      {
        id: 3150314700936,
        country: 300078301320,
        code: 'JP-24',
        name: 'Mie'
      },
      {
        id: 3150314045576,
        country: 300078301320,
        code: 'JP-04',
        name: 'Miyagi'
      },
      {
        id: 3150315389064,
        country: 300078301320,
        code: 'JP-45',
        name: 'Miyazaki'
      },
      {
        id: 3150314569864,
        country: 300078301320,
        code: 'JP-20',
        name: 'Nagano'
      },
      {
        id: 3150315290760,
        country: 300078301320,
        code: 'JP-42',
        name: 'Nagasaki'
      },
      {
        id: 3150314864776,
        country: 300078301320,
        code: 'JP-29',
        name: 'Nara'
      },
      {
        id: 3150314406024,
        country: 300078301320,
        code: 'JP-15',
        name: 'Niigata'
      },
      {
        id: 3150315356296,
        country: 300078301320,
        code: 'JP-44',
        name: 'Ōita'
      },
      {
        id: 3150314995848,
        country: 300078301320,
        code: 'JP-33',
        name: 'Okayama'
      },
      {
        id: 3150315454600,
        country: 300078301320,
        code: 'JP-47',
        name: 'Okinawa'
      },
      {
        id: 3150314799240,
        country: 300078301320,
        code: 'JP-27',
        name: 'Ōsaka'
      },
      {
        id: 3150315257992,
        country: 300078301320,
        code: 'JP-41',
        name: 'Saga'
      },
      {
        id: 3150314274952,
        country: 300078301320,
        code: 'JP-11',
        name: 'Saitama'
      },
      {
        id: 3150314733704,
        country: 300078301320,
        code: 'JP-25',
        name: 'Shiga'
      },
      {
        id: 3150314963080,
        country: 300078301320,
        code: 'JP-32',
        name: 'Shimane'
      },
      {
        id: 3150314635400,
        country: 300078301320,
        code: 'JP-22',
        name: 'Shizuoka'
      },
      {
        id: 3150314209416,
        country: 300078301320,
        code: 'JP-09',
        name: 'Tochigi'
      },
      {
        id: 3150315094152,
        country: 300078301320,
        code: 'JP-36',
        name: 'Tokushima'
      },
      {
        id: 3150314340488,
        country: 300078301320,
        code: 'JP-13',
        name: 'Tōkyō'
      },
      {
        id: 3150314930312,
        country: 300078301320,
        code: 'JP-31',
        name: 'Tottori'
      },
      {
        id: 3150314438792,
        country: 300078301320,
        code: 'JP-16',
        name: 'Toyama'
      },
      {
        id: 3150314897544,
        country: 300078301320,
        code: 'JP-30',
        name: 'Wakayama'
      },
      {
        id: 3150314111112,
        country: 300078301320,
        code: 'JP-06',
        name: 'Yamagata'
      },
      {
        id: 3150315061384,
        country: 300078301320,
        code: 'JP-35',
        name: 'Yamaguchi'
      },
      {
        id: 3150314537096,
        country: 300078301320,
        code: 'JP-19',
        name: 'Yamanashi'
      }
    ]
  },
  {
    id: 300080365704,
    name: 'Jersey',
    code: 'JE',
    provinces: []
  },
  {
    id: 300078334088,
    name: 'Jordan',
    code: 'JO',
    provinces: []
  },
  {
    id: 300078366856,
    name: 'Kazakhstan',
    code: 'KZ',
    provinces: []
  },
  {
    id: 300082167944,
    name: 'Kenya',
    code: 'KE',
    provinces: []
  },
  {
    id: 300084920456,
    name: 'Kiribati',
    code: 'KI',
    provinces: []
  },
  {
    id: 300078727304,
    name: "Korea, Democratic People's Republic Of",
    code: 'KP',
    provinces: []
  },
  {
    id: 300080398472,
    name: 'Kosovo',
    code: 'XK',
    provinces: []
  },
  {
    id: 300078399624,
    name: 'Kuwait',
    code: 'KW',
    provinces: []
  },
  {
    id: 300078432392,
    name: 'Kyrgyzstan',
    code: 'KG',
    provinces: []
  },
  {
    id: 300078465160,
    name: "Lao People's Democratic Republic",
    code: 'LA',
    provinces: []
  },
  {
    id: 300080431240,
    name: 'Latvia',
    code: 'LV',
    provinces: []
  },
  {
    id: 300078497928,
    name: 'Lebanon',
    code: 'LB',
    provinces: []
  },
  {
    id: 300082200712,
    name: 'Lesotho',
    code: 'LS',
    provinces: []
  },
  {
    id: 300082233480,
    name: 'Liberia',
    code: 'LR',
    provinces: []
  },
  {
    id: 300082266248,
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
    provinces: []
  },
  {
    id: 300080464008,
    name: 'Liechtenstein',
    code: 'LI',
    provinces: []
  },
  {
    id: 300080496776,
    name: 'Lithuania',
    code: 'LT',
    provinces: []
  },
  {
    id: 300080529544,
    name: 'Luxembourg',
    code: 'LU',
    provinces: []
  },
  {
    id: 300078530696,
    name: 'Macao',
    code: 'MO',
    provinces: []
  },
  {
    id: 300082299016,
    name: 'Madagascar',
    code: 'MG',
    provinces: []
  },
  {
    id: 300082331784,
    name: 'Malawi',
    code: 'MW',
    provinces: []
  },
  {
    id: 300078563464,
    name: 'Malaysia',
    code: 'MY',
    provinces: [
      {
        id: 3150315487368,
        country: 300078563464,
        code: 'JHR',
        name: 'Johor'
      },
      {
        id: 3150315520136,
        country: 300078563464,
        code: 'KDH',
        name: 'Kedah'
      },
      {
        id: 3150315552904,
        country: 300078563464,
        code: 'KTN',
        name: 'Kelantan'
      },
      {
        id: 3150315585672,
        country: 300078563464,
        code: 'KUL',
        name: 'Kuala Lumpur'
      },
      {
        id: 3150315618440,
        country: 300078563464,
        code: 'LBN',
        name: 'Labuan'
      },
      {
        id: 3150315651208,
        country: 300078563464,
        code: 'MLK',
        name: 'Melaka'
      },
      {
        id: 3150315683976,
        country: 300078563464,
        code: 'NSN',
        name: 'Negeri Sembilan'
      },
      {
        id: 3150315716744,
        country: 300078563464,
        code: 'PHG',
        name: 'Pahang'
      },
      {
        id: 3150315749512,
        country: 300078563464,
        code: 'PNG',
        name: 'Penang'
      },
      {
        id: 3150315782280,
        country: 300078563464,
        code: 'PRK',
        name: 'Perak'
      },
      {
        id: 3150315815048,
        country: 300078563464,
        code: 'PLS',
        name: 'Perlis'
      },
      {
        id: 3150315847816,
        country: 300078563464,
        code: 'PJY',
        name: 'Putrajaya'
      },
      {
        id: 3150315880584,
        country: 300078563464,
        code: 'SBH',
        name: 'Sabah'
      },
      {
        id: 3150315913352,
        country: 300078563464,
        code: 'SWK',
        name: 'Sarawak'
      },
      {
        id: 3150315946120,
        country: 300078563464,
        code: 'SGR',
        name: 'Selangor'
      },
      {
        id: 3150315978888,
        country: 300078563464,
        code: 'TRG',
        name: 'Terengganu'
      }
    ]
  },
  {
    id: 300078596232,
    name: 'Maldives',
    code: 'MV',
    provinces: []
  },
  {
    id: 300082364552,
    name: 'Mali',
    code: 'ML',
    provinces: []
  },
  {
    id: 300080562312,
    name: 'Malta',
    code: 'MT',
    provinces: []
  },
  {
    id: 300083806344,
    name: 'Martinique',
    code: 'MQ',
    provinces: []
  },
  {
    id: 300082397320,
    name: 'Mauritania',
    code: 'MR',
    provinces: []
  },
  {
    id: 300082430088,
    name: 'Mauritius',
    code: 'MU',
    provinces: []
  },
  {
    id: 300080595080,
    name: 'Mayotte',
    code: 'YT',
    provinces: []
  },
  {
    id: 300085477512,
    name: 'Mexico',
    code: 'MX',
    provinces: [
      {
        id: 3150341570696,
        country: 300085477512,
        code: 'AGS',
        name: 'Aguascalientes'
      },
      {
        id: 3150341603464,
        country: 300085477512,
        code: 'BC',
        name: 'Baja California'
      },
      {
        id: 3150341636232,
        country: 300085477512,
        code: 'BCS',
        name: 'Baja California Sur'
      },
      {
        id: 3150341669000,
        country: 300085477512,
        code: 'CAMP',
        name: 'Campeche'
      },
      {
        id: 3150341701768,
        country: 300085477512,
        code: 'CHIS',
        name: 'Chiapas'
      },
      {
        id: 3150341734536,
        country: 300085477512,
        code: 'CHIH',
        name: 'Chihuahua'
      },
      {
        id: 3150341767304,
        country: 300085477512,
        code: 'DF',
        name: 'Ciudad de México'
      },
      {
        id: 3150341800072,
        country: 300085477512,
        code: 'COAH',
        name: 'Coahuila'
      },
      {
        id: 3150341832840,
        country: 300085477512,
        code: 'COL',
        name: 'Colima'
      },
      {
        id: 3150341865608,
        country: 300085477512,
        code: 'DGO',
        name: 'Durango'
      },
      {
        id: 3150341898376,
        country: 300085477512,
        code: 'GTO',
        name: 'Guanajuato'
      },
      {
        id: 3150341931144,
        country: 300085477512,
        code: 'GRO',
        name: 'Guerrero'
      },
      {
        id: 3150341963912,
        country: 300085477512,
        code: 'HGO',
        name: 'Hidalgo'
      },
      {
        id: 3150341996680,
        country: 300085477512,
        code: 'JAL',
        name: 'Jalisco'
      },
      {
        id: 3150342029448,
        country: 300085477512,
        code: 'MEX',
        name: 'México'
      },
      {
        id: 3150342062216,
        country: 300085477512,
        code: 'MICH',
        name: 'Michoacán'
      },
      {
        id: 3150342094984,
        country: 300085477512,
        code: 'MOR',
        name: 'Morelos'
      },
      {
        id: 3150342127752,
        country: 300085477512,
        code: 'NAY',
        name: 'Nayarit'
      },
      {
        id: 3150342160520,
        country: 300085477512,
        code: 'NL',
        name: 'Nuevo León'
      },
      {
        id: 3150342193288,
        country: 300085477512,
        code: 'OAX',
        name: 'Oaxaca'
      },
      {
        id: 3150342226056,
        country: 300085477512,
        code: 'PUE',
        name: 'Puebla'
      },
      {
        id: 3150342258824,
        country: 300085477512,
        code: 'QRO',
        name: 'Querétaro'
      },
      {
        id: 3150342291592,
        country: 300085477512,
        code: 'Q ROO',
        name: 'Quintana Roo'
      },
      {
        id: 3150342324360,
        country: 300085477512,
        code: 'SLP',
        name: 'San Luis Potosí'
      },
      {
        id: 3150342357128,
        country: 300085477512,
        code: 'SIN',
        name: 'Sinaloa'
      },
      {
        id: 3150342389896,
        country: 300085477512,
        code: 'SON',
        name: 'Sonora'
      },
      {
        id: 3150342422664,
        country: 300085477512,
        code: 'TAB',
        name: 'Tabasco'
      },
      {
        id: 3150342455432,
        country: 300085477512,
        code: 'TAMPS',
        name: 'Tamaulipas'
      },
      {
        id: 3150342488200,
        country: 300085477512,
        code: 'TLAX',
        name: 'Tlaxcala'
      },
      {
        id: 3150342520968,
        country: 300085477512,
        code: 'VER',
        name: 'Veracruz'
      },
      {
        id: 3150342553736,
        country: 300085477512,
        code: 'YUC',
        name: 'Yucatán'
      },
      {
        id: 3150342586504,
        country: 300085477512,
        code: 'ZAC',
        name: 'Zacatecas'
      }
    ]
  },
  {
    id: 300080627848,
    name: 'Moldova, Republic of',
    code: 'MD',
    provinces: []
  },
  {
    id: 300080660616,
    name: 'Monaco',
    code: 'MC',
    provinces: []
  },
  {
    id: 300078629000,
    name: 'Mongolia',
    code: 'MN',
    provinces: []
  },
  {
    id: 300080693384,
    name: 'Montenegro',
    code: 'ME',
    provinces: []
  },
  {
    id: 300083839112,
    name: 'Montserrat',
    code: 'MS',
    provinces: []
  },
  {
    id: 300082462856,
    name: 'Morocco',
    code: 'MA',
    provinces: []
  },
  {
    id: 300082495624,
    name: 'Mozambique',
    code: 'MZ',
    provinces: []
  },
  {
    id: 300078661768,
    name: 'Myanmar',
    code: 'MM',
    provinces: []
  },
  {
    id: 300082528392,
    name: 'Namibia',
    code: 'NA',
    provinces: []
  },
  {
    id: 300084953224,
    name: 'Nauru',
    code: 'NR',
    provinces: []
  },
  {
    id: 300078694536,
    name: 'Nepal',
    code: 'NP',
    provinces: []
  },
  {
    id: 300080726152,
    name: 'Netherlands',
    code: 'NL',
    provinces: []
  },
  {
    id: 300084592776,
    name: 'Netherlands Antilles',
    code: 'AN',
    provinces: []
  },
  {
    id: 300084985992,
    name: 'New Caledonia',
    code: 'NC',
    provinces: []
  },
  {
    id: 300085018760,
    name: 'New Zealand',
    code: 'NZ',
    provinces: [
      {
        id: 3150340620424,
        country: 300085018760,
        code: 'AUK',
        name: 'Auckland'
      },
      {
        id: 3150340653192,
        country: 300085018760,
        code: 'BOP',
        name: 'Bay of Plenty'
      },
      {
        id: 3150340685960,
        country: 300085018760,
        code: 'CAN',
        name: 'Canterbury'
      },
      {
        id: 3150340718728,
        country: 300085018760,
        code: 'GIS',
        name: 'Gisborne'
      },
      {
        id: 3150340751496,
        country: 300085018760,
        code: 'HKB',
        name: "Hawke's Bay"
      },
      {
        id: 3150340784264,
        country: 300085018760,
        code: 'MWT',
        name: 'Manawatu-Wanganui'
      },
      {
        id: 3150340817032,
        country: 300085018760,
        code: 'MBH',
        name: 'Marlborough'
      },
      {
        id: 3150340849800,
        country: 300085018760,
        code: 'NSN',
        name: 'Nelson'
      },
      {
        id: 3150340882568,
        country: 300085018760,
        code: 'NTL',
        name: 'Northland'
      },
      {
        id: 3150340915336,
        country: 300085018760,
        code: 'OTA',
        name: 'Otago'
      },
      {
        id: 3150340948104,
        country: 300085018760,
        code: 'STL',
        name: 'Southland'
      },
      {
        id: 3150340980872,
        country: 300085018760,
        code: 'TKI',
        name: 'Taranaki'
      },
      {
        id: 3150341013640,
        country: 300085018760,
        code: 'TAS',
        name: 'Tasman'
      },
      {
        id: 3150341046408,
        country: 300085018760,
        code: 'WKO',
        name: 'Waikato'
      },
      {
        id: 3150341079176,
        country: 300085018760,
        code: 'WGN',
        name: 'Wellington'
      },
      {
        id: 3150341111944,
        country: 300085018760,
        code: 'WTC',
        name: 'West Coast'
      }
    ]
  },
  {
    id: 300083871880,
    name: 'Nicaragua',
    code: 'NI',
    provinces: []
  },
  {
    id: 300082561160,
    name: 'Niger',
    code: 'NE',
    provinces: []
  },
  {
    id: 300082593928,
    name: 'Nigeria',
    code: 'NG',
    provinces: [
      {
        id: 3150333870216,
        country: 300082593928,
        code: 'AB',
        name: 'Abia'
      },
      {
        id: 3150333902984,
        country: 300082593928,
        code: 'FC',
        name: 'Abuja Federal Capital Territory'
      },
      {
        id: 3150333935752,
        country: 300082593928,
        code: 'AD',
        name: 'Adamawa'
      },
      {
        id: 3150333968520,
        country: 300082593928,
        code: 'AK',
        name: 'Akwa Ibom'
      },
      {
        id: 3150334001288,
        country: 300082593928,
        code: 'AN',
        name: 'Anambra'
      },
      {
        id: 3150334034056,
        country: 300082593928,
        code: 'BA',
        name: 'Bauchi'
      },
      {
        id: 3150334066824,
        country: 300082593928,
        code: 'BY',
        name: 'Bayelsa'
      },
      {
        id: 3150334099592,
        country: 300082593928,
        code: 'BE',
        name: 'Benue'
      },
      {
        id: 3150334132360,
        country: 300082593928,
        code: 'BO',
        name: 'Borno'
      },
      {
        id: 3150334165128,
        country: 300082593928,
        code: 'CR',
        name: 'Cross River'
      },
      {
        id: 3150334197896,
        country: 300082593928,
        code: 'DE',
        name: 'Delta'
      },
      {
        id: 3150334230664,
        country: 300082593928,
        code: 'EB',
        name: 'Ebonyi'
      },
      {
        id: 3150334263432,
        country: 300082593928,
        code: 'ED',
        name: 'Edo'
      },
      {
        id: 3150334296200,
        country: 300082593928,
        code: 'EK',
        name: 'Ekiti'
      },
      {
        id: 3150334328968,
        country: 300082593928,
        code: 'EN',
        name: 'Enugu'
      },
      {
        id: 3150334361736,
        country: 300082593928,
        code: 'GO',
        name: 'Gombe'
      },
      {
        id: 3150334394504,
        country: 300082593928,
        code: 'IM',
        name: 'Imo'
      },
      {
        id: 3150334427272,
        country: 300082593928,
        code: 'JI',
        name: 'Jigawa'
      },
      {
        id: 3150334460040,
        country: 300082593928,
        code: 'KD',
        name: 'Kaduna'
      },
      {
        id: 3150334492808,
        country: 300082593928,
        code: 'KN',
        name: 'Kano'
      },
      {
        id: 3150334525576,
        country: 300082593928,
        code: 'KT',
        name: 'Katsina'
      },
      {
        id: 3150334558344,
        country: 300082593928,
        code: 'KE',
        name: 'Kebbi'
      },
      {
        id: 3150334591112,
        country: 300082593928,
        code: 'KO',
        name: 'Kogi'
      },
      {
        id: 3150334623880,
        country: 300082593928,
        code: 'KW',
        name: 'Kwara'
      },
      {
        id: 3150334656648,
        country: 300082593928,
        code: 'LA',
        name: 'Lagos'
      },
      {
        id: 3150334689416,
        country: 300082593928,
        code: 'NA',
        name: 'Nasarawa'
      },
      {
        id: 3150334722184,
        country: 300082593928,
        code: 'NI',
        name: 'Niger'
      },
      {
        id: 3150334754952,
        country: 300082593928,
        code: 'OG',
        name: 'Ogun'
      },
      {
        id: 3150334787720,
        country: 300082593928,
        code: 'ON',
        name: 'Ondo'
      },
      {
        id: 3150334820488,
        country: 300082593928,
        code: 'OS',
        name: 'Osun'
      },
      {
        id: 3150334853256,
        country: 300082593928,
        code: 'OY',
        name: 'Oyo'
      },
      {
        id: 3150334886024,
        country: 300082593928,
        code: 'PL',
        name: 'Plateau'
      },
      {
        id: 3150334918792,
        country: 300082593928,
        code: 'RI',
        name: 'Rivers'
      },
      {
        id: 3150334951560,
        country: 300082593928,
        code: 'SO',
        name: 'Sokoto'
      },
      {
        id: 3150334984328,
        country: 300082593928,
        code: 'TA',
        name: 'Taraba'
      },
      {
        id: 3150335017096,
        country: 300082593928,
        code: 'YO',
        name: 'Yobe'
      },
      {
        id: 3150335049864,
        country: 300082593928,
        code: 'ZA',
        name: 'Zamfara'
      }
    ]
  },
  {
    id: 300085051528,
    name: 'Niue',
    code: 'NU',
    provinces: []
  },
  {
    id: 300085084296,
    name: 'Norfolk Island',
    code: 'NF',
    provinces: []
  },
  {
    id: 300080758920,
    name: 'North Macedonia',
    code: 'MK',
    provinces: []
  },
  {
    id: 300080791688,
    name: 'Norway',
    code: 'NO',
    provinces: []
  },
  {
    id: 300078760072,
    name: 'Oman',
    code: 'OM',
    provinces: []
  },
  {
    id: 300078792840,
    name: 'Pakistan',
    code: 'PK',
    provinces: []
  },
  {
    id: 300078825608,
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    provinces: []
  },
  {
    id: 300083904648,
    name: 'Panama',
    code: 'PA',
    provinces: [
      {
        id: 3150335803528,
        country: 300083904648,
        code: 'PA-1',
        name: 'Bocas del Toro'
      },
      {
        id: 3150335836296,
        country: 300083904648,
        code: 'PA-4',
        name: 'Chiriquí'
      },
      {
        id: 3150335869064,
        country: 300083904648,
        code: 'PA-2',
        name: 'Coclé'
      },
      {
        id: 3150335901832,
        country: 300083904648,
        code: 'PA-3',
        name: 'Colón'
      },
      {
        id: 3150335934600,
        country: 300083904648,
        code: 'PA-5',
        name: 'Darién'
      },
      {
        id: 3150335967368,
        country: 300083904648,
        code: 'PA-EM',
        name: 'Emberá'
      },
      {
        id: 3150336000136,
        country: 300083904648,
        code: 'PA-6',
        name: 'Herrera'
      },
      {
        id: 3150336032904,
        country: 300083904648,
        code: 'PA-KY',
        name: 'Kuna Yala'
      },
      {
        id: 3150336065672,
        country: 300083904648,
        code: 'PA-7',
        name: 'Los Santos'
      },
      {
        id: 3150336098440,
        country: 300083904648,
        code: 'PA-NB',
        name: 'Ngöbe-Buglé'
      },
      {
        id: 3150336131208,
        country: 300083904648,
        code: 'PA-8',
        name: 'Panamá'
      },
      {
        id: 3150336163976,
        country: 300083904648,
        code: 'PA-10',
        name: 'Panamá Oeste'
      },
      {
        id: 3150336196744,
        country: 300083904648,
        code: 'PA-9',
        name: 'Veraguas'
      }
    ]
  },
  {
    id: 300085117064,
    name: 'Papua New Guinea',
    code: 'PG',
    provinces: []
  },
  {
    id: 300084625544,
    name: 'Paraguay',
    code: 'PY',
    provinces: []
  },
  {
    id: 300084658312,
    name: 'Peru',
    code: 'PE',
    provinces: [
      {
        id: 3150339506312,
        country: 300084658312,
        code: 'PE-AMA',
        name: 'Amazonas'
      },
      {
        id: 3150339539080,
        country: 300084658312,
        code: 'PE-ANC',
        name: 'Áncash'
      },
      {
        id: 3150339571848,
        country: 300084658312,
        code: 'PE-APU',
        name: 'Apurímac'
      },
      {
        id: 3150339604616,
        country: 300084658312,
        code: 'PE-ARE',
        name: 'Arequipa'
      },
      {
        id: 3150339637384,
        country: 300084658312,
        code: 'PE-AYA',
        name: 'Ayacucho'
      },
      {
        id: 3150339670152,
        country: 300084658312,
        code: 'PE-CAJ',
        name: 'Cajamarca'
      },
      {
        id: 3150339702920,
        country: 300084658312,
        code: 'PE-CAL',
        name: 'Callao'
      },
      {
        id: 3150339735688,
        country: 300084658312,
        code: 'PE-CUS',
        name: 'Cuzco'
      },
      {
        id: 3150339768456,
        country: 300084658312,
        code: 'PE-HUV',
        name: 'Huancavelica'
      },
      {
        id: 3150339801224,
        country: 300084658312,
        code: 'PE-HUC',
        name: 'Huánuco'
      },
      {
        id: 3150339833992,
        country: 300084658312,
        code: 'PE-ICA',
        name: 'Ica'
      },
      {
        id: 3150339866760,
        country: 300084658312,
        code: 'PE-JUN',
        name: 'Junín'
      },
      {
        id: 3150339899528,
        country: 300084658312,
        code: 'PE-LAL',
        name: 'La Libertad'
      },
      {
        id: 3150339932296,
        country: 300084658312,
        code: 'PE-LAM',
        name: 'Lambayeque'
      },
      {
        id: 3150339965064,
        country: 300084658312,
        code: 'PE-LIM',
        name: 'Lima (departamento)'
      },
      {
        id: 3150339997832,
        country: 300084658312,
        code: 'PE-LMA',
        name: 'Lima (provincia)'
      },
      {
        id: 3150340030600,
        country: 300084658312,
        code: 'PE-LOR',
        name: 'Loreto'
      },
      {
        id: 3150340063368,
        country: 300084658312,
        code: 'PE-MDD',
        name: 'Madre de Dios'
      },
      {
        id: 3150340096136,
        country: 300084658312,
        code: 'PE-MOQ',
        name: 'Moquegua'
      },
      {
        id: 3150340128904,
        country: 300084658312,
        code: 'PE-PAS',
        name: 'Pasco'
      },
      {
        id: 3150340161672,
        country: 300084658312,
        code: 'PE-PIU',
        name: 'Piura'
      },
      {
        id: 3150340194440,
        country: 300084658312,
        code: 'PE-PUN',
        name: 'Puno'
      },
      {
        id: 3150340227208,
        country: 300084658312,
        code: 'PE-SAM',
        name: 'San Martín'
      },
      {
        id: 3150340259976,
        country: 300084658312,
        code: 'PE-TAC',
        name: 'Tacna'
      },
      {
        id: 3150340292744,
        country: 300084658312,
        code: 'PE-TUM',
        name: 'Tumbes'
      },
      {
        id: 3150340325512,
        country: 300084658312,
        code: 'PE-UCA',
        name: 'Ucayali'
      }
    ]
  },
  {
    id: 300078858376,
    name: 'Philippines',
    code: 'PH',
    provinces: [
      {
        id: 3150316011656,
        country: 300078858376,
        code: 'PH-ABR',
        name: 'Abra'
      },
      {
        id: 3150316044424,
        country: 300078858376,
        code: 'PH-AGN',
        name: 'Agusan del Norte'
      },
      {
        id: 3150316077192,
        country: 300078858376,
        code: 'PH-AGS',
        name: 'Agusan del Sur'
      },
      {
        id: 3150316109960,
        country: 300078858376,
        code: 'PH-AKL',
        name: 'Aklan'
      },
      {
        id: 3150316142728,
        country: 300078858376,
        code: 'PH-ALB',
        name: 'Albay'
      },
      {
        id: 3150316175496,
        country: 300078858376,
        code: 'PH-ANT',
        name: 'Antique'
      },
      {
        id: 3150316208264,
        country: 300078858376,
        code: 'PH-APA',
        name: 'Apayao'
      },
      {
        id: 3150316241032,
        country: 300078858376,
        code: 'PH-AUR',
        name: 'Aurora'
      },
      {
        id: 3150316273800,
        country: 300078858376,
        code: 'PH-BAS',
        name: 'Basilan'
      },
      {
        id: 3150316306568,
        country: 300078858376,
        code: 'PH-BAN',
        name: 'Bataan'
      },
      {
        id: 3150316339336,
        country: 300078858376,
        code: 'PH-BTN',
        name: 'Batanes'
      },
      {
        id: 3150316372104,
        country: 300078858376,
        code: 'PH-BTG',
        name: 'Batangas'
      },
      {
        id: 3150316404872,
        country: 300078858376,
        code: 'PH-BEN',
        name: 'Benguet'
      },
      {
        id: 3150316437640,
        country: 300078858376,
        code: 'PH-BIL',
        name: 'Biliran'
      },
      {
        id: 3150316470408,
        country: 300078858376,
        code: 'PH-BOH',
        name: 'Bohol'
      },
      {
        id: 3150316503176,
        country: 300078858376,
        code: 'PH-BUK',
        name: 'Bukidnon'
      },
      {
        id: 3150316535944,
        country: 300078858376,
        code: 'PH-BUL',
        name: 'Bulacan'
      },
      {
        id: 3150316568712,
        country: 300078858376,
        code: 'PH-CAG',
        name: 'Cagayan'
      },
      {
        id: 3150316601480,
        country: 300078858376,
        code: 'PH-CAN',
        name: 'Camarines Norte'
      },
      {
        id: 3150316634248,
        country: 300078858376,
        code: 'PH-CAS',
        name: 'Camarines Sur'
      },
      {
        id: 3150316667016,
        country: 300078858376,
        code: 'PH-CAM',
        name: 'Camiguin'
      },
      {
        id: 3150316699784,
        country: 300078858376,
        code: 'PH-CAP',
        name: 'Capiz'
      },
      {
        id: 3150316732552,
        country: 300078858376,
        code: 'PH-CAT',
        name: 'Catanduanes'
      },
      {
        id: 3150316765320,
        country: 300078858376,
        code: 'PH-CAV',
        name: 'Cavite'
      },
      {
        id: 3150316798088,
        country: 300078858376,
        code: 'PH-CEB',
        name: 'Cebu'
      },
      {
        id: 3150316830856,
        country: 300078858376,
        code: 'PH-NCO',
        name: 'Cotabato'
      },
      {
        id: 3150316929160,
        country: 300078858376,
        code: 'PH-COM',
        name: 'Davao de Oro'
      },
      {
        id: 3150316961928,
        country: 300078858376,
        code: 'PH-DAV',
        name: 'Davao del Norte'
      },
      {
        id: 3150316994696,
        country: 300078858376,
        code: 'PH-DAS',
        name: 'Davao del Sur'
      },
      {
        id: 3150316863624,
        country: 300078858376,
        code: 'PH-DVO',
        name: 'Davao Occidental'
      },
      {
        id: 3150316896392,
        country: 300078858376,
        code: 'PH-DAO',
        name: 'Davao Oriental'
      },
      {
        id: 3150317027464,
        country: 300078858376,
        code: 'PH-DIN',
        name: 'Dinagat Islands'
      },
      {
        id: 3150317060232,
        country: 300078858376,
        code: 'PH-EAS',
        name: 'Eastern Samar'
      },
      {
        id: 3150317093000,
        country: 300078858376,
        code: 'PH-GUI',
        name: 'Guimaras'
      },
      {
        id: 3150317125768,
        country: 300078858376,
        code: 'PH-IFU',
        name: 'Ifugao'
      },
      {
        id: 3150317158536,
        country: 300078858376,
        code: 'PH-ILN',
        name: 'Ilocos Norte'
      },
      {
        id: 3150317191304,
        country: 300078858376,
        code: 'PH-ILS',
        name: 'Ilocos Sur'
      },
      {
        id: 3150317224072,
        country: 300078858376,
        code: 'PH-ILI',
        name: 'Iloilo'
      },
      {
        id: 3150317256840,
        country: 300078858376,
        code: 'PH-ISA',
        name: 'Isabela'
      },
      {
        id: 3150317289608,
        country: 300078858376,
        code: 'PH-KAL',
        name: 'Kalinga'
      },
      {
        id: 3150317322376,
        country: 300078858376,
        code: 'PH-LUN',
        name: 'La Union'
      },
      {
        id: 3150317355144,
        country: 300078858376,
        code: 'PH-LAG',
        name: 'Laguna'
      },
      {
        id: 3150317387912,
        country: 300078858376,
        code: 'PH-LAN',
        name: 'Lanao del Norte'
      },
      {
        id: 3150317420680,
        country: 300078858376,
        code: 'PH-LAS',
        name: 'Lanao del Sur'
      },
      {
        id: 3150317453448,
        country: 300078858376,
        code: 'PH-LEY',
        name: 'Leyte'
      },
      {
        id: 3150317486216,
        country: 300078858376,
        code: 'PH-MAG',
        name: 'Maguindanao'
      },
      {
        id: 3150317518984,
        country: 300078858376,
        code: 'PH-MAD',
        name: 'Marinduque'
      },
      {
        id: 3150317551752,
        country: 300078858376,
        code: 'PH-MAS',
        name: 'Masbate'
      },
      {
        id: 3150317584520,
        country: 300078858376,
        code: 'PH-00',
        name: 'Metro Manila'
      },
      {
        id: 3150317617288,
        country: 300078858376,
        code: 'PH-MSC',
        name: 'Misamis Occidental'
      },
      {
        id: 3150317650056,
        country: 300078858376,
        code: 'PH-MSR',
        name: 'Misamis Oriental'
      },
      {
        id: 3150317682824,
        country: 300078858376,
        code: 'PH-MOU',
        name: 'Mountain Province'
      },
      {
        id: 3150317715592,
        country: 300078858376,
        code: 'PH-NEC',
        name: 'Negros Occidental'
      },
      {
        id: 3150317748360,
        country: 300078858376,
        code: 'PH-NER',
        name: 'Negros Oriental'
      },
      {
        id: 3150317781128,
        country: 300078858376,
        code: 'PH-NSA',
        name: 'Northern Samar'
      },
      {
        id: 3150317813896,
        country: 300078858376,
        code: 'PH-NUE',
        name: 'Nueva Ecija'
      },
      {
        id: 3150317846664,
        country: 300078858376,
        code: 'PH-NUV',
        name: 'Nueva Vizcaya'
      },
      {
        id: 3150317879432,
        country: 300078858376,
        code: 'PH-MDC',
        name: 'Occidental Mindoro'
      },
      {
        id: 3150317912200,
        country: 300078858376,
        code: 'PH-MDR',
        name: 'Oriental Mindoro'
      },
      {
        id: 3150317944968,
        country: 300078858376,
        code: 'PH-PLW',
        name: 'Palawan'
      },
      {
        id: 3150317977736,
        country: 300078858376,
        code: 'PH-PAM',
        name: 'Pampanga'
      },
      {
        id: 3150318010504,
        country: 300078858376,
        code: 'PH-PAN',
        name: 'Pangasinan'
      },
      {
        id: 3150318043272,
        country: 300078858376,
        code: 'PH-QUE',
        name: 'Quezon'
      },
      {
        id: 3150318076040,
        country: 300078858376,
        code: 'PH-QUI',
        name: 'Quirino'
      },
      {
        id: 3150318108808,
        country: 300078858376,
        code: 'PH-RIZ',
        name: 'Rizal'
      },
      {
        id: 3150318141576,
        country: 300078858376,
        code: 'PH-ROM',
        name: 'Romblon'
      },
      {
        id: 3150318174344,
        country: 300078858376,
        code: 'PH-WSA',
        name: 'Samar'
      },
      {
        id: 3150318207112,
        country: 300078858376,
        code: 'PH-SAR',
        name: 'Sarangani'
      },
      {
        id: 3150318239880,
        country: 300078858376,
        code: 'PH-SIG',
        name: 'Siquijor'
      },
      {
        id: 3150318272648,
        country: 300078858376,
        code: 'PH-SOR',
        name: 'Sorsogon'
      },
      {
        id: 3150318305416,
        country: 300078858376,
        code: 'PH-SCO',
        name: 'South Cotabato'
      },
      {
        id: 3150318338184,
        country: 300078858376,
        code: 'PH-SLE',
        name: 'Southern Leyte'
      },
      {
        id: 3150318370952,
        country: 300078858376,
        code: 'PH-SUK',
        name: 'Sultan Kudarat'
      },
      {
        id: 3150318403720,
        country: 300078858376,
        code: 'PH-SLU',
        name: 'Sulu'
      },
      {
        id: 3150318436488,
        country: 300078858376,
        code: 'PH-SUN',
        name: 'Surigao del Norte'
      },
      {
        id: 3150318469256,
        country: 300078858376,
        code: 'PH-SUR',
        name: 'Surigao del Sur'
      },
      {
        id: 3150318502024,
        country: 300078858376,
        code: 'PH-TAR',
        name: 'Tarlac'
      },
      {
        id: 3150318534792,
        country: 300078858376,
        code: 'PH-TAW',
        name: 'Tawi-Tawi'
      },
      {
        id: 3150318567560,
        country: 300078858376,
        code: 'PH-ZMB',
        name: 'Zambales'
      },
      {
        id: 3150318633096,
        country: 300078858376,
        code: 'PH-ZAN',
        name: 'Zamboanga del Norte'
      },
      {
        id: 3150318665864,
        country: 300078858376,
        code: 'PH-ZAS',
        name: 'Zamboanga del Sur'
      },
      {
        id: 3150318600328,
        country: 300078858376,
        code: 'PH-ZSI',
        name: 'Zamboanga Sibugay'
      }
    ]
  },
  {
    id: 300085149832,
    name: 'Pitcairn',
    code: 'PN',
    provinces: []
  },
  {
    id: 300080824456,
    name: 'Poland',
    code: 'PL',
    provinces: []
  },
  {
    id: 300080857224,
    name: 'Portugal',
    code: 'PT',
    provinces: [
      {
        id: 3150329184392,
        country: 300080857224,
        code: 'PT-20',
        name: 'Açores'
      },
      {
        id: 3150329217160,
        country: 300080857224,
        code: 'PT-01',
        name: 'Aveiro'
      },
      {
        id: 3150329249928,
        country: 300080857224,
        code: 'PT-02',
        name: 'Beja'
      },
      {
        id: 3150329282696,
        country: 300080857224,
        code: 'PT-03',
        name: 'Braga'
      },
      {
        id: 3150329315464,
        country: 300080857224,
        code: 'PT-04',
        name: 'Bragança'
      },
      {
        id: 3150329348232,
        country: 300080857224,
        code: 'PT-05',
        name: 'Castelo Branco'
      },
      {
        id: 3150329381000,
        country: 300080857224,
        code: 'PT-06',
        name: 'Coimbra'
      },
      {
        id: 3150329413768,
        country: 300080857224,
        code: 'PT-07',
        name: 'Évora'
      },
      {
        id: 3150329446536,
        country: 300080857224,
        code: 'PT-08',
        name: 'Faro'
      },
      {
        id: 3150329479304,
        country: 300080857224,
        code: 'PT-09',
        name: 'Guarda'
      },
      {
        id: 3150329512072,
        country: 300080857224,
        code: 'PT-10',
        name: 'Leiria'
      },
      {
        id: 3150329544840,
        country: 300080857224,
        code: 'PT-11',
        name: 'Lisboa'
      },
      {
        id: 3150329577608,
        country: 300080857224,
        code: 'PT-30',
        name: 'Madeira'
      },
      {
        id: 3150329610376,
        country: 300080857224,
        code: 'PT-12',
        name: 'Portalegre'
      },
      {
        id: 3150329643144,
        country: 300080857224,
        code: 'PT-13',
        name: 'Porto'
      },
      {
        id: 3150329675912,
        country: 300080857224,
        code: 'PT-14',
        name: 'Santarém'
      },
      {
        id: 3150329708680,
        country: 300080857224,
        code: 'PT-15',
        name: 'Setúbal'
      },
      {
        id: 3150329741448,
        country: 300080857224,
        code: 'PT-16',
        name: 'Viana do Castelo'
      },
      {
        id: 3150329774216,
        country: 300080857224,
        code: 'PT-17',
        name: 'Vila Real'
      },
      {
        id: 3150329806984,
        country: 300080857224,
        code: 'PT-18',
        name: 'Viseu'
      }
    ]
  },
  {
    id: 300078891144,
    name: 'Qatar',
    code: 'QA',
    provinces: []
  },
  {
    id: 300081545352,
    name: 'Republic of Cameroon',
    code: 'CM',
    provinces: []
  },
  {
    id: 247297867912,
    name: 'Rest of World',
    code: '*',
    provinces: []
  },
  {
    id: 300080889992,
    name: 'Reunion',
    code: 'RE',
    provinces: []
  },
  {
    id: 300080922760,
    name: 'Romania',
    code: 'RO',
    provinces: [
      {
        id: 3150329839752,
        country: 300080922760,
        code: 'AB',
        name: 'Alba'
      },
      {
        id: 3150329872520,
        country: 300080922760,
        code: 'AR',
        name: 'Arad'
      },
      {
        id: 3150329905288,
        country: 300080922760,
        code: 'AG',
        name: 'Argeș'
      },
      {
        id: 3150329938056,
        country: 300080922760,
        code: 'BC',
        name: 'Bacău'
      },
      {
        id: 3150329970824,
        country: 300080922760,
        code: 'BH',
        name: 'Bihor'
      },
      {
        id: 3150330003592,
        country: 300080922760,
        code: 'BN',
        name: 'Bistrița-Năsăud'
      },
      {
        id: 3150330036360,
        country: 300080922760,
        code: 'BT',
        name: 'Botoșani'
      },
      {
        id: 3150330069128,
        country: 300080922760,
        code: 'BR',
        name: 'Brăila'
      },
      {
        id: 3150330101896,
        country: 300080922760,
        code: 'BV',
        name: 'Brașov'
      },
      {
        id: 3150330134664,
        country: 300080922760,
        code: 'B',
        name: 'București'
      },
      {
        id: 3150330167432,
        country: 300080922760,
        code: 'BZ',
        name: 'Buzău'
      },
      {
        id: 3150330331272,
        country: 300080922760,
        code: 'CL',
        name: 'Călărași'
      },
      {
        id: 3150330200200,
        country: 300080922760,
        code: 'CS',
        name: 'Caraș-Severin'
      },
      {
        id: 3150330232968,
        country: 300080922760,
        code: 'CJ',
        name: 'Cluj'
      },
      {
        id: 3150330265736,
        country: 300080922760,
        code: 'CT',
        name: 'Constanța'
      },
      {
        id: 3150330298504,
        country: 300080922760,
        code: 'CV',
        name: 'Covasna'
      },
      {
        id: 3150330396808,
        country: 300080922760,
        code: 'DB',
        name: 'Dâmbovița'
      },
      {
        id: 3150330364040,
        country: 300080922760,
        code: 'DJ',
        name: 'Dolj'
      },
      {
        id: 3150330429576,
        country: 300080922760,
        code: 'GL',
        name: 'Galați'
      },
      {
        id: 3150330462344,
        country: 300080922760,
        code: 'GR',
        name: 'Giurgiu'
      },
      {
        id: 3150330495112,
        country: 300080922760,
        code: 'GJ',
        name: 'Gorj'
      },
      {
        id: 3150330527880,
        country: 300080922760,
        code: 'HR',
        name: 'Harghita'
      },
      {
        id: 3150330560648,
        country: 300080922760,
        code: 'HD',
        name: 'Hunedoara'
      },
      {
        id: 3150330593416,
        country: 300080922760,
        code: 'IL',
        name: 'Ialomița'
      },
      {
        id: 3150330626184,
        country: 300080922760,
        code: 'IS',
        name: 'Iași'
      },
      {
        id: 3150330658952,
        country: 300080922760,
        code: 'IF',
        name: 'Ilfov'
      },
      {
        id: 3150330691720,
        country: 300080922760,
        code: 'MM',
        name: 'Maramureș'
      },
      {
        id: 3150330724488,
        country: 300080922760,
        code: 'MH',
        name: 'Mehedinți'
      },
      {
        id: 3150330757256,
        country: 300080922760,
        code: 'MS',
        name: 'Mureș'
      },
      {
        id: 3150330790024,
        country: 300080922760,
        code: 'NT',
        name: 'Neamț'
      },
      {
        id: 3150330822792,
        country: 300080922760,
        code: 'OT',
        name: 'Olt'
      },
      {
        id: 3150330855560,
        country: 300080922760,
        code: 'PH',
        name: 'Prahova'
      },
      {
        id: 3150330888328,
        country: 300080922760,
        code: 'SJ',
        name: 'Sălaj'
      },
      {
        id: 3150330921096,
        country: 300080922760,
        code: 'SM',
        name: 'Satu Mare'
      },
      {
        id: 3150330953864,
        country: 300080922760,
        code: 'SB',
        name: 'Sibiu'
      },
      {
        id: 3150330986632,
        country: 300080922760,
        code: 'SV',
        name: 'Suceava'
      },
      {
        id: 3150331019400,
        country: 300080922760,
        code: 'TR',
        name: 'Teleorman'
      },
      {
        id: 3150331052168,
        country: 300080922760,
        code: 'TM',
        name: 'Timiș'
      },
      {
        id: 3150331084936,
        country: 300080922760,
        code: 'TL',
        name: 'Tulcea'
      },
      {
        id: 3150331117704,
        country: 300080922760,
        code: 'VL',
        name: 'Vâlcea'
      },
      {
        id: 3150331150472,
        country: 300080922760,
        code: 'VS',
        name: 'Vaslui'
      },
      {
        id: 3150331183240,
        country: 300080922760,
        code: 'VN',
        name: 'Vrancea'
      }
    ]
  },
  {
    id: 300078923912,
    name: 'Russia',
    code: 'RU',
    provinces: [
      {
        id: 3150318698632,
        country: 300078923912,
        code: 'ALT',
        name: 'Altai Krai'
      },
      {
        id: 3150318731400,
        country: 300078923912,
        code: 'AL',
        name: 'Altai Republic'
      },
      {
        id: 3150318764168,
        country: 300078923912,
        code: 'AMU',
        name: 'Amur Oblast'
      },
      {
        id: 3150318796936,
        country: 300078923912,
        code: 'ARK',
        name: 'Arkhangelsk Oblast'
      },
      {
        id: 3150318829704,
        country: 300078923912,
        code: 'AST',
        name: 'Astrakhan Oblast'
      },
      {
        id: 3150318862472,
        country: 300078923912,
        code: 'BEL',
        name: 'Belgorod Oblast'
      },
      {
        id: 3150318895240,
        country: 300078923912,
        code: 'BRY',
        name: 'Bryansk Oblast'
      },
      {
        id: 3150318928008,
        country: 300078923912,
        code: 'CE',
        name: 'Chechen Republic'
      },
      {
        id: 3150318960776,
        country: 300078923912,
        code: 'CHE',
        name: 'Chelyabinsk Oblast'
      },
      {
        id: 3150318993544,
        country: 300078923912,
        code: 'CHU',
        name: 'Chukotka Autonomous Okrug'
      },
      {
        id: 3150319026312,
        country: 300078923912,
        code: 'CU',
        name: 'Chuvash Republic'
      },
      {
        id: 3150319059080,
        country: 300078923912,
        code: 'IRK',
        name: 'Irkutsk Oblast'
      },
      {
        id: 3150319091848,
        country: 300078923912,
        code: 'IVA',
        name: 'Ivanovo Oblast'
      },
      {
        id: 3150319124616,
        country: 300078923912,
        code: 'YEV',
        name: 'Jewish Autonomous Oblast'
      },
      {
        id: 3150319157384,
        country: 300078923912,
        code: 'KB',
        name: 'Kabardino-Balkarian Republic'
      },
      {
        id: 3150319190152,
        country: 300078923912,
        code: 'KGD',
        name: 'Kaliningrad Oblast'
      },
      {
        id: 3150319222920,
        country: 300078923912,
        code: 'KLU',
        name: 'Kaluga Oblast'
      },
      {
        id: 3150319255688,
        country: 300078923912,
        code: 'KAM',
        name: 'Kamchatka Krai'
      },
      {
        id: 3150319288456,
        country: 300078923912,
        code: 'KC',
        name: 'Karachay–Cherkess Republic'
      },
      {
        id: 3150319321224,
        country: 300078923912,
        code: 'KEM',
        name: 'Kemerovo Oblast'
      },
      {
        id: 3150319353992,
        country: 300078923912,
        code: 'KHA',
        name: 'Khabarovsk Krai'
      },
      {
        id: 3150319386760,
        country: 300078923912,
        code: 'KHM',
        name: 'Khanty-Mansi Autonomous Okrug'
      },
      {
        id: 3150319419528,
        country: 300078923912,
        code: 'KIR',
        name: 'Kirov Oblast'
      },
      {
        id: 3150319452296,
        country: 300078923912,
        code: 'KO',
        name: 'Komi Republic'
      },
      {
        id: 3150319485064,
        country: 300078923912,
        code: 'KOS',
        name: 'Kostroma Oblast'
      },
      {
        id: 3150319517832,
        country: 300078923912,
        code: 'KDA',
        name: 'Krasnodar Krai'
      },
      {
        id: 3150319550600,
        country: 300078923912,
        code: 'KYA',
        name: 'Krasnoyarsk Krai'
      },
      {
        id: 3150319583368,
        country: 300078923912,
        code: 'KGN',
        name: 'Kurgan Oblast'
      },
      {
        id: 3150319616136,
        country: 300078923912,
        code: 'KRS',
        name: 'Kursk Oblast'
      },
      {
        id: 3150319648904,
        country: 300078923912,
        code: 'LEN',
        name: 'Leningrad Oblast'
      },
      {
        id: 3150319681672,
        country: 300078923912,
        code: 'LIP',
        name: 'Lipetsk Oblast'
      },
      {
        id: 3150319714440,
        country: 300078923912,
        code: 'MAG',
        name: 'Magadan Oblast'
      },
      {
        id: 3150319747208,
        country: 300078923912,
        code: 'ME',
        name: 'Mari El Republic'
      },
      {
        id: 3150319779976,
        country: 300078923912,
        code: 'MOW',
        name: 'Moscow'
      },
      {
        id: 3150319812744,
        country: 300078923912,
        code: 'MOS',
        name: 'Moscow Oblast'
      },
      {
        id: 3150319845512,
        country: 300078923912,
        code: 'MUR',
        name: 'Murmansk Oblast'
      },
      {
        id: 3150319878280,
        country: 300078923912,
        code: 'NIZ',
        name: 'Nizhny Novgorod Oblast'
      },
      {
        id: 3150319911048,
        country: 300078923912,
        code: 'NGR',
        name: 'Novgorod Oblast'
      },
      {
        id: 3150319943816,
        country: 300078923912,
        code: 'NVS',
        name: 'Novosibirsk Oblast'
      },
      {
        id: 3150319976584,
        country: 300078923912,
        code: 'OMS',
        name: 'Omsk Oblast'
      },
      {
        id: 3150320009352,
        country: 300078923912,
        code: 'ORE',
        name: 'Orenburg Oblast'
      },
      {
        id: 3150320042120,
        country: 300078923912,
        code: 'ORL',
        name: 'Oryol Oblast'
      },
      {
        id: 3150320074888,
        country: 300078923912,
        code: 'PNZ',
        name: 'Penza Oblast'
      },
      {
        id: 3150320107656,
        country: 300078923912,
        code: 'PER',
        name: 'Perm Krai'
      },
      {
        id: 3150320140424,
        country: 300078923912,
        code: 'PRI',
        name: 'Primorsky Krai'
      },
      {
        id: 3150320173192,
        country: 300078923912,
        code: 'PSK',
        name: 'Pskov Oblast'
      },
      {
        id: 3150320205960,
        country: 300078923912,
        code: 'AD',
        name: 'Republic of Adygeya'
      },
      {
        id: 3150320238728,
        country: 300078923912,
        code: 'BA',
        name: 'Republic of Bashkortostan'
      },
      {
        id: 3150320271496,
        country: 300078923912,
        code: 'BU',
        name: 'Republic of Buryatia'
      },
      {
        id: 3150320304264,
        country: 300078923912,
        code: 'DA',
        name: 'Republic of Dagestan'
      },
      {
        id: 3150320337032,
        country: 300078923912,
        code: 'IN',
        name: 'Republic of Ingushetia'
      },
      {
        id: 3150320369800,
        country: 300078923912,
        code: 'KL',
        name: 'Republic of Kalmykia'
      },
      {
        id: 3150320402568,
        country: 300078923912,
        code: 'KR',
        name: 'Republic of Karelia'
      },
      {
        id: 3150320435336,
        country: 300078923912,
        code: 'KK',
        name: 'Republic of Khakassia'
      },
      {
        id: 3150320468104,
        country: 300078923912,
        code: 'MO',
        name: 'Republic of Mordovia'
      },
      {
        id: 3150320500872,
        country: 300078923912,
        code: 'SE',
        name: 'Republic of North Ossetia–Alania'
      },
      {
        id: 3150320533640,
        country: 300078923912,
        code: 'TA',
        name: 'Republic of Tatarstan'
      },
      {
        id: 3150320566408,
        country: 300078923912,
        code: 'ROS',
        name: 'Rostov Oblast'
      },
      {
        id: 3150320599176,
        country: 300078923912,
        code: 'RYA',
        name: 'Ryazan Oblast'
      },
      {
        id: 3150320631944,
        country: 300078923912,
        code: 'SPE',
        name: 'Saint Petersburg'
      },
      {
        id: 3150320664712,
        country: 300078923912,
        code: 'SA',
        name: 'Sakha Republic (Yakutia)'
      },
      {
        id: 3150320697480,
        country: 300078923912,
        code: 'SAK',
        name: 'Sakhalin Oblast'
      },
      {
        id: 3150320730248,
        country: 300078923912,
        code: 'SAM',
        name: 'Samara Oblast'
      },
      {
        id: 3150320763016,
        country: 300078923912,
        code: 'SAR',
        name: 'Saratov Oblast'
      },
      {
        id: 3150320795784,
        country: 300078923912,
        code: 'SMO',
        name: 'Smolensk Oblast'
      },
      {
        id: 3150320828552,
        country: 300078923912,
        code: 'STA',
        name: 'Stavropol Krai'
      },
      {
        id: 3150320861320,
        country: 300078923912,
        code: 'SVE',
        name: 'Sverdlovsk Oblast'
      },
      {
        id: 3150320894088,
        country: 300078923912,
        code: 'TAM',
        name: 'Tambov Oblast'
      },
      {
        id: 3150320926856,
        country: 300078923912,
        code: 'TOM',
        name: 'Tomsk Oblast'
      },
      {
        id: 3150320959624,
        country: 300078923912,
        code: 'TUL',
        name: 'Tula Oblast'
      },
      {
        id: 3150320992392,
        country: 300078923912,
        code: 'TVE',
        name: 'Tver Oblast'
      },
      {
        id: 3150321025160,
        country: 300078923912,
        code: 'TYU',
        name: 'Tyumen Oblast'
      },
      {
        id: 3150321057928,
        country: 300078923912,
        code: 'TY',
        name: 'Tyva Republic'
      },
      {
        id: 3150321090696,
        country: 300078923912,
        code: 'UD',
        name: 'Udmurtia'
      },
      {
        id: 3150321123464,
        country: 300078923912,
        code: 'ULY',
        name: 'Ulyanovsk Oblast'
      },
      {
        id: 3150321156232,
        country: 300078923912,
        code: 'VLA',
        name: 'Vladimir Oblast'
      },
      {
        id: 3150321189000,
        country: 300078923912,
        code: 'VGG',
        name: 'Volgograd Oblast'
      },
      {
        id: 3150321221768,
        country: 300078923912,
        code: 'VLG',
        name: 'Vologda Oblast'
      },
      {
        id: 3150321254536,
        country: 300078923912,
        code: 'VOR',
        name: 'Voronezh Oblast'
      },
      {
        id: 3150321287304,
        country: 300078923912,
        code: 'YAN',
        name: 'Yamalo-Nenets Autonomous Okrug'
      },
      {
        id: 3150321320072,
        country: 300078923912,
        code: 'YAR',
        name: 'Yaroslavl Oblast'
      },
      {
        id: 3150321352840,
        country: 300078923912,
        code: 'ZAB',
        name: 'Zabaykalsky Krai'
      }
    ]
  },
  {
    id: 300082626696,
    name: 'Rwanda',
    code: 'RW',
    provinces: []
  },
  {
    id: 300083970184,
    name: 'Saint Barthélemy',
    code: 'BL',
    provinces: []
  },
  {
    id: 300082856072,
    name: 'Saint Helena',
    code: 'SH',
    provinces: []
  },
  {
    id: 300084002952,
    name: 'Saint Kitts And Nevis',
    code: 'KN',
    provinces: []
  },
  {
    id: 300084035720,
    name: 'Saint Lucia',
    code: 'LC',
    provinces: []
  },
  {
    id: 300084068488,
    name: 'Saint Martin',
    code: 'MF',
    provinces: []
  },
  {
    id: 300084101256,
    name: 'Saint Pierre And Miquelon',
    code: 'PM',
    provinces: []
  },
  {
    id: 300085182600,
    name: 'Samoa',
    code: 'WS',
    provinces: []
  },
  {
    id: 300080955528,
    name: 'San Marino',
    code: 'SM',
    provinces: []
  },
  {
    id: 300082659464,
    name: 'Sao Tome And Principe',
    code: 'ST',
    provinces: []
  },
  {
    id: 300078956680,
    name: 'Saudi Arabia',
    code: 'SA',
    provinces: []
  },
  {
    id: 300082692232,
    name: 'Senegal',
    code: 'SN',
    provinces: []
  },
  {
    id: 300080988296,
    name: 'Serbia',
    code: 'RS',
    provinces: []
  },
  {
    id: 300082725000,
    name: 'Seychelles',
    code: 'SC',
    provinces: []
  },
  {
    id: 300082757768,
    name: 'Sierra Leone',
    code: 'SL',
    provinces: []
  },
  {
    id: 300078989448,
    name: 'Singapore',
    code: 'SG',
    provinces: []
  },
  {
    id: 300083937416,
    name: 'Sint Maarten',
    code: 'SX',
    provinces: []
  },
  {
    id: 300081021064,
    name: 'Slovakia',
    code: 'SK',
    provinces: []
  },
  {
    id: 300081053832,
    name: 'Slovenia',
    code: 'SI',
    provinces: []
  },
  {
    id: 300085215368,
    name: 'Solomon Islands',
    code: 'SB',
    provinces: []
  },
  {
    id: 300082790536,
    name: 'Somalia',
    code: 'SO',
    provinces: []
  },
  {
    id: 247297835144,
    name: 'South Africa',
    code: 'ZA',
    provinces: [
      {
        id: 2662737838216,
        country: 247297835144,
        code: 'EC',
        name: 'Eastern Cape'
      },
      {
        id: 2662737870984,
        country: 247297835144,
        code: 'FS',
        name: 'Free State'
      },
      {
        id: 2662737903752,
        country: 247297835144,
        code: 'GT',
        name: 'Gauteng'
      },
      {
        id: 2662737936520,
        country: 247297835144,
        code: 'NL',
        name: 'KwaZulu-Natal'
      },
      {
        id: 2662737969288,
        country: 247297835144,
        code: 'LP',
        name: 'Limpopo'
      },
      {
        id: 2662738002056,
        country: 247297835144,
        code: 'MP',
        name: 'Mpumalanga'
      },
      {
        id: 2662738034824,
        country: 247297835144,
        code: 'NW',
        name: 'North West'
      },
      {
        id: 2662738067592,
        country: 247297835144,
        code: 'NC',
        name: 'Northern Cape'
      },
      {
        id: 2662738100360,
        country: 247297835144,
        code: 'WC',
        name: 'Western Cape'
      }
    ]
  },
  {
    id: 300085608584,
    name: 'South Georgia And The South Sandwich Islands',
    code: 'GS',
    provinces: []
  },
  {
    id: 300079022216,
    name: 'South Korea',
    code: 'KR',
    provinces: [
      {
        id: 3150321385608,
        country: 300079022216,
        code: 'KR-26',
        name: 'Busan'
      },
      {
        id: 3150321418376,
        country: 300079022216,
        code: 'KR-43',
        name: 'Chungbuk'
      },
      {
        id: 3150321451144,
        country: 300079022216,
        code: 'KR-44',
        name: 'Chungnam'
      },
      {
        id: 3150321483912,
        country: 300079022216,
        code: 'KR-27',
        name: 'Daegu'
      },
      {
        id: 3150321516680,
        country: 300079022216,
        code: 'KR-30',
        name: 'Daejeon'
      },
      {
        id: 3150321549448,
        country: 300079022216,
        code: 'KR-42',
        name: 'Gangwon'
      },
      {
        id: 3150321582216,
        country: 300079022216,
        code: 'KR-29',
        name: 'Gwangju'
      },
      {
        id: 3150321614984,
        country: 300079022216,
        code: 'KR-47',
        name: 'Gyeongbuk'
      },
      {
        id: 3150321647752,
        country: 300079022216,
        code: 'KR-41',
        name: 'Gyeonggi'
      },
      {
        id: 3150321680520,
        country: 300079022216,
        code: 'KR-48',
        name: 'Gyeongnam'
      },
      {
        id: 3150321713288,
        country: 300079022216,
        code: 'KR-28',
        name: 'Incheon'
      },
      {
        id: 3150321746056,
        country: 300079022216,
        code: 'KR-49',
        name: 'Jeju'
      },
      {
        id: 3150321778824,
        country: 300079022216,
        code: 'KR-45',
        name: 'Jeonbuk'
      },
      {
        id: 3150321811592,
        country: 300079022216,
        code: 'KR-46',
        name: 'Jeonnam'
      },
      {
        id: 3150321844360,
        country: 300079022216,
        code: 'KR-50',
        name: 'Sejong'
      },
      {
        id: 3150321877128,
        country: 300079022216,
        code: 'KR-11',
        name: 'Seoul'
      },
      {
        id: 3150321909896,
        country: 300079022216,
        code: 'KR-31',
        name: 'Ulsan'
      }
    ]
  },
  {
    id: 300082823304,
    name: 'South Sudan',
    code: 'SS',
    provinces: []
  },
  {
    id: 300081086600,
    name: 'Spain',
    code: 'ES',
    provinces: [
      {
        id: 3150331216008,
        country: 300081086600,
        code: 'C',
        name: 'A Coruña'
      },
      {
        id: 3150331248776,
        country: 300081086600,
        code: 'VI',
        name: 'Álava'
      },
      {
        id: 3150331281544,
        country: 300081086600,
        code: 'AB',
        name: 'Albacete'
      },
      {
        id: 3150331314312,
        country: 300081086600,
        code: 'A',
        name: 'Alicante'
      },
      {
        id: 3150331347080,
        country: 300081086600,
        code: 'AL',
        name: 'Almería'
      },
      {
        id: 3150331379848,
        country: 300081086600,
        code: 'O',
        name: 'Asturias'
      },
      {
        id: 3150331412616,
        country: 300081086600,
        code: 'AV',
        name: 'Ávila'
      },
      {
        id: 3150331445384,
        country: 300081086600,
        code: 'BA',
        name: 'Badajoz'
      },
      {
        id: 3150331478152,
        country: 300081086600,
        code: 'PM',
        name: 'Balears'
      },
      {
        id: 3150331510920,
        country: 300081086600,
        code: 'B',
        name: 'Barcelona'
      },
      {
        id: 3150331543688,
        country: 300081086600,
        code: 'BU',
        name: 'Burgos'
      },
      {
        id: 3150331576456,
        country: 300081086600,
        code: 'CC',
        name: 'Cáceres'
      },
      {
        id: 3150331609224,
        country: 300081086600,
        code: 'CA',
        name: 'Cádiz'
      },
      {
        id: 3150331641992,
        country: 300081086600,
        code: 'S',
        name: 'Cantabria'
      },
      {
        id: 3150331674760,
        country: 300081086600,
        code: 'CS',
        name: 'Castellón'
      },
      {
        id: 3150331707528,
        country: 300081086600,
        code: 'CE',
        name: 'Ceuta'
      },
      {
        id: 3150331740296,
        country: 300081086600,
        code: 'CR',
        name: 'Ciudad Real'
      },
      {
        id: 3150331773064,
        country: 300081086600,
        code: 'CO',
        name: 'Córdoba'
      },
      {
        id: 3150331805832,
        country: 300081086600,
        code: 'CU',
        name: 'Cuenca'
      },
      {
        id: 3150331838600,
        country: 300081086600,
        code: 'GI',
        name: 'Girona'
      },
      {
        id: 3150331871368,
        country: 300081086600,
        code: 'GR',
        name: 'Granada'
      },
      {
        id: 3150331904136,
        country: 300081086600,
        code: 'GU',
        name: 'Guadalajara'
      },
      {
        id: 3150331936904,
        country: 300081086600,
        code: 'SS',
        name: 'Guipúzcoa'
      },
      {
        id: 3150331969672,
        country: 300081086600,
        code: 'H',
        name: 'Huelva'
      },
      {
        id: 3150332002440,
        country: 300081086600,
        code: 'HU',
        name: 'Huesca'
      },
      {
        id: 3150332035208,
        country: 300081086600,
        code: 'J',
        name: 'Jaén'
      },
      {
        id: 3150332067976,
        country: 300081086600,
        code: 'LO',
        name: 'La Rioja'
      },
      {
        id: 3150332100744,
        country: 300081086600,
        code: 'GC',
        name: 'Las Palmas'
      },
      {
        id: 3150332133512,
        country: 300081086600,
        code: 'LE',
        name: 'León'
      },
      {
        id: 3150332166280,
        country: 300081086600,
        code: 'L',
        name: 'Lleida'
      },
      {
        id: 3150332199048,
        country: 300081086600,
        code: 'LU',
        name: 'Lugo'
      },
      {
        id: 3150332231816,
        country: 300081086600,
        code: 'M',
        name: 'Madrid'
      },
      {
        id: 3150332264584,
        country: 300081086600,
        code: 'MA',
        name: 'Málaga'
      },
      {
        id: 3150332297352,
        country: 300081086600,
        code: 'ML',
        name: 'Melilla'
      },
      {
        id: 3150332330120,
        country: 300081086600,
        code: 'MU',
        name: 'Murcia'
      },
      {
        id: 3150332362888,
        country: 300081086600,
        code: 'NA',
        name: 'Navarra'
      },
      {
        id: 3150332395656,
        country: 300081086600,
        code: 'OR',
        name: 'Ourense'
      },
      {
        id: 3150332428424,
        country: 300081086600,
        code: 'P',
        name: 'Palencia'
      },
      {
        id: 3150332461192,
        country: 300081086600,
        code: 'PO',
        name: 'Pontevedra'
      },
      {
        id: 3150332493960,
        country: 300081086600,
        code: 'SA',
        name: 'Salamanca'
      },
      {
        id: 3150332526728,
        country: 300081086600,
        code: 'TF',
        name: 'Santa Cruz de Tenerife'
      },
      {
        id: 3150332559496,
        country: 300081086600,
        code: 'SG',
        name: 'Segovia'
      },
      {
        id: 3150332592264,
        country: 300081086600,
        code: 'SE',
        name: 'Sevilla'
      },
      {
        id: 3150332625032,
        country: 300081086600,
        code: 'SO',
        name: 'Soria'
      },
      {
        id: 3150332657800,
        country: 300081086600,
        code: 'T',
        name: 'Tarragona'
      },
      {
        id: 3150332690568,
        country: 300081086600,
        code: 'TE',
        name: 'Teruel'
      },
      {
        id: 3150332723336,
        country: 300081086600,
        code: 'TO',
        name: 'Toledo'
      },
      {
        id: 3150332756104,
        country: 300081086600,
        code: 'V',
        name: 'Valencia'
      },
      {
        id: 3150332788872,
        country: 300081086600,
        code: 'VA',
        name: 'Valladolid'
      },
      {
        id: 3150332821640,
        country: 300081086600,
        code: 'BI',
        name: 'Vizcaya'
      },
      {
        id: 3150332854408,
        country: 300081086600,
        code: 'ZA',
        name: 'Zamora'
      },
      {
        id: 3150332887176,
        country: 300081086600,
        code: 'Z',
        name: 'Zaragoza'
      }
    ]
  },
  {
    id: 300079054984,
    name: 'Sri Lanka',
    code: 'LK',
    provinces: []
  },
  {
    id: 300084134024,
    name: 'St. Vincent',
    code: 'VC',
    provinces: []
  },
  {
    id: 300082888840,
    name: 'Sudan',
    code: 'SD',
    provinces: []
  },
  {
    id: 300084691080,
    name: 'Suriname',
    code: 'SR',
    provinces: []
  },
  {
    id: 300081119368,
    name: 'Svalbard And Jan Mayen',
    code: 'SJ',
    provinces: []
  },
  {
    id: 300081152136,
    name: 'Sweden',
    code: 'SE',
    provinces: []
  },
  {
    id: 300081184904,
    name: 'Switzerland',
    code: 'CH',
    provinces: []
  },
  {
    id: 300079087752,
    name: 'Syria',
    code: 'SY',
    provinces: []
  },
  {
    id: 300079120520,
    name: 'Taiwan',
    code: 'TW',
    provinces: []
  },
  {
    id: 300079153288,
    name: 'Tajikistan',
    code: 'TJ',
    provinces: []
  },
  {
    id: 300082921608,
    name: 'Tanzania, United Republic Of',
    code: 'TZ',
    provinces: []
  },
  {
    id: 300079186056,
    name: 'Thailand',
    code: 'TH',
    provinces: [
      {
        id: 3150321942664,
        country: 300079186056,
        code: 'TH-37',
        name: 'Amnat Charoen'
      },
      {
        id: 3150321975432,
        country: 300079186056,
        code: 'TH-15',
        name: 'Ang Thong'
      },
      {
        id: 3150322008200,
        country: 300079186056,
        code: 'TH-10',
        name: 'Bangkok'
      },
      {
        id: 3150322040968,
        country: 300079186056,
        code: 'TH-38',
        name: 'Bueng Kan'
      },
      {
        id: 3150322073736,
        country: 300079186056,
        code: 'TH-31',
        name: 'Buriram'
      },
      {
        id: 3150322106504,
        country: 300079186056,
        code: 'TH-24',
        name: 'Chachoengsao'
      },
      {
        id: 3150322139272,
        country: 300079186056,
        code: 'TH-18',
        name: 'Chai Nat'
      },
      {
        id: 3150322172040,
        country: 300079186056,
        code: 'TH-36',
        name: 'Chaiyaphum'
      },
      {
        id: 3150322204808,
        country: 300079186056,
        code: 'TH-22',
        name: 'Chanthaburi'
      },
      {
        id: 3150322237576,
        country: 300079186056,
        code: 'TH-50',
        name: 'Chiang Mai'
      },
      {
        id: 3150322270344,
        country: 300079186056,
        code: 'TH-57',
        name: 'Chiang Rai'
      },
      {
        id: 3150322303112,
        country: 300079186056,
        code: 'TH-20',
        name: 'Chon Buri'
      },
      {
        id: 3150322335880,
        country: 300079186056,
        code: 'TH-86',
        name: 'Chumphon'
      },
      {
        id: 3150322368648,
        country: 300079186056,
        code: 'TH-46',
        name: 'Kalasin'
      },
      {
        id: 3150322401416,
        country: 300079186056,
        code: 'TH-62',
        name: 'Kamphaeng Phet'
      },
      {
        id: 3150322434184,
        country: 300079186056,
        code: 'TH-71',
        name: 'Kanchanaburi'
      },
      {
        id: 3150322466952,
        country: 300079186056,
        code: 'TH-40',
        name: 'Khon Kaen'
      },
      {
        id: 3150322499720,
        country: 300079186056,
        code: 'TH-81',
        name: 'Krabi'
      },
      {
        id: 3150322532488,
        country: 300079186056,
        code: 'TH-52',
        name: 'Lampang'
      },
      {
        id: 3150322565256,
        country: 300079186056,
        code: 'TH-51',
        name: 'Lamphun'
      },
      {
        id: 3150322598024,
        country: 300079186056,
        code: 'TH-42',
        name: 'Loei'
      },
      {
        id: 3150322630792,
        country: 300079186056,
        code: 'TH-16',
        name: 'Lopburi'
      },
      {
        id: 3150322663560,
        country: 300079186056,
        code: 'TH-58',
        name: 'Mae Hong Son'
      },
      {
        id: 3150322696328,
        country: 300079186056,
        code: 'TH-44',
        name: 'Maha Sarakham'
      },
      {
        id: 3150322729096,
        country: 300079186056,
        code: 'TH-49',
        name: 'Mukdahan'
      },
      {
        id: 3150322761864,
        country: 300079186056,
        code: 'TH-26',
        name: 'Nakhon Nayok'
      },
      {
        id: 3150322794632,
        country: 300079186056,
        code: 'TH-73',
        name: 'Nakhon Pathom'
      },
      {
        id: 3150322827400,
        country: 300079186056,
        code: 'TH-48',
        name: 'Nakhon Phanom'
      },
      {
        id: 3150322860168,
        country: 300079186056,
        code: 'TH-30',
        name: 'Nakhon Ratchasima'
      },
      {
        id: 3150322892936,
        country: 300079186056,
        code: 'TH-60',
        name: 'Nakhon Sawan'
      },
      {
        id: 3150322925704,
        country: 300079186056,
        code: 'TH-80',
        name: 'Nakhon Si Thammarat'
      },
      {
        id: 3150322958472,
        country: 300079186056,
        code: 'TH-55',
        name: 'Nan'
      },
      {
        id: 3150322991240,
        country: 300079186056,
        code: 'TH-96',
        name: 'Narathiwat'
      },
      {
        id: 3150323024008,
        country: 300079186056,
        code: 'TH-39',
        name: 'Nong Bua Lam Phu'
      },
      {
        id: 3150323056776,
        country: 300079186056,
        code: 'TH-43',
        name: 'Nong Khai'
      },
      {
        id: 3150323089544,
        country: 300079186056,
        code: 'TH-12',
        name: 'Nonthaburi'
      },
      {
        id: 3150323122312,
        country: 300079186056,
        code: 'TH-13',
        name: 'Pathum Thani'
      },
      {
        id: 3150323155080,
        country: 300079186056,
        code: 'TH-94',
        name: 'Pattani'
      },
      {
        id: 3150323187848,
        country: 300079186056,
        code: 'TH-S',
        name: 'Pattaya'
      },
      {
        id: 3150323220616,
        country: 300079186056,
        code: 'TH-82',
        name: 'Phangnga'
      },
      {
        id: 3150323253384,
        country: 300079186056,
        code: 'TH-93',
        name: 'Phatthalung'
      },
      {
        id: 3150323286152,
        country: 300079186056,
        code: 'TH-56',
        name: 'Phayao'
      },
      {
        id: 3150323318920,
        country: 300079186056,
        code: 'TH-67',
        name: 'Phetchabun'
      },
      {
        id: 3150323351688,
        country: 300079186056,
        code: 'TH-76',
        name: 'Phetchaburi'
      },
      {
        id: 3150323384456,
        country: 300079186056,
        code: 'TH-66',
        name: 'Phichit'
      },
      {
        id: 3150323417224,
        country: 300079186056,
        code: 'TH-65',
        name: 'Phitsanulok'
      },
      {
        id: 3150323449992,
        country: 300079186056,
        code: 'TH-14',
        name: 'Phra Nakhon Si Ayutthaya'
      },
      {
        id: 3150323482760,
        country: 300079186056,
        code: 'TH-54',
        name: 'Phrae'
      },
      {
        id: 3150323515528,
        country: 300079186056,
        code: 'TH-83',
        name: 'Phuket'
      },
      {
        id: 3150323548296,
        country: 300079186056,
        code: 'TH-25',
        name: 'Prachin Buri'
      },
      {
        id: 3150323581064,
        country: 300079186056,
        code: 'TH-77',
        name: 'Prachuap Khiri Khan'
      },
      {
        id: 3150323613832,
        country: 300079186056,
        code: 'TH-85',
        name: 'Ranong'
      },
      {
        id: 3150323646600,
        country: 300079186056,
        code: 'TH-70',
        name: 'Ratchaburi'
      },
      {
        id: 3150323679368,
        country: 300079186056,
        code: 'TH-21',
        name: 'Rayong'
      },
      {
        id: 3150323712136,
        country: 300079186056,
        code: 'TH-45',
        name: 'Roi Et'
      },
      {
        id: 3150323744904,
        country: 300079186056,
        code: 'TH-27',
        name: 'Sa Kaeo'
      },
      {
        id: 3150323777672,
        country: 300079186056,
        code: 'TH-47',
        name: 'Sakon Nakhon'
      },
      {
        id: 3150323810440,
        country: 300079186056,
        code: 'TH-11',
        name: 'Samut Prakan'
      },
      {
        id: 3150323843208,
        country: 300079186056,
        code: 'TH-74',
        name: 'Samut Sakhon'
      },
      {
        id: 3150323875976,
        country: 300079186056,
        code: 'TH-75',
        name: 'Samut Songkhram'
      },
      {
        id: 3150323908744,
        country: 300079186056,
        code: 'TH-19',
        name: 'Saraburi'
      },
      {
        id: 3150323941512,
        country: 300079186056,
        code: 'TH-91',
        name: 'Satun'
      },
      {
        id: 3150323974280,
        country: 300079186056,
        code: 'TH-17',
        name: 'Sing Buri'
      },
      {
        id: 3150324007048,
        country: 300079186056,
        code: 'TH-33',
        name: 'Sisaket'
      },
      {
        id: 3150324039816,
        country: 300079186056,
        code: 'TH-90',
        name: 'Songkhla'
      },
      {
        id: 3150324072584,
        country: 300079186056,
        code: 'TH-64',
        name: 'Sukhothai'
      },
      {
        id: 3150324105352,
        country: 300079186056,
        code: 'TH-72',
        name: 'Suphan Buri'
      },
      {
        id: 3150324138120,
        country: 300079186056,
        code: 'TH-84',
        name: 'Surat Thani'
      },
      {
        id: 3150324170888,
        country: 300079186056,
        code: 'TH-32',
        name: 'Surin'
      },
      {
        id: 3150324203656,
        country: 300079186056,
        code: 'TH-63',
        name: 'Tak'
      },
      {
        id: 3150324236424,
        country: 300079186056,
        code: 'TH-92',
        name: 'Trang'
      },
      {
        id: 3150324269192,
        country: 300079186056,
        code: 'TH-23',
        name: 'Trat'
      },
      {
        id: 3150324301960,
        country: 300079186056,
        code: 'TH-34',
        name: 'Ubon Ratchathani'
      },
      {
        id: 3150324334728,
        country: 300079186056,
        code: 'TH-41',
        name: 'Udon Thani'
      },
      {
        id: 3150324367496,
        country: 300079186056,
        code: 'TH-61',
        name: 'Uthai Thani'
      },
      {
        id: 3150324400264,
        country: 300079186056,
        code: 'TH-53',
        name: 'Uttaradit'
      },
      {
        id: 3150324433032,
        country: 300079186056,
        code: 'TH-95',
        name: 'Yala'
      },
      {
        id: 3150324465800,
        country: 300079186056,
        code: 'TH-35',
        name: 'Yasothon'
      }
    ]
  },
  {
    id: 300085248136,
    name: 'Timor Leste',
    code: 'TL',
    provinces: []
  },
  {
    id: 300082954376,
    name: 'Togo',
    code: 'TG',
    provinces: []
  },
  {
    id: 300085280904,
    name: 'Tokelau',
    code: 'TK',
    provinces: []
  },
  {
    id: 300085313672,
    name: 'Tonga',
    code: 'TO',
    provinces: []
  },
  {
    id: 300084166792,
    name: 'Trinidad and Tobago',
    code: 'TT',
    provinces: []
  },
  {
    id: 300082987144,
    name: 'Tunisia',
    code: 'TN',
    provinces: []
  },
  {
    id: 300081217672,
    name: 'Turkey',
    code: 'TR',
    provinces: []
  },
  {
    id: 300079218824,
    name: 'Turkmenistan',
    code: 'TM',
    provinces: []
  },
  {
    id: 300084199560,
    name: 'Turks and Caicos Islands',
    code: 'TC',
    provinces: []
  },
  {
    id: 300085346440,
    name: 'Tuvalu',
    code: 'TV',
    provinces: []
  },
  {
    id: 300083019912,
    name: 'Uganda',
    code: 'UG',
    provinces: []
  },
  {
    id: 300081250440,
    name: 'Ukraine',
    code: 'UA',
    provinces: []
  },
  {
    id: 300079251592,
    name: 'United Arab Emirates',
    code: 'AE',
    provinces: [
      {
        id: 3150324498568,
        country: 300079251592,
        code: 'AZ',
        name: 'Abu Dhabi'
      },
      {
        id: 3150324531336,
        country: 300079251592,
        code: 'AJ',
        name: 'Ajman'
      },
      {
        id: 3150324564104,
        country: 300079251592,
        code: 'DU',
        name: 'Dubai'
      },
      {
        id: 3150324596872,
        country: 300079251592,
        code: 'FU',
        name: 'Fujairah'
      },
      {
        id: 3150324629640,
        country: 300079251592,
        code: 'RK',
        name: 'Ras al-Khaimah'
      },
      {
        id: 3150324662408,
        country: 300079251592,
        code: 'SH',
        name: 'Sharjah'
      },
      {
        id: 3150324695176,
        country: 300079251592,
        code: 'UQ',
        name: 'Umm al-Quwain'
      }
    ]
  },
  {
    id: 300081283208,
    name: 'United Kingdom',
    code: 'GB',
    provinces: []
  },
  {
    id: 300085510280,
    name: 'United States',
    code: 'US',
    provinces: [
      {
        id: 3150342619272,
        country: 300085510280,
        code: 'AL',
        name: 'Alabama'
      },
      {
        id: 3150342652040,
        country: 300085510280,
        code: 'AK',
        name: 'Alaska'
      },
      {
        id: 3150342684808,
        country: 300085510280,
        code: 'AS',
        name: 'American Samoa'
      },
      {
        id: 3150342717576,
        country: 300085510280,
        code: 'AZ',
        name: 'Arizona'
      },
      {
        id: 3150342750344,
        country: 300085510280,
        code: 'AR',
        name: 'Arkansas'
      },
      {
        id: 3150344552584,
        country: 300085510280,
        code: 'AA',
        name: 'Armed Forces Americas'
      },
      {
        id: 3150344585352,
        country: 300085510280,
        code: 'AE',
        name: 'Armed Forces Europe'
      },
      {
        id: 3150344618120,
        country: 300085510280,
        code: 'AP',
        name: 'Armed Forces Pacific'
      },
      {
        id: 3150342783112,
        country: 300085510280,
        code: 'CA',
        name: 'California'
      },
      {
        id: 3150342815880,
        country: 300085510280,
        code: 'CO',
        name: 'Colorado'
      },
      {
        id: 3150342848648,
        country: 300085510280,
        code: 'CT',
        name: 'Connecticut'
      },
      {
        id: 3150342881416,
        country: 300085510280,
        code: 'DE',
        name: 'Delaware'
      },
      {
        id: 3150342914184,
        country: 300085510280,
        code: 'DC',
        name: 'District of Columbia'
      },
      {
        id: 3150342946952,
        country: 300085510280,
        code: 'FM',
        name: 'Federated States of Micronesia'
      },
      {
        id: 3150342979720,
        country: 300085510280,
        code: 'FL',
        name: 'Florida'
      },
      {
        id: 3150343012488,
        country: 300085510280,
        code: 'GA',
        name: 'Georgia'
      },
      {
        id: 3150343045256,
        country: 300085510280,
        code: 'GU',
        name: 'Guam'
      },
      {
        id: 3150343078024,
        country: 300085510280,
        code: 'HI',
        name: 'Hawaii'
      },
      {
        id: 3150343110792,
        country: 300085510280,
        code: 'ID',
        name: 'Idaho'
      },
      {
        id: 3150343143560,
        country: 300085510280,
        code: 'IL',
        name: 'Illinois'
      },
      {
        id: 3150343176328,
        country: 300085510280,
        code: 'IN',
        name: 'Indiana'
      },
      {
        id: 3150343209096,
        country: 300085510280,
        code: 'IA',
        name: 'Iowa'
      },
      {
        id: 3150343241864,
        country: 300085510280,
        code: 'KS',
        name: 'Kansas'
      },
      {
        id: 3150343274632,
        country: 300085510280,
        code: 'KY',
        name: 'Kentucky'
      },
      {
        id: 3150343307400,
        country: 300085510280,
        code: 'LA',
        name: 'Louisiana'
      },
      {
        id: 3150343340168,
        country: 300085510280,
        code: 'ME',
        name: 'Maine'
      },
      {
        id: 3150343372936,
        country: 300085510280,
        code: 'MH',
        name: 'Marshall Islands'
      },
      {
        id: 3150343405704,
        country: 300085510280,
        code: 'MD',
        name: 'Maryland'
      },
      {
        id: 3150343438472,
        country: 300085510280,
        code: 'MA',
        name: 'Massachusetts'
      },
      {
        id: 3150343471240,
        country: 300085510280,
        code: 'MI',
        name: 'Michigan'
      },
      {
        id: 3150343504008,
        country: 300085510280,
        code: 'MN',
        name: 'Minnesota'
      },
      {
        id: 3150343536776,
        country: 300085510280,
        code: 'MS',
        name: 'Mississippi'
      },
      {
        id: 3150343569544,
        country: 300085510280,
        code: 'MO',
        name: 'Missouri'
      },
      {
        id: 3150343602312,
        country: 300085510280,
        code: 'MT',
        name: 'Montana'
      },
      {
        id: 3150343635080,
        country: 300085510280,
        code: 'NE',
        name: 'Nebraska'
      },
      {
        id: 3150343667848,
        country: 300085510280,
        code: 'NV',
        name: 'Nevada'
      },
      {
        id: 3150343700616,
        country: 300085510280,
        code: 'NH',
        name: 'New Hampshire'
      },
      {
        id: 3150343733384,
        country: 300085510280,
        code: 'NJ',
        name: 'New Jersey'
      },
      {
        id: 3150343766152,
        country: 300085510280,
        code: 'NM',
        name: 'New Mexico'
      },
      {
        id: 3150343798920,
        country: 300085510280,
        code: 'NY',
        name: 'New York'
      },
      {
        id: 3150343831688,
        country: 300085510280,
        code: 'NC',
        name: 'North Carolina'
      },
      {
        id: 3150343864456,
        country: 300085510280,
        code: 'ND',
        name: 'North Dakota'
      },
      {
        id: 3150343897224,
        country: 300085510280,
        code: 'MP',
        name: 'Northern Mariana Islands'
      },
      {
        id: 3150343929992,
        country: 300085510280,
        code: 'OH',
        name: 'Ohio'
      },
      {
        id: 3150343962760,
        country: 300085510280,
        code: 'OK',
        name: 'Oklahoma'
      },
      {
        id: 3150343995528,
        country: 300085510280,
        code: 'OR',
        name: 'Oregon'
      },
      {
        id: 3150344028296,
        country: 300085510280,
        code: 'PW',
        name: 'Palau'
      },
      {
        id: 3150344061064,
        country: 300085510280,
        code: 'PA',
        name: 'Pennsylvania'
      },
      {
        id: 3150344093832,
        country: 300085510280,
        code: 'PR',
        name: 'Puerto Rico'
      },
      {
        id: 3150344126600,
        country: 300085510280,
        code: 'RI',
        name: 'Rhode Island'
      },
      {
        id: 3150344159368,
        country: 300085510280,
        code: 'SC',
        name: 'South Carolina'
      },
      {
        id: 3150344192136,
        country: 300085510280,
        code: 'SD',
        name: 'South Dakota'
      },
      {
        id: 3150344224904,
        country: 300085510280,
        code: 'TN',
        name: 'Tennessee'
      },
      {
        id: 3150344257672,
        country: 300085510280,
        code: 'TX',
        name: 'Texas'
      },
      {
        id: 3150344290440,
        country: 300085510280,
        code: 'UT',
        name: 'Utah'
      },
      {
        id: 3150344323208,
        country: 300085510280,
        code: 'VT',
        name: 'Vermont'
      },
      {
        id: 3150344355976,
        country: 300085510280,
        code: 'VI',
        name: 'Virgin Islands'
      },
      {
        id: 3150344388744,
        country: 300085510280,
        code: 'VA',
        name: 'Virginia'
      },
      {
        id: 3150344421512,
        country: 300085510280,
        code: 'WA',
        name: 'Washington'
      },
      {
        id: 3150344454280,
        country: 300085510280,
        code: 'WV',
        name: 'West Virginia'
      },
      {
        id: 3150344487048,
        country: 300085510280,
        code: 'WI',
        name: 'Wisconsin'
      },
      {
        id: 3150344519816,
        country: 300085510280,
        code: 'WY',
        name: 'Wyoming'
      }
    ]
  },
  {
    id: 300084232328,
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    provinces: []
  },
  {
    id: 300084723848,
    name: 'Uruguay',
    code: 'UY',
    provinces: []
  },
  {
    id: 300079284360,
    name: 'Uzbekistan',
    code: 'UZ',
    provinces: []
  },
  {
    id: 300085379208,
    name: 'Vanuatu',
    code: 'VU',
    provinces: []
  },
  {
    id: 300084756616,
    name: 'Venezuela',
    code: 'VE',
    provinces: []
  },
  {
    id: 300079317128,
    name: 'Vietnam',
    code: 'VN',
    provinces: []
  },
  {
    id: 300083380360,
    name: 'Virgin Islands, British',
    code: 'VG',
    provinces: []
  },
  {
    id: 300085411976,
    name: 'Wallis And Futuna',
    code: 'WF',
    provinces: []
  },
  {
    id: 300083052680,
    name: 'Western Sahara',
    code: 'EH',
    provinces: []
  },
  {
    id: 300079349896,
    name: 'Yemen',
    code: 'YE',
    provinces: []
  },
  {
    id: 300083085448,
    name: 'Zambia',
    code: 'ZM',
    provinces: []
  },
  {
    id: 300083118216,
    name: 'Zimbabwe',
    code: 'ZW',
    provinces: []
  }
]

export default countries
