import React from 'react'
import { connect } from 'react-redux'
import { Feather } from '@expo/vector-icons'
import { scale } from '@utils/scale'
import _ from 'lodash'

const Icon = ({ size, color, name, type, style, theme, ...otherProps }) => {
  let _color = color
  switch (type) {
    case 'secondary':
      _color = theme.contentText
      break
    case 'light':
      _color = theme.contentText
      break
    default:
      if (!color) {
        _color = theme.contentText
      }
      break
  }
  return (
    <Feather
      name={name}
      color={_color}
      size={scale(size)}
      style={style}
      {...otherProps}
    />
  )
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(Icon)
