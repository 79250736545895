import React from 'react'
import { Image, View } from 'react-native'
import _ from 'lodash'
import Text from '@components/Text'
import TouchableExpand from '@components/TouchableExpand'
import { width } from '@utils/scale'

const RadialCollectionItem = ({ itemPressed, item }) => {
  const handleOnPress = handle => {
    if (handle && _.isFunction(itemPressed)) {
      itemPressed(handle)
    }
  }

  let fallbackImg = _.get(item, 'products.edges[0].node.images.edges', [])
  fallbackImg = _.get(_.first(fallbackImg), 'node.transformedSrc')

  const img = _.get(item, 'image.transformedSrc') || fallbackImg

  return (
    <TouchableExpand
      style={styles.holder}
      onPress={() => handleOnPress(item.handle)}
    >
      <View style={styles.image}>
        {item && (
          <Image
            style={styles.image}
            source={{ uri: img }}
            resizeMode='cover'
          />
        )}
      </View>
      <View style={{ alignItems: 'center' }}>
        <Text fontSize={13} style={styles.title}>
          {item.title}
        </Text>
      </View>
    </TouchableExpand>
  )
}

const styles = {
  holder: {
    alignItems: 'center',
    padding: 10,
    marginTop: 10,
    marginBottom: 20
  },
  image: {
    width: width / 4.5,
    height: width / 4.5,
    borderRadius: width / 4.5 / 2,
    backgroundColor: '#ebebeb',
    resizeMode: 'cover',
    marginBottom: 10
  },
  title: {
    fontWeight: '500',
    marginTop: 5,
    marginBottom: 2,
    letterSpacing: 0.5
  }
}

export default RadialCollectionItem
