import React from 'react'
import { View } from 'react-native'
import { Query } from 'react-apollo'
import { useProductQuery } from '@gql/Hooks'
import _ from 'lodash'
import Text from '@components/Text'
import MainProductList from '@components/MainProductList'

const MainProductsSearch = ({
  dynamicHeight,
  horizontal,
  imageAlignment,
  mode,
  navigation,
  onScroll,
  searchTerm = '',
  showSaleBadge,
  theme
}) => {
  const searchProductsQuery = useProductQuery('searchProducts')
  return (
    <View style={{ flex: 1, paddingBottom: 30 }}>
      <Query
        query={searchProductsQuery}
        variables={{
          term: searchTerm
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <View style={{ padding: 30 }}>
                <Text>Searching products...</Text>
              </View>
            )
          }
          if (error) {
            return (
              <View style={{ padding: 30 }}>
                <Text>Something went wrong with you search. Please try again in a few.</Text>
              </View>
            )
          }
          const products = _.get(
            data,
            'products.edges',
            []
          )
          if (_.isEmpty(products)) {
            return (
              <View style={{ padding: 40 }}>
                <Text style={{ textAlign: 'center' }}>No products found, try a different search term.</Text>
              </View>
            )
          }
          return (
            <MainProductList
              onScroll={() => onScroll && onScroll()}
              theme={theme}
              loading={loading}
              mode={mode}
              horizontal={horizontal}
              items={products}
              navigation={navigation}
              dynamicHeight={dynamicHeight}
              imageAlignment={imageAlignment}
              showSaleBadge={showSaleBadge}
              goBackBeforeForward
            />
          )
        }}
      </Query>
    </View>
  )
}

export default MainProductsSearch
