import gql from 'graphql-tag'
import { useSelector } from 'react-redux'
import { generateFullProductResponse, generateGeneralProductResponse } from './utils'

export const useProductQuery = (type) => {
  const reviewProvider = useSelector(state => state?.config?.integrations?.reviews?.provider)
  const productResponse = generateGeneralProductResponse(reviewProvider)
  const fullProductResponse = generateFullProductResponse(reviewProvider)

  switch (type) {
    case 'getProductsForCollection':
      return gql`
      query($handle: String!, $sort: ProductCollectionSortKeys, $reverse: Boolean, $after: String, $limit: Int) {
        collectionByHandle(handle: $handle) {
          title
          description
          products(first: $limit, sortKey: $sort, reverse: $reverse, after: $after) {
            edges {
              cursor
              node {
                ${productResponse}
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
      }
    `
    case 'getProductsByIds':
      return gql`
      query($ids: [ID!]!) {
        nodes(ids: $ids) {
          ...on Product {
            ${productResponse}
          }
        }
      }
    `

    case 'getRecommendationsById':
      return gql`
      query ($productId: ID!) {
        productRecommendations(productId: $productId) {
          ${productResponse}
        }
      }
    `

    case 'searchProducts':
      return gql`
      query ($term: String) {
        products(first: 10, query: $term) {
          edges {
            node {
              ${productResponse}
            }
          }
        }
      }
    `

    case 'getProductByHandle':
      return gql`
      query($handle: String!) {
        productByHandle(handle: $handle) {
          ${fullProductResponse}
        }
      }
    `
  }
}
