import React, { useState } from 'react'
import { colors } from '@utils/theme'
import { TouchableOpacity, View } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'
import {
  renderers,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger
} from 'react-native-popup-menu'
import Icon from '@components/Icon'

const { SlideInMenu } = renderers

const FilterOptions = ({ option, onSelect }) => {
  return (
    <MenuOption onSelect={onSelect} style={{ paddingHorizontal: 15 }}>
      <View style={styles.filterItem}>
        <Text fontSize={18} style={styles.filterItemText}>
          {option.title}
        </Text>
      </View>
    </MenuOption>
  )
}

const SectionFilter = ({
  activeOption,
  options,
  reverse,
  selectedOption,
  changeReverse,
  theme
}) => {
  const [opened, setOpened] = useState()

  const selectOption = option => {
    if (selectedOption && _.isFunction(selectedOption)) {
      selectedOption(option)
    }
    setOpened(false)
  }

  const handleChangeReverse = () => {
    if (changeReverse && _.isFunction(changeReverse)) {
      changeReverse()
    }
  }

  if (!options) return <View />

  const title =
    (activeOption &&
      _.get(
        _.find(options, option => option.id === activeOption),
        'title'
      )) ||
    'Sort'

  return (
    <View>
      <View style={styles.filterButtonsHolder}>
        <TouchableOpacity
          onPress={() => setOpened(true)}
          style={styles.filterBarText}
        >
          <Text
            fontSize={14}
            color={theme.accentText}
            style={{ fontWeight: '500', letterSpacing: 0.8 }}
          >
            {title}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={handleChangeReverse}>
          <Icon
            color={theme.accentText}
            name={reverse ? 'arrow-down' : 'arrow-up'}
            size={16}
          />
        </TouchableOpacity>
      </View>
      <Menu
        renderer={SlideInMenu}
        opened={opened}
        onBackdropPress={() => setOpened(false)}
        onSelect={() => setOpened(false)}
      >
        <MenuTrigger />
        <MenuOptions
          optionsContainerStyle={{
            backgroundColor: theme.contentBackground,
            borderRadius: 20
          }}
          style={styles.menuOptionsHolder}
        >
          {options.map(option => {
            return (
              <FilterOptions
                key={option.id}
                option={option}
                onSelect={() => selectOption(option)}
              />
            )
          })}
        </MenuOptions>
      </Menu>
    </View>
  )
}

const styles = {
  filterItem: {
    borderBottomColor: colors.lineColor,
    borderBottomWidth: 1,
    marginHorizontal: 30
  },
  filterItemText: {
    fontWeight: '400',
    textAlign: 'center',
    paddingVertical: 10
  },
  filterButtonsHolder: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center'
  },
  filterBarText: {
    paddingRight: 10,
    justifyContent: 'center',
    marginRight: 5
  },
  menuOptionsHolder: {
    paddingBottom: 30,
    borderRadius: 20
  }
}

export default SectionFilter
