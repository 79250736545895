import React from 'react'
import { connect } from 'react-redux'
import { Animated, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import Image from '@components/DCImage'
import Text from '@components/Text'
import HtmlRenderer from '@components/HtmlRenderer'
import ModalContainer from '@containers/ModalContainer'
import { width } from '@utils/scale'
import _ from 'lodash'

const BlogArticle = ({ theme, navigation, route }) => {
  const scale = new Animated.Value(0)
  const article = route?.params ?? null
  const image = _.get(article, 'image.transformedSrc')

  const headerHeight = scale.interpolate({
    inputRange: [0, 200, 250], // width / 1.7
    outputRange: [0, -((width / 1.7) - 100), -((width / 1.7) - 80)],
    extrapolate: 'clamp'
  })

  const scrollTop = scale.interpolate({
    inputRange: [0, 200, 250], // width / 1.7
    outputRange: [width / 1.7, 80, 60],
    extrapolate: 'clamp'
  })

  const headerScale = scale.interpolate({
    inputRange: [-50, 0],
    outputRange: [1.3, 1],
    extrapolate: 'clamp'
  })
  return (
    <ModalContainer theme={theme} handleBack={() => navigation.goBack()}>
      <View style={{ paddingTop: 20, flex: 1 }}>
        {image ? (
          <Animated.View style={{ position: 'absolute', top: 0, right: 0, left: 0, height: width / 1.7, transform: [{ translateY: headerHeight }, { scale: headerScale }] }}>
            <Image
              style={{ width, height: width / 1.7, resizeMode: 'cover' }}
              source={{ uri: image }}
            />
            <LinearGradient
              pointerEvents='none'
              colors={[
                `${theme.contentBackground}00`,
                theme.contentBackground,
                theme.contentBackground,
                theme.contentBackground
              ]}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: width / 2,
                opacity: 0.4
              }}
            />
            <Text
              style={{ position: 'absolute', bottom: 20, left: 20 }}
              fontSize={24}
              bold
            >
              {article.title}
            </Text>
          </Animated.View>
        ) : (
          <View style={{ paddingLeft: 25, marginBottom: 15 }}>
            <Text fontSize={24} bold>
              {article.title}
            </Text>
          </View>
        )}
        <Animated.ScrollView
          showsVerticalScrollIndicator={false}
          automaticallyAdjustContentInsets
          style={{ position: 'relative', transform: [{ translateY: scrollTop }] }}
          contentContainerStyle={{ paddingBottom: 80 }}
          scrollEventThrottle={16}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scale } } }],
            { useNativeDriver: true }
          )}
        >
          <HtmlRenderer
            containerStyle={{ paddingLeft: 15, paddingRight: 15 }}
            theme={theme}
            html={article.contentHtml}
          />
        </Animated.ScrollView>
      </View>
    </ModalContainer>
  )
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', {})
  }
}

export default connect(mapStateToProps)(BlogArticle)
