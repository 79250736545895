import React, { useState, useEffect } from 'react'
import {
  View,
  TouchableOpacity
} from 'react-native'
import { checkoutUpdate } from '@gql/Mutations'
import { useDebounce } from '@utils'
import { scale } from '@utils/scale'
import Icon from '@components/Icon'
import Text from '@components/Text'
import _ from 'lodash'

const CartQuantityControl = ({ item, theme, updateQuantity }) => {
  const [quantity, setQuantity] = useState(_.get(item, 'node.quantity', 1))
  const [hasMounted, setHasMounted] = useState(false)
  const debouncedQuantity = useDebounce(quantity, 500)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted && debouncedQuantity) {
      if (_.isFunction(updateQuantity)) {
        updateQuantity(item, checkoutUpdate, debouncedQuantity)
      }
    }
  }, [debouncedQuantity])

  const handleUpdateQuantity = (method) => {
    if (method === 'add') {
      setQuantity(quantity + 1)
    }
    if (method === 'minus') {
      if ((quantity - 1) > 0) {
        setQuantity(quantity - 1)
      }
    }
  }

  return (
    <View style={styles.quantityHolder}>
      <TouchableOpacity
        disabled={item.node.quantity < 2}
        style={
          item.node.quantity < 2
            ? styles.quantityButtonDisabled
            : styles.quantityButton
        }
        onPress={() => handleUpdateQuantity('minus')}
      >
        <Icon
          size={19}
          name='minus-circle'
          color={theme.contentText}
        />
      </TouchableOpacity>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 5
        }}
      >
        <Text fontSize={14} style={{ justifyContent: 'space-between' }}>
          {quantity}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.quantityButton}
        onPress={() => handleUpdateQuantity('add')}
      >
        <Icon
          size={19}
          name='plus-circle'
          color={theme.contentText}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = {
  quantityHolder: {
    position: 'absolute',
    bottom: 5,
    left: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center'
  },
  quantityButton: {
    width: scale(20),
    height: scale(20),
    margin: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  quantityButtonDisabled: {
    opacity: 0.2,
    width: scale(20),
    height: scale(20),
    margin: 5,
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default CartQuantityControl
