import React from 'react'
import {
  View,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import { checkoutUpdate } from '@gql/Mutations'
import { moneyV2 } from '@utils'
import DCImage from '@components/DCImage'
import Icon from '@components/Icon'
import Text from '@components/Text'
import TouchableExpand from '@components/TouchableExpand'
import CartQuantityControl from '@components/CartQuantityControl'
import { Mutation } from 'react-apollo'
import { scale } from '@utils/scale'
import _ from 'lodash'

import Swipeable from '@components/Swipeable'

const CartItem = ({
  updateQuantity,
  removeItemFromCart,
  theme,
  item,
  navigation,
  hideProductSku = false
}) => {
  const handleRemoveItemFromCart = (item, mutation) => {
    if (_.isFunction(removeItemFromCart)) {
      removeItemFromCart(item, mutation)
    }
  }

  const handleUpdateQuantity = (item, mutation, newQuantity) => {
    if (_.isFunction(updateQuantity)) {
      updateQuantity(item, mutation, newQuantity)
    }
  }
  const parentProductHandle = _.get(item, 'node.variant.product.handle')
  const rightButtons = [
    // <View style={styles.swipeButtonHolder} key={`${item.id}-0`}>
    //   <TouchableOpacity
    //     style={{
    //       ...styles.swipeButtonBase,
    //       backgroundColor: '#2f7ae5'
    //     }}
    //     onPress={() => {}}
    //   >
    //     <Icon name='share' size={18} color='white' />
    //   </TouchableOpacity>
    //   <Text fontSize={12}>SHARE</Text>
    // </View>,
    <View style={styles.swipeButtonHolder} key={`${item.id}-1`}>
      <Mutation mutation={checkoutUpdate}>
        {(mutation, { loading }) => {
          return (
            <TouchableOpacity
              style={{
                ...styles.swipeButtonBase,
                backgroundColor: '#D52727'
              }}
              disabled={loading}
              onPress={() => {
                handleRemoveItemFromCart(item, mutation)
              }}
            >
              {loading ? (
                <ActivityIndicator size='small' color='#FFF' />
              ) : (
                <Icon name='x' size={18} color='white' />
              )}
            </TouchableOpacity>
          )
        }}
      </Mutation>
      <Text fontSize={12}>DELETE</Text>
    </View>
  ]

  return (
    <Swipeable rightButtonWidth={90} rightButtons={rightButtons}>
      <View
        style={{
          ...styles.itemHolder,
          backgroundColor: theme.contentBackground
        }}
      >
        <TouchableExpand
          onPress={() =>
            parentProductHandle &&
            navigation.navigate('Product', { handle: parentProductHandle })}
          style={{ ...styles.image, overflow: 'hidden' }}
        >
          <DCImage
            scalable
            style={styles.image}
            source={{ uri: _.get(item, 'node.variant.image.transformedSrc') }}
          />
        </TouchableExpand>
        <View style={{ flex: 1, height: '100%', paddingHorizontal: 10 }}>
          <View>
            <Text
              numberOfLines={2}
              ellipsizeMode='tail'
              fontSize={15}
              style={{ fontWeight: '500' }}
            >
              {item.node.title}{' '}
              {_.get(item, 'node.variant.title') &&
                item.node.variant.title !== 'Default Title' &&
                `- ${item.node.variant.title}`}
            </Text>
            {!hideProductSku && (
              <Text
                secondary
                fontSize={11}
                numberOfLines={1}
                ellipsizeMode='tail'
                style={{ marginTop: 2 }}
              >
                {_.get(item, 'node.variant.sku') &&
                  `SKU ${_.get(item, 'node.variant.sku')}`}
              </Text>
            )}
          </View>
          <CartQuantityControl
            item={item}
            theme={theme}
            updateQuantity={handleUpdateQuantity}
          />
          <View style={{ position: 'absolute', bottom: 10, right: 10 }}>
            <Text
              bold
              fontSize={17}
              style={{ justifyContent: 'space-between', fontWeight: '600' }}
            >
              {moneyV2(_.get(item, 'node.variant.priceV2', 0))}
            </Text>
          </View>
        </View>
      </View>
    </Swipeable>
  )
}

const styles = {
  swipeButtonHolder: {
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  swipeButtonBase: {
    width: scale(28),
    height: scale(28),
    borderRadius: scale(14),
    padding: scale(5),
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 5
  },
  itemHolder: {
    padding: 8,
    margin: 5,
    paddingBottom: 15,
    flexDirection: 'row'
  },
  image: { width: scale(90), height: scale(90) }
}

export default CartItem
