import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { View, TextInput } from 'react-native'
import Text from '@components/Text'
import { scale } from '@utils/scale'
import validator from 'validator'
import PhoneInput from '@components/PhoneInput'
import _ from 'lodash'
import CountryRegionInput from '@components/CountryRegionInput'
import SelectInput from '@components/SelectInput'

const Input = ({
  validation,
  onComplete,
  onChangeInternalData,
  onChange,
  hasSearch,
  type,
  inputProps = {},
  inputStyle,
  theme,
  label,
  labelStyle,
  viewStyle,
  labelFontSize,
  error: externalError
}) => {
  const [value, setValue] = useState(inputProps.defaultValue)
  const [error, setError] = useState(externalError)

  useEffect(() => {
    setError(externalError)
  }, [externalError])

  useEffect(() => {
    if (value && (type === 'country' || type === 'select')) {
      handleOnEndEditing()
    }
  }, [value])

  const handleOnEndEditing = (e, handleUpdateOnEnd = false) => {
    const _value = (handleUpdateOnEnd && e) || value
    const errors = []
    let amount
    const validators = (validation && validation.split(',')) || []
    validators.forEach(element => {
      switch (element) {
        case 'email':
          if (!validator.isEmail(_value || '')) {
            errors.push('Email is invalid')
          }
          break
        case 'phone':
          if (!validator.isMobilePhone(_value || '')) {
            errors.push('Mobile number is invalid')
          }
          break
        case 'min4':
        case 'min6':
        case 'min8':
        case 'min12':
          amount = parseInt(element.replace('min', ''), 10)
          if (!_value || _value.length < amount) {
            errors.push(`Min ${amount} chars.`)
          }
          break
        case 'required':
          if (!_value || !_value.length) {
            errors.push('Required')
          }
          break
      }
    })
    setError(errors)

    if (_.isEmpty(errors)) {
      if (_.isFunction(onComplete)) {
        onComplete(_value)
      }
    }
  }

  const handleChange = async (e) => {
    setValue(e)
    if (onChange) {
      onChange(e)
    }
  }

  const renderType = () => {
    switch (type) {
      case 'mobile':
        return (
          <PhoneInput
            theme={theme}
            value={inputProps.defaultValue}
            onEndEditing={(e) => handleOnEndEditing(e, true)}
          />
        )
      case 'country':
        return (
          <CountryRegionInput
            theme={theme}
            value={inputProps.defaultValue}
            onChange={e => handleChange(e.id, true)}
            onChangeInternalData={e =>
              onChangeInternalData && onChangeInternalData(e)}
          />
        )
      case 'select':
        return (
          <SelectInput
            hasSearch={hasSearch}
            value={inputProps.defaultValue}
            onChange={handleChange}
            items={inputProps.items}
            theme={theme}
          />
        )
      default:
        return (
          <TextInput
            style={{
              ...styles.inputDefault,
              backgroundColor: theme.pageBackground,
              color: theme.contentText,
              ...inputStyle
            }}
            placeholderTextColor={`${theme.contentText}80`}
            onChangeText={handleChange}
            onEndEditing={handleOnEndEditing}
            value={value}
            {...inputProps}
          />
        )
    }
  }

  return (
    <View style={{ marginBottom: 10, ...viewStyle }}>
      {!!label && (
        <Text
          bold
          fontSize={labelFontSize || 16}
          style={{ ...styles.labelDefault, ...labelStyle }}
        >
          {label}
        </Text>
      )}
      {renderType(type)}
      {_.isArray(error) && (
        <View style={{ position: 'absolute', top: scale(12), right: 0 }}>
          <Text fontSize={11} style={{ color: 'red' }}>
            {error.map(err => err)}
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    borderRadius: 5,
    paddingLeft: 10
  },
  labelDefault: {
    fontWeight: '600',
    marginVertical: 8
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(Input)
