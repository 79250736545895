import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import Image from '@components/DCImage'
import Text from '@components/Text'
import { width, spacing } from '@utils/scale'
import _ from 'lodash'
import InstaVideo from '@components/InstaVideo'

const InstaItemWithText = ({
  id,
  typography,
  colors,
  url = {},
  text,
  buttonText,
  mode,
  route,
  handle,
  navigation,
  transparentBackground
}) => {
  const {
    textBackgroundColor,
    textColor,
    buttonColor,
    buttonTextColor
  } = colors
  const { fontFamily, buttonFontFamily, capitalizeLetters, capitalizeButtonLetters } = typography
  const { url: uri, type } = url
  let extraStyles = {}
  switch (mode) {
    case 'top-left':
      extraStyles = {
        top: 0,
        alignItems: 'flex-start'
      }
      break
    case 'top-center':
      extraStyles = {
        top: 0,
        alignItems: 'center'
      }
      break
    case 'top-right':
      extraStyles = {
        top: 0,
        alignItems: 'flex-end'
      }
      break
    case 'left':
      extraStyles = {
        top: '50%',
        alignItems: 'flex-start',
        transform: [{ translateY: '-50%' }]
      }
      break
    case 'center':
      extraStyles = {
        top: '50%',
        alignItems: 'center',
        transform: [{ translateY: -100 }]
      }
      break
    case 'right':
      extraStyles = {
        top: '50%',
        alignItems: 'flex-end',
        transform: [{ translateY: '-50%' }]
      }
      break
    case 'bottom-left':
      extraStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 10,
        alignItems: 'flex-start'
      }
      break
    case 'bottom-center':
      extraStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 10,
        alignItems: 'center'
      }
      break
    case 'bottom-right':
      extraStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 10,
        alignItems: 'flex-end'
      }
      break
    default:
      break
  }

  const _text = (text && text.split('\n')) || []

  const handleNavigation = () => {
    if (route) {
      navigation.navigate(route, { handle })
    }
  }

  return (
    <View nativeID={id} style={{ position: 'relative', width, height: width, ...spacing }}>
      {type === 'image' ? (
        <Image style={{ width, height: width, resizeMode: 'cover' }} source={{ uri: uri }} />
      ) : (
        <InstaVideo url={uri} />
      )}
      <View
        style={{
          position: 'absolute',
          padding: 10,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <View style={{ ...extraStyles }}>
          {_text.map((txt, i) => {
            return (
              <Text
                style={{ margin: 5, width: 'auto', padding: 5, backgroundColor: transparentBackground ? 'transparent' : textBackgroundColor }}
                key={`${txt}-{i}`}
                fontSize={28}
                type='custom'
                color={textColor}
                capitalizeLetters={capitalizeLetters}
                customType={fontFamily}
              >
                {txt.trim()}
              </Text>
            )
          })}
          {route && !_.isEmpty(buttonText) && (
            <TouchableOpacity
              onPress={handleNavigation}
              style={{ margin: 5, padding: 10, backgroundColor: buttonColor }}
            >
              <Text
                type='custom'
                bold
                customType={buttonFontFamily}
                fontSize={20}
                color={buttonTextColor}
                capitalizeLetters={capitalizeButtonLetters}
              >
                {buttonText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

const mapStateToProps = state => {
  return {
    colors: _.get(state, 'config.theme.colors.instaPostWithTextOverlay', {}),
    typography: _.get(state, 'config.theme.typography.instaPostWithTextOverlay', {})
  }
}

export default connect(mapStateToProps)(InstaItemWithText)
