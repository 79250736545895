import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Animated } from 'react-native'
import { WebView } from 'react-native-webview'
import { width } from '@utils/scale'
import _ from 'lodash'
import WebviewSkeleton from '@components/Skeletons/WebviewSkeleton'
import Analytics from '@components/Analytics'
import ModalContainer from '@containers/ModalContainer'

const Webview = ({ theme, navigation, route }) => {
  const dispatch = useDispatch()
  const opacityValue = new Animated.Value(0)
  const [loading, setLoading] = useState(true)
  const [hasCompletedCheckout, setHasCompletedCheckout] = useState(false)

  const checkoutUrl = route.params?.url ?? 'defaultValue'
  const handleCheckouts = route.params?.handleCheckouts ?? false

  useEffect(() => {
    if (!loading) {
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true
      }).start()
    }
  }, [loading])

  const handleOnLoad = (e) => {
    setLoading(false)
  }

  const handleBack = () => {
    if (handleCheckouts && hasCompletedCheckout) {
      dispatch({
        type: 'RESET_CART'
      })
    }
    navigation.goBack()
  }

  const handleUrlChange = async e => {
    // TODO: dont show close button on the processing page
    const { url } = e
    if (url && url.indexOf('thank_you') !== -1) {
      setHasCompletedCheckout(true)
    }
  }

  return (
    <ModalContainer theme={theme} handleBack={handleBack}>
      {hasCompletedCheckout && handleCheckouts && <Analytics />}
      {loading && <WebviewSkeleton backgroundColor={theme.pageBackground} />}
      <Animated.View style={{ opacity: opacityValue }}>
        <WebView
          onNavigationStateChange={e => handleCheckouts && handleUrlChange(e)}
          onLoadEnd={handleOnLoad}
          style={{ flex: 1, width, height: '100%' }}
          source={{ uri: checkoutUrl }}
        />
      </Animated.View>
    </ModalContainer>
  )
}

Webview.navigationOptions = ({ navigation }) => {
  const handleCheckouts = _.get(navigation, 'state.params.handleCheckouts')
  return {
    gesturesEnabled: !handleCheckouts
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page'),
    checkout: _.get(state, 'cart.checkout', {})
  }
}

export default connect(mapStateToProps)(Webview)
