import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { FontAwesome } from '@expo/vector-icons'
import Text from '@components/Text'
import { scale } from '@utils/scale'
import _ from 'lodash'

const Stars = ({ rating = 5, count, theme }) => {
  const starArr = Array.from({ length: 5 }, (v, i) => i)

  return (
    <View style={{ flexDirection: 'row', marginBottom: 7, alignItems: 'center' }}>
      {
        starArr.map((i) => {
          const fixedNum = i + 1
          const mod = fixedNum % rating
          let status = (mod === fixedNum || mod === 0) ? 'star' : 'star-o'
          if (status === 'star-o' && mod < 1 && mod > 0) {
            status = 'star-half-full'
          }
          return <FontAwesome color={theme.stars} size={scale(13)} style={{ padding: 2 }} name={status} key={`st-${i}`} />
        })
      }
      {!!count && <Text fontSize={13} style={{ opacity: 0.4, marginLeft: 5 }}>{`(${count})`}</Text>}
    </View>
  )
}
// '#ffd200'
const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'config.theme.colors.reviews', { stars: '#ffd200' })
  }
}

export default connect(mapStateToProps)(Stars)
