import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { View, TextInput } from 'react-native'
import _ from 'lodash'
import ModalContainer from '@containers/ModalContainer'
import MainProductsSearch from '@containers/MainProductsSearch'
import Text from '@components/Text'
import { useDebounce } from '@utils'

const Search = ({ theme, navigation }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearch = useDebounce(searchTerm, 400)
  const scrollRef = useRef()

  const handleBack = () => {
    navigation.goBack()
  }

  return (
    <ModalContainer theme={theme} handleBack={handleBack} pageBack>
      <View style={{ padding: 20, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
        <View style={{ alignItems: 'center', marginBottom: 20 }}>
          <Text type='heading' bold fontSize={20}>Search</Text>
        </View>
        <TextInput
          autoFocus
          ref={scrollRef}
          onChangeText={setSearchTerm}
          value={searchTerm}
          placeholder='Search for a product'
          returnKeyType={searchTerm ? 'search' : 'done'}
          placeholderTextColor={`${theme.contentText}80`}
          style={{
            ...styles.inputDefault,
            backgroundColor: theme.contentBackground,
            color: theme.contentText
          }}
        />
      </View>
      {/* <View style={{ height: 50, marginBottom: 10 }}> // NICE TO HAVE
        <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ paddingLeft: 10, paddingRight: 20 }}>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, borderBottomColor: 'green', borderBottomWidth: 2, paddingBottom: 0, marginBottom: 10 }}>
            <Text bold>All</Text>
          </View>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 0, marginBottom: 10 }}>
            <Text>Crystals</Text>
          </View>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 0, marginBottom: 10 }}>
            <Text>Rollers</Text>
          </View>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 0, marginBottom: 10 }}>
            <Text>Beauty</Text>
          </View>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 0, marginBottom: 10 }}>
            <Text>Some stuff</Text>
          </View>
          <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 0, marginBottom: 10 }}>
            <Text>More stuff</Text>
          </View>
        </ScrollView>
      </View> */}
      {!searchTerm
        ? (
          <View style={{ padding: 30 }}>
            <Text>Enter a search term to get started</Text>
          </View>
        ) : (
          <MainProductsSearch
            onScroll={() => scrollRef && scrollRef.current && scrollRef.current.blur()}
            searchTerm={debouncedSearch}
            theme={theme}
            navigation={navigation}
          />
        )}
    </ModalContainer>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    borderRadius: 5,
    paddingLeft: 10
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(Search)
