import React from 'react'
import { View, TouchableOpacity, Platform } from 'react-native'
import Icon from '@components/Icon'
import { hasDefaultAspect } from '@utils/scale'

const ModalContainer = ({ children, theme, handleBack, pageBack, padTop, doubleTopPadding }) => {
  const values = {
    default: {
      normal: Platform.OS === 'android' ? '9%' : '7%',
      small: Platform.OS === 'android' ? '6%' : '2%'
    },
    padded: {
      normal: Platform.OS === 'android' ? '12%' : '9%',
      small: Platform.OS === 'android' ? '8%' : '4%'
    },
    doublePadded: {
      normal: Platform.OS === 'android' ? '14%' : '11%',
      small: Platform.OS === 'android' ? '10%' : '6%'
    }
  }
  const topPaddingGroup = { normal: values.default.normal, small: values.default.small }

  if (padTop) {
    topPaddingGroup.normal = values.padded.normal
    topPaddingGroup.small = values.padded.small
  }

  if (doubleTopPadding) {
    topPaddingGroup.normal = values.doublePadded.normal
    topPaddingGroup.small = values.doublePadded.small
  }

  const topPadding = hasDefaultAspect ? topPaddingGroup.normal : topPaddingGroup.small

  return (
    <View style={{ flex: 1, backgroundColor: 'transparent' }}>
      <View
        style={{
          position: 'absolute',
          top: topPadding,
          left: 0,
          right: 0,
          bottom: 0,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          shadowOffset: {
            width: 0,
            height: 0
          },
          shadowOpacity: 0.5,
          shadowRadius: 20,
          shadowColor: '#000000',
          elevation: 5
        }}
      >
        <View
          style={{
            backgroundColor: pageBack
              ? theme.pageBackground
              : theme.contentBackground,
            alignItems: 'center',
            flex: 1,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            overflow: 'hidden'
          }}
        >
          <TouchableOpacity
            style={{
              ...styles.closeButton,
              backgroundColor: theme.contentBackground
            }}
            onPress={handleBack}
          >
            <Icon name='x' size={14} />
          </TouchableOpacity>
          {children}
        </View>
      </View>
    </View>
  )
}

const styles = {
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 5,
    borderRadius: 15
  }
}

export default ModalContainer
