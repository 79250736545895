import _ from 'lodash'

export const initialState = {
  lineItems: [],
  checkout: {
    id: null,
    webUrl: null
  }
}

const cartReducer = (state = initialState, action) => {
  let checkout
  switch (action.type) {
    case 'SET_LOCAL_CHECKOUT':
    case 'UPSERT_CHECKOUT':
      checkout = _.get(action, 'data.checkout')
      return {
        ...state,
        lineItems: _.get(action, 'data.checkout.lineItems.edges', []),
        checkout
      }
    case 'LOGOUT':
    case 'PREVIEWR_RESET':
    case 'RESET_CART':
      return { ...initialState }
    default:
      return state
  }
}

export default cartReducer
