export const fontFamily = {
  default: 'GitReg',
  secondary: 'CormGLI'
}

export const fontSizes = {
  default: 14
}

export const colors = {
  borderColor: '#575757',
  backgroundColor: '#FFF',
  text: {
    default: '#575757',
    secondary: '#828695',
    light: '#AFB7C6',
    salePrice: '#D84948'
  },
  disabled: '#ccc',
  disabledText: '#b2b2b1',
  lineColor: '#D4D9E3',
  toast: {
    error: '#D0021B',
    warning: '#F5A623',
    info: '#4A90E2',
    success: '#7ED321'
  }
}

export const theme = {
  page: { flex: 1, backgroundColor: colors.backgroundColor },
  shadow: { shadowOffset: { width: 1, height: 1 }, shadowRadius: 1, shadowOpacity: 0.3, elevation: 3 },
  dropShadow: { shadowOffset: { width: 0, height: -1 }, shadowRadius: 3, shadowOpacity: 0.1, elevation: 3 }
}
