import React from 'react'
import { View } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'
import { colors } from '@utils/theme'

const ProductItemSaleBadge = ({ item: _item, fontSize }) => {
  const item = _.get(_item, 'variants.edges[0].node')
  if (item) {
    const comparePrice = _.get(item, 'compareAtPriceV2.amount', 0)
    const price = _.get(item, 'priceV2.amount', 0)
    if (parseFloat(comparePrice) > parseFloat(price)) {
      return (
        <View style={{ ...styles.holder, backgroundColor: colors.text.salePrice }}>
          <Text fontSize={fontSize || 12} color='white'>
            SALE
          </Text>
        </View>
      )
    }
  }
  return <View />
}

const styles = {
  holder: {
    position: 'absolute',
    top: 10,
    left: 8,
    backgroundColor: '#333',
    padding: 2,
    paddingHorizontal: 4
  }
}

export default ProductItemSaleBadge
