import React from 'react'
import { View, Text, Image } from 'react-native'
import expoJson from '../../../app.json'

const AppUnavailable = ({ colors = {}, appName = 'This app' }) => {
  return (
    <View
      style={{
        ...styles.mainScreen,
        backgroundColor: colors.contentBackground || '#ebebeb'
      }}
    >
      <Image style={styles.image} source={{ uri: expoJson?.expo?.icon }} />
      <Text
        style={{ ...styles.largeText, color: colors.contentText || '#333' }}
      >
        {appName} is currently unavailable.
      </Text>
      <Text style={{ ...styles.smallText, color: colors.accentText || '#333' }}>
        Sorry for the inconvenience, Please come back later.
      </Text>
    </View>
  )
}

const styles = {
  mainScreen: {
    padding: 20,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: { width: 140, height: 140, marginBottom: 60 },
  largeText: {
    fontSize: 28,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 10
  },
  smallText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 100
  }
}

export default AppUnavailable
