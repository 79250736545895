import React from 'react'
import { View } from 'react-native'
import Image from '@components/DCImage'
import TouchableExpand from '@components/TouchableExpand'
import { width, spacing } from '@utils/scale'
import InstaVideo from '@components/InstaVideo'

const InstaItemOnly = ({ id, url = {}, route, handle, navigation }) => {
  const { url: uri, type } = url

  const handleNavigation = () => {
    if (route) {
      navigation.navigate(route, { handle })
    }
  }

  return (
    <View nativeID={id}>
      <TouchableExpand
        onPress={handleNavigation}
        style={{ position: 'relative', width, height: width, ...spacing }}
      >
        {type === 'image' ? (
          <Image style={{ width, height: width, resizeMode: 'cover' }} source={{ uri: uri }} />
        ) : (
          <InstaVideo id={id} url={uri} />
        )}
      </TouchableExpand>
    </View>
  )
}

export default InstaItemOnly
