import { width as mainWidth } from '@utils/scale'
const width = Math.ceil(mainWidth)
export const orderResponse = `
  id
  customerUrl
  statusUrl
  currencyCode
  name
  orderNumber
  phone
  email
  processedAt
  totalRefundedV2 {
    amount
    currencyCode
  }
  totalPriceV2 {
    amount
    currencyCode
  }
`

export const checkoutResponse = `
  id
  webUrl
  email
  requiresShipping
  ready
  shippingAddress {
    firstName
    lastName
    address1
    address2
    province
    city
    country
    countryCodeV2
    zip
  }
  shippingLine {
    handle
    priceV2 {
      amount
    }
    title
  }
  subtotalPriceV2 {
   amount
   currencyCode
  }
  totalPriceV2 {
   amount
   currencyCode
  }
  totalTaxV2 {
   amount
   currencyCode
  }
  order {
    ${orderResponse}
  }
  orderStatusUrl
  lineItems(first: 15) {
    edges {
      node {
        id
        title
        quantity
        variant {
         id
         title
         sku
         availableForSale
         product {
          handle
         }
         image {
           id
           transformedSrc(maxWidth: ${width})
         }
         compareAtPriceV2 {
           amount
           currencyCode
         }
         priceV2 {
           amount
           currencyCode
         }
        }
      }
    }
  }
`

export const collectionResponse = `
  id
  handle
  description
  image {
    transformedSrc(maxWidth: ${width})
  }
  title
  products(first: 1) {
    edges {
      node {
        id
        images(first: 1) {
          edges {
            node {
              id
              transformedSrc(maxWidth: ${width})
            }
          }
        }
      }
    }
  }
`

export const productResponse = `
  id
  handle
  title
  description
  onlineStoreUrl
  images(first: 1) {
    edges {
      node {
        id
        transformedSrc(maxWidth: ${width})
      }
    }
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  metafields(first: 10, namespace: "stamped") {
    edges {
      node {
        id
        key
        namespace
        value
      }
    }
  }
  variants(first: 1) {
    edges {
      node {
        id
        title
        availableForSale
        sku
        image {
          id
          transformedSrc(maxWidth: ${width})
        }
        compareAtPriceV2 {
          amount
          currencyCode
        }
        priceV2 {
          amount
          currencyCode
        }
      }
    }
  }
`

export const fullProductResponse = `
id
title
description
descriptionHtml
onlineStoreUrl
images(first: 10) {
  edges {
    node {
      transformedSrc(maxWidth: ${width})
    }
  }
}
metafields(first: 10, namespace: "stamped") {
  edges {
    node {
      id
      description
      key
      namespace
      value
    }
  }
}
options(first: 100) {
  id
  name
  values
}
priceRange {
  maxVariantPrice {
    amount
    currencyCode
  }
  minVariantPrice {
    amount
    currencyCode
  }
}
variants(first: 100) {
  edges {
    node {
      id
      title
      sku
      weight
      weightUnit
      availableForSale
      selectedOptions {
        name
        value
      }
      compareAtPriceV2 {
        amount
        currencyCode
      }
      priceV2 {
        amount
        currencyCode
      }
      image {
        id
        transformedSrc(maxWidth: ${width})
      }
    }
  }
}`

export const generateGeneralProductResponse = (meta = 'stamped') => {
  return `
  id
  handle
  title
  description
  onlineStoreUrl
  images(first: 1) {
    edges {
      node {
        id
        transformedSrc(maxWidth: ${width})
      }
    }
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  metafields(first: 10, namespace: "${meta}") {
    edges {
      node {
        id
        key
        namespace
        value
      }
    }
  }
  variants(first: 1) {
    edges {
      node {
        id
        title
        availableForSale
        sku
        image {
          id
          transformedSrc(maxWidth: ${width})
        }
        compareAtPriceV2 {
          amount
          currencyCode
        }
        priceV2 {
          amount
          currencyCode
        }
      }
    }
  }
`
}

export const generateFullProductResponse = (meta = 'stamped') => {
  return `
  id
  title
  description
  descriptionHtml
  onlineStoreUrl
  images(first: 10) {
    edges {
      node {
        transformedSrc(maxWidth: ${width})
      }
    }
  }
  metafields(first: 10, namespace: "${meta}") {
    edges {
      node {
        id
        description
        key
        namespace
        value
      }
    }
  }
  options(first: 100) {
    id
    name
    values
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  variants(first: 100) {
    edges {
      node {
        id
        title
        sku
        weight
        weightUnit
        availableForSale
        selectedOptions {
          name
          value
        }
        compareAtPriceV2 {
          amount
          currencyCode
        }
        priceV2 {
          amount
          currencyCode
        }
        image {
          id
          transformedSrc(maxWidth: ${width})
        }
      }
    }
  }`
}
