import React from 'react'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'
import * as Screens from '@screens'
import HeaderBar from '@components/HeaderBar'

const Stack = createStackNavigator()

const BlogStack = () => {
  return (
    <Stack.Navigator
      initialRouteName='Blog'
      screenOptions={{
        header: ({ navigation }) => <HeaderBar navigation={navigation} />,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
      }}
    >
      <Stack.Screen
        name='Blog'
        component={Screens.Blog}
      />
    </Stack.Navigator>
  )
}

export default BlogStack
