import React, { useState } from 'react'
import { connect } from 'react-redux'
import { View, TouchableOpacity, Image, Platform, ActivityIndicator } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'
import * as SecureStore from 'expo-secure-store'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import * as Cellular from 'expo-cellular'
import ModalContainer from '@containers/ModalContainer'
import AppConfig from '@appConfig'
import moment from 'moment'
import expoJson from '../../../app.json'

const NotificationsScreen = ({ theme, navigation, store }) => {
  const [loading, setLoading] = useState(false)

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      try {
        const { status: existingStatus } = await Notifications.getPermissionsAsync()
        let finalStatus = existingStatus

        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync()
          finalStatus = status
        }
        if (finalStatus !== 'granted') {
          return
        }
        const token = (await Notifications.getExpoPushTokenAsync()).data
        const country = Cellular.isoCountryCode

        try {
          const body = {
            device: {
              token,
              store,
              country,
              device: Platform.OS === 'android' ? 'Android' : 'iOS'
            }
          }

          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': AppConfig.TOKEN
            },
            body: JSON.stringify(body)
          }
          await global.fetch(
            `${AppConfig.DEVICE_ROOT}/create-device`,
            requestOptions
          )
        } catch (e) {
          console.log('ERROR CREATING DEVICE', e)
        }
        await SecureStore.setItemAsync('hasAllowedNotifications', 'true')
      } catch (e) {
        console.log('Failed to register device', e)
        // alert('Failed to register notifications')
      }
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C'
      })
    }
  }

  const handleAllowPush = async () => {
    setLoading(true)
    await registerForPushNotificationsAsync()
    setLoading(false)
    handleBack()
  }

  const handleBack = async () => {
    const hasAllowedNotifications = await SecureStore.getItemAsync(
      'hasAllowedNotifications'
    )
    if (!hasAllowedNotifications || hasAllowedNotifications !== 'true') {
      await SecureStore.setItemAsync(
        'hasAllowedNotifications',
        _.toString(
          moment()
            .add(7, 'days')
            .format('X')
        )
      )
    }
    navigation.goBack()
  }

  return (
    <ModalContainer theme={theme} handleBack={handleBack}>
      <View
        style={{
          justifyContent: 'space-around',
          alignItems: 'center',
          flex: 1
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <View>
            <View style={{ borderRadius: 10, overflow: 'hidden' }}>
              <Image style={{ width: 110, height: 110 }} source={{ uri: expoJson?.expo?.icon }} />
            </View>
            <View
              style={{
                backgroundColor: 'red',
                position: 'absolute',
                width: 30,
                height: 30,
                borderRadius: 15,
                alignItems: 'center',
                justifyContent: 'center',
                right: -10,
                top: -10
              }}
            >
              <Text bold style={{ color: 'white' }} fontSize={16}>
                1
              </Text>
            </View>
          </View>

          <Text style={{ marginTop: 30 }} bold fontSize={34}>
            Welcome
          </Text>
          <Text
            secondary
            fontSize={14}
            style={{ padding: 20, fontWeight: '300', textAlign: 'center' }}
          >
            To have the best experience and keep up to date with news, specials
            and promotions, enable push notifications.
          </Text>
        </View>
        <View>
          <TouchableOpacity
            disabled={loading}
            onPress={handleAllowPush}
            style={{
              backgroundColor: theme.buttonColor,
              padding: 15,
              paddingHorizontal: 40
            }}
          >
            {
              loading ? <ActivityIndicator color={theme.buttonTextColor} />
                : (
                  <Text fontSize={14} type='button' bold>
                ENABLE PUSH NOTIFICATIONS
                  </Text>
                )
            }
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleBack}
            style={{
              backgroundColor: 'transparent',
              padding: 15,
              marginTop: 10,
              paddingHorizontal: 40
            }}
          >
            <Text fontSize={14} bold style={{ textAlign: 'center' }}>
              No, Thanks
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ModalContainer>
  )
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page'),
    store: _.get(state, 'config._id')
  }
}

export default connect(mapStateToProps)(NotificationsScreen)
