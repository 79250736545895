import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import PopOverMenu from '@components/PopOverMenu'
import Text from '@components/Text'
import _ from 'lodash'
import data from './countries'
import flags from '@utils/flags'
import { useApolloClient } from 'react-apollo'
import * as gql from '@gql/Queries'

const CountryRegionInput = ({
  theme,
  onChange,
  onChangeInternalData,
  value
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [countries, setCountries] = useState(
    data.map(item => {
      return { name: item.name, id: item.code }
    })
  )
  const [countryValue, setCountryValue] = useState(
    data.find(i => (i.code === value || i.name === value)) || {}
  )
  const [flag, setFlag] = useState(
    flags.get(_.toLower(countryValue.code))
  )
  const client = useApolloClient()

  useEffect(() => {
    const getData = async () => {
      const res = await client.query({
        query: gql.getShippingCountries
      })
      const allowedCountries = _.get(res, 'data.shop.shipsToCountries')
      if (!_.isEmpty(allowedCountries)) {
        const filteredCountries = countries.filter((country) => allowedCountries.indexOf(country.id) !== -1)
        if (!_.isEmpty(filteredCountries) && filteredCountries.length !== countries.length) {
          setCountries(filteredCountries)
        }
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const countryData = data.find(i => (i.code === value || i.name === value)) || {}

    setCountryValue(countryData)
    if (onChangeInternalData && typeof onChangeInternalData === 'function') {
      const regions = _.get(countryData, 'provinces', []).map((region) => {
        return {
          id: region.code,
          name: region.name
        }
      })
      onChangeInternalData(regions)
    }
  }, [value])

  useEffect(() => {
    setFlag(flags.get(_.toLower(countryValue.code)))
  }, [JSON.stringify(countryValue)])

  const handleSelect = selection => {
    if (selection.id === value) return
    const result = data.find(i => i.name === selection.id)
    if (onChange && typeof onChange === 'function') {
      onChange(selection)
    }

    if (onChangeInternalData && typeof onChangeInternalData === 'function') {
      onChangeInternalData(result)
    }
  }

  return (
    <View>
      <TouchableOpacity
        style={{
          ...styles.inputDefault,
          backgroundColor: theme.pageBackground
        }}
        onPress={() => setIsMenuOpen(!isMenuOpen)}
      >
        <View
          style={{
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Image
            source={flag}
            style={{ width: 30, height: 20, resizeMode: 'stretch' }}
          />
        </View>
        <Text style={{ marginLeft: 10 }}>{countryValue.name}</Text>
      </TouchableOpacity>
      <PopOverMenu
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        theme={theme}
        hasSearch
        onSelect={handleSelect}
        options={countries}
        opened={isMenuOpen}
      />
    </View>
  )
}

const styles = {
  inputDefault: {
    height: 46,
    width: '100%',
    backgroundColor: '#f6f6f6',
    borderRadius: 5,
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelDefault: {
    fontWeight: '600',
    marginVertical: 8
  }
}

export default CountryRegionInput
