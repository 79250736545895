import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import MainCollections from '@containers/MainCollections'
import HeaderBar from '@components/HeaderBar'
import _ from 'lodash'
const CollectionsScreen = ({ theme, config, navigation }) => {
  return (
    <View style={{ backgroundColor: theme.pageBackground, flex: 1, overflowY: 'scroll' }}>
      {/* <Image source={require('../../../assets/MysticCategory-1.png')} style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, opacity: 0.5, width: '100%', height: '100%' }} /> */}
      <MainCollections
        theme={theme}
        hideHeader
        navigation={navigation}
        imageAlignment={config.imageAlignment}
        dynamicHeight={config.dynamicHeight}
        mode={config.mode}
      />
    </View>
  )
}

CollectionsScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page', { pageBackground: '#fff' }),
    config: _.get(state, 'config.theme.collectionsListPage', {})
  }
}

export default connect(mapStateToProps)(CollectionsScreen)
