import React, { useState } from 'react'
import { View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { scale } from '@utils/scale'
import TouchableExpand from '@components/TouchableExpand'
import Text from '@components/Text'
import _ from 'lodash'

const StarInput = ({ theme = {}, onChange, value = 4 }) => {
  const [starValue, setStarValue] = useState(value)
  const starArr = Array.from({ length: 5 }, (v, i) => i)
  const handleStarSelect = (val) => {
    setStarValue(val)
    if (_.isFunction(onChange)) {
      onChange(val)
    }
  }

  return (
    <View style={{ marginTop: 20, marginBottom: 10 }}>
      <Text fontSize={13} bold>Rating</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
        {starArr.map((item) => {
          const val = item + 1
          const status = val <= starValue ? 'star' : 'star-o'
          return (
            <TouchableExpand onPress={() => handleStarSelect(val)} key={`star-${item}`} style={{ padding: 5 }}>
              <FontAwesome color={theme.stars} size={scale(24)} style={{ padding: 2 }} name={status} key={`st-${item}`} />
            </TouchableExpand>
          )
        })}
      </View>
    </View>
  )
}

export default StarInput
