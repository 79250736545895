import React from 'react'
import { FlatList } from 'react-native'
import _ from 'lodash'
import MainCollectionItem from '@components/MainCollectionItem'
import SplitCollectionItem from '@components/MainCollectionItem/SplitCollectionItem'
import RadialCollectionItem from '@components/MainCollectionItem/RadialCollectionItem'
import LetterBoxCollectionItem from '@components/MainCollectionItem/LetterBoxCollection'
import { width } from '@utils/scale'

class MainCollectionsList extends React.Component {
  shouldComponentUpdate (newProps) {
    const tempNewItems = newProps.items && newProps.items.map((newItem) => newItem.node)
    const tempOldItems = this.props.items && this.props.items.map((oldItem) => oldItem.node)
    return (
      !_.isEqual(tempNewItems, tempOldItems) ||
      !_.isEqual(newProps.mode, this.props.mode) ||
      !_.isEqual(newProps.horizontal, this.props.horizontal) ||
      !_.isEqual(newProps.imageAlignment, this.props.imageAlignment) ||
      !_.isEqual(newProps.dynamicHeight, this.props.dynamicHeight) ||
      !_.isEqual(newProps.theme, this.props.theme)
    )
  }

  handleOnPress = (handle, node) => {
    const { onCollectionItemPressed } = this.props
    if (handle && _.isFunction(onCollectionItemPressed)) {
      onCollectionItemPressed(handle, node)
    }
  }

  handleOnEndReached = () => {
    const { loadMore } = this.props
    if (_.isFunction(loadMore)) {
      loadMore()
    }
  }

  renderCollectionItem = ({ item }) => {
    const { horizontal, theme, imageAlignment, dynamicHeight } = this.props
    const { node } = item
    return (
      <MainCollectionItem
        imageAlignment={imageAlignment}
        dynamicHeight={dynamicHeight}
        theme={theme}
        horizontal={horizontal}
        item={node}
        itemPressed={e => this.handleOnPress(e, node)}
      />
    )
  }

  renderRadialCollectionItem = ({ item }) => {
    const { horizontal, theme } = this.props
    const { node } = item
    return (
      <RadialCollectionItem
        theme={theme}
        horizontal={horizontal}
        item={node}
        itemPressed={e => this.handleOnPress(e, node)}
      />
    )
  }

  renderSplitCollectionItem = ({ item }) => {
    const { horizontal, theme } = this.props
    const { node } = item
    return (
      <SplitCollectionItem
        theme={theme}
        horizontal={horizontal}
        item={node}
        itemPressed={e => this.handleOnPress(e, node)}
      />
    )
  }

  renderLetterBoxCollectionItem = ({ item }) => {
    const { horizontal, theme, imageAlignment, dynamicHeight } = this.props
    const { node } = item
    return (
      <LetterBoxCollectionItem
        imageAlignment={imageAlignment}
        dynamicHeight={dynamicHeight}
        theme={theme}
        horizontal={horizontal}
        item={node}
        itemPressed={e => this.handleOnPress(e, node)}
      />
    )
  }

  render () {
    const {
      items,
      horizontal,
      mode,
      listHeaderComponent,
      onCollectionItemPressed,
      loadMore,
      ...extraProps
    } = this.props
    let renderMethod
    let customProps = {}
    switch (mode) {
      case 'radial':
        customProps = {
          contentContainerStyle: { paddingLeft: 10 },
          horizontal: true,
          pagingEnabled: true,
          scrollEnabled: true,
          style: { width: width }
        }
        renderMethod = this.renderRadialCollectionItem
        break
      case 'split':
        customProps = horizontal ? {} : { numColumns: 2 }
        renderMethod = this.renderSplitCollectionItem
        break
      case 'letterbox':
        renderMethod = this.renderLetterBoxCollectionItem
        break
      default:
        renderMethod = this.renderCollectionItem
        break
    }

    return (
      <FlatList
        key={horizontal ? `${mode}-hoz` : `${mode}-vert`}
        style={{ width }}
        ListHeaderComponent={listHeaderComponent}
        extraData={extraProps}
        horizontal={horizontal}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={horizontal}
        data={items}
        renderItem={renderMethod}
        keyExtractor={item => `${horizontal ? 'hoz' : 'vert'}-${_.get(item, 'node.id')}`}
        onEndReached={this.handleOnEndReached}
        onEndReachedThreshold={0.5}
        pagingEnabled={horizontal}
        {...customProps}
      />
    )
  }
}

export default MainCollectionsList
