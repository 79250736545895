import React from 'react'
import SkeletonBase from './SkeletonBase'

const xml = `
<svg width="1204px" height="2460px" viewBox="0 0 1204 2460" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M0,0 L1204,0 L1204,593 L0,593 L0,0 Z M0,858 L1204,858 L1204,1451 L0,1451 L0,858 Z M0,1716 L1204,1716 L1204,2309 L0,2309 L0,1716 Z M62,613 L557,613 L557,678 L62,678 L62,613 Z M62,1471 L557,1471 L557,1536 L62,1536 L62,1471 Z M62,2329 L557,2329 L557,2394 L62,2394 L62,2329 Z M62,698 L488,698 L488,744 L62,744 L62,698 Z M62,1556 L488,1556 L488,1602 L62,1602 L62,1556 Z M62,2414 L488,2414 L488,2460 L62,2460 L62,2414 Z" id="Combined-Shape"></path>
</svg>
`

const CollectionsSkeleton = ({ theme }) => {
  return (
    <SkeletonBase backgroundColor={theme.pageBackground} xml={xml} />
  )
}

export default CollectionsSkeleton
