import React, { useEffect } from 'react'
import { View, ScrollView, TouchableOpacity } from 'react-native'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import HeaderBar from '@components/HeaderBar'
import Text from '@components/Text'
import Icon from '@components/Icon'
import _ from 'lodash'

import CheckoutButton from '@components/CheckoutButton'
import CartItem from '@components/CartItem'
import * as gql from '@gql/Queries'
import { checkoutEmailUpdate } from '@gql/Mutations'

const CartScreen = ({
  checkout,
  client,
  dispatch,
  lineItems,
  navigation,
  theme,
  user,
  hideProductSku = false
}) => {
  useEffect(() => {
    const update = async () => {
      if (checkout && checkout.id) {
        const response = await client.query({
          query: gql.getCheckoutById,
          variables: { id: checkout.id }
        })

        if (_.get(response, 'data.node')) {
          dispatch({
            type: 'SET_LOCAL_CHECKOUT',
            data: {
              checkout: _.get(response, 'data.node')
            }
          })
          // Add users email to checkout
          if (!_.get(response, 'data.node.email') && _.get(user, 'email')) {
            await client.mutate({
              mutation: checkoutEmailUpdate,
              variables: { checkoutId: checkout.id, email: user.email }
            })
          }
        }
      }
    }

    update()
  }, [])

  const removeItemFromCart = async (item, mutation) => {
    const updatedLineItems = lineItems
      .map(lineItem => {
        const variantId = _.get(lineItem, 'node.variant.id')
        const quantity = _.get(lineItem, 'node.quantity')
        return {
          variantId,
          quantity
        }
      })
      .filter(filteredItem => {
        const id = _.get(item, 'node.variant.id')
        return filteredItem.variantId !== id
      })

    const response = await mutation({
      variables: { checkoutId: checkout.id, lineItems: updatedLineItems }
    })
    if (response.data) {
      dispatch({
        type: 'UPSERT_CHECKOUT',
        data: {
          checkout: _.get(response, 'data.checkoutLineItemsReplace.checkout')
        }
      })
    }
  }

  const updateQuantity = async (item, mutation, newQuantity) => {
    const updatedLineItems = lineItems.map(lineItem => {
      const variantId = _.get(lineItem, 'node.variant.id')
      let quantity = _.get(lineItem, 'node.quantity')
      const shouldUpdate = _.get(item, 'node.variant.id') === variantId
      if (shouldUpdate) {
        quantity = newQuantity || 1
      }
      return {
        variantId,
        quantity
      }
    })

    const response = await client.mutate({
      mutation,
      variables: { checkoutId: checkout.id, lineItems: updatedLineItems }
    })

    if (response.data) {
      dispatch({
        type: 'UPSERT_CHECKOUT',
        data: {
          checkout: _.get(response, 'data.checkoutLineItemsReplace.checkout')
        }
      })
    }
  }

  const renderEmpty = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          paddingTop: 80
        }}
      >
        <Icon type='light' name='shopping-cart' size={40} />
        <Text
          secondary
          fontSize={14}
          style={{ padding: 20, fontWeight: '300' }}
        >
          You have no items in your cart!
        </Text>
        <TouchableOpacity
          onPress={() => navigation.navigate('Shop')}
          style={{
            backgroundColor: theme.buttonColor,
            padding: 15,
            paddingHorizontal: 40
          }}
        >
          <Text fontSize={14} type='button' bold>
            GET SHOPPING
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <>
      <ScrollView
        automaticallyAdjustContentInsets
        contentContainerStyle={{
          padding: 10,
          paddingBottom: 80,
          paddingTop: 30
        }}
        style={{ flex: 1, backgroundColor: theme.pageBackground }}
      >
        {checkout && checkout.id && (
          <View>
            {lineItems.map(item => {
              return (
                <CartItem
                  navigation={navigation}
                  theme={theme}
                  key={item.node.id}
                  item={item}
                  updateQuantity={updateQuantity}
                  removeItemFromCart={removeItemFromCart}
                  hideProductSku={hideProductSku}
                />
              )
            })}
          </View>
        )}
        {_.isEmpty(lineItems) && renderEmpty()}
      </ScrollView>
      {!_.isEmpty(lineItems) && (
        <CheckoutButton
          lineItems={lineItems}
          checkout={checkout}
          theme={theme}
          onPress={() => navigation.navigate('CheckoutReview')}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  const lineItems = _.get(state, 'cart.checkout.lineItems.edges', [])
  return {
    user: state.user.customer,
    cart: state.cart,
    checkout: state.cart.checkout,
    theme: _.get(state, 'config.theme.colors.page'),
    hideProductSku: _.get(state, 'config.theme.productPage.hideProductSku', false),
    lineItems
  }
}

CartScreen.navigationOptions = ({ navigation }) => {
  return {
    header: () => <HeaderBar navigation={navigation} />
  }
}

export default connect(mapStateToProps)(withApollo(CartScreen))
