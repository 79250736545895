import React, { useState, useEffect } from 'react'
import { Image, Platform, StatusBar, Text } from 'react-native'
import AppLoading from 'expo-app-loading'
import { createStore } from 'redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import 'react-native-gesture-handler'
import reducers from '@reducers'
import UserStore from '@gql/Apollo'
import { MenuProvider } from 'react-native-popup-menu'
import Toaster from '@components/Toaster'
import * as Font from 'expo-font'
import _ from 'lodash'
import { NavigationContainer } from '@react-navigation/native'
import { navigationRef } from '@navigation/NavigationService'

import AppConfig from '@appConfig'

import { composeWithDevTools } from 'redux-devtools-extension'
import MainRouter from './app/navigation'
import AppUnavailable from '@components/AppUnavailable'

import Analytics from '@components/Analytics'

export const store = createStore(reducers, {}, composeWithDevTools())
const persistor = persistStore(store)
const App = () => {
  const [error, setError] = useState(false)
  const [client, setClient] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const handleInitialLoad = async () => {
      try {
        await Font.loadAsync({
          'oswald-regular': require('./assets/fonts/Oswald-Regular.ttf'),
          'oswald-bold': require('./assets/fonts/Oswald-Bold.ttf'),
          'opensans-regular': require('./assets/fonts/OpenSans-Regular.ttf'),
          'opensans-bold': require('./assets/fonts/OpenSans-Bold.ttf'),
          'antonio-regular': require('./assets/fonts/Antonio-Regular.ttf'),
          'antonio-bold': require('./assets/fonts/Antonio-Bold.ttf'),
          'roboto-regular': require('./assets/fonts/Roboto-Regular.ttf'),
          'roboto-bold': require('./assets/fonts/Roboto-Bold.ttf'),
          'lato-regular': require('./assets/fonts/Lato-Regular.ttf'),
          'lato-bold': require('./assets/fonts/Lato-Bold.ttf'),
          'quicksand-regular': require('./assets/fonts/Quicksand-Regular.otf'),
          'quicksand-bold': require('./assets/fonts/Quicksand-Bold.otf'),
          'arvo-regular': require('./assets/fonts/Arvo-Regular.ttf'),
          'arvo-bold': require('./assets/fonts/Arvo-Bold.ttf'),
          'crimson-regular': require('./assets/fonts/CrimsonText-Regular.ttf'),
          'crimson-bold': require('./assets/fonts/CrimsonText-Bold.ttf'),
          'courierprime-regular': require('./assets/fonts/CourierPrime-Regular.ttf'),
          'courierprime-bold': require('./assets/fonts/CourierPrime-Bold.ttf'),
          'inriaserif-regular': require('./assets/fonts/InriaSerif-Regular.ttf'),
          'inriaserif-bold': require('./assets/fonts/InriaSerif-Bold.ttf'),
          'playfair-display-regular': require('./assets/fonts/PlayfairDisplay-VariableFont_wght.ttf'),
          'playfair-display-bold': require('./assets/fonts/PlayfairDisplay-VariableFont_wght.ttf'),
          'poppins-regular': require('./assets/fonts/Poppins-Regular.ttf'),
          'poppins-bold': require('./assets/fonts/Poppins-Bold.ttf')
        })
        const res = await global.fetch(
          `${AppConfig.API_ROOT}/store?id=${AppConfig.APP}`
        )

        const { store: shop } = await res.json()
        if (!_.isEmpty(shop) && shop.storeToken && shop.url) {
          store.dispatch({
            type: 'SET_CONFIG',
            data: { ...shop }
          })
          if (_.get(shop, 'theme.header.image')) {
            await Image.prefetch(_.get(shop, 'theme.header.image'))
          }
          const client = UserStore.setCredentials(shop.url, shop.storeToken)
          setClient(client)
          setLoading(false)
        } else {
          setError(true)
        }
      } catch (e) {
        setError(true)
      }
    }
    handleInitialLoad()
  }, [])

  if (_.isEmpty(client) || loading) {
    return <AppLoading />
  }

  if (error) {
    return <Text>Error</Text>
  }

  const {
    config: { theme, active, appName }
  } = store.getState()
  // TODO: Handle the case something breaks here
  const tabLayout = _.get(theme, 'tabs.layout', [])
  const tabs = _.get(theme, 'tabs')
  if (!active) {
    const colors = _.get(theme, 'colors.page', {})
    return (
      <AppUnavailable colors={colors} appName={appName} />
    )
  }
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MenuProvider
            customStyles={{ backdrop: { backgroundColor: '#333', opacity: 0.3 } }}
          >
            <>
              {/* TODO: MAKE BAR COLOR DYNAMIC */}
              {Platform.OS !== 'android' && <StatusBar barStyle='light-content' />}
              <Analytics data={{ category: 'AppOpen', action: 'AppOpen' }} />
              <NavigationContainer ref={navigationRef}>
                <MainRouter tabs={tabLayout} config={tabs} />
              </NavigationContainer>
              <Toaster />
            </>
          </MenuProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

export default App
