import React, { useRef, useEffect, useState } from 'react'
import { Animated, View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import Icon from '@components/Icon'
import * as Animatable from 'react-native-animatable'
import _ from 'lodash'
import LoginForm from '@components/LoginForm'
import SignupForm from '@components/SignupForm'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { height } from '@utils/scale'
import { login, signup } from '@gql/Mutations'
import { useApolloClient } from 'react-apollo'

const LoginSignUp = ({ navigation, dispatch, theme }) => {
  const client = useApolloClient()
  const fadeAnim = useRef(new Animated.Value(0)).current
  const loginView = useRef()
  const signupView = useRef()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [mode, setMode] = useState('login')
  const [loading, setLoading] = useState(false)

  const toggleMode = mode => {
    if (mode === 'login') {
      signupView.current.fadeOutLeft(200).then(() => {
        setMode('login')
        setEmail()
        setPassword()
        setConfirmPassword()
        loginView.current.fadeInRight(200)
      })
    } else {
      loginView.current.fadeOutRight(200).then(() => {
        setMode('signup')
        setEmail()
        setPassword()
        setConfirmPassword()
        signupView.current.fadeInLeft(200)
      })
    }
  }

  useEffect(() => {
    Animated.timing(fadeAnim, {
      delay: 300,
      toValue: 0.5,
      duration: 500,
      useNativeDriver: true
    }).start()
  }, [])

  const handleDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true
    }).start()
    setTimeout(() => navigation.goBack(), 100)
  }

  const handleLogin = async () => {
    try {
      const response = await client.mutate({
        mutation: login,
        variables: {
          input: {
            email,
            password
          }
        }
      })
      const errors = _.get(
        response,
        'data.customerAccessTokenCreate.userErrors'
      )
      const token = _.get(
        response,
        'data.customerAccessTokenCreate.customerAccessToken'
      )
      if (_.isEmpty(errors)) {
        if (token) {
          dispatch({
            type: 'SET_ACCESS_TOKEN',
            data: {
              token
            }
          })
          handleDismiss()
        }
      } else {
        const msg = errors.reduce((nxt, err) => {
          if (err.message === 'Unidentified customer') {
            err.message =
              'User/Password not found. Please try again or sign up.'
          }
          return err.message + ' ' + nxt
        }, '')
        dispatch({
          type: 'ADD_TOAST',
          data: {
            message: {
              message: msg,
              type: 'Error'
            }
          }
        })
      }
    } catch (e) {
      console.log('Caught login error', e)
    }
    setLoading(false)
  }

  const handleSignup = async () => {
    try {
      const response = await client.mutate({
        mutation: signup,
        variables: {
          input: {
            email,
            password
          }
        }
      })

      const errors = _.get(response, 'data.customerCreate.userErrors')
      const customer = _.get(response, 'data.customerCreate.customer')
      if (_.isEmpty(errors)) {
        if (customer) {
          await handleLogin()
        }
      } else {
        const msg = errors.reduce((nxt, err) => {
          return err.message + ' ' + nxt
        }, '')
        dispatch({
          type: 'ADD_TOAST',
          data: {
            message: {
              message: msg,
              type: 'Error'
            }
          }
        })
      }
    } catch (e) {
      console.log('Caught Signup error', e)
    }
    setLoading(false)
  }

  const submit = () => {
    setLoading(true)
    if (mode === 'login') {
      if (email && password) {
        handleLogin()
      }
    }

    if (mode === 'signup') {
      if (email && password) {
        handleSignup()
      }
    }
  }

  const updateValue = (key, value) => {
    if (key === 'email') {
      setEmail(value)
    }
    if (key === 'password') {
      setPassword(value)
    }
    if (key === 'confirmPassword') {
      setConfirmPassword(value)
    }
  }

  const renderSignup = () => {
    return (
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Animatable.View style={{ flex: 1 }} ref={signupView}>
          <SignupForm
            disabled={!email || !password || !confirmPassword}
            password={password}
            theme={theme}
            toggleMode={toggleMode}
            onSubmit={submit}
            onUpdateValue={e => updateValue(e.key, e.value)}
            loading={loading}
          />
        </Animatable.View>
      </View>
    )
  }

  const renderLogin = () => {
    return (
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Animatable.View style={{ flex: 1 }} ref={loginView}>
          <LoginForm
            disabled={!email || !password}
            theme={theme}
            toggleMode={toggleMode}
            onSubmit={submit}
            onUpdateValue={e => updateValue(e.key, e.value)}
            loading={loading}
          />
        </Animatable.View>
      </View>
    )
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'transparent' }}>
      <Animated.View
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          backgroundColor: '#000',
          opacity: fadeAnim
        }}
      />
      <View
        style={{ ...styles.holder, backgroundColor: theme.contentBackground }}
      >
        <TouchableOpacity style={styles.closeButton} onPress={handleDismiss}>
          <Icon color={theme.contentText} name='x' size={16} />
        </TouchableOpacity>
        <KeyboardAwareScrollView
          enableOnAndroid
          style={{ flex: 1 }}
          contentContainerStyle={{ flex: 1 }}
        >
          {mode === 'login' && renderLogin()}
          {mode === 'signup' && renderSignup()}
        </KeyboardAwareScrollView>
      </View>
    </View>
  )
}

const styles = {
  holder: {
    flex: 1,
    margin: 20,
    marginVertical: height / 9,
    borderRadius: 8,
    padding: 20
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    zIndex: 999
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.page')
  }
}

export default connect(mapStateToProps)(LoginSignUp)
