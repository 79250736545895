import React from 'react'
import { connect } from 'react-redux'
import { View, TouchableOpacity } from 'react-native'
import Text from '@components/Text'
import _ from 'lodash'

const AnnouncementBar = ({ config = {}, theme, typography, navigation }) => {
  const { route, handle, body, buttonText } = config
  const {
    fontFamily,
    buttonFontFamily,
    capitalizeButtonLetters,
    capitalizeLetters
  } = typography

  const handleNavigation = () => {
    if (route) {
      navigation.navigate(route, { handle })
    }
  }

  return (
    !_.isEmpty(body) && (
      <View
        style={{ ...styles.container, backgroundColor: theme.backgroundColor }}
      >
        <View style={styles.innerContainer}>
          <Text
            type='custom'
            capitalizeLetters={capitalizeLetters}
            customType={fontFamily}
            style={{ flex: 1 }}
            fontSize={16}
            color={theme.textColor}
          >
            {body}
          </Text>
          {route && !_.isEmpty(buttonText) && (
            <View style={{ flex: 0.5 }}>
              <TouchableOpacity
                onPress={handleNavigation}
                style={{
                  ...styles.button,
                  backgroundColor: theme.buttonColor
                }}
              >
                <Text
                  type='custom'
                  bold
                  style={{ flex: 1 }}
                  color={theme.buttonTextColor}
                  numberOfLines={1}
                  capitalizeLetters={capitalizeButtonLetters}
                  customType={buttonFontFamily}
                >
                  {buttonText}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    )
  )
}

const styles = {
  container: { padding: 10, paddingBottom: 5, paddingTop: 5 },
  innerContainer: {
    padding: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  button: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    alignItems: 'center'
  }
}

const mapStateToProps = state => {
  return {
    theme: _.get(state, 'config.theme.colors.announcementBar', {}),
    typography: _.get(state, 'config.theme.typography.announcementBar', {})
  }
}

export default connect(mapStateToProps)(AnnouncementBar)
