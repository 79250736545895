import React from 'react'
import { ActivityIndicator, View, TouchableOpacity } from 'react-native'
import { Mutation } from 'react-apollo'
import Text from '@components/Text'
import Icon from '@components/Icon'
import Swipeable from '@components/Swipeable'
import AddressCard from '@components/AddressCard'
import { scale } from '@utils/scale'
import _ from 'lodash'

const AddressCardSwipeable = ({
  address,
  customerAccessToken,
  dispatch,
  mutation,
  theme,
  onPress,
  onCaretPress
}) => {
  const removeItem = async mutation => {
    const { data } = await mutation({
      variables: {
        id: _.get(address, 'node.id'),
        customerAccessToken
      }
    })
    if (_.get(data, 'customerAddressDelete.deletedCustomerAddressId')) {
      dispatch({
        type: 'REMOVE_ADDRESS',
        data: {
          id: _.get(address, 'node.id')
        }
      })
    }
  }

  const rightButtons = [
    <View style={styles.swipeButtonHolder} key='button-0'>
      <Mutation mutation={mutation}>
        {(mutation, { loading }) => {
          return (
            <TouchableOpacity
              style={{
                ...styles.swipeButtonBase,
                backgroundColor: '#D52727'
              }}
              disabled={loading}
              onPress={() => {
                removeItem(mutation)
              }}
            >
              {loading ? (
                <ActivityIndicator size='small' color='#FFF' />
              ) : (
                <Icon name='x' size={18} color='white' />
              )}
            </TouchableOpacity>
          )
        }}
      </Mutation>
      <Text fontSize={12}>DELETE</Text>
    </View>
  ]

  return (
    <Swipeable rightButtonWidth={90} rightButtons={rightButtons}>
      <AddressCard
        padded
        onPress={address => {
          onPress && onPress(address)
        }}
        address={address.node}
        theme={theme}
        onCaretPress={address => onCaretPress && onCaretPress(address)}
      />
    </Swipeable>
  )
}

const styles = {
  swipeButtonHolder: {
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  swipeButtonBase: {
    width: scale(28),
    height: scale(28),
    borderRadius: scale(14),
    padding: scale(5),
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 5
  }
}

export default AddressCardSwipeable
