import React from 'react'
import { Image, View } from 'react-native'
import _ from 'lodash'
import Text from '@components/Text'
import TouchableExpand from '@components/TouchableExpand'
import { width } from '@utils/scale'

const SplitCollectionItem = ({ itemPressed, item }) => {
  const handleOnPress = handle => {
    if (handle && _.isFunction(itemPressed)) {
      itemPressed(handle)
    }
  }

  let fallbackImg = _.get(item, 'products.edges[0].node.images.edges', [])
  fallbackImg = _.get(_.first(fallbackImg), 'node.transformedSrc')

  const img = _.get(item, 'image.transformedSrc') || fallbackImg

  return (
    <TouchableExpand
      style={styles.holder}
      onPress={() => handleOnPress(item.handle)}
    >
      <View style={styles.image}>
        {item && (
          <Image
            style={styles.image}
            source={{ uri: img }}
            resizeMode='cover'
          />
        )}
      </View>
      <View style={{ alignItems: 'center' }}>
        <Text fontSize={16} style={styles.title}>
          {item.title}
        </Text>
      </View>
    </TouchableExpand>
  )
}

const styles = {
  holder: {
    width: width / 2,
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  image: {
    width: width / 2.4,
    height: width / 2.2,
    backgroundColor: '#ebebeb',
    resizeMode: 'cover'
  },
  title: {
    fontWeight: '600',
    marginTop: 5,
    marginBottom: 2,
    letterSpacing: 0.5
  }
}

export default SplitCollectionItem
